import { GlClinicActiveRecord } from "models/clinic-active-records.model";
import { PatientDocument } from "models/patient-document.model";
import {
  GlPatientRecordType,
  PatientRecord,
} from "models/patient-record.model";
import { IGlPatientData, User } from "models/user.model";
import {
  DicomTagHelper,
  DICOM_MODALITY_DISC,
  DICOM_MODALITY_OPHTHALMIC_PHOTO,
  DICOM_TAG_DOCUMENT_TITLE,
  DICOM_TAG_ID_IMAGE_LATERALITY,
  DICOM_TAG_ID_LATERALITY,
  DICOM_TAG_ID_MODAILTY as DICOM_TAG_ID_MODALITY,
  DICOM_TAG_ID_SOP_INSTANCE_UID,
  DICOM_TAG_MANUFACTURER_MODEL_NAME,
} from "../../../../core/services/dicom-tag-helper/dicom-tag-helper";
import {
  DocumentsService,
  GlDocumentType,
  GL_DOCUMENT_A_SCAN,
  GL_DOCUMENT_DISC_LEFT,
  GL_DOCUMENT_DISC_RIGHT,
  GL_DOCUMENT_FIELD_LEFT,
  GL_DOCUMENT_FIELD_RIGHT,
  GL_DOCUMENT_OCT_GCC,
  GL_DOCUMENT_OCT_MAC,
  GL_DOCUMENT_OCT_RNFL,
} from "../../../../core/services/documents-service/documents.service";
import {
  IOrthancApiServiceConfig,
  OrthancApiService,
} from "../../../../core/services/orthanc-api/orthanc-api.service";
import {
  IOrthancDicomTags,
  IOrthancPatient,
} from "../../../../core/services/orthanc-api/orthanc.models";
import { PatientDocumentService } from "../../../../core/services/patient-document.service";

export interface IDocumentImportUpload {
  progress: number;
}

export interface IDocumentImportError {
  patient: User;
  errorDescription: string;
}

interface IOrthancInstanceWithTags {
  id: string;
  tags: IOrthancDicomTags;
}

type OrthancDocumentMatchResults = Partial<
  Record<GlDocumentType, IOrthancInstanceWithTags[]>
>;

export interface IDocumentImportMatchUpload {
  documentType: GlDocumentType;
  record: PatientRecord;
  orthancDocumentId: string;
  orthancDocumentTags: IOrthancDicomTags;
  existingPatientDocumentToDelete?: PatientDocument;
}

export interface IDocumentImportMatchError {
  documentType: GlDocumentType;
  record: PatientRecord;
  orthancMatches: IOrthancInstanceWithTags[];
}

export interface IDocumentUploadPatientMatch {
  uploads: IDocumentImportMatchUpload[];
  errors: IDocumentImportMatchError[];
}

export class DocumentImportService {
  static injectionName = "DocumentImportService";

  constructor(
    private OrthancApiService: OrthancApiService,
    private PatientDocumentService: PatientDocumentService,
    private DocumentsService: DocumentsService,
    private $q: angular.IQService,
    private DicomTagHelper: DicomTagHelper
  ) {
    "ngInject";
  }

  setConfig(config: Partial<IOrthancApiServiceConfig>) {
    this.OrthancApiService.setConfig(config);
  }

  /**
   * @description this function removes the patients from the Orthanc server
   * that are included in the active records array. This ensures that when
   * documents are imported from Forum, only the most up to dat documents are
   * imported. Any any documents previously imported from Forum that have been
   * subsequently removed are also removed from Orthanc as well
   * @param activeRecords
   */
  deletePatientsFromOrthanc(activeRecords?: GlClinicActiveRecord[]) {
    return this.OrthancApiService.patientsList(true).then((patients) => {
      const orthancPatientsToDelete = activeRecords
        ? this.patientsToDeleteFromOrthanc(activeRecords, patients)
        : patients;
      return this.$q.all(
        orthancPatientsToDelete.map((p) =>
          this.OrthancApiService.patientDelete(p.ID)
        )
      );
    });
  }

  importPatientDocumentsFromForum(patientId: string) {
    // to void issues importing patient from Forum, make sure the patient number
    // is 5 digits, 0 padded string.
    const paddedPatientId = patientId.padStart(5, "0");
    /*
      old version pre-fetches from forum then
      the gateway
    */
    // return this.OrthancApiService.queryRemoteDicomForGlDocuments(
    //   paddedPatientId
    // )
    //   .then((query) => this.OrthancApiService.queryRetrieve(query.Path))
    //   .then(() => this.OrthancApiService.patientGetInstanceIds(paddedPatientId))

    /*
      new version bypasses forum since data is all in gateway
    */
    return this.OrthancApiService.patientGetInstanceIds(paddedPatientId)
      .then((instanceIds) =>
        this.$q.all(
          instanceIds.map((id) =>
            this.OrthancApiService.instanceGetTags(id).then((tags) => ({
              id,
              tags,
            }))
          )
        )
      )
      .then((instances) => {
        return this.sortOrthancDocsIntoKnownGlDocuments(instances);
      });
  }

  automaticallyUploadDocumentsForRecord(
    record: PatientRecord,
    orthancDocuments: OrthancDocumentMatchResults
  ): IDocumentUploadPatientMatch {
    const knownDocumentsForRecord = this.DocumentsService.getNamedDocuments(
      record.documents
    );

    const result: IDocumentUploadPatientMatch = {
      uploads: [],
      errors: [],
    };

    this.getAutomaticallyManagedDocumentList(record).forEach((docType) => {
      const possibleOrthancDocs = orthancDocuments[docType];
      const orthancDoc =
        possibleOrthancDocs &&
        possibleOrthancDocs.length === 1 &&
        possibleOrthancDocs[0];
      const orthancDocId =
        orthancDoc &&
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_SOP_INSTANCE_UID,
          orthancDoc.tags
        );
      const existingDoc = knownDocumentsForRecord[docType] as PatientDocument;
      const existingDicomId =
        existingDoc?.dicom_data &&
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_SOP_INSTANCE_UID,
          existingDoc.dicom_data
        );
      if (
        orthancDoc &&
        possibleOrthancDocs.length === 1 &&
        orthancDocId !== existingDicomId
      ) {
        result.uploads.push({
          record,
          documentType: docType,
          orthancDocumentId: orthancDoc.id,
          orthancDocumentTags: orthancDoc.tags,
          // If there is an existing automatically uploaded Dicom Image and a
          // new one to replace it, include details on the existing document
          // that needs to be delete prior to uploading the new one
          ...(existingDicomId && {
            existingPatientDocumentToDelete: existingDoc,
          }),
        });
      } else if (possibleOrthancDocs && possibleOrthancDocs.length > 1) {
        // this is an error. Add it to the errors array
        result.errors.push({
          record,
          documentType: docType,
          orthancMatches: possibleOrthancDocs,
        });
      }
    });
    return result;
  }

  patientsToCheckForDocuments(activeRecords: GlClinicActiveRecord[]) {
    const validRecordTypes: GlPatientRecordType[] = [
      "patient_record",
      "tech_record",
    ];
    return activeRecords.filter(
      (ar) =>
        ar.record.data_status === "IN_PROGRESS" &&
        validRecordTypes.includes(ar.record.type)
    );
  }

  private patientsToDeleteFromOrthanc(
    glPatientsToCheck: GlClinicActiveRecord[],
    orthancPatientList: IOrthancPatient[]
  ) {
    return orthancPatientList.filter((p) =>
      glPatientsToCheck.some(
        // Both Glauconet & Zeiss Forum use the Mediwiz patient id stored as a 0
        // padded string as the file number. To avoid problems with the zero
        // padding, convert these strings to integers and compare the patients
        // based on the integer patient number
        (ar) =>
          +(ar.patient.data as IGlPatientData).file_no ===
          +p.MainDicomTags.PatientID
      )
    );
  }

  /**
   * Work out which Forum documents to import. We only import Forum documents
   * that haven't already been uploaded into Glauconet previously
   * @param activeRecords
   * @param forumQueryResponse
   */
  private getDicomDocumentIdsAlreadyImported(record: PatientRecord) {
    // get all the dicom document IDs from the documents list
    // only include docuents with dicom data
    const documents = record?.documents || [];
    return documents
      .filter((d) => d.dicom_data)
      .map((d) => {
        return this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_SOP_INSTANCE_UID,
          d.dicom_data
        );
      });
  }

  private getAutomaticallyManagedDocumentList(record: PatientRecord) {
    const allDocuments: GlDocumentType[] = [
      GL_DOCUMENT_A_SCAN,
      GL_DOCUMENT_DISC_LEFT,
      GL_DOCUMENT_DISC_RIGHT,
      GL_DOCUMENT_FIELD_LEFT,
      GL_DOCUMENT_FIELD_RIGHT,
      GL_DOCUMENT_OCT_GCC,
      GL_DOCUMENT_OCT_MAC,
      GL_DOCUMENT_OCT_RNFL,
    ];
    // remove any document type that has a manually uploaded document
    const knownUploadedDocuments = this.DocumentsService.getNamedDocuments(
      record.documents
    );

    // automatically managed documents are those document types which either
    // haven't been uploaded yet or do not have a manually uploaded document
    // (which doesn't have dicom tags)
    return allDocuments.filter((docType) => {
      const knownDocument = knownUploadedDocuments[docType] as PatientDocument;
      return !(knownDocument && !knownDocument.dicom_data);
    });
  }

  private sortOrthancDocsIntoKnownGlDocuments(
    orthancDocs: IOrthancInstanceWithTags[]
  ) {
    const documentObj: OrthancDocumentMatchResults = {};

    orthancDocs.forEach((doc) => {
      const modality = this.DicomTagHelper.getValueForDicomId(
        DICOM_TAG_ID_MODALITY,
        doc.tags
      );
      const side =
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_LATERALITY,
          doc.tags
        ) ||
        this.DicomTagHelper.getValueForDicomId(
          DICOM_TAG_ID_IMAGE_LATERALITY,
          doc.tags
        );
      const documentTitle = this.DicomTagHelper.getValueForDicomId(
        DICOM_TAG_DOCUMENT_TITLE,
        doc.tags
      );
      const glDocumentType =
        this.glDocumentType(modality, side, documentTitle, doc.tags) ||
        this.matchFundusImage(doc.tags);

      if (glDocumentType) {
        const docs = documentObj[glDocumentType] || [];
        docs.push(doc);
        documentObj[glDocumentType] = docs;
      }
    });
    return documentObj;
  }

  private glDocumentType(
    modality: string,
    side: string,
    documentTitle: string,
    document: IOrthancDicomTags
  ): GlDocumentType {
    let isDisc = false;
    if (
      [DICOM_MODALITY_DISC, DICOM_MODALITY_OPHTHALMIC_PHOTO].includes(modality)
    ) {
      const tags = JSON.stringify(document);
      // work out if it is a DISC or "central" photo
      isDisc = tags.includes("OPTIC_DISK") || tags.includes("OpticDisc");
    }
    if (isDisc) {
      return side === "L" ? GL_DOCUMENT_DISC_LEFT : GL_DOCUMENT_DISC_RIGHT;
    } else if (documentTitle?.includes("SFA")) {
      return side === "L" ? GL_DOCUMENT_FIELD_LEFT : GL_DOCUMENT_FIELD_RIGHT;
    } else if (documentTitle === "IOLMaster 700 Report") {
      return GL_DOCUMENT_A_SCAN;
    } else if (documentTitle === "Cirrus_OU_ONH and RNFL OU Analysis") {
      return GL_DOCUMENT_OCT_RNFL;
    } else if (documentTitle === "OU Macular Thickness OU Analysis") {
      return GL_DOCUMENT_OCT_MAC;
    } else if (documentTitle === "OU Ganglion Cell OU Analysis") {
      return GL_DOCUMENT_OCT_GCC;
    }
  }

  private matchFundusImage(document: IOrthancDicomTags) {
    const machine = this.DicomTagHelper.getValueForDicomId(
      DICOM_TAG_MANUFACTURER_MODEL_NAME,
      document
    ) as string;
    const modality = this.DicomTagHelper.getValueForDicomId(
      DICOM_TAG_ID_MODALITY,
      document
    );
    const side = this.DicomTagHelper.getValueForDicomId(
      DICOM_TAG_ID_LATERALITY,
      document
    );

    const tags = JSON.stringify(document);
    // work out if it is a DISC or "central" photo
    const isDisc = tags.includes("OPTIC_DISK") || tags.includes("OpticDisc");

    if (
      machine?.toLowerCase() === "CIRRUS photo 800".toLowerCase() &&
      modality === DICOM_MODALITY_OPHTHALMIC_PHOTO &&
      isDisc
    ) {
      // work out if it is a DISC or "central" photo
      return side === "L" ? GL_DOCUMENT_DISC_LEFT : GL_DOCUMENT_DISC_RIGHT;
    }
  }
}
