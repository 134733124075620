import * as cleanDeep from "clean-deep";
import { get, isEmpty, isNil, pick } from "lodash";
import { PatientRecordData } from "../../../models/patient-record.model";

export type Segments = keyof ISegmentFields;
export type SegmentsDashboard = keyof ISegmentDashboard;

export interface ISegmentFields {
  notes: string[];
  info: string[];
  providers: string[];
  medicalHistory: string[];
  patientUploads: string[];
  whatToDo: string[];
  lensStatus: string[];
  clinicalData: string[];
  documents: string[];
  todaysDrops: string[];
  dryEye: string[];
  anterior: string[];
  vaccineStatus: string[];
  gonio: string[];
  general: string[];
  posterior: string[];
  field: string[];
  letters: string[];
  drops: string[];
  externalProcedures: string[];
  inHouseProcedures: string[];
  management: string[];
  dayProcedures: string[];
  drugs: string[];
  prescriptions: string[];
  postDilationIop: string[];
}

export interface ISegmentDashboard {
  // incoming patients
  pending: string[],
  // current records
  inProgress: string[],
  // document uploads
  documentUploads: string[];
  // signed records
  signed: string[],
  // ophthal only
  virtualReviews: string[],
  // admin and tech only
  importPatients: string[],
  patientsNeedReview: string[];
}

const SEGMENT_FIELDS_MANAGEMENT = [
  // cataract
  "management.cataract_pre_op",
  "management.cataract_aim",
  "management.cataract_lens",
  "management.cataract_migs",
  "management.cataract_injectables",
  "management.cataract_steroid",
  "management.cataract_anaesthetic",
  "management.cataract_operation",
  "management.cataract_dry_eye",
  "management.cataract_post_op",

  // diagnosis
  "management.diagnosis",
  "management.diagnosis_array",
  // glaucoma
  "management.glaucoma_risk",
  "management.target_iop",
  "management.diagnosis_progression",
  "management.what",
];

export const SEGMENT_FIELD_CLINICAL_DATA = [
  "va_numerator",
  "va_denominator",
  "aided_va_numerator",
  "aided_va_denominator",
  "subjective_va_numerator",
  "subjective_va_denominator",
  "iop",
  "max_iop",
  "refraction",
  "refraction.status",
  "refraction.sphere",
  "refraction.cylinder",
  "refraction.axis",
  "lens_status",
  "cct",
  "ph_numerator",
  "ph_denominator",
  "lens_notes",
  "technician_notes",
  "lens_status_other",
];

const SEGMENT_FIELDS_ANTERIOR = [
  "blepharitis",
  "blepharitis_other",
  "conj",
  "cornea",
  "cornea_other",
  "ac",
  "acQuantifier",
  "ac_other",
  "acQuantifier_other",
  "iris",
  "iris_other",
  "pi",
];

const SEGMENT_FIELDS_GONIO = [
  "gonioSuperior",
  "gonioTemporal",
  "gonioNasal",
  "gonioInferior",
  "gonioComment",
  "gonioCommentFree",
  "gonioFinding",
  "gonioFindingFree",
  "gonio_date",
  "repeat",
];

const SEGMENT_FIELDS_POSTERIOR = [
  "lens.status",
  "lens.observations",
  "vit",
  "vit_other",
  "macular",
  "macular_other",
  "periphery",
  "periphery_other",
  "other_findings",
  "cdr",
  "disc",
  "disc_other",
  "oct",
  "oct_other",
];

const SEGMENT_FIELDS_FIELD = [
  "vfi_reliable",
  "vfi_reliable_other",
  "vfi_status",
  "vfi_status_other",
  "vfi",
  "vfi_comment",
];

const SEGMENT_FIELDS_DRY_EYE = [
  "dry_eye_date",
  "osdi",
  "schirmer",
  "tear_meniscus_height",
  "nitbut",
  "tbut",
  "lissamine",
  "lissamine_other",
  "blepharitis",
  "blepharitis_other",
  "meibography",
  "meibography_other",
  "meibum",
  "conj",
  "cornea",
];

const SEGMENT_FIELDS_VISIT_DROPS = ["visit_drops"];

const SEGMENT_FIELDS_MEDICAL_HISTORY = [
  "medical_history",
  "has_family_history",
  "has_family_history_eye_disease",
  "medications_list",
  "family_history",
  "family_history_eye_disease",
  "ocular_history",
  "reason_for_referral",
];

const SEGMENT_FIELDS_PROVIDERS = [
  "providers.gp",
  "providers.optometrist",
  "providers.referrer, providers.referrerDetails",
];

const SEGMENT_FIELDS_POST_DILATION_IOP = ["visit_drops"];

const SEGMENT_FIELDS: Partial<ISegmentFields> = {
  management: SEGMENT_FIELDS_MANAGEMENT,
  clinicalData: SEGMENT_FIELD_CLINICAL_DATA,
  anterior: SEGMENT_FIELDS_ANTERIOR,
  posterior: SEGMENT_FIELDS_POSTERIOR,
  field: SEGMENT_FIELDS_FIELD,
  medicalHistory: SEGMENT_FIELDS_MEDICAL_HISTORY,
  todaysDrops: SEGMENT_FIELDS_VISIT_DROPS,
  providers: SEGMENT_FIELDS_PROVIDERS,
  gonio: SEGMENT_FIELDS_GONIO,
  dryEye: SEGMENT_FIELDS_DRY_EYE,
  postDilationIop: SEGMENT_FIELDS_POST_DILATION_IOP,
};

export class SegmentHelperService {
  static injectionName = "SegmentHelperService";
  constructor() {
    "ngInject";
  }

  getSegmentList() {
    return Object.keys(SEGMENT_FIELDS);
  }

  recordHasSegment(recordData: PatientRecordData, segment: string) {
    const fieldList: string[] = SEGMENT_FIELDS[segment] || [];
    // create a copy of the record data that has no empty values;
    const cleanedRecordData = cleanDeep(recordData);
    return fieldList.some((fieldName) => {
      const val = get(cleanedRecordData, fieldName);
      return !isNil(val);
    });
  }

  hasFields(recordData: PatientRecordData, fields: string[]) {
    const dataFields = pick(recordData, fields);
    return !isEmpty(cleanDeep(dataFields));
  }
}
