// just a centralised way to maintain consistency on keys

// MAC OCT V2
export const MAC_OCT_LEGACY_KEY: string = "oct_mac";
export const MAC_OCT_V2_KEY: string = "oct_mac_v2";

// MAcULAR
export const MACULAR_LEGACY_KEY: string = "macular";
export const MACULAR_V2_KEY: string = "macular_v2";

// POSTERIOR LENS
export const LENS_OBSERVATIONS_KEY: string = "lens.observations";
export const LENS_STATUS_KEY: string = "lens.status";

export const DIAGNOSIS_ARRAY_KEY: string = "diagnosis_array";

export const EXTERNAL_PROCEDURES_KEY: string = "external_procedures";
