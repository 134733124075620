import { AuthService } from "app/core/services/auth.service";
import { isNil } from "lodash";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { GlStaff, Patient, User } from "../../../../../models/user.model";
import "./button-record-save-sign.scss";

const MCES_CLINIC_ID = 50;
const SUNBURY_CLINIC_ID = 2;
export class ButtonRecordSaveSignController implements angular.IController {
  // @Input()
  currentState: string;
  disableSave: boolean = true;
  disableSign = false;
  record: PatientRecord;
  recordType: "history" | "record" = "record";
  user: User;
  patient: Patient;

  // based off current user clinic
  clinicProviders: GlStaff[];

  // who to proxy as?
  proxyUser: GlStaff;

  signInProgress: boolean = false;

  // button functions
  reopenRecord: () => void;
  signRecord: (args?: { skipOphthalReview: boolean, proxyUser?: GlStaff; }) => void;
  save: () => void;

  constructor(
    private AuthService: AuthService,
    private $uibModal: angular.ui.bootstrap.IModalService,
  ) {
    "ngInject";
  }

  signRecordAndSkipOphthalReview() {
    this.signRecord({ skipOphthalReview: true });
  }

  ifSigned() {
    return !!this.record && this.record.data_signed_by_id;
  }

  userIsOptometrist() {
    return !!(this.user.type?.name === "optometrist");
  }

  // anyone can sign it but 
  canSign() {
    return (
      (this.user.type.name === "optometrist" &&
        this.record?.type !== "virtual_review") ||
      this.user.type?.name === "ophthalmologist" || // this also works for consent form
      this.recordType === "history" ||
      (this.record?.type === "tech_record" &&
        this.user.type?.name === "technician")
    );
  }

  // these users can proxy sign on behalf of someone
  canProxySign() {
    // check if the record can be proxy signed
    // then the user

    // for now its only technicians with their personal accounts
    // we forbid admins from doing so
    return this.user.type.name === 'technician';
  }

  fromSameClinic() {
    return (
      !this.record?.clinic || this.record?.clinic?.id === this.user?.clinic.id
    );
  }

  canReOpen() {
    // if any of the predicates are true
    return !!(
      // ophthals can re-open anything
      this.user?.type?.name === "ophthalmologist" ||
      // optoms can do that if not a VR 
      (this.user?.type?.name === "optometrist" &&
        this.record?.type !== "virtual_review") ||
      // history records can be reopened
      this.recordType === "history" ||
      // // technicians and tech records
      // (this?.record?.type === "tech_record" &&
      //   this.user?.type?.name === "technician") ||

      // allow technicians to re-open all record types BUT limit them to proxy sign only
      // the blame will be on the proxy signed ID
      this?.user?.type?.name === "technician"
    );
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record?.data_status === "SIGNED";
  }

  isWhiteListed() {
    // Simply allow optoms at MCES & Sunbury to send patients to other team members.
    if (this.user) {
      return [MCES_CLINIC_ID, SUNBURY_CLINIC_ID].includes(this.user.clinic_id);
    }
  }

  // event propagation
  handleEvent(event: Event) {
    event.stopPropagation();
  }

  /** MODAL VERSION */
  openProxySignModal() {
    this.$uibModal
      .open({
        component: 'proxySign',
        resolve: {
          // current user
          currentUser: () => this.user,
          // proxy users
          proxyUser: () => this.proxyUser,
          patient: () => this.patient,
          clinicProviders: () => this.clinicProviders,

          // sign in progress?
          signInProgress: () => this.signInProgress,
          disableSign: () => this.disableSign
        }
      })
      .result.then(({ proxyUser, skipOphthalReview }) => {
        // send down and if proxy user is defined, send to signRecord
        if (!isNil(proxyUser)) {
          this.signRecord({ proxyUser, skipOphthalReview });
        }
      })
      .catch(() => {
        // surpress error for closing modal
      });
  }
}

export class ButtonRecordSaveSignComponent
  implements angular.IComponentOptions {
  static selector = "buttonRecordSaveSign";
  static template = require("./button-record-save-sign.html");
  static controller = ButtonRecordSaveSignController;
  static bindings = {
    completeRecord: "&",
    disableSave: "<?",
    disableSign: "<?",
    record: "<",
    recordType: "<?",
    reopenInProgress: "<",
    reopenRecord: "&",
    save: "&",
    saveInProgress: "<",
    signInProgress: "<",
    signRecord: "&",
    user: "<",
    patient: "<",
    clinicProviders: "<",
  };
}
