import { GlToastrCategory } from "../toastr-appendix";

export const PRESCRIPTION_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    save: {
      no_drugs: "Prescriptions must include at least one drug",
    },
    create: {
      selection: "Error creating prescriptions from all, please try again.",
      all: "Error creating prescriptions from selection, please try again.",
      single: "Error creating prescription, please try again.",
    },
    print: {
      selection: "",
      all: "",
    },
  },
  info: {},
};
