import { IGlOption } from "app/core/services/appendix";
import { ChangesService } from "app/core/services/changes/changes.service";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { cloneDeep, get, isNil, set } from "lodash";
import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlLensObservation,
  PatientRecordData,
} from "models/patient-record.model";
import { v4 as uuidv4 } from "uuid";
import {
  LENS_OBSERVATIONS_KEY,
  LENS_STATUS_KEY,
} from "../../../../../lib/key-appendix";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import angular = require("angular");
import { AutofillService } from "app/core/services/autofill/autofill.service";

// wrapper for posteriror to accomodate for state logic
export class BilateralPosteriorLensController
  extends GlFormController
  implements angular.IComponentController
{
  PARENT_KEY: string = "lens";
  PARENT_KEY_APPENDIX: string = "lensPhakic";

  record: PatientRecordData;
  recordId: number = this.$stateParams.recordId;
  patientId: number = this.$stateParams.patientId;
  enableRight: boolean = false;
  enableLeft: boolean = false;

  // attributes for posterior lens specifically
  lensAttributes: string[] = this.PatientRecordService.getLensAttributes();

  bilateralPosteriorLensForm: angular.IFormController;

  // refs for the L/R controllers
  rightPosteriorLensRef: HTMLElement;
  leftPosteriorLensRef: HTMLElement;

  constructor(
    private PatientRecordService: PatientRecordService,
    private ToastrAppendix: ToastrAppendix,
    private $stateParams: angular.ui.IStateParamsService,
    private ChangesService: ChangesService,
    private AutofillService: AutofillService
  ) {
    "ngInject";
    super();
  }

  // copy button
  handleOnCopy(side: IGlSideBilateral, previousRecordData: PatientRecordData) {
    // status
    const currentStatus: IGlOption = get(this.record, `lens.status.${side}`);
    const currentObservations: GlLensObservation[] =
      get(this.record, `lens.observations.${side}`) ?? [];

    // ENACT A CHANGE
    this.formDidChange();

    // CHECK PREVIOUS DATA FIRST
    // do a check if the previous status or any observations has any existing
    // autofill and remove
    const previousStatus: IGlOption = get(
      previousRecordData,
      `lens.status.${side}`
    );
    const previousObservations: GlLensObservation[] =
      get(previousRecordData, `lens.observations.${side}`) ?? [];

    // 1. undo any observation autofills first
    // handle these in reverse order
    this._undoAutofillForObservations({
      observations: previousObservations,
      side,
    });

    // 2. then handle status autofill undos
    this._undoAutofillStatus(previousStatus, side);
    // for status its more straightforward

    // 3a. clone data and set ids for status
    const newStatus: IGlOption = cloneDeep(currentStatus);
    if (!isNil(newStatus)) {
      set(newStatus, "id", this.generateOptionId());
    }

    // and observations
    const newObservations: GlLensObservation[] = cloneDeep(
      currentObservations ?? []
    );
    newObservations.forEach((obs) => {
      if (!isNil(obs?.type)) {
        set(obs, "type.id", this.generateOptionId());
      }
    });

    // 3b. set data
    set(this.record, `${LENS_STATUS_KEY}.${side}`, newStatus);
    set(this.record, `${LENS_OBSERVATIONS_KEY}.${side}`, newObservations);

    // 4. then after that execute autofill for both status and observations
    // set data
    this._handleTriggerAutofillStatus(newStatus, previousStatus, side);

    // 4a. then for all observations in case
    this._handleTriggerAutofillObservations(
      newObservations,
      previousObservations,
      side
    );
  }

  // set form dirty
  formDidChange() {
    this.bilateralPosteriorLensForm.$setDirty();
  }

  generateOptionId() {
    return uuidv4();
  }

  /* UNDO SIDE OF THINGS */
  // do for all
  private _undoAutofillForObservations({
    observations = [],
    side,
  }: {
    observations?: GlLensObservation[];
    side: IGlSideBilateral;
  }) {
    // * important to reverse both sides if anything to ensure theres no issues
    const observationsToUndo = observations?.reverse();
    // convert the observations indo undo change obejcts
    const changesObjects: IGlChangesRecordDataOrigin[] =
      observationsToUndo?.map((obs, idx) => {
        return {
          currentValue: obs?.type,
          side: side,
          sourceKey: LENS_OBSERVATIONS_KEY,
          sourcePath: "",
          recordData: this.record,
          type: "autofill_undo",
          id: obs?.type?.id,
          origin: "source",
          index: idx,

          originKey: LENS_OBSERVATIONS_KEY,
          originPath: this.AutofillService.getAutofillPath(
            LENS_OBSERVATIONS_KEY
          ),
          extraData: {
            recordId: this.recordId,
            patientId: this.patientId,
          },
        };
      });

    // then send them
    for (const change of changesObjects ?? []) {
      this.ChangesService.publish(change);
    }
  }

  private _undoAutofillStatus(status: IGlOption, side: IGlSideBilateral) {
    // create change object
    const changeObj: IGlChangesRecordDataOrigin = {
      currentValue: status,
      side,
      sourceKey: LENS_STATUS_KEY,
      sourcePath: "",
      recordData: this.record,
      type: "autofill_undo",
      id: status?.id,
      origin: "source",

      originKey: LENS_STATUS_KEY,
      originPath: this.AutofillService.getAutofillPath(LENS_STATUS_KEY),
      extraData: {
        recordId: this.recordId,
        patientId: this.patientId,
      },
    };

    // publish
    this.ChangesService.publish(changeObj);
  }

  /* CONFIRM SIDE OF THINGS */
  private _handleTriggerAutofillStatus(
    status: IGlOption,
    previousStatus: IGlOption,
    side: IGlSideBilateral
  ) {
    // create change obj and publish
    const changesObj: IGlChangesRecordData = {
      currentValue: status,
      previousValue: previousStatus,
      side,
      sourceKey: LENS_STATUS_KEY,
      recordData: this.record,
      type: "copy",
      id: status?.id,

      originKey: LENS_STATUS_KEY,
      originPath: this.AutofillService.getAutofillPath(LENS_STATUS_KEY),
      extraData: {
        recordId: this.recordId,
        patientId: this.patientId,
      },
    };
    this.ChangesService.publish(changesObj);
  }

  private _handleTriggerAutofillObservations(
    currentObservations: GlLensObservation[],
    previousObservations: GlLensObservation[],
    side: IGlSideBilateral
  ) {
    // for each of them just publish
    (currentObservations ?? [])?.forEach((currObs, idx) => {
      const changesObj: IGlChangesRecordData = {
        currentValue: currObs?.type,
        previousValue: get(previousObservations, `${side}.${idx}`),
        side,
        sourceKey: LENS_OBSERVATIONS_KEY,
        recordData: this.record,
        type: "copy",
        id: currObs?.type?.id,

        originKey: LENS_OBSERVATIONS_KEY,
        originPath: this.AutofillService.getAutofillPath(LENS_OBSERVATIONS_KEY),
        extraData: {
          recordId: this.recordId,
          patientId: this.patientId,
        },
      };

      this.ChangesService.publish(changesObj);
    });
  }
}

export class BilateralPosteriorLens {
  static selector = "bilateralPosteriorLens";
  static template = require("./bilateral-posterior-lens.html");
  static controller = BilateralPosteriorLensController;
  static bindings = {
    enable: "<",
    record: "<",
    enableRight: "<",
    enableLeft: "<",
    isEditable: "<?",
    ...GlFormControllerBindings,
  };
}
