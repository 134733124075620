import { GlToastrCategory } from "../toastr-appendix";

export const DIAGNOSIS_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    osdi: {
      value: "OSDI score must be a number between 0 and 100",
    },
    copy: {
      diagnosis: {
        doesnt_exist: "Cannot copy an empty diagnosis over",
      },
      multi_select: {
        cannot_copy_to_non_multiselect:
          "Target side to copy to does not allow for multiple diagnoses",
      },
    },
  },
  info: {},
};
