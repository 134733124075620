export class MediaPermissionsModalController implements angular.IController {

  resolve: any;
  close: () => void;

  constructor() {
    "ngInject";
  }
}

export class MediaPermissionsModal implements angular.IComponentOptions {
  static selector: string = "mediaPermissionsModal";
  static controller = MediaPermissionsModalController;
  static template = require('./media-permissions-modal.html');
  static bindings = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
}