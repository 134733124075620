import { PatientProcedureFormService } from "app/core/services/PatientProcedureFormService/patient-procedure-form.service";
import { AccessLockService } from "app/core/services/access-lock.service";
import { Appendix, IGlOption } from "app/core/services/appendix";
import { AuthService } from "app/core/services/auth.service";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { PageTitleService } from "app/core/services/page-title.service";
import { PatientDocumentService } from "app/core/services/patient-document.service";
import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "app/core/services/patient.service";
import { QzPrinterService } from "app/core/services/qz-printer/qz-printer";
import { cloneDeep, every, isArray, isEmpty, isNil, set } from "lodash";
import { AccessLock } from "models/access-lock.model";
import { IGlSideBilateral } from "models/gl-side.model";
import { PatientDocument } from "models/patient-document.model";
import {
  PatientProcedure,
  PatientProcedureExternal,
} from "models/patient-procedure";
import {
  GlBilateral,
  GlPatientRecordWorkflowState,
  PatientCataractConsentRecord,
  PatientConsentFormRecord,
  PatientRecord,
} from "models/patient-record.model";
import { GlStaff } from "models/user.model";
import "./cataract-consent.scss";
import moment = require("moment");
import angular = require("angular");
import { SubscriptionService } from "app/core/services/subscription.service";
import { Subscription } from "models/subscription.model";

export interface IConsentFormRisk {
  text: string;
  key: string;
  tags?: string[]; // can label a checkbox with a tag
  required?: boolean;
  checked?: boolean; // has it been ticked or not
}

// RISK ROW TEXTS
export const RISK_ROW_TEXTS: IConsentFormRisk[] = [
  {
    key: "endophthalmitis",
    text: "1 in 1,000 risk of endophthalmitis and subsequent loss of vision or eye.",
  },
  {
    key: "vision",
    text: "1-2% risk of reduced vision following surgery.",
  },
  {
    key: "complication",
    text: "5% risk of complication that will require treatment including further surgery.",
  },
  {
    key: "retinal_detatchment",
    text: "1-5% risk of retinal detachment.",
  },
  {
    key: "artificial_lens",
    text: "Artificial lenses may cause symptoms of glare, haloes or dysphotopsia.",
  },
  {
    key: "cataract_info",
    text: "I have watched the cataract information video.",
  },
  {
    key: "doctor_questions",
    text: "I have had the opportunity to ask my doctor any questions.",
  },
  {
    key: "alternative_measures",
    text: "I consent for further or alternative operative measures that may be necessary during surgery.",
  },
  {
    key: "adult_drive",
    text: "I have made arrangements for a responsible adult to drive me home and care for me for 24 hours.",
  },
  {
    key: "post_operative_refractive",
    text: "Post operative refractive options have been discussed with me (MFIOL, EDOF).",
  },
  {
    key: "floaters",
    text: "I have been advised that floaters may worsen after cataract surgery.",
  },
  {
    key: "dry_grittiness",
    text: "I have been advised that Dry/Grittiness in my eyes may worsen for up to 6 months after surgery.",
  },
  {
    key: "rights_responsibilities",
    text: "I have read and understood my rights and responsibilities.",
  },
];

/**
 * TO DO: add user options or a check to filter out empty rows
 * by null, undefined or values of null or undefined as an array, or a single option
 */
export class CataractConsentPageController
  implements angular.IController, angular.IOnInit {
  user: GlStaff;
  patient: IPatientResource;
  record: PatientRecord; // parent record

  cataractConsentRecord: PatientCataractConsentRecord; // reference record
  patientDocuments: PatientDocument[];
  // external procedures
  externalCataractProcedure: PatientProcedureExternal;

  patientId: number = this.$stateParams.patientId;
  recordId: number = this.$stateParams.recordId; // this is the cataract consent record id
  cataractConsentFormId: number = this.$stateParams["form-record"];
  debug: boolean = this.$stateParams.debug;

  accessLockForRecord: AccessLock;
  autoSaveIntervalPromise: angular.IPromise<void>;

  // singing logic from cataracts-section goes here
  cataractConsentForm: angular.IFormController;

  // SIGNATURE
  patientSignatureRef: JQLite;
  userSignatureRef: JQLite;

  // printing
  show1ClickPrint = this.QzPrinterService.getQzTrayIsInstalled();
  generateDocumentPreviewInProgress: boolean = false;
  printInProgress: boolean = false;

  // progress
  saveInProgress: boolean = false;
  lockInProgress: boolean = false;
  reopenInProgress: boolean = false;
  deleteInProgress: boolean = false;
  completeInProgress: boolean = false;
  saveAndProgressInProgress: boolean = false;

  // access lock?
  disableAccessLockInProgress: boolean = false;

  // sign
  // progress state (use for both signing/unsigning)
  userSignInProgress: boolean = false;
  patientSignInProgress: boolean = false;

  // re-open
  reOpenInProgressPatient: boolean = false;
  reOpenInProgressUser: boolean = false;

  // document
  generatingInProgress: boolean = false;

  // toggle
  accessibilityTextEnabled: boolean = false;

  // error
  recordMessages = this.ToastrAppendix.getRecordsMessages();

  subscriptionDetails: Subscription;

  constructor(
    private PatientService: PatientService,
    private PageTitleService: PageTitleService,
    private PatientRecordService: PatientRecordService,
    private PatientProcedureService: PatientProcedureService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    private AccessLockService: AccessLockService,
    private toastr: angular.toastr.IToastrService,
    private appendix: Appendix,
    private QzPrinterService: QzPrinterService,
    private PatientProcedureFormService: PatientProcedureFormService,
    private ToastrAppendix: ToastrAppendix,
    private PatientDocumentService: PatientDocumentService,
    private AuthService: AuthService,
    private SubscriptionService: SubscriptionService,

    private $stateParams: angular.ui.IStateParamsService,

    private $q: angular.IQService,
    private $state: angular.ui.IStateService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,

    private AUTO_SAVE_INTERVAL: number
  ) {
    "ngInject";
  }

  $onInit() {
    this.patient = this.PatientService.get(this.patientId);
    this.patient.$promise.then((patient) =>
      this.PageTitleService.set(
        `${patient.data.first_name} ${patient.data.last_name} - Cataract Consent Form - GlaucoNet`
      )
    );

    // subscription
    const patientSubscriptionPromise = this.updatePatientSubscriptionDetails(this.patientId);

    const getRecordPromise = this.PatientRecordService.getRecordV2ForEditing(
      this.patientId,
      this.recordId
    );

    // initiate on parent record as well?
    const accessLockPromise = this.AccessLockService.initPatientLock({
      recordId: this.cataractConsentFormId,
      onBeforeUnload: this.onBeforeUnload.bind(this),
    }).catch(() => undefined);

    const patientDocPromise = this.updateDocuments();

    const patientProcedurePromise =
      this.PatientProcedureService.getAllForPatient({
        patientId: this.patientId,
      });

    this.$q
      .all([
        this.patient.$promise,
        getRecordPromise,
        accessLockPromise,
        patientDocPromise,
        patientProcedurePromise,
        patientSubscriptionPromise,
      ])
      // eslint-disable-next-line
      .then(([patient, record, accessLock, documents, procedures]) => {
        this.record = record as PatientConsentFormRecord;
        this.cataractConsentRecord = this.record?.cataract_consent_form;
        this.accessLockForRecord = accessLock;

        // if no existing cataract risks exist, use the default template
        if (
          isNil(
            this?.cataractConsentRecord.data?.procedure_risk_acknowledgements
          )
        ) {
          set(
            this.cataractConsentRecord,
            "data.procedure_risk_acknowledgements",
            cloneDeep(RISK_ROW_TEXTS)
          );
        }

        // set external procedures
        this.getLinkedCataractProcedure(procedures);
      })
      .catch((error) => {
        console.error("Cataract Consent Page - Error loading details", error);
        this.toastr.error("Unable to load patient details");
        this.$state.go("main.dashboard");
      });
  }

  $onDestroy() {
    // clear any saved patient procedures on destroy
    this.PatientProcedureService.reset();
    this.cancelAutoSaveTimeout();
  }

  // fetch external procedures
  getLinkedCataractProcedure(procedures: PatientProcedure[]) {
    // if the consent form has a linked procedure id in the data
    const linkedProcedureId = this.cataractConsentRecord?.data?.linked_procedure_id;
    // filter out viewable external procedures based on the parent record
    const proceduresFiltered: PatientProcedure[] =
      procedures?.filter(
        (p) =>
          p.type === 'external' && this.PatientProcedureService.showProcedureInEditMode(p, this.recordId)
      );

    // then find the linked procedure
    let foundProcedure: PatientProcedureExternal;
    // if there is a linked procedure - find a reference to it
    if (!isNil(linkedProcedureId)) {
      foundProcedure = proceduresFiltered.find((p) => p.id === linkedProcedureId) as PatientProcedureExternal;
    } else {
      // otherwise go by the legacy method assuming 
      // the procedure is created in the record itself
      // alongside the consent form
      foundProcedure = this.PatientProcedureHelperService.getExternalProcedureForRecordByName(
        this.recordId,
        "Cataract",
        proceduresFiltered
      );
    }

    // assign it
    this.externalCataractProcedure = foundProcedure ?? null;
  }

  getProcedureSummary() {
    if (!this.externalCataractProcedure) {
      return "Cataract";
    }
    const { data } = this.externalCataractProcedure;

    /*
      if another name exists and the key is actually other
        use that
        otherwise check level 2 appendix
          use display text or the name
          otherwise general name
    */
    const name =
      data.name_other && data?.level2Appendix.key === "other"
        ? data.name_other
        : data.level2Appendix
          ? data.level2Appendix.displayText || data.level2Appendix.name
          : data.name;

    // some cases we use name_other as a description key
    const descOther: string =
      !isEmpty(data?.name_other) && data?.level2Appendix.key !== "other"
        ? ` (${data.name_other})`
        : "";

    // handle showing side of procedure
    let side: string;
    if (data?.eye === "both") {
      side =
        data?.order === "left_right"
          ? "Left then Right Eye"
          : "Right then Left Eye";
    } else if (data?.eye === "left") {
      side = "Left Eye";
    } else if (data?.eye === "right") {
      side = "Right Eye";
    }

    return `${side} ${name ?? "Cataract"}${descOther}`.trim().toUpperCase();
  }

  // TO DO: fix on init resize bug
  // toggle
  toggleAccessibilityText() {
    this.accessibilityTextEnabled = !this.accessibilityTextEnabled;
    // then based on the new state, determine if header should be hidden
    const glNavBar = document.getElementsByTagName("gl-nav-bar")[0];
    if (glNavBar) {
      // if enabled display none
      if (this.accessibilityTextEnabled) {
        glNavBar.classList.add("hidden");
      } else {
        glNavBar.classList.remove("hidden");
      }
      // otherwise default
    }

    // remember to always update the signature pad
    this.$timeout(100).then(() => {
      this.updateSignaturePad();
    });
  }

  updateSignaturePad() {
    // then handle resize for signature area
    this.resizeSignaturePad(this?.userSignatureRef);
    this.resizeSignaturePad(this?.patientSignatureRef);
  }

  preventDefault(e: Event) {
    return e.preventDefault();
  }

  // only can be toggled if neither patient or surgeon has signed
  canBeToggled(e: Event) {
    if (this.eitherSignaturePresent()) {
      this.preventDefault(e);
    }
  }

  // RISK
  getCataractRiskTexts() {
    return (
      this.cataractConsentRecord?.data?.procedure_risk_acknowledgements ?? []
    );
  }

  allCataractRiskTextsChecked() {
    return every(
      this.getCataractRiskTexts() ?? [],
      (t: IConsentFormRisk) => t.checked
    );
  }

  // data related
  getCataractConsentFormKeys() {
    return this.appendix.getCataractFormKeys();
  }

  // getCataractConsentFormOptions(key: string) {
  //   return this.appendix.get(key);
  // }

  getCataractConsentFormOptions(key: string, side?: IGlSideBilateral) {
    if (this.cataractOptionIsSingle(key)) {
      return [this.cataractConsentRecord?.data?.management?.[key]];
    }

    // else check if array
    const options = this.cataractConsentRecord?.data?.management?.[key]?.[side];
    return isArray(options) ? options : [options];
  }

  getOtherOption(option: IGlOption, formKey: string, side?: IGlSideBilateral) {
    if (option?.other) {
      return option.other;
    }
    // otherwise check directly if there is a key
    if (this.cataractOptionIsSingle(formKey)) {
      return this.cataractConsentRecord?.data?.management?.[`${formKey}_other`];
    } else {
      return this.cataractConsentRecord?.data?.management?.[
        `${formKey}_other`
      ]?.[side];
    }
  }

  // options
  cataractOptionIsSingle(key: string) {
    return ["cataract_dry_eye"].includes(key);
  }

  cataractOptionIsBilateralMultiple(key: string) {
    return [
      "cataract_pre_op",
      "cataract_injectables",
      "cataract_steroid",
      "cataract_operation",
    ].includes(key);
  }

  // IGlOption
  isOptionSelectedSingle(key: string, option: IGlOption) {
    const field: IGlOption = this?.record?.data?.management?.[key];
    return field?.key === option?.key;
  }

  // GlBilateral<IGlOption>
  isOptionSelectedBilateral(
    key: string,
    side: IGlSideBilateral,
    option: IGlOption
  ) {
    const field: GlBilateral<IGlOption> = this?.record?.data?.management?.[key];
    return field?.[side]?.key === option?.key;
  }

  // GlBilateral<IGlOption[]>
  isOptionSelectedBilateralMultiple(
    key: string,
    side: IGlSideBilateral,
    option: IGlOption
  ) {
    const field: GlBilateral<IGlOption[]> =
      this?.record?.data?.management?.[key];
    return field?.[side]?.find((o) => o?.key === option.key);
  }

  // choose by key
  isOptionSelected(option: IGlOption, key: string, side?: IGlSideBilateral) {
    if (this.cataractOptionIsSingle(key)) {
      return this.isOptionSelectedSingle(key, option);
    } else if (this.cataractOptionIsBilateralMultiple(key)) {
      return this.isOptionSelectedBilateralMultiple(key, side, option);
    } else {
      return this.isOptionSelectedBilateral(key, side, option);
    }
  }

  // logic used to check if we should render a row
  isFieldDisabledOrEmpty(key: string) {
    if (this.cataractOptionIsSingle(key)) {
      return isNil(this.record?.data?.management?.[key]);
    } else if (this.cataractOptionIsBilateralMultiple(key)) {
      // for arrays check if they exist or have all values that arent null
      return (
        isNil(this.record?.data?.management?.[key]) ||
        (every(this.record?.data?.management?.[key]?.left, (o) => isNil(o)) &&
          every(this.record?.data?.management?.[key]?.right, (o) => isNil(o)))
      );
    } else {
      return (
        isNil(this.record?.data?.management?.[key]?.left) &&
        isNil(this.record?.data?.management?.[key]?.right)
      );
    }
  }

  getCataractConsentFormScopeHelpers() {
    return {
      appendix: this.appendix,
      cataractConsentRecord: this.cataractConsentRecord,
      // getter
      getCataractConsentFormKeys: this.getCataractConsentFormKeys,
      getCataractConsentFormOptions: this.getCataractConsentFormOptions,
      // main
      isOptionSelected: this.isOptionSelected,
      getOtherOption: this.getOtherOption,
      // helper
      isOptionSelectedSingle: this.isOptionSelectedSingle,
      isOptionSelectedBilateralMultiple: this.isOptionSelectedBilateralMultiple,
      isOptionSelectedBilateral: this.isOptionSelectedBilateral,
      isFieldDisabledOrEmpty: this.isFieldDisabledOrEmpty,

      // checkers
      cataractOptionIsSingle: this.cataractOptionIsSingle,
      cataractOptionIsBilateralMultiple: this.cataractOptionIsBilateralMultiple,

      // risks
      // procedureRisks: this.record.data.procedure_risk_acknowledgements,
      getCataractRiskTexts: this.getCataractRiskTexts,
      externalCataractProcedure: this.externalCataractProcedure,
      getProcedureSummary: this.getProcedureSummary,
    };
  }

  // misc
  uiCanExit() {
    if (
      this?.cataractConsentForm?.$dirty &&
      !this.$window.confirm(
        "There are unsaved changes, are you sure you want to exit without saving?"
      )
    ) {
      return false;
    }
    return this.accessLockForRecord
      ? this.AccessLockService.clearPatientLock()
      : true;
  }

  fromSameClinic() {
    return !!(
      this.record &&
      this.user &&
      this.record.clinic.id === this.user.clinic.id
    );
  }

  consentFormComplete(): boolean {
    return this?.cataractConsentRecord?.status === "COMPLETE";
  }

  // is it signed?
  userSignedCataractConsentForm(): boolean {
    return this.cataractConsentRecord?.data_status === "SIGNED";
  }

  // is it singed by patient?
  patientSignedCataractConsentForm(): boolean {
    return (
      this.cataractConsentRecord?.data?.signature_data?.patient?.status ===
      "SIGNED"
    );
  }

  bothSignaturesPresent() {
    return (
      this.userSignedCataractConsentForm() &&
      this.patientSignedCataractConsentForm()
    );
  }

  eitherSignaturePresent() {
    return (
      this.userSignedCataractConsentForm() ||
      this.patientSignedCataractConsentForm()
    );
  }

  // cna the practitioner sign
  userCanSign() {
    // can only sign if patient has signed
    return this.patientSignedCataractConsentForm();
  }

  // signing
  // sign function for patient
  signCataractConsentFormForPatient(signature: string) {
    // sign for patient
    this.patientSignInProgress = true;
    // sign
    return this.PatientRecordService.updateAsPatient({
      record: this.cataractConsentRecord,
      signature: signature,
    })
      .then((cataractConsentForm) => {
        this.cataractConsentRecord =
          cataractConsentForm as PatientCataractConsentRecord;

        // pristine form?
        this.toastr.success("Successfully signed patient record as patient!");
        this.cataractConsentForm.$setPristine();
      })
      .catch((err) => {
        console.error("err", err);
        this.toastr.error(this.recordMessages.error.sign.error_try_again);
      })
      .finally(() => {
        this.patientSignInProgress = false;
      });
  }

  // sign function for user (can only sign if patient has signed)
  signCataractConsentFormForUser(signature: string) {
    if (!this.userCanSign()) {
      return this.toastr.error(
        "Cannot sign record if Patient has not signed first"
      );
    }

    this.userSignInProgress = true;

    // sign
    return this.PatientRecordService.sign({
      record: this.cataractConsentRecord,
      signature,
    })
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        this.cataractConsentRecord = cataractConsentForm;

        // pristine form?
        this.toastr.success("Successfully signed patient record as surgeon!");
        this.cataractConsentForm.$setPristine();
      })
      .catch((err) => {
        console.error("err", err);
        this.toastr.error(this.recordMessages.error.sign.error_try_again);
      })
      .finally(() => {
        this.userSignInProgress = false;
      });
  }

  // reopening
  // re-open for patient
  reOpenCataractConsentFormPatient() {
    // reopen in progress
    this.reOpenInProgressPatient = true;
    // set as in progress again
    this.cataractConsentRecord.status = "IN PROGRESS";
    return this.PatientRecordService.reopenAsPatient(this.cataractConsentRecord)
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        this.toastr.success("Successfully unsigned cataract form!");
        this.cataractConsentRecord = cataractConsentForm;

        // then based on the ref clear
        this.$timeout(150).then(() => {
          this._clearSignaturePad(this?.patientSignatureRef);
          this.resizeSignaturePad(this?.patientSignatureRef);
        });
        return cataractConsentForm;
      })
      .finally(() => {
        this.reOpenInProgressPatient = false;
      });
  }

  // re-open for user
  reOpenCataractConsentFormUser() {
    // reopen in progress
    this.reOpenInProgressUser = true;
    this.cataractConsentRecord.status = "IN PROGRESS";
    return this.PatientRecordService.reopen(this.cataractConsentRecord)
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        this.toastr.success("Successfully unsigned cataract form!");
        this.cataractConsentRecord = cataractConsentForm;

        // then based on ref clear signature if exisitng
        // then based on the ref clear
        this.$timeout(150).then(() => {
          this._clearSignaturePad(this?.userSignatureRef);
          this.resizeSignaturePad(this?.userSignatureRef);
        });
        return cataractConsentForm;
      })
      .finally(() => {
        this.reOpenInProgressUser = false;
      });
  }

  // access lock
  userHasAccessLockForRecord() {
    return !!this.accessLockForRecord;
  }

  overrideAccessLock() {
    this.disableAccessLockInProgress = true;
    this.AccessLockService.disablePageLock({
      recordId: this.cataractConsentFormId,
      force: true,
    })
      .then(() => {
        return this.$state.reload();
      })
      .finally(() => (this.disableAccessLockInProgress = false));
  }

  // record
  canEditRecord() {
    return !this.eitherSignaturePresent() && this.userHasAccessLockForRecord();
  }

  // saving
  save() {
    if (this.cataractConsentRecord?.id) {
      this.saveInProgress = true;
      this.PatientRecordService.updateAndSetPractitioner(
        this.cataractConsentRecord,
        this.user
      )
        .then(() => {
          this.cataractConsentForm.$setPristine();
          this.toastr.success("Successfully saved consent form!");
        })
        .catch((err) => {
          console.error(err);
          this.toastr.error("Issue with saving consent form, please try again");
        })
        .finally(() => {
          this.saveInProgress = false;
          this.setAutoSaveTimeout();
        });
    }
  }

  autoSave() {
    // authenticated
    const canContinue: boolean = this.AuthService.autoProcessCanContinue();
    // only auto save if the form has changes
    // and the session is authenticated to avoid issues
    // with spamming 401's to api
    if (this.cataractConsentForm.$dirty && canContinue) {
      this.save();
    }
  }

  // progress
  saveAndProgressClicked(nextWorkflowState?: GlPatientRecordWorkflowState) {
    this.cataractConsentRecord.workflow_state = nextWorkflowState;
    this.saveAndProgressInProgress = true;

    return this.generateInternalDocument()
      .then(() => {
        return this.PatientRecordService.updateAndSetPractitioner(
          this.cataractConsentRecord,
          this.user
        );
      })
      .then(() => this.setFormPristine())
      .then(() => {
        // the record is save, so redirect back to the dashboard
        return this.$state.go("main.dashboard");
      })
      .catch((error) => {
        console.error("Error in saveAndProgressClicked saving record", error);
        this.toastr.error("Could not save record. Please try again");
      })
      .finally(() => {
        this.saveAndProgressInProgress = false;
      });
  }

  // complete/close
  completeRecord() {
    // do a double check first
    if (!this.bothSignaturesPresent()) {
      return this.toastr.error(
        "Both signatures required before closing record."
      );
    }
    // then proceed
    this.completeInProgress = true;
    this.cataractConsentRecord.status = "COMPLETE";
    this.saveAndProgressClicked().finally(() => {
      this.completeInProgress = false;
    });
  }

  // delete
  deleteConsentRecord() {
    // must not be signed at all
    if (!this.cataractConsentRecord) {
      return this.toastr.error("Cannot delete record, please try again.");
    }

    const confirm: boolean = window.confirm(
      "Are you sure you want to delete this record? You will have to create a new consent form if so."
    );

    // on confirm
    if (confirm) {
      this.deleteInProgress = true;
      return this.$q
        .resolve()
        .then(() => {
          // check if patient signed first then undo
          if (this.patientSignedCataractConsentForm()) {
            return this.reOpenCataractConsentFormPatient();
          }
          return this.cataractConsentRecord;
        })
        .then(() => {
          // then check if surgeon signed and undo
          if (this.userSignedCataractConsentForm()) {
            return this.reOpenCataractConsentFormUser();
          }
          return this.cataractConsentRecord;
        })
        .then((consentRecord: PatientCataractConsentRecord) => {
          // then delete consent form
          return this.PatientRecordService.delete(consentRecord);
        })
        .then(() => {
          this.toastr.success(
            "Successfully deleted the consent record, redirecting to dashboard..."
          );
          return this.$state.go("main.dashboard");
        })
        .catch((error) => {
          console.error("Unable to delete record", error);
          return this.toastr.error(
            "Unable to delete the conent form, please try again."
          );
        })
        .finally(() => {
          this.deleteInProgress = false;
        });
    }
  }

  // INTERNAL DOCUMENT OR PRINTING
  createPdf() {
    const helperScope = this.getCataractConsentFormScopeHelpers();
    return this.PatientProcedureFormService.printConsentFormPdf({
      type: "cataract",
      patient: this.patient,
      surgeon: this.user,
      record: this.cataractConsentRecord,
      helperFunctions: helperScope,
    });
  }

  generateDocumentPreview() {
    this.toastr.info("Generating document preview for consent form...");
    this.generateDocumentPreviewInProgress = true;
    return this.createPdf()
      .then((pdf) => {
        const objectURL: string = URL.createObjectURL(pdf);
        // then open
        // eslint-disable-next-line
        const window = this.$window.open(objectURL);
      })
      .finally(() => {
        this.generateDocumentPreviewInProgress = false;
      });
  }

  print() {
    this.toastr.info("Printing consent form...");
    return this.createPdf()
      .then((pdf) => {
        this.QzPrinterService.printPdf(pdf);
      })
      .then(() => {
        this.printInProgress = false;
      });
  }

  // DOCUMENTS
  updateDocuments() {
    return this.PatientDocumentService.getAllForPatient(this.patientId).then(
      (documents) => {
        this.patientDocuments = documents.filter(
          (d) => d.patient_record_id == null && d.data.field === "generic"
        );
      }
    );
  }

  generateInternalDocument() {
    // create PDF
    this.generatingInProgress = true;
    this.toastr.info("Generating internal document...");
    return this.createPdf()
      .then((pdf) => {
        const objectURL: string = URL.createObjectURL(pdf);
        const timestamp: moment.Moment = moment();
        // // // upload document
        return this.PatientDocumentService.create(
          this.patientId,
          null, // to ensure its linked to patient file
          pdf, // pdf blob
          objectURL, // object url is the same as name
          {
            field: "generic",
            tags: "cataract", // cataract tag
            mime: "application/pdf", // pdf
            // we go by date to ensure any overrides will be updated
            extra_details: {
              title: `cataract_consent_${timestamp.format(
                "DD-MM-YYYY_HH:mm:ss"
              )}`.toLowerCase(),
              more_information: timestamp.format("DD-MM-YYYY_HH:mm:ss"),
            },
            // extra metadata
            consent_record_id: this.record?.id, // for parent record
          }
        );
      })
      .then(() => {
        this.toastr.success(
          "Successfully uploaded consent form as internal document!"
        );
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error("An error has occurred, please try again.");
      })
      .finally(() => {
        this.generatingInProgress = false;
      });
  }

  // gauge by this
  getExistingConsentDocument() {
    const todaysDate = moment().format("DD-MM-YYYY");
    // serach for cataract consent with today's date and consent_record_id
    return (this.patientDocuments ?? []).find(
      (d) =>
        d.name.includes("cataract_consent") &&
        d.name.includes(todaysDate) &&
        d.data.consent_record_id === this.record?.id
    );
  }

  resizeSignaturePad(ref: JQLite) {
    // find signature pad, if existing clear signature on re-open
    const signatureRef: any = angular.element(ref);
    if (signatureRef) {
      // signatureRef.resizeCanvas();
      this.$timeout(100).then(() => {
        signatureRef?.[0]?.resizeCanvas();
      });
    }
  }

  updatePatientSubscriptionDetails(patient_id: number) {
    this.SubscriptionService.get({ patient_id })
      .then((subscription) => {
        this.subscriptionDetails = { ...subscription };
      });
  }


  private setAutoSaveTimeout() {
    this.cancelAutoSaveTimeout();
    if (this.AUTO_SAVE_INTERVAL > 0) {
      this.autoSaveIntervalPromise = this.$timeout(this.AUTO_SAVE_INTERVAL);
      this.autoSaveIntervalPromise
        .then(() => this.autoSave())
        .catch(() => {
          // If this timeout is cancelled, it throws an error. Catch all errors
          // to silence this error
        });
    }
  }

  private _clearSignaturePad(ref: JQLite) {
    if (ref) {
      // find signature pad, if existing clear signature on re-open
      const signatureRef: any = angular.element(ref);
      const signaturePad: any = signatureRef?.[0]?.signaturePad;
      if (signaturePad) {
        signaturePad.clear();
      }
    }
  }

  private cancelAutoSaveTimeout() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.autoSaveIntervalPromise) {
      this.$timeout.cancel(this.autoSaveIntervalPromise);
      this.autoSaveIntervalPromise = null;
    }
  }

  private onBeforeUnload() {
    if (this?.cataractConsentForm.$dirty) {
      return "Are you sure you want to close this window and discard any changes?";
    }
  }

  // This sets the form to pristine after a short delay to allow gl-model to set
  // any defaults for fields
  private setFormPristine() {
    return this.$timeout(250).then(() =>
      this?.cataractConsentForm?.$setPristine()
    );
  }
}

export class CataractConsentPage implements angular.IComponentOptions {
  static selector = "cataractConsentPage";
  static template = require("./cataract-consent.html");
  static controller = CataractConsentPageController;
  static bindings = {
    user: "<",
  };
}
