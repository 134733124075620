import { AuthService } from "app/core/services/auth.service";
import { ChangesService } from "app/core/services/changes/changes.service";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import {
  cloneDeep,
  defaultsDeep,
  get,
  isEmpty,
  isFunction,
  isNil,
  partition,
} from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlLensObservation,
  PatientRecordData,
} from "models/patient-record.model";
import { Appendix, IGlOption } from "../../../services/appendix";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import {
  LENS_OBSERVATIONS_KEY,
  LENS_STATUS_KEY,
} from "../../../../../lib/key-appendix";
import { IGlChangesRecordDataOrigin } from "models/changes.model";
import {
  AutofillService,
  IAutofillIconParams,
} from "app/core/services/autofill/autofill.service";
import { AutofillHelperService } from "app/core/services/autofill-helper/autofill-helper.service";

export class PosteriorLensController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // CONSTANTS
  PARENT_KEY: string = "lens"; // actual key
  PARENT_KEY_APPENDIX: string = "lensPhakic"; // internal reference

  // @Input()
  enable: boolean = true;
  record: PatientRecordData;
  recordId: number = this.$stateParams.recordId;
  patientId: number = this.$stateParams.patientId;
  side: IGlSideBilateral;
  // Controller Properties
  lensOptions = this.appendix.get(this.PARENT_KEY_APPENDIX);
  cataractTypeOptions = this.appendix.get("cataractType", true); // Pass true to signify we don't want to sort the returned list;
  IOLTypeOptions = this.appendix.get("IOLType");
  quantifierOptions = this.appendix.get("quantifier");

  // form
  posteriorLensForm: angular.IFormController;

  lensAttributes: string[] = this.PatientRecordService.getLensAttributes();

  // prefills are always set on init once
  prefillsSet: boolean = false;

  // for autofill icon
  autofillIconSourceStatusParams: IAutofillIconParams[];
  autofillIconSourceObsParams: IAutofillIconParams[];

  // dirty
  formDidChange: () => void;

  constructor(
    private appendix: Appendix,
    private PatientRecordService: PatientRecordService,
    private AutofillHelperService: AutofillHelperService,
    private ToastrAppendix: ToastrAppendix,
    private AuthService: AuthService,
    private ChangesService: ChangesService,
    private AutofillService: AutofillService,
    private $stateParams: angular.ui.IStateParamsService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    this.autofillIconSourceStatusParams =
      this.AutofillService.getAutofillIconParamsForSourceKey(LENS_STATUS_KEY);
    this.autofillIconSourceObsParams =
      this.AutofillService.getAutofillIconParamsForSourceKey(
        LENS_OBSERVATIONS_KEY
      );
  }

  $onChanges() {
    if (this.isEditMode() && this.record) {
      const defaults = {
        lens: {
          observations: {},
        },
      };
      defaultsDeep(this.record, defaults);

      // this works on init once, so we need a state flag
      this.handleLensStatusPrefill(this.record);
    }
  }

  /* 
    on init or after gl model initiated, check if any of the 
    observations and diagnosis that are autofills are there

    and immediately add them
  */
  handleLensStatusPrefill(recordData: PatientRecordData) {
    if (this.prefillsSet) {
      return;
    }
    // else check for each side
    this.setLensStatusPrefillsForSide(recordData, this.side);

    // set true
    this.prefillsSet = true;
    return;
  }

  // helper
  setLensStatusPrefillsForSide(
    recordData: PatientRecordData,
    side: IGlSideBilateral
  ) {
    // get lens status option
    const option: IGlOption = get(recordData, `lens.status.${side}`);
    if (!isNil(option)) {
      // create changes object
      const changesObj: IGlChangesRecordDataOrigin = {
        currentValue: option,
        side: this.side,
        sourceKey: LENS_STATUS_KEY,
        recordData: this.record,
        type: "prefill",
        id: option?.id,
        origin: "source",
        originKey: LENS_STATUS_KEY,
        originPath: "",
        extraData: {
          recordId: this.recordId,
          patientId: this.patientId,
        },
      };

      // then publish
      this.ChangesService.publish(changesObj);
    }
  }

  // EXPERIMENTAL
  // for autofill before confirming
  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  // lens did change to trigger sanitisation
  lensDidChange(optionId: string) {
    if (!this.record.lens.observations) {
      this.record.lens.observations = {};
    }

    // clean options
    this.sanitizeLensObservations();

    // set id based on instantiated info only on edit mode
    this.setId(this.record?.lens?.status[this.side], optionId);
  }

  getSelectedOptions() {
    if (this.record.lens?.status[this.side]) {
      if (this.lensStatusIsIol()) {
        return this.IOLTypeOptions;
      } else if (this.lensStatusIsCataract()) {
        return this.cataractTypeOptions;
      }
      return null;
    } else {
      return null;
    }
  }

  getSelectedOptionsObservationKey() {
    if (this.record.lens?.status[this.side]) {
      if (this.lensStatusIsIol()) {
        return "IOLType";
      } else if (this.lensStatusIsCataract()) {
        return "cataractType";
      }
      return null;
    } else {
      return null;
    }
  }

  shouldShowObservations() {
    if (this.record.lens?.status[this.side]) {
      return !["Clear", "Aphakic", "Other", "Not Examined"].includes(
        this.getLensStatus().name
      );
    } else {
      return false;
    }
  }

  shouldShowDescription(observation: any) {
    return ["other", "dislocated", "subluxed"].includes(observation.type.key);
  }

  insertRow(index: number) {
    this.record.lens.observations[this.side].splice(index + 1, 0, {});
    this.posteriorLensOnChange();
  }

  removeRow(index: number) {
    const observationToDelete: GlLensObservation =
      this.record.lens.observations[this.side][index];

    this._undoAutofillObservation(observationToDelete, index);

    // remove
    this.record.lens.observations[this.side].splice(index, 1);
    this.posteriorLensOnChange();
  }

  // form did change
  posteriorLensOnChange() {
    this.posteriorLensForm.$setDirty();
    if (isFunction(this.formDidChange)) {
      this.formDidChange();
    }
  }

  cleanObservation(observation: any) {
    // this allows this component to work with both v1 legacy observations
    // and v2 new observations.
    if (!observation?.cataractType || !observation.IOLType) {
      // this is a v2 observation
      return observation;
    }
    const observationType = this.lensStatusIsCataract()
      ? observation.cataractType
      : observation.IOLType;
    return { type: observationType };
  }

  lensStatusIsCataract() {
    return this.getLensStatus().name === "Cataract";
  }

  lensStatusIsIol() {
    const lensStatus = this.getLensStatus();
    return ["ACIOL", "PCIOL", "Sulcus IOL"].includes(lensStatus.name);
  }

  // OPTIONS
  // if option has id use that otherwise generate
  generateOptionId(option?: IGlOption) {
    return option?.id ?? this.AutofillHelperService.generateAutofillId();
  }

  getOptionId(option: IGlOption, altId: string) {
    return option?.id ?? altId;
  }

  // status: pass it directly
  // observation: get by index then type
  setId(option: IGlOption, id: string) {
    // if no option or already have an id, ignore
    if (isNil(option) || !isNil(option?.id)) {
      return;
    }

    // instantiated id always takes precedence
    option.id = id;
  }

  private sanitizeLensObservations() {
    // options can be applied

    // applicable observations
    const applicableObservationOptions = this.getSelectedOptions();
    if (applicableObservationOptions) {
      // get reference
      const observations = cloneDeep(this.record.lens.observations[this.side]);
      // filter them out
      // partition them into:
      // those that can be applied, those that cant
      const chunkedObservations = partition(observations ?? [], (o) =>
        applicableObservationOptions.some((ob) => ob.key === o?.type?.key)
      );

      // those we can use
      const filteredObservations = chunkedObservations?.[0] ?? [];
      // those we cant use
      const excludedObservations = chunkedObservations?.[1] ?? [];

      // IF THERES ANY OBSERVATIONS TO EXCLUDE, PASS THEM TO UNDO
      // since all of these are read in order, we can just read them in
      // reverse to ensure safe deletion
      if (!isEmpty(excludedObservations)) {
        // go through each, get index and pass to undo
        for (const obs of excludedObservations.reverse()) {
          const index: number = observations.indexOf(obs);
          index !== -1 && this._undoAutofillObservation(obs, index);
        }
      }

      // then afterwards apply
      this.record.lens.observations[this.side] =
        filteredObservations.length > 0 ? filteredObservations : [{}];
    } else {
      // otherwise undo for all as theres nothing in common at all
      this._undoAutofillForObservations();
    }
  }

  // private helper for mass undo on observations
  // the reason why we do this differently is
  // mostly to ensure its in order when undo'ing
  private _undoAutofillForObservations(observations?: GlLensObservation[]) {
    const observationsToUndo: GlLensObservation[] =
      observations ?? this.record.lens.observations[this.side] ?? [];

    // convert the observations indo undo change obejcts
    const changesObjects: IGlChangesRecordDataOrigin[] =
      observationsToUndo?.map((obs, idx) => {
        return {
          currentValue: obs?.type,
          side: this.side,
          sourceKey: LENS_OBSERVATIONS_KEY,
          sourcePath: "",
          recordData: this.record,
          type: "autofill_undo",
          id: obs?.type?.id,
          origin: "source",
          index: idx,
          originKey: LENS_OBSERVATIONS_KEY,
          originPath: "",
          extraData: {
            recordId: this.recordId,
            patientId: this.patientId,
          },
        };
      });

    // then send them
    for (const change of changesObjects ?? []) {
      this.ChangesService.publish(change);
    }
  }

  // same as above but singular operation
  private _undoAutofillObservation(
    observation: GlLensObservation,
    index: number
  ) {
    const changeObj: IGlChangesRecordDataOrigin = {
      currentValue: observation?.type,
      side: this.side,
      sourceKey: LENS_OBSERVATIONS_KEY,
      sourcePath: "",
      recordData: this.record,
      type: "autofill_undo",
      id: observation?.type?.id,
      origin: "source",
      index,
      originKey: LENS_OBSERVATIONS_KEY,
      originPath: "",
      extraData: {
        recordId: this.recordId,
        patientId: this.patientId,
      },
    };

    // push it
    this.ChangesService.publish(changeObj);
  }

  private getLensStatus() {
    return this.record.lens.status[this.side];
  }
}

export class PosteriorLens implements angular.IComponentOptions {
  static selector = "posteriorLens";
  static template = require("./posterior-lens.html");
  static controller = PosteriorLensController;
  static bindings = {
    enable: "<",
    record: "<",
    side: "@",
    formDidChange: "&",
    ...GlFormControllerBindings,
  };
}
