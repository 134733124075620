import angular = require("angular");
import { GlStaff, Patient } from "models/user.model";

import './proxy-sign.scss';

export class ProxySignController implements angular.IController {
  resolve: { proxyUser: GlStaff; patient: Patient; clinicProviders: GlStaff[]; currentUser: GlStaff; };

  // resolved
  currentUser: GlStaff;
  proxyUser: GlStaff;
  patient: Patient;
  clinicProviders: GlStaff[];
  clinicProvidersMapped: Record<string, GlStaff[]>;

  // only toggled if optom and patient sbuscribed
  createVR: boolean = false;

  // disclaimed ackowledged?
  disclaimerAckowledged: boolean = true;


  // SIGNING
  signInProgress: boolean = false;

  close: (args?: {
    $value: { proxyUser?: GlStaff, skipOphthalReview?: boolean, };
  }) => void;

  // sign record
  signRecord: (args?: { skipOphthalReview: boolean, proxyUser?: GlStaff; }) => void;

  constructor(
  ) {
    "ngInject";
  }

  $onInit(): void {
    // set proxy user 
    this.proxyUser = this.resolve.proxyUser;
    this.patient = this.resolve.patient;
    this.clinicProviders = this.resolve.clinicProviders;
    this.currentUser = this.resolve.currentUser;
  }

  // set VR to false if not an optometrist
  onProxyUserChange(proxyUser: GlStaff) {
    if (proxyUser?.type?.name !== 'optometrist') {
      this.createVR = false;
    }
  }

  handleOnCancel() {
    return this.close();
  }


  handleSignRecord() {
    // pass down args on sign confirm
    // createVR means skipOphthalReview = false
    // that means createVR is the inverse of skipOphthalReview
    return this.close({ $value: { proxyUser: this.proxyUser, skipOphthalReview: !this.createVR } });
  }
}


export class ProxySignComponent implements angular.IComponentOptions {
  static selector = "proxySign";
  static template = require("./proxy-sign.html");
  static controller = ProxySignController;

  static bindings = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
}