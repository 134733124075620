import { isEqual, transform } from "lodash";
import { AutofillHelperService } from "../autofill-helper/autofill-helper.service";

export class PatientRecordHelperService {
  static injectionName: string = "PatientRecordHelperService";

  // the actual changes
  private autofillRecordChangesStack: Map<string, any> = new Map<string, any>();

  constructor(private AutofillHelperService: AutofillHelperService) {
    "ngInject";
  }

  /*
   * FOR JUST BASIC STACK RECORDING
   */
  /** STACK CHANGES  */
  // get the map
  getRecordChangesStack() {
    return this.autofillRecordChangesStack;
  }

  // fetch value
  getValueFromChangesStack(key: string): any {
    if (!this.existingInChangesStack(key)) {
      return;
    }
    return this.autofillRecordChangesStack.get(key);
  }

  // if not existing or existing we still need to update
  updateChangesStack(key: string, value: any) {
    this.autofillRecordChangesStack.set(key, value);
  }

  // key exists
  existingInChangesStack(key: string): boolean {
    return this.autofillRecordChangesStack.has(key);
  }

  // on destroy always call this
  resetChangesStack() {
    this.autofillRecordChangesStack = new Map<string, any>();
  }

  deleteFromChangesStack(key: string) {
    this.autofillRecordChangesStack.delete(key);
  }

  // removes by source
  deleteBothFromChangesStackBySource(key: string) {
    const targetKeys: string[] =
      this.AutofillHelperService.getSourceTargetAutofillValues(key) ?? [];
    // delete for source
    this.deleteFromChangesStack(key);
    // delete for targets
    // for each target remove any other links to source key
    targetKeys.forEach((_targetKey: string) => {
      // this time we use target key as the source
      this.deleteFromChangesStack(_targetKey);
    });
  }

  // compare changes
  getChangesDeep(obj1: any, obj2: any) {
    return transform(
      obj1,
      (result, value, key) => {
        if (!isEqual(value, obj2?.[key])) {
          result[key] = value;
        }
      },
      {}
    );
  }
}
