import { copy, isFunction } from "angular";
import { defaults, get, has, isNil, isString } from "lodash";
import { IGlSideOrder } from "models/gl-side-order.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { parseServerDate } from "../../../../../lib/parse_server_date";
import { ProcedureDetails } from "../../../../../models/gl-external-procedure";
import { IGlSide } from "../../../../../models/gl-side.model";
import { PatientProcedureExternal } from "../../../../../models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { Appendix, IGlButtonOption } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

export class GlSurgeryController
  extends GlFormController
  implements angular.IComponentController, angular.IOnChanges
{
  record: PatientRecord;
  procedure: PatientProcedureExternal;
  procedureModel: PatientProcedureExternal;
  types = this.appendix.get("surgical", true);
  secondarySurgeryOptions = [];
  historicalEditableOnly: boolean;
  retroactiveEditingEnabled: boolean = false;

  // Datepicker settings
  // Set to track date popup state
  leftEyeDatePopup = { opened: false };
  rightEyeDatePopup = { opened: false };
  // Toggles the state
  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;
  saveInProgress = false;
  deleteInProgress = false;

  procedureModelEyeSide: IGlSide | IGlSideOrder;

  onSave: (arg: { procedure: PatientProcedureExternal }) => void;
  onDelete: (arg: { procedure: PatientProcedureExternal }) => void;
  onCancel: () => void;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.procedure && this.procedure) {
      this.procedureModel = copy(this.procedure);
      defaults(this.procedureModel, {
        data: {
          created_in_record_id: this.record?.id,
        },
      });
      this.procedureModel.procedure_date = this.parseDateString(
        this.procedureModel.procedure_date
      );
      if (has(this.procedureModel, "data.left.date")) {
        this.procedureModel.data.left.date = this.parseDateString(
          this.procedureModel.data.left.date
        );
      }
      if (has(this.procedureModel, "data.right.date")) {
        this.procedureModel.data.right.date = this.parseDateString(
          this.procedureModel.data.right.date
        );
      }

      // LEGACY ON INIT
      // all legacy data will be converted to right_left order since thats the default
      if (
        this.procedureModel.data.eye === "both" &&
        isNil(this.procedureModel?.data?.order)
      ) {
        defaults(this.procedureModel.data, { order: "right_left" });
      }

      // update eye side based on order or eye
      this.procedureModelEyeSide =
        this?.procedureModel?.data?.order ?? this.procedureModel?.data?.eye;

      // update the secondary options list
      this.updateLevel2Options();
    }
  }

  // history procedures have different editing rules to them
  //
  // but we must also differentiate if the procedure being edited
  // is being edited retroactively
  // this returns false for anything that isnt a historical procedure
  isHistoricalEditable() {
    return (
      this.historicalEditableOnly &&
      (isNil(this.procedureModel?.id) ||
        this.procedureModel.status === "historical")
    );
  }

  openDatepicker(side: IGlSide) {
    if (side === "left") {
      this.leftEyeDatePopup.opened = true;
    } else {
      this.rightEyeDatePopup.opened = true;
    }
  }

  saveClicked() {
    if (isFunction(this.onSave)) {
      this.onSave({ procedure: copy(this.procedureModel) });
    }
  }

  primarySurgeryTypeDidChange() {
    const level1Value = get(this.procedureModel, "data.nameAppendix.key");
    if (!level1Value) {
      return;
    }
    this.procedureModel.data.level2Appendix =
      this.appendix.getDefaultFromDictionary(level1Value);
    this.updateLevel2Options();
  }

  hasSide(side: IGlSide) {
    return ["both", "left_right", "right_left", side].includes(
      this.procedureModel.data?.eye
    );
  }

  eyeDidChange(option: IGlButtonOption) {
    // quick check on eye side to handle order edge case
    this.eyeOrderOnSelect(option);

    // set defaults
    const sideDefaults = { complete: false };
    // consider side defaults first
    if (this.hasSide("left")) {
      defaults(this.procedureModel.data.left, { ...sideDefaults });
    }
    if (this.hasSide("right")) {
      defaults(this.procedureModel.data.right, { ...sideDefaults });
    }
  }

  eyeOrderOnSelect(option: IGlButtonOption) {
    // if left/right or right/left
    if (option?.secondary_key === "both") {
      // set eye and option appropriately
      this.procedureModel.data.eye = "both";
      this.procedureModel.data.order = option?.key as IGlSideOrder;
    } else {
      // otherwise set as usual
      this.procedureModel.data.eye = option?.key as IGlSide;
      delete this.procedureModel.data.order;
    }
  }

  completeDidChange(side: IGlSide) {
    const details: ProcedureDetails = get(this.procedureModel, `data.${side}`);
    if (details.complete) {
      defaults(details, {
        routine: true,
        date: new Date(),
        completed_in_record_id: this.record?.id,
      });
    }
  }

  private updateLevel2Options() {
    const level1Value = get(this.procedureModel, "data.nameAppendix.key");
    if (!level1Value) {
      return;
    }
    const options = this.appendix.get(level1Value, true);
    this.secondarySurgeryOptions = options;
  }

  private parseDateString(dateStr: string | Date) {
    // need to convert the procedure_date to a date object
    // older procedures have SQL date string formats
    if (isString(dateStr) && dateStr.includes("Z")) {
      // ISO8601 String
      return new Date(dateStr);
    } else if (isString(dateStr)) {
      return parseServerDate(dateStr).toDate();
    } else {
      return dateStr;
    }
  }
}

export class GlSurgeryComponent implements angular.IComponentOptions {
  static selector = "glSurgery";
  static template = require("./gl-surgery.html");
  static controller = GlSurgeryController;
  static bindings = {
    procedure: "<",
    onSave: "&",
    onCancel: "&",
    onDelete: "&",
    mode: "@?",
    isEditable: "<?",
    saveInProgress: "<?",
    deleteInProgress: "<?",
    record: "<",
    historicalEditableOnly: "<?",
    retroactiveEditingEnabled: "<?",
  };
}
