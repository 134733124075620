import { Subject } from "rxjs";
import { LoggingService } from "../logging/logging.service";
import {
  IGlChanges,
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";

export class ChangesService {
  static injectorName: string = "ChangesService";

  private _changes$ = new Subject<IGlChangesRecordData>();

  public changes$ = this._changes$.asObservable();

  constructor(private LoggingService: LoggingService) {
    "ngInject";

    // add the .next thing to push changes
    this.changes$.subscribe({
      next: (change: IGlChanges) => {
        this.LoggingService.pushToRecordChangesLog({
          ...change,
          type: "log",
        });
      },
    });
  }

  // takes a change object and publishes it
  publish(change: IGlChangesRecordData | IGlChangesRecordDataOrigin) {
    this._changes$.next(change);
  }
}
