import {
  DIAGNOSIS_ARRAY_KEY,
  EXTERNAL_PROCEDURES_KEY,
} from "../../../../lib/key-appendix";
import { get, cloneDeep, isNil, set, isEmpty, isEqual } from "lodash";
import { GlDiagnosis, PatientRecordData } from "models/patient-record.model";
import { DiagnosisService } from "../diagnosis.service";
import { AutofillHelperService } from "../autofill-helper/autofill-helper.service";
import { LoggingService } from "../logging/logging.service";
import { IGlAutofill } from "../autofill/autofill.service";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { PatientRecordHelperService } from "../patient-record-helper/patient-record-helper.service";
import { AutofillExternalProcedureService } from "../autofill-external-procedure.service/autofill-external-procedure.service";
import { PatientProcedureExternal } from "models/patient-procedure";
import { ChangesService } from "../changes/changes.service";
import {
  GlToastrCategory,
  ToastrAppendix,
} from "../toastr-appendix/toastr-appendix";

export class AutofillDiagnosisService implements IGlAutofill {
  static injectionName: string = "AutofillDiagnosisService";

  autofillMessages: GlToastrCategory =
    this.ToastrAppendix.getAutofillMessages();

  constructor(
    private AutofillHelperService: AutofillHelperService,
    private AutofillExternalProcedureService: AutofillExternalProcedureService,
    private LoggingService: LoggingService,
    private DiagnosisService: DiagnosisService,
    private ChangesService: ChangesService,
    private PatientRecordHelperService: PatientRecordHelperService,
    private toastr: angular.toastr.IToastrService,
    private ToastrAppendix: ToastrAppendix
  ) {
    "ngInject";
  }

  autofillAsSource(change: IGlChangesRecordData) {
    // get inspiration from other source autofills
    const {
      sourceKey,
      sourcePath,
      targetKey,
      targetPath,
      targetValue,
      currentValue,
      side,
      id,
      index,
      oldRecordData,
      extraData,
    } = change;

    // * before that double check if the option assigned has an id or not, if not manually set here

    // we could use the previous value passed down but
    // there is too much uncertainty over what the value is and
    // if the struture conforms
    // so it is easier to get an old reference instead directly with old data
    const diagnosisArrayPath: string = `${sourcePath}.${sourceKey}.${side}`;
    const oldDiagnosisSide: GlDiagnosis[] =
      get(oldRecordData, diagnosisArrayPath) ?? [];
    const previousValue: GlDiagnosis = oldDiagnosisSide[index];

    // examples in this case
    // generate reference, instead of parent key leave it as absolute path
    // this always refers to target no matter what
    // then handle depending on others
    let internalTargetReferenceKey: string;
    switch (targetKey) {
      case EXTERNAL_PROCEDURES_KEY:
        // * 1. check for any CREATED external procedures from the same record
        const hasExistingProcedure: boolean =
          this.AutofillExternalProcedureService.hasExistingCreatedProcedureWithData(
            targetValue?.nameAppendix?.name,
            extraData?.recordId
          );

        if (hasExistingProcedure) {
          return;
        }

        // * 2. generate relevant keys for autofill handling and association
        // source key
        const internalSourceReferenceKey: string =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: sourcePath,
            fieldKey: sourceKey,
            side,
            valueKey: this.AutofillHelperService.getOptionKey(
              sourceKey,
              currentValue
            ),
            id,
          });

        // target key
        internalTargetReferenceKey =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: targetPath,
            fieldKey: targetKey,
            side,
            valueKey: this.AutofillHelperService.getOptionKey(
              targetKey,
              targetValue
            ),
            id,
          });

        // update key link
        this.AutofillHelperService.setAutofillLinkSourceTargetBidirectional(
          internalSourceReferenceKey,
          internalTargetReferenceKey
        );

        // set stack changes
        this.PatientRecordHelperService.updateChangesStack(
          internalSourceReferenceKey,
          previousValue
        );

        // * 3. check for any existing TEMPORARY procedure to ddetermine type of autofill
        const existingTemporaryProcedure: PatientProcedureExternal =
          this.AutofillExternalProcedureService.getTemporaryProcedureWithData(
            targetValue?.nameAppendix?.name,
            extraData?.recordId
          );

        // if there is an existing temporary procedure
        if (existingTemporaryProcedure) {
          // update it by laterality
          this.AutofillExternalProcedureService.updateAsTarget(change);
        } else {
          this.AutofillExternalProcedureService.autofillAsTarget(change);
        }

        // target key doesnt need anything stored as its just removing it
        break;
      default:
        break;
    }
  }

  // handle diagnosis autofill
  autofillAsTarget(change: IGlChangesRecordData) {
    const { targetKey, targetPath, targetValue, recordData, id, extraData } =
      change;
    const side: IGlSideBilateral = change.side as IGlSideBilateral;
    // cast
    const autofillDiagnosis = targetValue as GlDiagnosis;
    // set id
    autofillDiagnosis.id = id;

    // old diagnosis array
    const oldDiagnosisArraySide: GlDiagnosis[] =
      get(recordData, `management.diagnosis_array.${side}`) ?? [];

    // else continue
    let newDiagnosisArraySide: GlDiagnosis[] = [cloneDeep(autofillDiagnosis)];

    // if current diagnosis array isnt empty concat with new entry on top
    if (oldDiagnosisArraySide && oldDiagnosisArraySide?.length > 0) {
      // SECOND EDGE CASE
      // only allow adding to array if the diagnosis allows for
      // multiple selections after it (i.e. not Healthy)
      // otherwise it will be just the new array instantiated above
      if (
        this.DiagnosisService.checkIfDiagnosisArrayAllowsMultiSelectValues(
          oldDiagnosisArraySide
        )
      ) {
        newDiagnosisArraySide = oldDiagnosisArraySide.concat(
          newDiagnosisArraySide
        );
      }
    }

    // set diagnosis array
    // only add if we dont find a similar key there
    const diagnosisSide: GlDiagnosis[] = get(
      recordData,
      `management.diagnosis_array.${side}`
    );

    // if there isnt any existing diagnosis found, set the newly formed array
    // otherwise the autofill will not commence
    if (
      isNil(
        diagnosisSide?.find(
          (d) =>
            this.AutofillHelperService.getOptionKey(targetKey, d) ===
            this.AutofillHelperService.getOptionKey(
              targetKey,
              autofillDiagnosis
            )
        )
      )
    ) {
      set(
        recordData,
        `management.diagnosis_array.${side}`,
        cloneDeep(newDiagnosisArraySide)
      );
    }

    // // index check, the array will always be defined
    // const newIndex: number = !isEmpty(newDiagnosisArraySide)
    //   ? newDiagnosisArraySide.length - 1
    //   : -1;

    // autofill

    // // setup timestamp linking
    // this.LoggingService.pushToRecordChangesLog({
    //   sourceKey: DIAGNOSIS_ARRAY_KEY,
    //   sourcePath: `management.diagnosis_array.${side}`,
    //   previousValue: oldDiagnosisArraySide,
    //   currentValue: recordData?.management?.diagnosis_array?.[side],
    //   type: "autofill",

    //   originKey: DIAGNOSIS_ARRAY_KEY,
    //   originPath: `management.diagnosis_array.${side}`,
    // });

    // alert user
    this.toastr.info(this.autofillMessages.info.autofill.diagnosis);

    // if autofilled as a target, we must also
    // check if we need to do a second level autofill
    // by emiting a new autofill object
    const diagnosisIndex: number = newDiagnosisArraySide.findIndex((d) =>
      isEqual(d, autofillDiagnosis)
    );

    this.emitAutofillEvent({
      sourceKey: targetKey,
      sourcePath: targetPath,
      side,
      currentValue: cloneDeep(autofillDiagnosis),
      previousValue: null, // no previous value as it was an autofill
      id,
      type: "autofill",
      index: diagnosisIndex,

      originKey: targetKey,
      originPath: targetPath,
      recordData,
      extraData,

      disableOnBeforeCleanup: true,
    });
  }

  // handles prefill where
  // this is only triggered if a value in the source and target destinations
  prefillAsTarget(change: IGlChangesRecordData) {
    const { targetKey, targetPath, targetValue, recordData, id, extraData } =
      change;
    const side: IGlSideBilateral = change.side as IGlSideBilateral;

    const autofillDiagnosis = targetValue as GlDiagnosis;

    // find index to the first instance of this key
    const diagnosisArrayPath: string = `${targetPath}.${targetKey}.${side}`;
    const diagnosisSide: GlDiagnosis[] = get(recordData, diagnosisArrayPath);
    const indexOfFoundDiagnosis: number = diagnosisSide.findIndex(
      (d) =>
        this.AutofillHelperService.getOptionKey(targetKey, d) ===
        this.AutofillHelperService.getOptionKey(targetKey, targetValue)
    );

    // if no index dont bother
    if (indexOfFoundDiagnosis === -1) {
      return;
    }

    //else first set the id of the source to be the same as the target
    set(recordData, `${diagnosisArrayPath}.${indexOfFoundDiagnosis}.id`, id);

    // alert user
    this.toastr.info(this.autofillMessages.info.prefill.diagnosis);

    // do autofill event after
    this.emitAutofillEvent({
      sourceKey: targetKey,
      sourcePath: targetPath,
      side,
      currentValue: cloneDeep(autofillDiagnosis),
      previousValue: null, // no previous value as it was an autofill
      id,
      type: "manual",
      index: indexOfFoundDiagnosis,

      originKey: targetKey,
      originPath: targetPath,
      recordData,
      extraData,

      disableOnBeforeCleanup: true,
    });
  }

  clear({
    side,
    path,
    key = DIAGNOSIS_ARRAY_KEY,
    diagnosis,
    recordData,
    extraData,
  }: {
    side: IGlSideBilateral;
    key: string;
    path: string;
    diagnosis: GlDiagnosis;
    recordData: PatientRecordData;
    extraData?: any;
  }) {
    // diagnosis array we need to find target value and remove it by key
    const diagnosisArrayPath: string = `${path}.${key}.${side}`;
    const diagnosisSide: GlDiagnosis[] =
      get(recordData, diagnosisArrayPath) ?? [];
    const diagnosisIndex: number = diagnosisSide?.findIndex(
      (d) =>
        this.AutofillHelperService.getOptionKey(key, d) ===
        this.AutofillHelperService.getOptionKey(key, diagnosis)
    );

    // get out of jail card (dont touch anything)
    if (diagnosisIndex === -1) {
      return;
    }

    // if index is 0 and current length is 1, replace with default
    if (diagnosisIndex === 0 && diagnosisSide.length === 1) {
      set(
        recordData,
        diagnosisArrayPath,
        cloneDeep([this.DiagnosisService.getDefaultDiagnosis("All")])
      );
    } else {
      // otherwise remove by index
      recordData[path][key][side].splice(diagnosisIndex, 1);
    }

    this.toastr.success(this.autofillMessages.success.undo);

    // * ADD THIS TO TRICKLE DOWN AND CHECK IF VALUE WAS A SOURCE
    // we emit an undo autofill event as there are cases where a target can
    // be a source as well
    this.emitUndoAutofillEvent({
      value: diagnosis,
      side,
      recordData,
      extraData,
    });
  }

  // removes autofill
  revert(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { sourcePath, sourceKey, side, currentValue, id, recordData, index } =
      change;

    // get internal source key
    const internalSourceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        side,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        id,
      });

    // get path to value we need to replace
    const diagnosisArrayPath: string = `${!isEmpty(sourcePath) ? `${sourcePath}.` : ""
      }${sourceKey}.${side}`;
    const diagnosisSide: GlDiagnosis[] =
      get(recordData, diagnosisArrayPath) ?? [];

    // find index
    const diagnosisIndex: number =
      index ??
      diagnosisSide?.findIndex(
        (d) =>
          this.AutofillHelperService.getOptionKey(sourceKey, d) ===
          this.AutofillHelperService.getOptionKey(sourceKey, currentValue)
      );

    // for the previous value, we either use what is there from the
    // previous data
    // or depending on the
    // index of the diagnosis
    // more than 1: undefined (hide it)
    // 0 or -1, set to an empty object to avoid bugs
    const previousSourceValue: any =
      this.PatientRecordHelperService.getValueFromChangesStack(
        internalSourceKey
      ) ?? (diagnosisIndex > 0 ? undefined : {});

    // if index is 0 and current length is 1, replace with default
    if (diagnosisIndex === 0 && diagnosisSide.length === 1) {
      set(recordData, diagnosisArrayPath, cloneDeep([previousSourceValue]));
    } else if (diagnosisIndex >= 0) {
      // otherwise set by index
      set(
        recordData,
        `${diagnosisArrayPath}.${diagnosisIndex}`,
        cloneDeep(previousSourceValue)
      );
    }
  }

  // * HAS DIAGNOSIS
  hasExistingDiagnosis(changes: IGlChangesRecordData): boolean {
    const { targetValue, side, recordData } = changes;
    // cast
    const autofillDiagnosis = targetValue as GlDiagnosis;
    // old diagnosis array
    const diagnosisArraySide: GlDiagnosis[] =
      get(recordData, `management.diagnosis_array.${side}`) ?? [];

    const existingDiagnosis: GlDiagnosis = diagnosisArraySide.find((d) => {
      const checkDiagnosis: string = this.AutofillHelperService.getOptionKey(
        DIAGNOSIS_ARRAY_KEY,
        d
      );
      const targetDiagnosis: string = this.AutofillHelperService.getOptionKey(
        DIAGNOSIS_ARRAY_KEY,
        autofillDiagnosis
      );
      return (
        !isNil(checkDiagnosis) &&
        !isNil(targetDiagnosis) &&
        this.AutofillHelperService.getOptionKey(DIAGNOSIS_ARRAY_KEY, d) ===
        this.AutofillHelperService.getOptionKey(
          DIAGNOSIS_ARRAY_KEY,
          autofillDiagnosis
        )
      );
    });

    return !isNil(existingDiagnosis);
  }

  emitUndoAutofillEvent({
    key = DIAGNOSIS_ARRAY_KEY,
    value,
    side,
    recordData,
    extraData,
  }: {
    key?: string;
    value: any;
    side: IGlSideBilateral;
    recordData: PatientRecordData;
    extraData: any;
  }) {
    const path: string = this.AutofillHelperService.getAutofillPath(key);
    const undoChange: IGlChangesRecordDataOrigin = {
      currentValue: value,
      side,
      originKey: key,
      originPath: path,
      sourceKey: key,
      sourcePath: path,
      type: "autofill_undo",
      id: value?.id,
      origin: "both",
      recordData,
      extraData,
    };
    this.ChangesService.publish(undoChange);
  }

  emitAutofillEvent(change: IGlChangesRecordData) {
    this.ChangesService.publish(change);
  }
}
