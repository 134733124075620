import { isNil } from "lodash";
import { WebAudioPeakMeter } from 'web-audio-peak-meter';

import './audio-peak-meter.scss';
import angular = require("angular");

export class AudioPeakMeterController implements angular.IOnChanges, angular.IOnInit {
  audioStream: MediaStream;

  private audioCtx: AudioContext;
  private audioSource: AudioNode;

  private peakMeterRef: any;
  private meterInstance: WebAudioPeakMeter;


  constructor() {
    "ngInject";
  }

  $onInit() {
    this.audioCtx = new window.AudioContext();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes?.audioStream) {
      if (this.audioStream) {
        this.visualiseAudio();
      } else {
        this.removeInstance();
        this.suspendAudioContext();
      }
    }
  }


  visualiseAudio() {
    if (!this.audioStream) {
      return;
    }

    // audio context
    this.audioCtx = new window.AudioContext();
    // create audio source
    this.audioSource = this.audioCtx.createMediaStreamSource(this.audioStream);
    if (!isNil(this.peakMeterRef?.[0])) {
      this.meterInstance = new WebAudioPeakMeter(this.audioSource, this.peakMeterRef?.[0]);
    }
  }

  removeInstance() {
    if (this.meterInstance) {
      this.meterInstance.cleanup();
      this.meterInstance = undefined;

      // cancel audio source
      this.audioSource = undefined;
    }
  }

  suspendAudioContext() {
    if (this.audioCtx) {
      this.audioCtx.suspend();
    }
  }

  hasAudioStream() {
    return !isNil(this.audioStream);
  }
}

export class AudioPeakMeter {
  static selector: string = "audioPeakMeter";
  static template = require('./audio-peak-meter.html');
  static controller = AudioPeakMeterController;
  static bindings = {
    audioElementRef: "<",
    audioStream: "<",
  };
}