import { cloneDeep, get, isEmpty, isNil, set } from "lodash";
import { DIAGNOSIS_ARRAY_KEY } from "../../../../lib/key-appendix";
import { AutofillHelperService } from "../autofill-helper/autofill-helper.service";

import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { IGlSide } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";
import { AutofillDiagnosisService } from "../autofill-diagnosis/autofill-diagnosis.service";
import { IGlAutofill } from "../autofill/autofill.service";
import { ChangesService } from "../changes/changes.service";
import { ToastrAppendix } from "../toastr-appendix/toastr-appendix";
import { PatientRecordHelperService } from "../patient-record-helper/patient-record-helper.service";

export class AutofillBilateralMultipleService implements IGlAutofill {
  static injectionName: string = "AutofillBilateralMultipleService";

  autofillMessages = this.ToastrAppendix.getAutofillMessages();

  constructor(
    private ToastrAppendix: ToastrAppendix,
    private toastr: angular.toastr.IToastrService,

    private PatientRecordHelperService: PatientRecordHelperService,
    private AutofillHelperService: AutofillHelperService,
    private AutofillDiagnosisService: AutofillDiagnosisService,
    private ChangesService: ChangesService
  ) {
    "ngInject";
  }

  // autofill handlers by source
  autofillAsSource(change: IGlChangesRecordData) {
    const {
      sourceKey,
      sourcePath,
      targetKey,
      targetPath,
      targetValue,
      currentValue,
      previousValue,
      side,
      id,
    } = change;

    // examples in this case
    // generate reference, instead of parent key leave it as absolute path
    // this always refers to target no matter what
    const internalSourceReferenceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        side,
        id,
      });

    // then handle depending on others
    let internalTargetReferenceKey: string;

    // we handle the target edge cases by switch key or a loop
    switch (targetKey) {
      // diagnosis has its own autofill
      case DIAGNOSIS_ARRAY_KEY:
        // we have to check if an exisitng diagnosis exsits before continuing
        if (this.AutofillDiagnosisService.hasExistingDiagnosis(change)) {
          return this.toastr.info(this.autofillMessages.error.autofill.exists);
        }

        // MOVE EXISTING DIAGNOSIS CHECK HERE
        // THEN DOUBLE CONFIRM
        internalTargetReferenceKey =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: targetPath,
            fieldKey: targetKey,
            valueKey: this.AutofillHelperService.getOptionKey(
              targetKey,
              targetValue
            ),
            // this.DiagnosisService.convertDiagnosisOptionToKey(targetValue),
            side,
            id,
          });

        // setup bidirectional link
        this.AutofillHelperService.setAutofillLinkSourceTargetBidirectional(
          internalSourceReferenceKey,
          internalTargetReferenceKey
        );

        // autofill
        this.AutofillDiagnosisService.autofillAsTarget({
          ...change,
          internalSourceKey: internalSourceReferenceKey,
          internalTargetKey: internalTargetReferenceKey,
        });

        // set value for old source to undo
        // target isnt needed as there was nothing before that
        this.PatientRecordHelperService.updateChangesStack(
          internalSourceReferenceKey,
          previousValue
        );

        break;

      default:
        break;
    }
  }

  revert(change: IGlChangesRecordDataOrigin) {
    // get all values
    const {
      sourcePath,
      sourceKey,
      side,
      currentValue,
      id,
      recordData,
      index,
      // extraData,
    } = change;

    // get path to value we need to replace
    const pathToValue: string = `${
      !isEmpty(sourcePath) ? `${sourcePath}.` : ""
    }${sourceKey}.${side}`;

    // * its either use provided index or fallback on finding it
    const valueIndex: number =
      index ??
      get(recordData, pathToValue)?.findIndex(
        (o) =>
          this.AutofillHelperService.getOptionKey(sourceKey, currentValue) ===
          this.AutofillHelperService.getOptionKey(sourceKey, o)
      );

    // get internal source key
    const internalSourceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        side: side,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        id,
      });

    // get previous value
    const previousSourceValue: any =
      this.PatientRecordHelperService.getValueFromChangesStack(
        internalSourceKey
      ) ?? null;

    // if (this.getOptionKey(sourceKey, currentValue) !== this.getOptionKey(sourceKey, targetCheck)) {
    //   return
    // }

    if (!isNil(internalSourceKey) && valueIndex >= 0) {
      // set value
      set(
        recordData,
        `${pathToValue}.${valueIndex}`,
        cloneDeep(previousSourceValue)
      );
    }

    // // try and undo the value as a target as well
    // this.emitUndoAutofillEvent({
    //   value: currentValue,
    //   key: sourceKey,
    //   side,
    //   recordData,
    //   extraData,
    // });
  }

  emitUndoAutofillEvent({
    key,
    value,
    side,
    recordData,
    extraData,
  }: {
    key: string;
    value: any;
    side: IGlSide;
    recordData: PatientRecordData;
    extraData: any;
  }) {
    // bilateral select multiple requires a key as there can be multiple differne types
    if (!key) {
      return;
    }
    const path: string = this.AutofillHelperService.getAutofillPath(key);
    // undo event
    const undoChange: IGlChangesRecordDataOrigin = {
      currentValue: value,
      side,
      originKey: key,
      originPath: path,
      sourceKey: key,
      sourcePath: path,
      type: "autofill_undo",
      id: value?.id,
      origin: "both",
      recordData,
      extraData,
    };
    this.ChangesService.publish(undoChange);
  }
}
