import {
  cloneDeep,
  every,
  isEmpty,
  isEqual,
  isNil,
  some,
  transform,
} from "lodash";
import {
  IGlChanges,
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { IGlSide, IGlSideBilateral } from "models/gl-side.model";
import { GlDiagnosis, PatientRecordData } from "models/patient-record.model";
import { Observable, Subject, filter } from "rxjs";
import {
  DIAGNOSIS_ARRAY_KEY,
  EXTERNAL_PROCEDURES_KEY,
  LENS_OBSERVATIONS_KEY,
  LENS_STATUS_KEY,
  MACULAR_LEGACY_KEY,
  MACULAR_V2_KEY,
  MAC_OCT_V2_KEY,
} from "../../../../lib/key-appendix";
import { Appendix, IGlOptionExtra } from "../appendix";
import { AutofillBilateralMultipleService } from "../autofill-bilateral-multiple/autofill-bilateral-multiple.service";
import { AutofillDiagnosisService } from "../autofill-diagnosis/autofill-diagnosis.service";
import { AutofillExternalProcedureService } from "../autofill-external-procedure.service/autofill-external-procedure.service";
import {
  AutofillHelperService,
  IValueAutofillKeyParams,
} from "../autofill-helper/autofill-helper.service";
import { AutofillPosteriorLensService } from "../autofill-posterior-lens/autofill-posterior-lens.service";
import { ChangesService } from "../changes/changes.service";
import { PatientRecordHelperService } from "../patient-record-helper/patient-record-helper.service";
import { ToastrAppendix } from "../toastr-appendix/toastr-appendix";

export class AutofillService {
  static injectionName: string = "AutofillService";

  // for autofill
  private _autofillSubject$: Subject<IGlChanges> = new Subject<IGlChanges>();

  // reference for old data
  private previousRecordData: PatientRecordData;

  private autofillMessages = this.ToastrAppendix.getAutofillMessages();

  public autofillSubject$ = this._autofillSubject$.asObservable();

  constructor(
    private appendix: Appendix,
    private toastr: angular.toastr.IToastrService,
    private PatientRecordHelperService: PatientRecordHelperService,
    private ChangesService: ChangesService,
    private ToastrAppendix: ToastrAppendix,

    // AUTOFILL HELPERS
    private AutofillHelperService: AutofillHelperService,
    private AutofillBilateralMultipleService: AutofillBilateralMultipleService,
    private AutofillDiagnosisService: AutofillDiagnosisService,
    private AutofillPosteriorLensService: AutofillPosteriorLensService,
    private AutofillExternalProcedureService: AutofillExternalProcedureService
  ) {
    "ngInject";

    // instatiate the autofill service to hook to the changes
    // * handle all autofill changes
    this.ChangesService.changes$
      .pipe(
        filter(
          (change: IGlChangesRecordData) =>
            !isNil(change?.recordData) && !isEmpty(change?.recordData)
        )
      )
      .subscribe(
        (change: IGlChangesRecordData | IGlChangesRecordDataOrigin) => {
          const oldRecordData: PatientRecordData = cloneDeep(change.recordData);
          // then after set previous data
          // set record data, since it has already been filtered
          this.previousRecordData = oldRecordData;
          // setup internal paths and replace
          // paths as well for the system
          change = {
            ...change,
            sourcePath: this.getAutofillPath(change.sourceKey),
            targetPath: this.getAutofillPath(change.targetKey),
            originPath: this.getAutofillPath(change.originKey),
          };

          // switch case
          switch (change.type) {
            case "copy":
            case "manual":
            case "autofill":
              // before autofilling see what we need to undo
              // by default this is always triggered but there are cases where we can bypass
              // with a flag
              // e.g. consequitve autofills
              if (!change.disableOnBeforeCleanup) {
                this.onBeforeAutofill(change);
              }

              // should check if we need to autofill or not first
              if (!this.checkIfShouldAutofill(change)) {
                return;
              }

              // if all good then handle on before autofill
              // then confirm autofill
              this.handleAutofill(change);
              break;
            case "prefill":
              this.handlePrefill(change);
              break;
            case "autofill_confirm":
              this.handleConfirmAutofill(change as IGlChangesRecordDataOrigin);
              break;
            case "autofill_undo":
              this.handleUndoAutofill(change as IGlChangesRecordDataOrigin);
              break;
            default:
              break;
          }
        }
      );
  }

  /*  HELPFUL DEBUGGER */
  checkMapping() {
    console.log(
      "mapping",
      this.AutofillHelperService.getSourceToTargetAutofillMapping(),
      this.AutofillHelperService.getTargetToSourceAutofillMapping()
    );
  }

  /* 
    PREFILL SECTION 
    prefills will always be called from the source regardless
  */
  handlePrefill(change: IGlChangesRecordData) {
    const { sourceKey, currentValue } = change;

    // 1. cehck if option is prefill based on key
    const isPrefill: boolean = this.AutofillHelperService.isOptionPrefill(
      sourceKey,
      this.getOptionKey(sourceKey, currentValue)
    );

    // do nothign if not prefill
    if (!isPrefill) {
      return;
    }

    // targets
    const prefillTargets: string[] =
      this.AutofillHelperService.getPrefillTargetKeys(sourceKey);

    // for each target key found, only enact autofill if the value exists in the specified
    // target destination
    for (const targetKey of prefillTargets) {
      const targetPath: string =
        this.AutofillHelperService.getAutofillPath(targetKey);
      const targetValue: any = this.convertToAutofillOutput({
        targetKey,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
      });

      // go by source key
      switch (sourceKey) {
        case LENS_STATUS_KEY:
          this.AutofillPosteriorLensService.prefillLensStatus({
            ...change,
            targetKey,
            targetPath,
            targetValue,
          });
          break;
        default:
          break;
      }
    }
  }

  /**
   * before autofill, handle these changes first
   * @param change cahgnes object to listen out to
   * @returns
   */
  checkIfShouldAutofill(change: IGlChanges): boolean {
    const { originKey, originPath, currentValue, side } = change;
    // for autofill, we do not use the passed down path as there
    // needs to be a unique reference for it

    // for now
    /*
      STEP 1: determine if the current selected value already 
      has an existing autofill
      if so ignore outright 
    */
    if (
      this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
        path: originPath,
        fieldKey: originKey,
        side,
        valueKey: this.getOptionKey(originKey, currentValue),
      })
    ) {
      this.toastr.error(this.autofillMessages.error.autofill.exists);
      return false;
    }

    /* 
      STEP 2: check if the current value has an existing value
      in the target area and if so, ignore and dont move on
    */

    // if any of the top has been triggered it should be false

    // determine if we can continue after that
    return true;
  }

  // this runs first on cleanup
  // the function is always called on autofill, meaning that it will
  // always be triggered from a source value and never a target value
  // we will always trace from the origin key
  onBeforeAutofill(change: IGlChangesRecordData | IGlChangesRecordDataOrigin) {
    const { previousValue, side, originKey, originPath, id } = change;

    // * try and handle as a source and as a target
    const targetKeys: string[] =
      this.AutofillHelperService.getAutofillTargets(originKey);
    const sourceKeys: string[] =
      this.AutofillHelperService.getAutofillSources(originKey);

    // * AS A SOURCE AUTOFILL
    // get a filtered projection of which source <-> targets to autofill
    const filteredTargetAutofillKeys: string[] = targetKeys?.filter(
      (_targetKey) => {
        const _internalSourceKey: string =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: originPath,
            fieldKey: originKey,
            side,
            valueKey: this.getOptionKey(originKey, previousValue),
            id,
          });

        // infer by active target autofill values which ones we should trigger
        const _internalTargetKeys: string[] =
          this.AutofillHelperService.getSourceTargetAutofillValues(
            _internalSourceKey
          );
        const _targetKeys: string[] =
          this.convertInternalTargetKeysToDestinationKeys(_internalTargetKeys);

        return _targetKeys.includes(_targetKey);
      }
    );

    // * we treat the source key as a target key here
    const filteredSourceAutofillKeys: string[] = sourceKeys.filter(
      (_sourceKey: string) => {
        // treat source key as a target key
        const _internalTargetKey: string =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: originPath,
            fieldKey: originKey,
            side,
            valueKey: this.getOptionKey(originKey, previousValue),
            id,
          });

        // infer by active source autofill values which ones we should trigger
        const _internalSourceKeys: string[] =
          this.AutofillHelperService.getTargetSourceAutofillValues(
            _internalTargetKey
          );

        const _sourceKeys: string[] =
          this.convertInternalTargetKeysToDestinationKeys(_internalSourceKeys);

        return _sourceKeys.includes(_sourceKey);
      }
    );

    // for each key found undo it
    // * TRY AS A SOURCE UNDO
    for (const _targetKey of filteredTargetAutofillKeys) {
      this.undoAutofillFromSource({
        ...change,
        sourceKey: originKey,
        sourcePath: originPath,
        targetKey: _targetKey,
        targetPath: this.getAutofillPath(_targetKey),
        currentValue: previousValue,
        previousValue: null,
        origin: "source",
        id,
      });
    }

    // * TRY AS A TARGET UNDO
    for (const _sourceKey of filteredSourceAutofillKeys) {
      const _sourcePath: string =
        this.AutofillHelperService.getAutofillPath(_sourceKey);
      this.undoAutofillFromTarget({
        ...change,
        sourceKey: _sourceKey,
        sourcePath: _sourcePath,
        // treat "current source" as a taret
        targetKey: originKey,
        targetPath: originPath,
        currentValue: previousValue,
        previousValue: null,
        origin: "target",
        id,
      });
    }
  }

  // for checking autofill on changes
  getAutofillSubject(): Observable<IGlChanges> {
    return this.autofillSubject$;
  }

  /* PUBLISHERS */
  publishToAutofillSubject(change: IGlChanges) {
    this._autofillSubject$.next(change);
  }

  // DELETE
  // remove via needle and haystack method
  removeTargetKeyFromSourceAutofill(targetKey: string, sourceKey: string) {
    const targetKeys: string[] =
      this.AutofillHelperService.getSourceTargetAutofillValues(sourceKey);
    if (!isEmpty(targetKeys)) {
      const removeIndex: number = targetKeys?.findIndex((k) =>
        isEqual(k.toLowerCase(), targetKey.toLowerCase())
      );
      // only execute if there is an index
      removeIndex !== -1 && targetKeys.splice(removeIndex, 1);

      // if no keys are left, remove the entire thing
      if (isEmpty(targetKeys)) {
        this.AutofillHelperService.deleteFromSourceAutofillMapping(sourceKey);
        // if source is also empty then handle remove for that
      } else {
        // otherwise add remaining target keys back
        this.AutofillHelperService.setAutofillSourceValueArray(
          sourceKey,
          targetKeys
        );
      }
    }
  }

  removeSourceKeyFromTargetAutofill(sourceKey: string, targetKey: string) {
    const sourceKeys: string[] =
      this.AutofillHelperService.getTargetSourceAutofillValues(targetKey);

    // * only remove if there are remaining keys
    if (!isEmpty(sourceKeys)) {
      const removeIndex: number = sourceKeys?.findIndex((k) =>
        isEqual(k.toLowerCase(), sourceKey.toLowerCase())
      );

      // only execute if there is an index
      removeIndex !== -1 && sourceKeys.splice(removeIndex, 1);

      // if no keys are left, remove the entire thing
      if (isEmpty(sourceKeys)) {
        this.AutofillHelperService.deleteFromTargetAutofillMapping(targetKey);
      } else {
        // otherwise add remaining target keys back
        this.AutofillHelperService.setAutofillTargetValueArray(
          targetKey,
          sourceKeys
        );
      }
    }
  }

  // delete source and any reference to source from its targets
  removeSourceAutofillValue(sourceKey: string) {
    // first get target keys
    const targetKeys: string[] =
      this.AutofillHelperService.getSourceTargetAutofillValues(sourceKey);
    if (!targetKeys) {
      return;
    }

    // delete
    this.AutofillHelperService.deleteFromSourceAutofillMapping(sourceKey);

    // for each target remove any other links to source key
    targetKeys.forEach((_targetKey: string) => {
      // this time we use target key as the source
      this.removeSourceKeyFromTargetAutofill(sourceKey, _targetKey);
    });
  }

  removeTargetAutofillValue(targetKey: string) {
    const sourceKeys: string[] =
      this.AutofillHelperService.getTargetSourceAutofillValues(targetKey);

    this.AutofillHelperService.deleteFromTargetAutofillMapping(targetKey);

    sourceKeys.forEach((_sourceKey: string) => {
      this.removeTargetKeyFromSourceAutofill(targetKey, _sourceKey);
    });
  }

  // both ways technically
  removeAutofillValueForBoth(key: string, value: string) {
    this.removeSourceAutofillValue(key);
    this.removeSourceAutofillValue(value);
  }

  // MISC
  // surpresses autofill warnings if outside of any of the main pages
  shouldShowAutofillWarnings() {
    // check for
    // record -> patient/{}/record/
    // history record -> patient/{}/history/
    // virtual review -> patient/{}/virtual-review/
    const HREF_REGEX_PATTERNS: RegExp[] = [
      /patient\/\d+\/record\//,
      /patient\/\d+\/history\//,
      /patient\/\d+\/virtual-review\//,
    ];
    // should map
    const matchMapping: boolean[] = HREF_REGEX_PATTERNS.map((regex) =>
      regex.test(window.location.href)
    );
    // if any of them match show
    return some(matchMapping, true);
  }

  // compare changes
  getChangesDeep(obj1: any, obj2: any) {
    return transform(
      obj1,
      (result, value, key) => {
        if (!isEqual(value, obj2?.[key])) {
          result[key] = value;
        }
      },
      {}
    );
  }

  // given a key, get the absolute path
  getAutofillPath(field: string) {
    // default would be "" referencing data
    return this.AutofillHelperService.getAutofillPath(field);
  }

  convertSectionValueToAnotherByKey(
    valueKey: string,
    sourceKey: string,
    targetKey: string
  ) {
    // checks on observation as thats where most of
    // the autofill sources are from/will be
    const sourceKeyFromObservation: boolean =
      this.keyIsFromObservation(sourceKey);
    const targetKeyFromObservation: boolean =
      this.keyIsFromObservation(targetKey);

    // OBS <-> DIAG
    if (sourceKey === DIAGNOSIS_ARRAY_KEY && targetKeyFromObservation) {
      return this.AutofillHelperService.getObservationAutofillByKey(valueKey);
    }

    // DIAG <-> OBS
    if (sourceKeyFromObservation && targetKey === DIAGNOSIS_ARRAY_KEY) {
      return this.AutofillHelperService.getDiagnosisAutofillByKey(valueKey);
    }

    return undefined;
  }

  keyIsFromObservation(sourceKey: string) {
    const observationKeys: string[] = [
      MAC_OCT_V2_KEY,
      // macular
      MACULAR_LEGACY_KEY,
      MACULAR_V2_KEY,
    ];
    return observationKeys.includes(sourceKey);
  }

  // autofill?
  isOptionAutofill(field: string, key: string) {
    const option: IGlOptionExtra =
      this.AutofillHelperService.getAutofillOptionWhereKey(field, key);
    if (!isNil(option)) {
      return option.autofill;
    }
    return false;
  }

  // by default will always be true
  isOptionMultiSelect(field: string, key: string) {
    const option: IGlOptionExtra =
      this.AutofillHelperService.getAutofillOptionWhereKey(field, key);
    if (!isNil(option)) {
      return !option.disableMultiSelect;
    }
    return true;
  }

  // get autofill option key
  getOptionKey(key: string, option: any) {
    return this.AutofillHelperService.getOptionKey(key, option);
  }

  findOptionFromParentKey(sourceKey: string, targetKey: string, option: any) {
    const _sourceOptionKey: string = this.getOptionKey(sourceKey, option);
    // check by target
    switch (targetKey) {
      case DIAGNOSIS_ARRAY_KEY:
        return this.AutofillHelperService.getDiagnosisAutofillByKey(
          _sourceOptionKey
        );
      case EXTERNAL_PROCEDURES_KEY:
        return this.AutofillHelperService.getExternalProcedureAutofillByKey(
          _sourceOptionKey
        );
      default:
        return;
    }
  }

  // retrieve the potential destination field from a compound key
  getDestinationFieldFromKey(internalKey: string) {
    // honestly the easiest was is just a large statement
    const _internalKey: string = internalKey.toLowerCase();

    // OBSERVATION
    if ([MAC_OCT_V2_KEY].find((k) => _internalKey.includes(k))) {
      return MAC_OCT_V2_KEY;
    }
    if ([MACULAR_V2_KEY].find((k) => _internalKey.includes(k))) {
      return MACULAR_V2_KEY;
    }

    // DIAGNOSIS
    if ([DIAGNOSIS_ARRAY_KEY].find((k) => _internalKey.includes(k))) {
      return DIAGNOSIS_ARRAY_KEY;
    }

    // POSTERIOR LENS
    if ([LENS_OBSERVATIONS_KEY].find((k) => _internalKey.includes(k))) {
      return LENS_OBSERVATIONS_KEY;
    }
    if ([LENS_STATUS_KEY].find((k) => _internalKey.includes(k))) {
      return LENS_STATUS_KEY;
    }

    // EXTERNAL PROCEDURE
    if ([EXTERNAL_PROCEDURES_KEY].find((k) => _internalKey.includes(k))) {
      return EXTERNAL_PROCEDURES_KEY;
    }

    // default is empty
    return "";
  }

  convertInternalTargetKeysToDestinationKeys(targetKeys: string[]): string[] {
    return targetKeys.map((k) => this.getDestinationFieldFromKey(k)) ?? [];
  }

  // conversion depending on soruce and target keys
  convertToAutofillOutput({
    targetKey,
    valueKey,
  }: Pick<IGlChanges, "targetKey"> & { valueKey: string; }) {
    // map to diagnosis
    switch (targetKey) {
      // OBS
      case "mac_oct_v2":
      case MAC_OCT_V2_KEY:
      case MACULAR_V2_KEY:
      case MACULAR_LEGACY_KEY:
        return this.AutofillHelperService.getObservationAutofillByKey(valueKey);

      // DIAG
      case DIAGNOSIS_ARRAY_KEY:
        return this.AutofillHelperService.getDiagnosisAutofillByKey(valueKey);

      // external procedures
      case EXTERNAL_PROCEDURES_KEY:
        return this.AutofillHelperService.getExternalProcedureAutofillByKey(
          valueKey
        );

      case LENS_STATUS_KEY:
        return this.AutofillHelperService.getObservationAutofillByKey(valueKey);
      default:
        return;
    }
  }

  // AUTOFILL HELPERS
  // test autofill call
  handleAutofill(change: IGlChangesRecordData) {
    const { sourcePath, sourceKey, currentValue, recordData } = change;

    // fetches any found autofill option
    const currentValueExtra = this.appendix.isOptionAutofill(
      sourceKey,
      this.getOptionKey(sourceKey, currentValue)
    );

    // find if there are autofills
    const autofillTargets: string[] =
      this.AutofillHelperService.getAutofillTargets(sourceKey) ?? [];

    // only if we have autofill targets and
    // currentValueExtra has an autofill, we proceed
    if (isNil(currentValueExtra) || isEmpty(autofillTargets)) {
      return;
    }

    // * each source can result in many target autofills
    for (const targetKey of autofillTargets) {
      // get the path for target key
      const targetPath: string = this.getAutofillPath(targetKey);
      // fetch the relevant option key mapping
      const valueKey: string = this.getOptionKey(sourceKey, currentValue);
      // conversion
      const targetValue: any = this.convertToAutofillOutput({
        // get key based on where it originated from
        valueKey,
        targetKey,
      });

      // if we find theres no value dont bother
      if (isNil(targetValue)) {
        return;
      }

      // clone the section we will be updating
      const oldRecordData: PatientRecordData = cloneDeep(recordData);

      const changeObj: IGlChangesRecordData = {
        ...change,
        sourcePath,
        targetKey,
        targetPath,
        targetValue,
        oldRecordData,
      };

      // previous and current value check
      switch (sourceKey) {
        // mac_oct
        case "oct_mac":
        case "mac_oct":
        case MAC_OCT_V2_KEY:
        case "mac_oct_v2":
          this.handleMacOctAutofill(changeObj);
          break;

        // macular
        case MACULAR_LEGACY_KEY:
        case MACULAR_V2_KEY:
          this.handleMacularAutofill(changeObj);
          break;

        case LENS_STATUS_KEY:
        case "lens_status":
        case "lensPhakic":
          this.handleLensStatusAutofill(changeObj);
          break;

        case LENS_OBSERVATIONS_KEY:
        case "lens_observations":
        case "IOLType":
          this.handleLensObservationAutofill(changeObj);
          break;
        // daignosis will autofill on external procedures for now
        case DIAGNOSIS_ARRAY_KEY:
          this.handleDiagnosisAutofillAsSource(changeObj);
          break;

        default:
          break;
      }
    }
  }

  /**
   * * AUTOFILL CASE HANDLERS
   * * These help handle different use cases and keys
   */

  // Segment Posterior
  // lens
  handleLensStatusAutofill(change: IGlChangesRecordData) {
    this.AutofillPosteriorLensService.autofillStatus(change);
  }
  handleLensObservationAutofill(change: IGlChangesRecordData) {
    this.AutofillPosteriorLensService.autofillObservation(change);
  }

  // mac_oct_v2
  handleMacOctAutofill(change: IGlChangesRecordData) {
    this.AutofillBilateralMultipleService.autofillAsSource(change);
  }

  // macular_v2
  handleMacularAutofill(change: IGlChangesRecordData) {
    this.AutofillBilateralMultipleService.autofillAsSource(change);
  }

  // Diagnosis
  handleDiagnosisAutofillAsTarget(change: IGlChangesRecordData) {
    this.AutofillDiagnosisService.autofillAsTarget(change);
  }

  handleDiagnosisAutofillAsSource(change: IGlChangesRecordData) {
    this.AutofillDiagnosisService.autofillAsSource(change);
  }

  /* CONFIRM */
  // source never has an autofill confirm  for both as it is a case by case thing
  handleConfirmAutofill(change: IGlChangesRecordDataOrigin) {
    switch (change.origin) {
      case "source":
        return this.confirmAutofillFromSource(change);
      case "target":
        return this.confirmAutofillFromTarget(change);
      case "both":
        return this.confirmAutofillAsBoth(change);
      default:
        return;
    }
  }

  // from source
  confirmAutofillFromSource(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { targetPath, sourceKey, targetKey, currentValue, extraData } =
      change;
    const targetValue: any = this.convertToAutofillOutput({
      valueKey: this.getOptionKey(sourceKey, currentValue),
      targetKey,
    });

    // see if we need to confirm anything from the source side
    switch (targetKey) {
      case EXTERNAL_PROCEDURES_KEY:
        // have to create a procedure and remove the temporary one
        this.AutofillExternalProcedureService.createExternalProcedureFromTemporary(
          {
            externalProcedureData: targetValue,
            recordId: extraData?.recordId,
          }
        );
        break;
      default:
        break;
    }

    // * handle keys
    // if the target key might have a both side autofill (i.e. two sources linked to one target)
    if (this.AutofillHelperService.isBilateralAutofillType(targetKey)) {
      // basically handling from the left and right side respective with
      // each side's uuid
      for (const side of this.AutofillHelperService.getSides()) {
        const similarTargetKey: string =
          this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
            path: targetPath,
            fieldKey: targetKey,
            side,
            valueKey: this.getOptionKey(targetKey, currentValue),
          });
        const foundId: string =
          similarTargetKey?.split(".")?.slice(-1)?.[0] ?? undefined;

        // confirm
        this._confirmAutofillFromSource({
          ...change,
          side,
          id: foundId,
        });
      }
    } else {
      // otherwise its a 1-1 thing (e.g. left side to left side)
      this._confirmAutofillFromSource(change);
    }

    this.toastr.success(this.autofillMessages.success.confirm);
  }

  confirmAutofillFromTarget(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { targetKey, targetPath, currentValue, side, extraData } = change;

    // * check if there's any specific autofill actions required
    // see if we need to confirm anything from the target side
    switch (targetKey) {
      case EXTERNAL_PROCEDURES_KEY:
        // have to create a procedure and remove the temporary one
        this.AutofillExternalProcedureService.createExternalProcedureFromTemporary(
          {
            externalProcedureData: currentValue,
            recordId: extraData?.recordId,
          }
        );
        break;
      default:
        break;
    }

    // * then handle the autofill
    // if both eyes, we need to sort by L/R
    switch (side) {
      case "both":
        // find id for left and right side
        for (const side of this.AutofillHelperService.getSides()) {
          const similarTargetKey: string =
            this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
              path: targetPath,
              fieldKey: targetKey,
              side,
              valueKey: this.getOptionKey(targetKey, currentValue),
            });
          const foundId: string =
            similarTargetKey?.split(".")?.slice(-1)?.[0] ?? undefined;

          // confirm
          this._confirmAutofillFromTarget({
            ...change,
            side,
            id: foundId,
          });
        }
        break;
      default:
        this._confirmAutofillFromTarget(change);
        break;
    }

    return this.toastr.success(this.autofillMessages.success.confirm);
  }

  // since we dont know  if its a source or target
  // that means we have to attempt to find each related link and remove it instead
  confirmAutofillAsBoth(change: IGlChangesRecordData) {
    // instead of handling by source and target if we need to
    const { originKey, originPath, currentValue, extraData } = change;

    // first confirm the action
    // * check if there's any specific autofill actions required
    // see if we need to confirm anything
    switch (originKey) {
      case EXTERNAL_PROCEDURES_KEY:
        // have to create a procedure and remove the temporary one
        this.AutofillExternalProcedureService.createExternalProcedureFromTemporary(
          {
            externalProcedureData: currentValue,
            recordId: extraData?.recordId,
          }
        );
        break;
      default:
        break;
    }

    // get internal source and target keys
    for (const side of this.AutofillHelperService.getSides()) {
      const internalSourceKey: string =
        this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
          path: originPath,
          fieldKey: originKey,
          side,
          valueKey: this.getOptionKey(originKey, currentValue),
        });

      // then get target key based on similarity since we are lacking timestamp
      const internalTargetKey: string =
        this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
          path: originPath,
          fieldKey: originKey,
          side,
          valueKey: this.getOptionKey(originKey, currentValue),
        });

      // depending on source and target, just continue on as is
      if (internalSourceKey) {
        this.removeSourceAutofillValue(internalSourceKey);
        this.PatientRecordHelperService.deleteFromChangesStack(
          internalSourceKey
        );
      }

      if (internalTargetKey) {
        this.removeTargetAutofillValue(internalTargetKey);
        this.PatientRecordHelperService.deleteFromChangesStack(
          internalTargetKey
        );
      }
    }

    return this.toastr.success(this.autofillMessages.success.confirm);
  }

  // this will just be the same as clearing the mapping and changes stack
  confirmAllAutofills() {
    this.AutofillHelperService.resetAllAutofillMappings();
    this.PatientRecordHelperService.resetChangesStack();

    // NOTE: disabled per MCES request
    // this also requires to save all temporary external prcoedures
    // this.AutofillExternalProcedureService.confirmAllTemporaryProcedures();
  }

  // resets everything
  // difference is that we dont save the procedures
  resetAllAutofills() {
    this.AutofillHelperService.resetAllAutofillMappings();
    this.PatientRecordHelperService.resetChangesStack();

    // this also requires to save all temporary external prcoedures
    this.AutofillExternalProcedureService.resetTemporaryProcedures();
  }

  // autofill confirm that targets confirming autofill for a given key
  // is a helper function
  confirmAutofillsForSide(key: string, side: IGlSide) {
    const path: string = this.getAutofillPath(key);

    // params
    const params: string[] = [key, path, side].filter(
      (p) => !isNil(p) && !isEmpty(p)
    );

    // find source keys that include all params
    const foundSourceKeys: string[] =
      this.AutofillHelperService.getSourceTargetAutofillKeys().filter((k) =>
        every(params, (p) => k.includes(p?.toLowerCase()))
      );

    // for each found source key
    for (const internalSourceKey of foundSourceKeys) {
      // clear autofill mapping
      this.removeSourceAutofillValue(internalSourceKey);

      // remove any stack changes
      this.PatientRecordHelperService.deleteBothFromChangesStackBySource(
        internalSourceKey
      );
    }
  }

  /* 
    UNDO 
    undo's removes the value completely
  */

  handleUndoAutofill(change: IGlChangesRecordDataOrigin) {
    switch (change.origin) {
      case "source":
        this.undoAutofillFromSource(change);
        break;
      case "target":
        this.undoAutofillFromTarget(change);
        break;
      case "both":
        this.undoAutofillAsBoth(change);
        break;
      default:
        break;
    }
  }

  // if its undone from source, it has to remove all references
  // we never use the change target for this as one source can have many targets
  undoAutofillFromSource(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { sourcePath, sourceKey, side, currentValue, id } = change;

    /*

      since in external <-> diag edge case it deosnt use side
      we need to find a way to trigger for not only this source but also the one with no side
      
      so the possible fix is just to try again with a different key or do
      two undo's

      one with the side, the other without
    */
    // get internal source key (or something similar)
    const internalSourceKey: string =
      this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
        path: sourcePath,
        fieldKey: sourceKey,
        side,
        valueKey: this.getOptionKey(sourceKey, currentValue),
        id,
      });

    // * GET OUT OF JAIL: internal source key has no autofill reference sources
    if (
      isEmpty(
        this.AutofillHelperService.getSourceTargetAutofillValues(
          internalSourceKey
        )
      )
    ) {
      return;
    }

    // keys with and without key to avoid the issue of
    // origin key === target key and origin key === source key
    // * get target keys to undo by
    const foundTargetKeys: string[] =
      this.AutofillHelperService.getSourceTargetAutofillValues(
        internalSourceKey
      );

    // concatenation of found keys
    // we get a mix of potential target keys with and without side
    // and only retain the unique ones
    const targetKeys: string[] =
      this.convertInternalTargetKeysToDestinationKeys(foundTargetKeys);

    // // * GET OUT OF JAIL: internal source key has no autofill reference targets
    // if (
    //   isEmpty(
    //     this.AutofillHelperService.getSourceTargetAutofillValues(
    //       internalSourceKey
    //     )
    //   )
    // ) {
    //   // if fails, try to generate without the side as an edge case
    //   return;
    // }

    // * 1. REVERT SOURCE AND DELETE CHANGES
    // only revert if the flag isnt toggled which leaves the value as is
    // useful in cases where value is both a source and a target
    // where child target autofills need to be handled
    // before the value (as a target to a different source)
    // is undone
    this._revertSource(change);

    /*
     * 2. HANDLE UNDO ON ALL TARGETS
      CLEAR TARGET (aka sourceKey)
      prev target data just involves finding the relevant index and removing it
      we have to do this via a switch statement
     
      the worst case scenario for this is mostly just removing all referneces and
      the user has to input it again
    */
    // * source value deletion flag checks
    // a simple flag to check if we should remove the autofill value
    // if theres no remaining links

    // * then for each just iterate and apply the same behaviour
    for (const _targetKey of targetKeys) {
      // get path
      const _targetPath: string = this.getAutofillPath(_targetKey);
      // fetch target value
      const _targetValue: any = this.convertToAutofillOutput({
        valueKey: this.getOptionKey(sourceKey, currentValue),
        targetKey: _targetKey,
      });

      // * generate internal target key based on targets
      // then get target key based on similarity since we are lacking timestamp
      const internalTargetKey: string =
        this.AutofillHelperService.generateAutofillReferenceKey({
          path: _targetPath,
          fieldKey: _targetKey,
          side,
          valueKey: this.getOptionKey(_targetKey, _targetValue),
          id,
        });

      // clear target
      this._clearTarget({
        ...change,
        sourceKey,
        sourcePath,
        targetKey: _targetKey,
        targetPath: _targetPath,
        targetValue: _targetValue,
      });

      // then delete changes
      this.PatientRecordHelperService.deleteFromChangesStack(internalTargetKey);
    }

    // * 3. cleanup autofill keys after that
    this.PatientRecordHelperService.deleteFromChangesStack(internalSourceKey);
    this.removeSourceAutofillValue(internalSourceKey);
  }

  // so targetKey will be the main autofill
  undoAutofillFromTarget(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { targetKey, targetPath, side, currentValue, targetValue, id } =
      change;

    const targetValueKey: string = this.getOptionKey(
      targetKey,
      targetValue ?? currentValue
    );

    // * FIND TARGET KEY
    // then get target key based on similarity since we are lacking timestamp
    const internalTargetKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: targetPath,
        fieldKey: targetKey,
        side,
        valueKey: targetValueKey,
        id,
      });

    // * GET OUT OF JAIL: internal target key has no autofill reference sources
    if (
      isEmpty(
        this.AutofillHelperService.getTargetSourceAutofillValues(
          internalTargetKey
        )
      )
    ) {
      return;
    }

    // * CLEAR TARGET
    this._clearTarget(change);

    // then remove autofill
    // then remove the autofill
    this.removeTargetAutofillValue(internalTargetKey);
  }

  /*
    if mostly uncertain, set origin to both
    this will send out a list of change objects in relative order of 

    - as a source autofill
    - as a target of another source autofill

    and result in a cleared mapping
  */
  undoAutofillAsBoth(change: IGlChangesRecordDataOrigin) {
    // the reason why we use origin key alongisde source and target keys
    // is that the origin is ambigious, so we have to do a check where it
    // acts as both a soruce and a target here
    const { side } = change;

    switch (side) {
      // this requires specific handling as we need to check for L/R keys
      // since input will be "BOTH"
      case "both":
        this._undoAutofillAsBothBilateral(change);
        break;
      // default will be by L/R
      default:
        this._undoAutofillAsBothDefault(change);
        break;
    }
  }

  /* AUTOFILL REFERENCE STUFF */
  // generate reference key
  generateAutofillReferenceKey(params: IValueAutofillKeyParams) {
    return this.AutofillHelperService.generateAutofillReferenceKey(params);
  }

  // given a source key, find out all related target keys and return as a
  // fillable autofill icon handler
  getAutofillIconParamsForSourceKey(sourceKey: string): IAutofillIconParams[] {
    // 1. get targets
    const targetKeys: string[] =
      this.AutofillHelperService.getAutofillTargets(sourceKey) ?? [];
    if (isEmpty(targetKeys)) {
      return [];
    }

    // 2. continue and map out
    return targetKeys.map((targetKey: string) => {
      return {
        title: "Autofill Mapping",
        sourceKey,
        sourcePath: this.AutofillHelperService.getAutofillPath(sourceKey),
        targetKey,
        targetPath: this.AutofillHelperService.getAutofillPath(targetKey),
      };
    });
  }

  getAutofillIconParamsForTargetKey(targetKey: string): IAutofillIconParams[] {
    const sourceKeys: string[] =
      this.AutofillHelperService.getAutofillSources(targetKey) ?? [];
    if (!sourceKeys) {
      return;
    }

    return sourceKeys.map((sourceKey: string) => {
      return {
        title: "Autofill Mapping",
        sourceKey,
        sourcePath: this.AutofillHelperService.getAutofillPath(sourceKey),
        targetKey,
        targetPath: this.AutofillHelperService.getAutofillPath(targetKey),
      };
    });
  }

  /* HELPER FUNCTIONS */
  // generic clear target function
  _clearTarget(change: IGlChangesRecordDataOrigin) {
    const { targetKey, currentValue, side, recordData, extraData } = change;

    // * get path
    const targetPath: string = this.getAutofillPath(targetKey);

    // * target value
    const targetValue: any =
      change?.targetValue ??
      this.convertToAutofillOutput({
        valueKey: this.getOptionKey(targetKey, currentValue),
        targetKey,
      });

    // depending on the target, handle autofill
    switch (targetKey) {
      case DIAGNOSIS_ARRAY_KEY:
        this.AutofillDiagnosisService.clear({
          side: side as IGlSideBilateral,
          path: targetPath,
          key: targetKey,
          diagnosis: targetValue,
          recordData,
          extraData,
        });
        // then if empty
        break;

      case EXTERNAL_PROCEDURES_KEY:
        this.AutofillExternalProcedureService.clear({
          ...change,
          targetValue,
        });
        break;
      default:
        break;
    }
  }

  // generic revert source function
  _revertSource(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { sourceKey } = change;

    // * 1. REVERT SOURCE AND DELETE CHANGES
    switch (sourceKey) {
      case MAC_OCT_V2_KEY:
      case MACULAR_V2_KEY:
        this.AutofillBilateralMultipleService.revert(change);
        break;

      case LENS_STATUS_KEY:
        this.AutofillPosteriorLensService.revertStatus(change);
        break;
      case LENS_OBSERVATIONS_KEY:
        this.AutofillPosteriorLensService.revertObservation(change);
        break;

      case DIAGNOSIS_ARRAY_KEY:
        this.AutofillDiagnosisService.revert(change);
        break;
      default:
        break;
    }
  }

  // generic confirm from source function
  _confirmAutofillFromSource(change: IGlChangesRecordDataOrigin) {
    // get all values
    const {
      sourcePath,
      targetPath,
      sourceKey,
      targetKey,
      side,
      currentValue,
      id,
    } = change;

    const targetValue: any = this.convertToAutofillOutput({
      valueKey: this.getOptionKey(sourceKey, currentValue),
      targetKey,
    });
    // get internal source key
    const internalSourceKey: string =
      this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
        path: sourcePath,
        fieldKey: sourceKey,
        side: side,
        valueKey: this.getOptionKey(sourceKey, currentValue),
        id,
      });

    // then get target key based on similarity since we are lacking timestamp
    const internalTargetKey: string =
      this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
        path: targetPath,
        fieldKey: targetKey,
        side,
        valueKey: this.getOptionKey(targetKey, targetValue),
        id,
      });

    // delete, if not found these wont do anything
    this.PatientRecordHelperService.deleteFromChangesStack(internalSourceKey);
    this.PatientRecordHelperService.deleteFromChangesStack(internalTargetKey);

    this.removeSourceAutofillValue(internalSourceKey);
  }

  // generic confirm from target function
  _confirmAutofillFromTarget(change: IGlChangesRecordDataOrigin) {
    // get all values
    const {
      sourceKey,
      targetKey,
      sourcePath,
      targetPath,
      side,
      currentValue,
      id,
    } = change;
    const sourceValue: any = this.convertToAutofillOutput({
      targetKey: sourceKey,
      valueKey: this.getOptionKey(targetKey, currentValue),
    });

    // * FIND TARGET KEY
    // then get target key based on similarity since we are lacking timestamp
    const internalTargetKey: string =
      this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
        path: targetPath,
        fieldKey: targetKey,
        side,
        valueKey: this.getOptionKey(targetKey, currentValue),
        id,
      });

    // * GET SOURCE KEY FROM TARGET
    // get assumed internal source key since target might not have a timestamp
    const internalSourceKey: string =
      this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
        path: sourcePath,
        fieldKey: sourceKey,
        side: side,
        valueKey: this.getOptionKey(sourceKey, sourceValue),
        id,
      });

    // * then confirm by just a similar way
    this.PatientRecordHelperService.deleteFromChangesStack(internalTargetKey);

    // * difference is that we only delete source autofill data if its
    // * confirmed its all gone
    if (
      isEmpty(
        this.AutofillHelperService.getSourceTargetAutofillValues(
          internalSourceKey
        )
      )
    ) {
      this.PatientRecordHelperService.deleteFromChangesStack(internalSourceKey);
    }

    // remove autofill
    this.removeTargetAutofillValue(internalTargetKey);
  }

  _undoAutofillAsBothDefault(change: IGlChangesRecordData) {
    // the reason why we use origin key alongisde source and target keys
    // is that the origin is ambigious, so we have to do a check where it
    // acts as both a soruce and a target here
    const { originKey, originPath, side, currentValue, id } = change;

    // * 1. WITH ORIGIN KEY AS A TARGET VALUE: FIND ALL SOURCE VALUES
    // * TO UNDO AUTOFILL targets
    // we need to clear all targets first
    // 2b. generate a internal target reference
    const internalReferenceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: originPath,
        fieldKey: originKey,
        side,
        valueKey: this.getOptionKey(originKey, currentValue),
        id,
      });

    // found source keys with active autofills
    const foundSourceKeys: string[] =
      this.AutofillHelperService.getTargetSourceAutofillValues(
        internalReferenceKey
      );

    // create source keys
    const sourceKeys: string[] =
      this.convertInternalTargetKeysToDestinationKeys(foundSourceKeys);

    // 2e. iterate through the found list and undo as a target value
    for (const _sourceKey of sourceKeys) {
      // generate path and potential value
      const _sourcePath: string =
        this.AutofillHelperService.getAutofillPath(_sourceKey);
      const _sourceValue: any = this.convertToAutofillOutput({
        valueKey: this.getOptionKey(originKey, currentValue),
        targetKey: _sourceKey,
      });

      // if all ok then set an id
      if (_sourceValue) {
        _sourceValue.id = id;
      }

      // generate change object with origin key as the target
      // this means we have to generate the source value to "pretend"
      // its from the source
      const _changeObj: IGlChangesRecordDataOrigin = {
        ...change,
        sourceKey: _sourceKey,
        sourcePath: _sourcePath,
        currentValue: _sourceValue,
        targetValue: currentValue,
        targetKey: originKey,
        targetPath: originPath,
        origin: "target",
        id,
      };

      // * 1A) undo as a target value (each target originates from one source only)
      this.undoAutofillFromTarget(_changeObj);
    }

    // * 2. UNDO FROM SOURCE LAST AFTER ALL CHILDREN ARE DONE
    this.undoAutofillFromSource({
      ...change,
      sourceKey: originKey,
      sourcePath: originPath,
      origin: "source",
    });
  }

  // bilateral is similar to above but we retrieve the source and target keys for each side
  // to get the appropriate ID's to undo
  // these can be seen as partial
  _undoAutofillAsBothBilateral(change: IGlChangesRecordData) {
    const { originKey, originPath, sourceKey, targetKey, currentValue } =
      change;

    // go by laterality
    for (const side of this.AutofillHelperService.getSides()) {
      // * SOURCE FIRST
      // try and find the id either by source or target if possible
      const sourceKeyWithId: string =
        this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
          path: originPath,
          fieldKey: originKey,
          side,
          valueKey: this.getOptionKey(originKey, currentValue),
        });

      // extract id
      const sourceId: string =
        sourceKeyWithId?.split(".")?.slice(-1)?.[0] ?? undefined;

      // * THEN TARGET
      const targetKeyWithId: string =
        this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
          path: originPath,
          fieldKey: originKey,
          side,
          valueKey: this.getOptionKey(originKey, currentValue),
        });
      const targetId: string =
        targetKeyWithId?.split(".")?.slice(-1)?.[0] ?? undefined;

      // * use the id that isnt empty, if all else fails in terms of conflict, use the key that matches
      // * origin key

      const isOriginSource: boolean = sourceKey === originKey;
      const isOriginTarget: boolean = targetKey === originKey;

      // id to use
      let idToUse: string;
      if (isOriginSource && sourceId) {
        idToUse = sourceId;
      } else if (isOriginTarget && targetId) {
        idToUse = targetId;
      } else {
        idToUse = sourceId ?? targetId;
      }

      // pass to default
      this._undoAutofillAsBothDefault({
        ...change,
        side,
        id: idToUse,
      });
    }
  }
}

// string it an interface
export interface IAutofillIconParams {
  title: string;
  sourceKey: string;
  targetKey: string;
  sourcePath: string;
  targetPath: string;
}

/**
 * Each component that handles autofill can implement a combination
 * of these fucnctions to their linkings
 */
export interface IGlAutofill {
  // sometimes this is implemented by the target so we ignore this
  // else its source -> target
  autofillAsSource?(change: IGlChangesRecordData);
  autofillAsTarget?(change: IGlChangesRecordData);

  // handles prefill where
  // this is only triggered if a value in the source and target destinations
  // and creates an autofill link between them
  // source === triggers the prefill
  // target === handles the final end of the prefill
  prefillAsSource?(change: IGlChangesRecordData);
  prefillAsTarget?(change: IGlChangesRecordData);

  // remove autofill
  revert?(change: IGlChangesRecordDataOrigin);

  // clears autofill
  clear?(...args: any[]);

  // undo autofill either from source or target
  undoAutofillFromSource?(change: IGlChangesRecordDataOrigin);

  undoAutofillFromTarget?(change: IGlChangesRecordDataOrigin);

  // confirm autofill either from source or target
  confirmAutofillFromTarget?(change: IGlChangesRecordDataOrigin);

  confirmAutofillFromSource?(change: IGlChangesRecordDataOrigin);

  emitUndoAutofillEvent({
    value,
    side,
    recordData,
    extraData,
  }: {
    key?: string; // optional
    value: GlDiagnosis;
    side: IGlSide;
    recordData: PatientRecordData;
    extraData: any;
  });

  emitAutofillEvent?(change: IGlChangesRecordData);
}
