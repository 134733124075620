import { extend } from "angular";
import { pick, set } from "lodash";
import { PatientRecord, PatientRecordData } from "models/patient-record.model";
import { GlStaff, Patient } from "models/user.model";
import { API_PATH_v2 } from "../api-paths";
import { CATARACT_FORM_KEYS, IGlOption } from "../appendix";
import { PdfPrintService } from "../pdf-print.service";

export type IConsentFormType = "cataract" | "injection";

export interface IGlPrintConsentFormArgs {
  type: IConsentFormType;
  patient: Patient;
  surgeon: GlStaff;
  record: PatientRecord;
  helperFunctions: { [key: string]: any; };
}

export const CONSENT_FORM_TEMPLATES: { [key: string]: string; } = {
  cataract: require("./templates/cataracts-consent-template.html"),
};

// the template is the same, the logo's are hard coded for now
// if the clinic doesnt exist, we ignore
// TODO: dynamic logo attachment to a clinic that also includes a base64 version
export const FORM_LOGO_BASE64_MAPPING: { [clinic_id: number]: string; } = {
  // demo clinic
  1: require('./base64/demo_clinic_logo.ts'),
  // sunbury
  2: require('./base64/sunbury_logo.ts'),
  // mces
  50: require('./base64/mces_logo.ts')
};

export class PatientProcedureFormService {
  static injectionName = "PatientProcedureFormService";
  public baseUrl = `${this.API_URL}${API_PATH_v2}`;

  private procedureAutofillMap: Map<string, any> = new Map<string, any>();

  constructor(
    private $q: angular.IQService,
    private $compile: angular.ICompileService,
    private $timeout: angular.ITimeoutService,
    private $rootScope: angular.IRootScopeService,
    private API_URL: string,
    private PdfPrintService: PdfPrintService
  ) {
    "ngInject";
  }

  // AUTOFILL RELATED
  getAutofillMap() {
    return this.procedureAutofillMap;
  }

  // fetch as object
  getCataractFormAsObject(recordData: PatientRecordData) {
    return pick(
      recordData?.management ?? {},
      CATARACT_FORM_KEYS.map((fk) => fk.key)
    );
  }

  // glaucoma
  getGlaucomaFormAsObject(recordData: PatientRecordData) {
    return pick(recordData?.management ?? {}, [
      "glaucoma_risk",
      "target_iop",
      "diagnosis_progression",
    ]);
  }

  // print consent form
  printConsentFormPdf({
    type,
    patient,
    surgeon,
    record,
    helperFunctions,
  }: IGlPrintConsentFormArgs) {
    return this.compileConsentFormHTML({
      type,
      patient,
      surgeon,
      record,
      helperFunctions,
    }).then((html) => {
      return this.PdfPrintService.print({
        headerData: "",
        footerData: "",
        background: true,
        pageData: html,
        margin: {
          left: "0mm",
          right: "0mm",
          top: "0mm",
          bottom: "0mm",
        },
      });
    });
  }

  // Post op Selection autofills based on the option selected
  handleAutofillWhenWithPostOp(
    recordData: PatientRecordData,
    option: IGlOption
  ) {
    const ROUTINE_OPTION: IGlOption = {
      name: "Routine Post-Op Review",
      key: "postopRoutine",
    };
    const HIGH_RISK_OPTION: IGlOption = {
      name: "High Risk Post-Op Review",
      key: "postopHighRisk",
    };

    // high risk
    if (option?.name?.includes("High Risk")) {
      // set high risk
      set(recordData, "management.months_until", HIGH_RISK_OPTION);
    } else if (option?.name.includes("Routine")) {
      set(recordData, "management.months_until", ROUTINE_OPTION);
    }
    // otherwise do nothing and leave it
  }

  private compileConsentFormHTML({
    type,
    patient,
    surgeon,
    record,
    helperFunctions,
  }: IGlPrintConsentFormArgs) {
    return this.$q.resolve().then(() => {
      const scope = this.$rootScope.$new(true);

      // fetch clinic logo
      const clinicLogoBase64: string = FORM_LOGO_BASE64_MAPPING[surgeon.clinic.id] ?? null;


      // extend with the helper functions as well if added in
      extend(scope, {
        type,
        patient,
        surgeon,
        record,
        clinicLogoBase64,
        ...helperFunctions,
      });

      const consentFormTemplate = CONSENT_FORM_TEMPLATES[type];

      const compiledTemplate = this.$compile(
        `<div>${consentFormTemplate}</div>`
      )(scope);

      // weird eventHandler bug here?
      return this.$timeout().then(() => {
        return compiledTemplate?.html();
      });
    });
  }
}
