import { get, has, isEmpty } from "lodash";
import { Invoice } from "../../../../../models/invoice.model";
import { Subscription } from "../../../../../models/subscription.model";
import { IUserResource } from "../../../services/auth.service";
import { InvoiceService } from "../../../services/invoice.service";
import { SubscriptionService } from "../../../services/subscription.service";

class SubscriptionController implements angular.IOnChanges, angular.IOnInit {
  patient: IUserResource;
  mode: string;
  showInvoices = true;
  subscriptionDetails: Subscription;
  loadingSubscription = false;
  loadingInvoices = false;
  invoices: Invoice[];
  loadingInvoice = {};
  cancellingSubscription = false;
  resumingSubscription = false;

  constructor(
    private SubscriptionService: SubscriptionService,
    private InvoiceService: InvoiceService,
    private $window: angular.IWindowService,
    private $uibModal: angular.ui.bootstrap.IModalService
  ) {
    "ngInject";
  }

  ////////////////

  $onInit() {
    this.mode = this.mode || "edit";
  }

  $onChanges() {
    if (has(this.patient, "$promise")) {
      this.patient.$promise.then(() => {
        this.fetchSubscription();
        this.fetchInvoices();
      });
    }
  }

  fetchInvoices() {
    if (!this.showInvoices) {
      return;
    }
    this.loadingInvoices = true;
    this.InvoiceService.getAll({
      patient_id: this.patient.id,
    })
      .then((invoices) => {
        this.loadingInvoices = false;
        this.invoices = invoices;
      })
      .finally(() => {
        this.loadingInvoices = false;
      });
  }

  fetchSubscription() {
    this.loadingSubscription = true;
    this.SubscriptionService.get({ patient_id: this.patient.id })
      .then((subscription) => {
        this.subscriptionDetails = { ...subscription };
        this.loadingSubscription = false;
      })
      .finally(() => {
        this.loadingSubscription = false;
      });
  }

  getInvoiceUrl(invoice_id: number) {
    this.loadingInvoice[invoice_id] = true;
    this.InvoiceService.get({
      patient_id: this.patient.id,
      invoice_id: invoice_id,
    })
      .then((url) => {
        this.$window.open(url, "_blank");
      })
      .finally(() => {
        this.loadingInvoice[invoice_id] = false;
      });
  }

  invoiceInProgress(id: number) {
    return this.loadingInvoice[id];
  }

  cancel() {
    const confirm = this.$window.confirm(
      "Are you sure you want to cancel " +
      this.patient.name +
      " subscription to Glauconet."
    );
    if (confirm) {
      this.cancellingSubscription = true;
      this.SubscriptionService.cancel({ patient_id: this.patient.id })
        .then(() => {
          this.cancellingSubscription = false;
          return this.fetchSubscription();
        })
        .finally(() => {
          this.cancellingSubscription = false;
        });
    }
  }

  resume() {
    this.resumingSubscription = true;
    this.SubscriptionService.resume({ patient_id: this.patient.id })
      .then(() => {
        this.cancellingSubscription = false;
        return this.fetchSubscription();
      })
      .finally(() => {
        this.resumingSubscription = false;
      });
  }

  changeCreditCard() {
    const updateModal = this.$uibModal.open({
      component: "updateCreditCard",
      resolve: {
        patient: () => {
          return this.patient;
        },
      },
    });

    updateModal.result.then((result) => {
      if (result) {
        this.fetchSubscription();
      }
    });
  }

  isEditMode() {
    return this.mode === "edit";
  }

  isManualSubscription() {
    return isEmpty(get(this.subscriptionDetails, "subscription.braintree_id"));
  }
}

export class SubscriptionComponent implements angular.IComponentOptions {
  static selector = "patientSubscription";
  static template = require("./subscription.html");
  static controller = SubscriptionController;

  static bindings = {
    patient: "<",
    showInvoices: "@?",
    mode: "@",

    // can also be passed down from outside
    subscriptionDetails: "=?",
    loadingSubscription: "=?"
  };
}
