import { isNil } from "lodash";
import { IGlChanges } from "models/changes.model";
import { PatientRecord, PatientRecordData } from "models/patient-record.model";

export class LoggingService implements angular.IOnInit, angular.IOnDestroy {
  static injectionName: string = "LoggingService";

  // a list of changes for this cycle
  private recordChangesLog: IGlChanges[] = []; // for logging all events

  constructor() {
    "ngInject";
  }

  $onInit(): void {
    this.recordChangesLog = [];
  }

  $onDestroy(): void {
    // reset
    this.recordChangesLog = [];
  }

  /*
   * FOR CHANGES/LOGGING
   */
  getRecordChangesLog() {
    return this.recordChangesLog;
  }

  resetRecordChangesLog() {
    this.recordChangesLog = [];
  }

  // simple push
  pushToRecordChangesLog(change: IGlChanges) {
    this.recordChangesLog.push(change);
  }

  saveChangesToRecord(record: PatientRecord) {
    const data: PatientRecordData = record?.data ?? {};
    record.data = data;
  }

  decodeRecordDataChangesLog(recordData: PatientRecordData): IGlChanges[] {
    if (isNil(recordData?.changesLog)) {
      return [];
    }

    // decode
    return JSON.parse(recordData?.changesLog) ?? [];
  }
}
