import { isString } from "angular";
import { filter, flatMap, isNil, some, sortBy } from "lodash";
import { GlDrop } from "models/gl-drop";
import { PatientProcedureType } from "models/patient-procedure";
import { GlUserTypeString } from "models/user.model";
import {
  GNET_DIAG_AUTOFILL_MAPPING,
  GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING,
  GNET_OBSERVATIONS_AUTOFILL_MAPPING,
} from "./autofill-helper/autofill.mapping";
import {
  DIAGNOSIS_ARRAY_KEY,
  EXTERNAL_PROCEDURES_KEY,
} from "../../../lib/key-appendix";

/**
 * this defines the min/max and increments for
 * any field
 */
const GNET_NUMERIC_OPTIONS = {
  md: {
    min: -30,
    max: 5.0,
    step: 0.01,
    decimals: 2,
  },
  vfi: {
    min: 0,
    max: 100,
    step: 1,
    decimals: 0,
  },
  psd: {
    min: 0.5,
    max: 20.0,
    step: 0.01,
    decimals: 2,
  },
  cct: {
    min: 400,
    max: 1000,
    step: 1,
    decimals: 0,
  },
  iop: {
    min: 0,
    max: 99,
    step: 1,
    decimals: 0,
  },
  cdr: {
    min: 0,
    max: 0.99,
    step: 0.01,
    decimals: 2,
  },
  target_iop: {
    min: 0,
    max: 99,
    step: 1,
    decimals: 0,
  },
  sphere: {
    min: -30.0,
    max: 30.0,
    step: 0.01,
    decimals: 2,
  },
  axis: {
    min: 0,
    max: 180,
    step: 1,
    decimals: 0,
  },
  cylinder: {
    min: -10,
    max: 9.99,
    step: 0.01,
    decimals: 2,
  },
  osdi: {
    min: 0,
    max: 100,
    step: 0.1,
    decimals: 1,
  },
  tear_meniscus_height: {
    min: 0,
    max: 2,
    step: 0.01,
    decimals: 2,
  },
};

/**
 * this defines categories for any field
 * e.g. low - med - high
 */
const GNET_NUMERIC_OPTION_RANGES: {
  [key: string]: {
    min: number;
    max: number;
    title: string;
  }[];
} = {
  osdi: [
    {
      title: "Normal",
      min: 0,
      max: 12,
    },
    {
      title: "Mild",
      min: 12,
      max: 22,
    },
    {
      title: "Moderate",
      min: 22,
      max: 32,
    },
    {
      title: "Severe",
      min: 32,
      max: 100,
    },
  ],
};

export type GlJsonValue = { [key: string]: string } | string;

export interface IGlOption {
  name: string;
  key: string;
  showOther?: boolean;
  default?: boolean;
  type?: string;
  requireQuantifier?: boolean;
  displayText?: string;
  repeats?: number;
  order?: number;
  other?: string; // makes more sense to be a part of the option field

  id?: string; // a unique identifier for the option
}

export interface IGlOptionExtra extends IGlOption {
  // SINGLE OPTION ONLY
  disableMultiSelect?: boolean;

  // AUTOFILL CHANGES
  autofill?: boolean; // whether field has been autofilled

  // some keys might be different e.g. PCO and p_c_o...
  autofill_key_alternative?: string;

  // DILLATION
  dilation_timer_disabled?: true;
}

const GNET_OPTIONS_DICTIONARY: { [key: string]: IGlOption[] } = {
  glaucomaDiagnosis: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Not Glaucoma", key: "notGlaucoma" },
    {
      name: "Ocular Hypertension (primary)",
      key: "ocularHypertension",
    },
    {
      name: "Glaucoma Suspect (primary)",
      key: "glaucomaSuspect",
    },
    {
      name: "Chronic Open Angle Glaucoma",
      key: "chronicOpenAngleGlaucoma",
      default: true,
    },
    {
      name: "Normal Tension Glaucoma",
      key: "normalTensionGlaucoma",
    },
    {
      name: "Angle Closure Suspect",
      key: "angleClosureSuspect",
    },
    {
      name: "Treated Angle Closure",
      key: "treatedAngleClosure",
    },
    { name: "Angle Closure", key: "angleGlaucoma" },
    {
      name: "Angle Closure Glaucoma",
      key: "angleClosureGlaucoma",
    },
    { name: "Pseudoexfoliation Syndrome", key: "pxf" },
    { name: "Pseudoexfoliation Glaucoma", key: "pxg" },
    { name: "Pigment Dispersion Syndrome", key: "pds" },
    { name: "Pigment Dispersion Glaucoma", key: "pdg" },
    { name: "Plateau Iris Configuration", key: "pic" },
    { name: "Plateau Iris Syndrome", key: "pis" },
    {
      name: "Secondary Open Angle Glaucoma",
      key: "secondaryOpenAngleGlaucoma",
    },
    {
      name: "Secondary Angle Closure Glaucoma",
      key: "secondaryAngleClosureGlaucoma",
    },
    {
      name: "Secondary Open Angle Ocular Hypertension",
      key: "secondaryOpenAngleOcularHypertension",
    },
    {
      name: "Secondary Angle Closure Ocular Hypertension",
      key: "secondaryAngleClosureOcularHypertension",
    },
  ],
  reasonForReferral: [
    { name: "None", key: "none" },
    {
      name: "Ocular Hypertension",
      key: "ocular-hypertension",
    },
    { name: "Glaucoma Suspect", key: "glaucomaSuspect" },
    {
      name: "Glaucoma",
      key: "glaucoma",
    },
    {
      name: "Angle Closure",
      key: "angle-closure",
    },
    {
      name: "Angle Closure Glaucoma",
      key: "angle-closure-glaucoma",
    },
    {
      name: "Cataract",
      key: "cataract",
    },
    {
      name: "Secondary Glaucoma",
      key: "secondary-glaucoma",
      showOther: true,
    },
    { name: "Other", key: "other", showOther: true },
  ],
  visualAccuityNumerator: [
    { name: "Not Examined", key: "not-examined" },
    { name: "6", key: "6", default: true },
    { name: "5", key: "5" },
    { name: "4", key: "4" },
    { name: "3", key: "3" },
    { name: "2", key: "2" },
    { name: "1", key: "1" },
  ],
  visualAccuityDenominator: [
    { name: "Not Examined", key: "not-examined" },
    { name: "4", key: "4" },
    { name: "4.5", key: "4.5" },
    { name: "5", key: "5" },
    { name: "6", key: "6", default: true },
    { name: "7.5", key: "7.5" },
    { name: "9", key: "9" },
    { name: "12", key: "12" },
    { name: "15", key: "15" },
    { name: "18", key: "18" },
    { name: "24", key: "24" },
    { name: "30", key: "30" },
    { name: "38", key: "38" },
    { name: "48", key: "48" },
    { name: "60", key: "60" },
    { name: "75", key: "75" },
    { name: "96", key: "96" },
    { name: "120", key: "120" },
    { name: "150", key: "150" },
    { name: "190", key: "190" },
    { name: "CF", key: "CF" },
    { name: "HM", key: "HM" },
    { name: "PL", key: "PL" },
    { name: "NPL", key: "NPL" },
  ],

  subjectiveVisualAccuityNumerator: [
    { name: "Not Examined", key: "not-examined" },
    { name: "6", key: "6", default: true },
    { name: "5", key: "5" },
    { name: "4", key: "4" },
    { name: "3", key: "3" },
    { name: "2", key: "2" },
    { name: "1", key: "1" },
  ],
  subjectiveVisualAccuityDenominator: [
    { name: "Not Examined", key: "not-examined" },
    { name: "4", key: "4" },
    { name: "4.5", key: "4.5" },
    { name: "5", key: "5" },
    { name: "6", key: "6", default: true },
    { name: "7.5", key: "7.5" },
    { name: "9", key: "9" },
    { name: "12", key: "12" },
    { name: "15", key: "15" },
    { name: "18", key: "18" },
    { name: "24", key: "24" },
    { name: "30", key: "30" },
    { name: "38", key: "38" },
    { name: "48", key: "48" },
    { name: "60", key: "60" },
    { name: "75", key: "75" },
    { name: "96", key: "96" },
    { name: "120", key: "120" },
    { name: "150", key: "150" },
    { name: "190", key: "190" },
    { name: "CF", key: "CF" },
    { name: "HM", key: "HM" },
    { name: "PL", key: "PL" },
    { name: "NPL", key: "NPL" },
  ],

  vfi_status: [
    { name: "Not Examined", key: "not-examined" },
    { name: "WNL", key: "WNL", default: true },
    { name: "Suspect", key: "suspect", showOther: true },
    { name: "Glaucomatous", key: "glaucomatous", showOther: true },
    { name: "Unreliable", key: "unreliable", showOther: true },
    { name: "Non-specific Changes", key: "unspecific", showOther: true },
    { name: "Other", key: "other" },
  ],
  vfi_reliable: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Yes", key: "yes", default: true },
    { name: "No", key: "no", showOther: true },
    { name: "Other", key: "other" },
  ],
  laser: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Capsulotomy", key: "capsulotomy", displayText: "Capsulotomy" },
    {
      name: "Peripheral Iridotomy",
      key: "peripheralIridotomy",
      displayText: "Peripheral Iridotomy",
    },
    {
      name: "Selective Laser Trabeculoplasty",
      key: "slt",
      displayText: "Selective Laser Trabeculoplasty",
    },
    {
      name: "Peripheral Iridoplasty",
      key: "peripheralIridoplasty",
      displayText: "Peripheral Iridoplasty",
    },
    {
      name: "Retinal Laser",
      key: "retinalLaser",
      displayText: "Retinal Laser",
    },
    { name: "Cyclodiode", key: "cyclodiode", displayText: "Cyclodiode" },
    { name: "Vitreolysis", key: "vitreolysis", displayText: "Vitreolysis" },
    { name: "Suturelysis", key: "suturelysis", displayText: "Suturelysis" },
    { name: "Other", key: "other", displayText: "Other Laser" },
  ],
  surgical: [
    // { name: "Not Examined", key: "not-examined" },
    { name: "Cataract", key: "cataractSurgery" },
    { name: "Trabeculectomy", key: "trabeculectomy" },
    { name: "Tube", key: "tube" },
    { name: "MIG", key: "mig" },
    { name: "Cyclodiode", key: "cyclodiode" },
    { name: "Needling", key: "needling" },
    { name: "IOL manipulation", key: "iolManipulation" },
    { name: "Other", key: "otherSurgery" },
    { name: "Intra-vitreal Injection", key: "intravitreal-injection" },
    { name: "Laser", key: "laser" },
    { name: "Plastics", key: "plastics" },
  ],
  dayProcedures: [
    { name: "Capsulotomy", key: "capsulotomy", type: "Laser" },
    { name: "Peripheral Iridotomy", key: "peripheralIridotomy", type: "Laser" },
    { name: "Selective Laser Trabeculoplasty", key: "slt", type: "Laser" },
    {
      name: "Peripheral Iridoplasty",
      key: "peripheralIridoplasty",
      type: "Laser",
    },
    { name: "Retinal Laser", key: "retinalLaser", type: "Laser" },
    { name: "Cyclodiode", key: "cyclodiode", type: "Laser" },
    { name: "Vitreolysis", key: "vitreolysis", type: "Laser" },
    { name: "Suturelysis", key: "suturelysis", type: "Laser" },
    { name: "Other", key: "otherLaser", type: "Laser", showOther: true },
    {
      name: "Intravitreal injection",
      key: "intravitrealInjection",
      type: "Surgery",
    },
    { name: "5-FU injection", key: "5FuInjection", type: "Surgery" },
    { name: "Bleb Needling", key: "blebNeedling", type: "Surgery" },
    { name: "Other", key: "otherSurgery", type: "Surgery", showOther: true },
  ],
  complication: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Yes", key: "yes" },
    { name: "No", key: "no", default: true },
  ],
  procedureDegreeOptions: [
    { name: "Not Examined", key: "not-examined" },
    { name: "360", key: "360", default: true },
    { name: "inf 180", key: "inf180" },
    { name: "sup 180", key: "sup180", default: true },
  ],
  cornea: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Clear", key: "clear", default: true },
    // {
    //   name: "Pigment Cells 1/2+",
    //   key: "pigmentCells1/2",
    //   showOther: true,
    // },
    // { name: "Pigment Cells 1+", key: "pigmentCells1", showOther: true },
    // { name: "Pigment Cells 2+", key: "pigmentCells2", showOther: true },
    // { name: "Pigment Cells 3+", key: "pigmentCells3", showOther: true },
    // { name: "Pigment Cells 4+", key: "pigmentCells4", showOther: true },
    { name: "Pigment Cells", key: "pigmentCells", showOther: true },
    { name: "KP", key: "KP", showOther: true },
    { name: "Guttatae", key: "guttatae", showOther: true },
    { name: "Scar", key: "scar", showOther: true },
    { name: "Graft", key: "graft", showOther: true },
    { name: "Oedema", key: "oedema", showOther: true },
    { name: "PEE", key: "pee", showOther: true },
    { name: "Other", key: "other" },
  ],
  blepharitis: [
    { name: "Not Examined", key: "not-examined" },
    { name: "None", key: "none", showOther: true },
    { name: "Mild", key: "mild", showOther: true },
    { name: "Moderate", key: "moderate", showOther: true },
    { name: "Severe", key: "severe", showOther: true },
    { name: "Other", key: "other", showOther: true },
  ],
  ac: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Deep", key: "deep", default: true },
    { name: "Shallow", key: "shallow" },
    { name: "Other", key: "other" },
  ],
  acQuantifier: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Quiet", key: "quiet", default: true },
    { name: "WBC 1/2+", key: "wbc1/2" },
    { name: "WBC 1+", key: "wbc1" },
    { name: "WBC 2+", key: "wbc2" },
    { name: "WBC 3+", key: "wbc3" },
    { name: "WBC 4+", key: "wbc4" },
    { name: "RBC Deep 1/2+", key: "rbcDeep1/2" },
    { name: "RBC 1+", key: "rbc1" },
    { name: "RBC 2+", key: "rbc2" },
    { name: "RBC 3+", key: "rbc3" },
    { name: "RBC 4+", key: "rbc4" },
    { name: "Pigment Cells +1", key: "pigmentCells1" },
    { name: "Pigment Cells +2", key: "pigmentCells2" },
    { name: "Pigment Cells +3", key: "pigmentCells3" },
    { name: "Pigment Cells +4", key: "pigmentCells4" },
    { name: "Other", key: "other" },
  ],
  iris: [
    { name: "Not Examined", key: "not-examined", showOther: true },
    { name: "Normal", key: "normal", showOther: true },
    { name: "PXF", key: "pxf", showOther: true },
    {
      name: "Transillumination defects",
      key: "transillumination",
      showOther: true,
    },
    { name: "Naevus", key: "naevus", showOther: true },
    { name: "New Vessels", key: "newVessels", showOther: true },
    { name: "Small Pupil", key: "small-pupil", showOther: true },
    { name: "Other", key: "other" },
  ],
  gonio: [
    { name: "Not Examined", key: "not-examined" },
    { name: "CB", key: "CB", default: true },
    { name: "SS", key: "SS" },
    { name: "TM", key: "TM" },
    { name: "ATM", key: "ATM" },
    { name: "SL", key: "SL" },
    {
      name: "Angle Closure Suspect",
      key: "angleClosureSuspect",
    },
    {
      name: "Closed - open on dynamic",
      key: "closedOpenOnDynamic",
    },
    { name: "Closed - PAS", key: "closedPAS" },
    { name: "Closed - NVA", key: "closedNVA" },
  ],
  gonioFinding: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Open Angle", key: "openAngle", default: true },
    { name: "Occludable Angle", key: "occludableAngle" },
    { name: "Closed Angle", key: "closedAngle" },
    { name: "Other", key: "other" },
  ],
  treatmentReasonForDiscontinuation: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Irritation", key: "irritation" },
    {
      name: "Allergic Reaction",
      key: "allergicReaction",
    },
    { name: "Ineffective", key: "ineffective" },
    { name: "No longer required", key: "no_longer_required" },
    { name: "Other", key: "other" },
  ],
  conj: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Normal", key: "normal", showOther: true },
    { name: "Inflamed +1", key: "inflamed1", showOther: true },
    { name: "Inflamed +2", key: "inflamed2", showOther: true },
    { name: "Inflamed +3", key: "inflamed3", showOther: true },
    { name: "Inflamed +4", key: "inflamed4", showOther: true },
    { name: "Scarred", key: "scarred", showOther: true },
    { name: "Tube - good", key: "tubeGood", showOther: true },
    { name: "Tube - failed", key: "tubeFailed", showOther: true },
    { name: "Bleb - good", key: "blebGood", showOther: true },
    { name: "Bleb - encysted", key: "blebEncysted", showOther: true },
    { name: "Bleb - cystic no leak", key: "6", showOther: true },
    { name: "Bleb - cystic leak", key: "blebCysticLeak", showOther: true },
    { name: "Bleb - failed", key: "blebFailed", showOther: true },
    { name: "Other", key: "other" },
  ],
  gonioComment: [
    { name: "Not Examined", key: "not-examined" },
    { name: "WNL", key: "WNL", default: true },
    { name: "Prominant IP", key: "prominantIP" },
    {
      name: "Pigmented Schwalbes Line",
      key: "pigmentedSwalbesLines",
    },
    { name: "Previous ITC", key: "previousITC" },
    { name: "Scattered PAS", key: "scatteredPAS" },
    { name: "Complete PAS", key: "completePAS" },
    { name: "Scattered NVA", key: "scatteredNVA" },
    { name: "Complete NVA", key: "completeNVA" },
    { name: "Double Hump", key: "doubleHump" },
    { name: "Other", key: "other" },
  ],
  lensPhakic: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Clear", key: "clear", default: true },
    { name: "Cataract", key: "cataract" },
    { name: "PCIOL", key: "PCIOL" },
    { name: "Sulcus IOL", key: "sulcusIOL" },
    { name: "ACIOL", key: "ACIOL" },
    { name: "Aphakic", key: "aphakic" },
    { name: "Other", key: "other" },
  ],
  get lens() {
    return this.lensPhakic;
  },
  vit: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Clear", key: "clear" },
    { name: "Old cells", key: "oldCells" },
    { name: "Vitritis 1+", key: "vitritis1" },
    { name: "Vitritis 2+", key: "vitritis2" },
    { name: "Vitritis 3+", key: "vitritis3" },
    { name: "Vitritis 4+", key: "vitritis4" },
    {
      name: "Vit haemorrhage 1+",
      key: "vitHaemorrhage1",
    },
    {
      name: "Vit haemorrhage 2+",
      key: "vitHaemorrhage2",
    },
    {
      name: "Vit haemorrhage 3+",
      key: "vitHaemorrhage3",
    },
    { name: "Vit haemorrhage 4+", key: "vitHaemorrhage" },
    { name: "Tobacco dust", key: "tobaccoDust" },
    { name: "Other", key: "other" },
  ],
  disc: [
    { name: "Not Examined", key: "not-examined" },
    { name: "WNL", key: "WNL", default: true },
    { name: "Suspect", key: "suspect", showOther: true },
    { name: "Glaucomatous", key: "glaucomatous", showOther: true },
    { name: "Other", key: "other" },
  ],
  oct: [
    { name: "Not Examined", key: "not-examined" },
    { name: "WNL", key: "WNL", default: true },
    { name: "Suspect", key: "suspect", showOther: true },
    { name: "Glaucomatous", key: "glaucomatous", showOther: true },
    { name: "Other", key: "other" },
  ],
  mac_oct: [
    { name: "Not Examined", key: "not-examined" },
    {
      name: "IRC",
      key: "IRC",
      displayText: "Intraretinal cysts",
      showOther: true,
    },
    { name: "WNL", key: "WNL", default: true },
    {
      name: "Dry",
      key: "dry",
      displayText: "Dry",
      showOther: true,
    },
    {
      name: "IRF",
      key: "IRF",
      displayText: "Intraretinal fluid",
      showOther: true,
    },
    {
      name: "SRF",
      key: "SRF",
      displayText: "Sub-retinal fluid",
      showOther: true,
    },
    {
      name: "ERM",
      key: "ERM",
      displayText: "Epiretinal membrane",
      showOther: true,
    },
    {
      name: "PED",
      key: "PED",
      displayText: "Pigment epithelial detachment",
      showOther: true,
    },
    {
      name: "Atrophy",
      key: "atrophy",
      displayText: "Retinal Atrophy",
      showOther: true,
    },
    {
      name: "SR Scar",
      key: "srscar",
      displayText: "Sub-retinal scarring",
      showOther: true,
    },
    { name: "Drusen", key: "drusen", displayText: "Drusen", showOther: true },
    {
      name: "VMT",
      key: "VMT",
      displayText: "Vitreomacular traction",
      showOther: true,
    },
    {
      name: "SRHM",
      key: "SRHM",
      displayText: "Sub-retinal hyperreflective material",
      showOther: true,
    },
    {
      name: "RPE Migration",
      key: "rpe",
      displayText: "Retinal pigment epithelial migration",
      showOther: true,
    },
    {
      name: "PTMH",
      key: "PTMH",
      displayText: "partial thickness macular hole",
      showOther: true,
    },
    {
      name: "FTMH",
      key: "FTHM",
      displayText: "full thickness macular hole",
      showOther: true,
    },
    { name: "Other", key: "other", showOther: true },
  ],
  // mac_oct
  get mac_oct_v2() {
    return this.mac_oct;
  },
  get oct_mac() {
    return this.mac_oct;
  },
  get oct_mac_v2() {
    return this.mac_oct;
  },
  macular: [
    { name: "Not Examined", key: "not-examined" },
    { name: "WNL", key: "WNL", default: true },
    {
      name: "AMD",
      key: "AMD",
      showOther: true,
    },
    {
      name: "DR",
      key: "DR",
      showOther: true,
    },
    {
      name: "Vein Occlusion",
      key: "veinOcclusion",
      showOther: true,
    },
    { name: "ERM", key: "erm", showOther: true },
    { name: "Other", key: "other" },
  ],
  get macular_v2() {
    return this.macular;
  },
  periphery: [
    { name: "Not Examined", key: "not-examined" },
    { name: "WNL", key: "WNL", default: true },
    { name: "No Tear", key: "no-tear" },
    { name: "Other", key: "other" },
  ],
  targetIOPFuture: [
    { name: "Not Examined", key: "not-examined" },
    { name: "20%", key: "20" },
    { name: "30%", key: "30" },
    { name: "40%", key: "40" },
  ],
  cataractType: [
    { name: "Not Examined", key: "not-examined" },
    {
      name: "Nuclear Sclerosis",
      key: "nuclearSclerosis",

      requireQuantifier: true,
    },
    {
      name: "Posterior Subcapsular",
      key: "posteriorSubcapsular",

      requireQuantifier: true,
    },
    {
      name: "Anterior Cortical",
      key: "anteriorCortical",

      requireQuantifier: true,
    },
    { name: "PXF", key: "PXF", requireQuantifier: false },
    {
      name: "White",
      key: "white",

      requireQuantifier: false,
    },
    {
      name: "Brunescent",
      key: "brunescent",

      requireQuantifier: false,
    },
    {
      name: "Christmas",
      key: "christmas",

      requireQuantifier: false,
    },
    {
      name: "Posterior Polar",
      key: "posteriorPolar",

      requireQuantifier: false,
    },
    {
      name: "Blue Dot",
      key: "blueDot",

      requireQuantifier: false,
    },
    {
      name: "Phacodenesis",
      key: "phacodenesis",

      requireQuantifier: true,
    },
    {
      name: "Other",
      key: "other",

      requireQuantifier: false,
    },
  ],
  IOLType: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Clear", key: "clear" },
    {
      name: "PCO",
      key: "PCO",
      requireQuantifier: true,
    },
    { name: "Subluxed", key: "subluxed" },
    { name: "Dislocated", key: "dislocated" },
    { name: "Other", key: "other" },
  ],
  quantifier: [
    { name: "Not Examined", key: "not-examined" },
    { name: "1/2+", key: "1/2+" },
    { name: "1+", key: "1+", default: true },
    { name: "2+", key: "2+" },
    { name: "3+", key: "3+" },
    { name: "4+", key: "4+" },
  ],
  glaucomaRisk: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Negligible", key: "negligible", default: true },
    { name: "Low", key: "low" },
    { name: "Moderate", key: "moderate" },
    { name: "High", key: "high" },
  ],
  treatment: [
    { name: "Not Examined", key: "not-examined" },
    { name: "No Treatment", key: "noTreatment", default: true },
    { name: "SLT", key: "SLT" },
    { name: "Drops", key: "drops" },
    { name: "Surgery", key: "surgery" },
  ],
  cataract: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Not for surgery", key: "notForSurgery", default: true },
    {
      name: "Book for surgery - right eye only",
      key: "bookForSurgeryRightEyeOnly",
    },
    {
      name: "Book for surgery - left eye only",
      key: "bookForSurgeryLeftEyeOnly",
    },
    { name: "Book surgery 1st", key: "bookSurgery1st" },
    { name: "Book surgery 2nd", key: "bookSurgery2nd" },
  ],
  lens_status: [
    { name: "Not Examined", key: "not-examined" },
    { name: "Phakic", key: "phakic" },
    { name: "Pseudophakic", key: "pseudophakic" },
    { name: "Other", key: "other" },
  ],
  noDocumentDataReasons: [
    {
      name: "Technical problem",
      key: "technicalProblem",
    },
    {
      name: "Patient unwilling to do test",
      key: "patientUnwilling",
    },
    {
      name: "Patient unable to do test",
      key: "patientUnable",
    },
    { name: "Not required", key: "notRequired" },
    {
      name: "Vision not adequate for test",
      key: "visionNotAdequate",
    },
    { name: "Other", key: "other" },
  ],
  drugFrequency: [
    { name: "Mane", key: "mane" },
    { name: "Nocte", key: "nocte" },
    { name: "Daily", key: "daily" },
    { name: "BD", key: "BD" },
    { name: "TDS", key: "tds" },
    { name: "PRN", key: "prn" },
    { name: "6/day", key: "6Day" },
    { name: "qid", key: "qid" },
    { name: "q6h", key: "q6h" },
    { name: "q4h", key: "q4h" },
    { name: "q3h", key: "q3h" },
    { name: "q2h", key: "q2h" },
    { name: "q1h", key: "q1h" },
    { name: "None", key: "none" },
    { name: "Other", key: "other" },
  ],
  drugRoot: [
    { name: "Both Eyes", key: "be" },
    { name: "Right Eye", key: "re" },
    { name: "Left Eye", key: "le" },
    { name: "Oral", key: "oral" },
    { name: "IVI", key: "IVI" },
    { name: "Other", key: "other" },
  ],
  managementFrequency: [
    { name: "1 Week", key: "1week" },
    { name: "2 Weeks", key: "2weeks" },
    { name: "3 Weeks", key: "3weeks" },
    { name: "1 Month", key: "1month" },
    { name: "6 Weeks", key: "6weeks" },
    { name: "2 Months", key: "2months" },
    { name: "10 Weeks", key: "10weeks" },
    { name: "3 Months", key: "3months" },
    { name: "4 Months", key: "4months" },
    { name: "6 Months", key: "6months" },
    { name: "9 Months", key: "9months" },
    { name: "12 Months", key: "12months" },
    { name: "Routine Post-Op Review", key: "postopRoutine" },
    { name: "High Risk Post-Op Review", key: "postopHighRisk" },
  ],
  progressionTypes: [
    { name: "Not Examined", key: "not-examined" },
    { name: "No Progression", key: "no-progression", default: true },
    { name: "Progression", key: "progression", showOther: true },
    {
      name: "Possible Progression",
      key: "possible-progression",
      showOther: true,
    },
  ],
  repeatUnit: [
    { name: "Weeks", key: "weeks" },
    { name: "Months", key: "months" },
    { name: "Years", key: "years", default: true },
  ],
  visitProcedureDropTypes: [
    {
      name: "Tropicamide",
      key: "tropicamide",
      default: true,
    },
    {
      name: "Phenylepherine 2.5%",
      key: "phenylepherine_25",
    },
    {
      name: "Phenylepherine 10%",
      key: "phenylepherine_10",
    },
    {
      name: "Cyclopentolate",
      key: "cyclopentolate",
    },
    {
      name: "Atropine",
      key: "atropine",
    },
    {
      name: "Pilocarpine",
      key: "pilocarpine",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
  ],
  cataractSurgery: [
    {
      name: "Normal",
      key: "normal",
      default: true,
      displayText: "Cataract + IOL",
    },
    {
      name: "Clear Lens Exchange",
      key: "clear-lens-exchange",
      displayText: "Clear Lens Exchange",
    },
    { name: "+ Hydrus", key: "hydrus", displayText: "Cataract + Hydrus" },
    { name: "+ IStent", key: "istent", displayText: "Cataract + iStent" },
    { name: "+ Xen", key: "xen", displayText: "Cataract + Xen" },
    {
      name: "+ Trabeculectomy",
      key: "trabeculectomy",
      displayText: "Phaco Trabeculectomy",
    },
    { name: "+ Tube", key: "tube", displayText: "Cataract + Tube" },
    { name: "+ CyPass", key: "cypass", displayText: "Cataract + CyPass" },
  ],
  trabeculectomy: [
    {
      name: "Normal",
      key: "normal",
      default: true,
      displayText: "Trabeculectomy",
    },
    { name: " + 5FU", key: "5fu", displayText: "Trabeculectomy + 5FU" },
    { name: "+ MMC", key: "mmc", displayText: "Trabeculectomy + MMC" },
    {
      name: "+ PreserFlo + MMC",
      key: "mmc",
      displayText: "Trabeculectomy +PreserFlo + MMC",
    },
    {
      name: "Revision",
      key: "revision",
      displayText: "Revision Trabeculectomy",
    },
    { name: "Re-do", key: "redo", displayText: "Re-do Trabeculectomy" },
  ],
  tube: [
    {
      name: "Baerveldt",
      key: "baerveldt",
      default: true,
      displayText: "Baerveldt Tube",
    },
    { name: "Molteno", key: "molteno", displayText: "Molteno Tube" },
    { name: "Ahmed", key: "ahmed", displayText: "Ahmed Tube" },
    { name: "Revision", key: "revision", displayText: "Tube Revision" },
  ],
  mig: [
    { name: "Hydrus", key: "hydrus", displayText: "Hydrus" },
    { name: "iStent", key: "istent", default: true, displayText: "iStent" },
    { name: "Xen", key: "xen", displayText: "Xen" },
    { name: "Cypass", key: "cypass", displayText: "Cypass" },
  ],
  cyclodiode: [
    { name: "90", key: "90", default: true, displayText: "Cyclodiode 90" },
    { name: "180", key: "180", displayText: "Cyclodiode 180" },
    { name: "270", key: "270", displayText: "Cyclodiode 270" },
    { name: "360", key: "360", displayText: "Cyclodiode 360" },
  ],
  needling: [
    {
      name: "Normal",
      key: "normal",
      default: true,
      displayText: "Bleb Needling",
    },
    { name: "5-FU", key: "5fu", displayText: "Bleb Needling + 5-FU" },
    { name: "MMC", key: "MMC", displayText: "Bleb Needling + MMC" },
  ],
  iolManipulation: [
    {
      name: "IOL exchange",
      key: "iolExchange",
      default: true,
      displayText: "IOL exchange",
    },
    {
      name: "IOL insertion",
      key: "iolInsertion",
      displayText: "IOL insertion",
    },
    { name: "IOL removal", key: "iolRemoval", displayText: "IOL removal" },
    {
      name: "IOL repositioning",
      key: "iolRepositioning",
      displayText: "IOL repositioning",
    },
  ],
  otherSurgery: [
    {
      name: "Tube removal",
      key: "tubeRemoval",
      default: true,
      displayText: "Tube removal",
    },
    {
      name: "Pterygium",
      key: "pterygium",
      displayText: "Pterygium + conj autograft",
    },
    {
      name: "Choroidal drainage",
      key: "choroidalDrainage",
      displayText: "Choroidal drainage",
    },
    {
      name: "Bleb excision",
      key: "blebExcision",
      displayText: "Bleb excision",
    },
    {
      name: "AC re-formation",
      key: "acReformation",
      displayText: "AC re-formation",
    },
    { name: "Wound suture", key: "woundSuture", displayText: "Wound suture" },
    {
      name: "OSSN excision",
      key: "ossnEexcision",
      displayText: "OSSN excision",
    },
    { name: "Other", key: "other", showOther: true },
  ],
  plastics: [
    {
      name: "Wedge Resection",
      key: "wedge-resection",
      showOther: true,
    },
    {
      name: "Entropion Repair",
      key: "entropion-repair",
      showOther: true,
    },
    {
      name: "Ectropion Repair",
      key: "ectropion-repair",
      showOther: true,
    },
    {
      name: "Blepharoplasty",
      key: "blepharoplasty",
      showOther: true,
    },
    {
      name: "Ptosis Repair",
      key: "ptosis-repair",
      showOther: true,
    },
    {
      name: "Blepharoptosis Repair",
      key: "blepharoptosis-repair",
      showOther: true,
    },
    {
      name: "Trichiasis",
      key: "trichiasis",
      showOther: true,
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
  ],
  referralValidTimeFrames: [
    { name: "Indefinite", key: "0" },
    { name: "3 months", key: "3" },
    { name: "6 months", key: "6" },
    { name: "12 months", key: "12" },
  ],
  "intravitreal-injection": [
    { name: "Lucentis", key: "lucentis", displayText: "Lucentis" },
    { name: "Eylea", key: "eylea", displayText: "Eylea" },
    { name: "Avastin", key: "avastin", displayText: "Avastin" },
    { name: "Kenacourt", key: "kenacourt", displayText: "Kenacourt" },
    { name: "Triesence", key: "triesence", displayText: "Triesence" },
    { name: "Ozurdex", key: "ozurdex", displayText: "Ozurdex" },
    { name: "Vabysmo", key: "vabysmo", displayText: "Vabysmo" },
    { name: "Other", key: "other", showOther: true },
  ],
  "injection-frequency": [
    { name: "1", key: "1", repeats: 1 },
    { name: "q4w", key: "4", repeats: 3 },
    { name: "q6w", key: "6", repeats: 3 },
    { name: "q8w", key: "8", repeats: 3 },
    { name: "q10w", key: "10", repeats: 3 },
    { name: "q12w", key: "12", repeats: 2 },
    { name: "q16w", key: "16", repeats: 2 },
    { name: "Complete", key: "complete" },
  ],
  referralStatus: [
    { name: "Accepted", key: "accepted" },
    { name: "Booked", key: "booked" },
    { name: "Declined", key: "declined", showOther: true },
  ],
  referralUrgency: [
    { name: "Urgent (< 3 months)", key: "urgent" },
    { name: "Non Urgent (6 -> 12 months)", key: "non-urgent" },
  ],
  presentingComplaint: [
    { name: "Glaucoma", key: "glaucoma" },
    { name: "Retina", key: "retina" },
    { name: "Cataract", key: "cataract", default: true },
    { name: "Other", key: "other" },
  ],
  lissamine: [
    { name: "Staining", key: "staining", showOther: true },
    { name: "No Staining", key: "no-staining", showOther: true, default: true },
    { name: "N/A", key: "na", showOther: true },
    { name: "Other", key: "other", showOther: true },
  ],
  meibography: [
    { name: "Normal", key: "normal", showOther: true },
    { name: "Mild", key: "mild", showOther: true, default: true },
    { name: "Moderate", key: "moderate", showOther: true },
    { name: "Severe", key: "severe", showOther: true },
    { name: "Other", key: "other", showOther: true },
  ],
  /**
   * These are for the cataract form
   */
  cataractPreOp: [
    {
      name: "Repeat Ascan",
      key: "repeat_ascan",
      showOther: true,
    },
    {
      name: "Repeat PCAM",
      key: "repeat_pcam",
      showOther: true,
    },
    {
      name: "IVI",
      key: "ivi",
      showOther: true,
    },
    {
      name: "Complex",
      key: "complex",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_pre_op() {
    return this.cataractPreOp;
  },
  cataractAim: [
    {
      name: "Dominance",
      key: "dominance",
      showOther: true,
    },
    {
      name: "Emme",
      key: "emme",
      showOther: true,
    },
    {
      name: "MF/IOL",
      key: "mf_iol",
      showOther: true,
    },
    {
      name: "EDOF/IOL",
      key: "edof_iol",
      showOther: true,
    },
    {
      name: "Monovision",
      key: "monovosion",
      showOther: true,
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_aim() {
    return this.cataractAim;
  },
  cataractLens: [
    {
      name: "Non-Toric",
      key: "non_toric",
    },
    {
      name: "Toric",
      key: "toric",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_lens() {
    return this.cataractLens;
  },
  cataractInjectables: [
    {
      name: "Orbital Floor KC",
      key: "orbital_floor_kc",
    },
    {
      name: "IVI",
      key: "ivi",
      showOther: true,
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_injectables() {
    return this.cataractInjectables;
  },
  cataractMigs: [
    {
      name: "iStent",
      key: "istent",
    },
    {
      name: "Hydrus",
      key: "hydrus",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_migs() {
    return this.cataractMigs;
  },
  cataractSteroid: [
    {
      name: "PredForte",
      key: "predforte",
    },
    {
      name: "STK",
      key: "stk",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_steroid() {
    return this.cataractSteroid;
  },
  cataractAnaesthetic: [
    {
      name: "Topical",
      key: "topical",
    },
    {
      name: "Local",
      key: "local",
    },
    {
      name: "GA",
      key: "ga",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_anaesthetic() {
    return this.cataractAnaesthetic;
  },
  cataractOperation: [
    {
      name: "High-Risk PO",
      key: "high_risk_po",
      showOther: true, // this has to store the 1/x blank
    },
    {
      name: "Intracam Trop/Phnl/Xylo",
      key: "intracam_trop",
    },
    {
      name: "Iris Ring",
      key: "iris_ring",
    },
    {
      name: "Vision Blue",
      key: "vision_blue",
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_operation() {
    return this.cataractOperation;
  },
  cataractDryEye: [
    {
      name: "Dry Eye",
      key: "dry_eye",
    },
    {
      name: "Initial Appt + Lipiflow",
      key: "initial_appt_lipiflow",
    },
    {
      name: "Lipiflow Standalone",
      key: "lipiflow_standalone",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
    // { name: "None", key: "none", default: true },
  ],
  get cataract_dry_eye() {
    return this.cataractDryEye;
  },
  cataractPostOp: [
    {
      name: "Routine",
      key: "routine",
    },
    {
      name: "High Risk Day 1",
      key: "high_risk_day_1",
    },
    {
      name: "High Risk Week 1",
      key: "high_risk_week_1",
    },
    {
      name: "High Risk Other",
      key: "high_risk_other",
      showOther: true,
    },
    {
      name: "Other",
      key: "other",
    },
  ],
  get cataract_post_op() {
    return this.cataractPostOp;
  },
  patientContactTypes: [
    {
      name: "Optometrist",
      key: "optometrist",
      default: true,
    },
    {
      name: "GP",
      key: "gp",
    },
    {
      name: "Other",
      key: "other",
      showOther: true,
    },
  ],
};

export interface IGlMedication {
  name: string;
  key: string;
  default?: boolean;
  frequency: string;
  instructions?: string;
  repeats: number;
  group?: string;
  onceOff?: boolean;
  authorityScript?: boolean;
  streamlinedAuthorityNumber?: string;
  type: PatientProcedureType;
  quantity?: number;
  description?: string;
}

/**
 * @deprecated
 * pbs system in use
 */
const GNET_DROPS: IGlMedication[] = [
  {
    name: "Test",
    key: "test",
    repeats: 0,
    frequency: "NOCT",
    authorityScript: true,
    onceOff: true,
    type: "other",
  },
  {
    name: "Alphagan P",
    key: "alphaganP",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Alphagan",
    key: "alphagan",
    frequency: "BD",
    repeats: 5,
    instructions: "1 week post laser",
    type: "drops",
  },
  {
    name: "Azarga",
    key: "azarga",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Azopt",
    key: "azopt",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Brinzolamide",
    key: "brinzolamide",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Cellufresh",
    key: "cellufresh",
    frequency: "qid",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Combigan",
    key: "combigan",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Cosopt",
    key: "cosopt",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Diamox",
    key: "diamox",
    frequency: "TDS",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Dorzolamide",
    key: "dorzolamide",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Duotrav",
    key: "duotrav",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Flarex",
    key: "flarex",
    frequency: "qid",
    repeats: 5,
    type: "drops",
  },
  {
    name: "FML",
    key: "fml",
    frequency: "qid",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Ganfort PF",
    key: "GanfortPF",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Ganfort",
    key: "ganfort",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Genteal Gel",
    key: "genteal-gel",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Lumigan PF",
    key: "lumiganPF",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Lumigan",
    key: "lumigan",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Maxidex",
    key: "maxidex",
    frequency: "qid",
    repeats: 2,
    instructions: "in operated eye for 4 weeks",
    type: "drops",
  },
  {
    name: "Other",
    key: "other",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Prednefrin Forte",
    key: "prednefrin-forte",
    frequency: "qid",
    repeats: 2,
    instructions: "one drop in the operated eye for 4 weeks",
    type: "drops",
  },
  {
    name: "Saflutan",
    key: "saflutan",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Simbrinza",
    key: "simbrinza",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Systane",
    key: "systane",
    frequency: "qid",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Timolol",
    key: "timolol",
    frequency: "BD",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Travatan",
    key: "travatan",
    default: true,
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Xalacom",
    key: "xalacom",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Xalatan",
    key: "xalatan",
    frequency: "Nocte",
    repeats: 5,
    type: "drops",
  },
  {
    name: "Lucentis PFS AMD",
    key: "lucentis-pfs-amd",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13406",
    type: "drops",
  },
  {
    name: "Lucentis PFS BRVO",
    key: "lucentis-pfs-brvo",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13387",
    type: "drops",
  },
  {
    name: "Lucentis PFS CRVO",
    key: "lucentis-pfs-crvo",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13336",
    type: "drops",
  },
  {
    name: "Lucentis PFS DMO",
    key: "lucentis-pfs-dmo",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13402",
    type: "drops",
  },
  {
    name: "Lucentis PFS Rare CNV (not AMD)",
    key: "Lucentis PFS Rare CNV (not AMD)",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13340",
    type: "drops",
  },
  {
    name: "Ozurdex BRVO",
    key: "ozurdex-brvo",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13387",
    type: "drops",
  },
  {
    name: "Ozurdex CRVO",
    key: "ozurdex-crvo",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13428",
    type: "drops",
  },
  {
    name: "Vabysmo Vial AMD",
    key: "vabysmo-vial-amd",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13406",
    type: "drops",
  },
  {
    name: "Vabysmo Vial DMO",
    key: "Vabysmo Vial DMO",
    frequency: "Daily",
    repeats: 1,
    authorityScript: true,
    streamlinedAuthorityNumber: "13402",
    type: "drops",
  },
];

export const VACCINE_STATUSES = [
  {
    name: "Not Vaccinated",
    key: "novax",
    color: "danger",
  },
  {
    name: "Single Dose",
    key: "singledose",
    color: "success",
  },
  {
    name: "Double Dosed",
    key: "doubledosed",
    color: "success",
  },
  {
    name: "Awaiting Vaccine Appointment",
    key: "avappointment",
    color: "warning",
  },
  {
    name: "Medical Exemption",
    key: "medicalexemption",
    color: "info",
  },
  {
    name: "Refusal",
    key: "refusal",
    color: "danger",
  },
];

export const APPOINTMENT_REGEX = {
  Retina: {
    regex: /(10(4|5)(r|ij))|(opinj)|(injct)/,
  },
  Glaucoma: {
    regex: /10(4|5)g/,
  },
  Cataract: {
    regex: /10(4|5)c/,
  },
};

export const LETTER_STATUSES = [
  {
    name: "Sent",
    key: "sent",
    color: "success",
  },
  {
    name: "Draft",
    key: "draft",
    color: "info",
  },
  {
    name: "Created",
    key: "created",
    color: "warning",
  },
  {
    name: "Complete",
    key: "complete",
    color: "danger",
  },
];

export const REPORTING_CATEGORIES = [
  {
    name: "Billing",
    key: "billing",
  },
  {
    name: "Letters",
    key: "letters",
  },
];

export interface IPrescriptionDetail {
  description: string;
  quantity?: string;
}

/**
 * @deprecated
 * pbs system in use
 */
const GNET_TREATMENTS_PRESCRIPTION_DETAIL: {
  [key: string]: IPrescriptionDetail;
} = {
  "Alphagan P": {
    description: "Brimonidine PF (0.15%/5ml) - Alphagan P Drops - Qty/Pack: 1",
  },
  Alphagan: {
    description:
      "Brimonidine (0.2%/5ml) - Alphagan Eye Drops 2mg/1mL - Qty/Pack 1",
    quantity: "5mL",
  },
  Azarga: {
    description:
      "Brinzolamide (1%), Timolol (Maleate) (0.5%) - Azarga Eye Drops 1",
    quantity: "5mL",
  },
  Azopt: {
    description: "Brinzolamide 1% - Azopt Eye Drops 1.0% 10mg/1m - Qty/Pack 1",
    quantity: "5mL",
  },
  Brinzolamide: { description: "Brinzolamide Drops" },
  Cellufresh: { description: "Cellufresh Drops" },
  Combigan: {
    description:
      "Brimonidine Tartrate (0.2%), Timolol (Maleate) (0.5%) - Combigan Drops",
  },
  Cosopt: {
    description:
      "Dorzolamide (HCl) 2%, Timolol (Maleate) 0.5% - Cosopt Eye Drops 1",
    quantity: "5ml",
  },
  Diamox: { description: "Acetazolamide 250mg - Diamox Drops" },
  Dorzolamide: { description: "Dorzolamide Drops" },
  Duotrav: {
    description: "Travoprost 0.004%, Timolol Maleate 0.68% - Duotrav Drops",
  },
  Flarex: {
    description: "Flarex 0.1% ( Fluorometholone acetate) 5ml",
  },
  FML: {
    description: "FML 0.1% (Fluorometholone) 5ml",
  },
  "Ganfort PF": {
    description:
      "Bimatoprost 0.3 mg/mL, Timolol (Maleate) 5.0 mg/mL PF - Ganfort PF 0.3/5 Eye Drops 30",
    quantity: "0.4mL 30 x 1",
  },
  Ganfort: {
    description:
      "Bimatoprost 0.3 mg/mL, Timolol (Maleate) 5.0 mg/mL - Ganfort Drops",
  },
  "Genteal Gel": { description: "Genteal Gel" },
  "Lumigan PF": {
    description:
      "Bimatoprost 0.03% in 3ml PF - Lumigan PF Eye Drops 0.03% - Qty/Pack: 30",
    quantity: "0.4mL 30 x 1",
  },
  Lumigan: { description: "Bimatoprost 0.03% in 3ml - Lumigan Drops" },
  Maxidex: {
    description: "Maxidex Eye Drops 0.1% - Qty/Pack: 1",
    quantity: "5mL",
  },
  "Prednefrin Forte": {
    description:
      "Prednisone Acetate 10mg/mL and Phenylephrine Hydrochloride 1.2mg/mL - Prednefrin Forte Eye Drops  1",
    quantity: "10mL",
  },
  Saflutan: {
    description: "Tafluprost 0.0015% Eye Drops - Saflutan Eye Drops",
    quantity: "0.3mL 30 x 1",
  },
  Simbrinza: {
    description:
      "Brinzolamide (1%), Brimonidine Tartrate (0.2%) - Simbrinza Eye Drops 1",
    quantity: "5mL",
  },
  Systane: { description: "Systane Drops" },
  Timolol: { description: "Timolol (Maleate) (0.5%) - Timolol Drops" },
  Travatan: {
    description:
      "Travoprost 0.004% - Travatan Eye Drops 40mcg 1/mL - Qty/Pack: 1",
    quantity: "2.5mL",
  },
  Xalacom: {
    description:
      "Latanoprost (0.005%), Timolol (maleate) (0.5%) - Xalacom Drops",
  },
  Xalatan: {
    description:
      "Latanoprost (0.005%) - Xalatan Eye Drops 50mcg 1/mL - Qty/Pack: 1",
    quantity: "2.5mL",
  },
  "Lucentis PFS AMD": {
    description: "Aflibercept PFs 3.6 mg/0.09mL",
  },
  "Lucentis PFS BRVO": {
    description: "Aflibercept PFs 3.6 mg/0.09mL",
  },
  "Lucentis PFS CRVO": {
    description: "Aflibercept PFs 3.6 mg/0.09mL",
  },
  "Lucentis PFS DMO": {
    description: "Aflibercept PFs 3.6 mg/0.09mL",
  },
  "Ozurdex BRVO": {
    description: "Dexamethasone 700mcg Implant",
  },
  "Ozurdex CRVO": {
    description: "Dexamethasone 700mcg Implant",
  },
  "Vabysmo Vial AMD": {
    description: "Faricimab Vial 6 mg/0.05mL",
  },
  "Vabysmo Vial DMO": {
    description: "Faricimab Vial 6 mg/0.05mL",
  },
};

// export const DOCUMENT_TAGS = {
//   documents: [
//     { name: "referral", title: "Referral" },
//     { name: "prescription", title: "Prescription" },
//     { name: "letter", title: "Letter" },
//     { name: "other", title: "Other" },
//   ],
// };

export const DOCUMENT_TAGS_V2 = {
  documents: [
    // correspondence
    {
      type: "Correspondence",
      name: "letter",
      title: "Letter",
      legacy: true,
    },
    {
      type: "Correspondence",
      name: "referral",
      title: "Referral",
      // legacy: true,
    },
    {
      type: "Correspondence",
      name: "referral-old",
      title: "Referral Old",
      legacy: true,
    },
    {
      type: "Correspondence",
      name: "ophthalmic_correspondence",
      title: "Ophthalmic Correspondence",
    },
    {
      type: "Correspondence",
      name: "optometrist_correspondence",
      title: "Optometrist Correspondence",
    },
    {
      type: "Correspondence",
      name: "medical_correspondence",
      title: "Medical Correspondence",
    },
    {
      type: "Correspondence",
      name: "other_referral",
      title: "Other correspondence",
    },
    // results
    {
      type: "Results",
      name: "radiology",
      title: "Radiology",
    },
    {
      type: "Results",
      name: "pathology",
      title: "Pathology",
    },
    {
      type: "Results",
      name: "ocular_diagnostic",
      title: "Ocular Diagnostic",
    },
    {
      type: "Results",
      name: "other_results",
      title: "Other Result",
    },
    // internal_documents
    {
      type: "Internal Documents",
      name: "prescription",
      title: "Prescription",
      legacy: true,
    },
    { type: "Internal Documents", name: "cataract", title: "Cataract" },
    { type: "Internal Documents", name: "injection", title: "Injection" },
    { type: "Internal Documents", name: "registration", title: "Registration" },
    { type: "Internal Documents", name: "dry_eye", title: "Dry Eye" },
    { type: "Internal Documents", name: "plastics", title: "Plastics" },
    {
      type: "Internal Documents",
      name: "other_internal_documents",
      title: "Other Internal Doc",
    },
    //other
    { type: "Other", name: "other", title: "Other" },
  ],
};

// Correspondence in (includes referral) one of these is the current referral. Categories include:
// ---Referrals (one of these is active referral)
// Ophthalmic correspondence
// Optometrist correspondence
// Medical correspondence
// Other correspondence
// ---Results (these will need to be automatically retrieved from labs)
// Radiology
// Pathology
// Ocular Diagnostic
// Other
// ---Internal documents
// Cataract
// Injection
// Registration
// Other
// ---Other

export const DASHBOARD_STATES = {
  Default: {
    name: "Arrived",
    key: "default",
    types: [
      {
        name: "Arrived",
        key: "arrived",
        displayText: "Arrived",
      },
    ],
  },
  Injection: {
    name: "Injection",
    key: "injection",
    types: [
      {
        name: "Arrived",
        key: "arrivedInj",
        displayText: "Arrived (INJ)",
      },
      {
        name: "Ready",
        key: "readyR",
        displayText: "Ready (INJ)",
      },
      {
        name: "Admin",
        key: "adminR",
        displayText: "Admin (INJ)",
      },
    ],
  },
  Glaucoma: {
    name: "Glaucoma",
    key: "glaucoma",
    types: [
      {
        name: "Arrived",
        key: "arrivedG",
        displayText: "Arrived (G)",
      },
      {
        name: "Orthop",
        key: "orthopG",
        displayText: "Orthop (G)",
      },
      {
        name: "Toy Room",
        key: "toyroomG",
        displayText: "Toy Room (G)",
      },
      {
        name: "Optom",
        key: "optomG",
        displayText: "Optom (G)",
      },
      {
        name: "Ophthal",
        key: "opthalG",
        displayText: "Ophthal (G)",
      },
      {
        name: "Admin",
        key: "adminG",
        displayText: "Admin (G)",
      },
    ],
  },
  Retina: {
    name: "Retina",
    key: "retina",
    types: [
      {
        name: "Arrived",
        key: "arrivedR",
        displayText: "Arrived (R)",
      },
      {
        name: "Orthop",
        key: "orthopR",
        displayText: "Orthop (R)",
      },
      {
        name: "Toy Room",
        key: "toyroomR",
        displayText: "Toy Room (R)",
      },
      {
        name: "Optom",
        key: "optomR",
        displayText: "Optom (R)",
      },
      {
        name: "Ophthal",
        key: "opthalR",
        displayText: "Ophthal (R)",
      },
      {
        name: "Admin",
        key: "adminR",
        displayText: "Admin (R)",
      },
    ],
  },
  Cataract: {
    name: "Cataract",
    key: "cataract",
    types: [
      {
        name: "Arrived",
        key: "arrivedC",
        displayText: "Arrived (C)",
      },
      {
        name: "Orthop",
        key: "orthopC",
        displayText: "Orthop (C)",
      },
      {
        name: "Toy Room",
        key: "toyroomC",
        displayText: "Toy Room (C)",
      },
      {
        name: "Optom",
        key: "optomC",
        displayText: "Optom (C)",
      },
      {
        name: "Ophthal",
        key: "opthalC",
        displayText: "Ophthal (C)",
      },
      {
        name: "Admin",
        key: "adminC",
        displayText: "Admin (C)",
      },
    ],
  },
  Other: {
    name: "Other",
    key: "other",
    types: [
      {
        name: "Arrived",
        key: "arrivedO",
        displayText: "Arrived (O)",
      },
      {
        name: "Orthop",
        key: "orthop",
        displayText: "Orthop (O)",
      },
      {
        name: "Toy Room",
        key: "toyroom",
        displayText: "Toy Room (O)",
      },
      {
        name: "Optom",
        key: "optom",
        displayText: "Optom (O)",
      },
      {
        name: "Ophthal",
        key: "ophthal",
        displayText: "Ophthal (O)",
      },
      {
        name: "Admin",
        key: "admin",
        displayText: "Admin (O)",
      },
    ],
  },
};

export const DASHBOARD_STATE_TO_USER_TYPE: {
  [key: string]: GlUserTypeString;
} = {
  Optom: "optometrist",
  Ophthal: "ophthalmologist",
  Admin: "administrator",
};

const GL_PRESCRIPTION_DRUG_TYPES: PatientProcedureType[] = [
  "drops",
  "laser",
  "surgical",
  "inHouse",
  "external",
  "injection",
  "tablets",
  "oral",
  "other",
];

const GL_DRUG_TYPE_KEYWORDS: {
  [key: string]: string[] | [];
} = {
  drops: ["drops", "drop", "vial"],
  tablets: ["tablet", "tablets", "capsule"],
  injection: ["injection"],
  oral: ["oral"],
  laser: ["lazer", "laser"],
};

const GL_DRUGS_PER_PRESCRIPTION_CHUNK: number = 3;

export type PbsProgramCode =
  | "GE"
  | "CT"
  | "DB"
  | "GH"
  | "HB"
  | "HS"
  | "IF"
  | "MD"
  | "MF"
  | "PL"
  | "PQ"
  | "R1"
  | "SA"
  | "IN"
  | "IP";

// not all of them will have mappings, so can specify as we go
export const PBS_DRUG_TYPE_TO_PROGRAM_CODE_MAP: {
  [key in PbsProgramCode]?: string[];
} = {
  GE: ["GE", "SB", "DT", "DS", "OT"],
  CT: ["CT", "CS"],
};

// for display reasons
export type TreatmentDisplayStatus =
  | "critical"
  | "temporary"
  | "discontinued"
  | "active";

export const TREATMENT_STATUS_CLASS_MAP: {
  [key in TreatmentDisplayStatus]: string;
} = {
  critical: "alert-danger", // red
  temporary: "alert-secondary", // grey
  discontinued: "alert-warning", // yellow
  active: "alert-success", // green
};

export interface ProcedureFormOption {
  key: string;
  name: string;
  option_key?: string; // linked to which option under Appendix
}

export type ProcedureFormType = "cataract" | "injection";

export const CATARACT_FORM_KEYS: ProcedureFormOption[] = [
  {
    key: "cataract_pre_op",
    name: "Pre Op",
    option_key: "cataractPreOp",
  },
  {
    key: "cataract_aim",
    name: "Aim",
    option_key: "cataractAim",
  },
  {
    key: "cataract_lens",
    name: "Lens",
    option_key: "cataractLens",
  },
  {
    key: "cataract_migs",
    name: "MIGS",
    option_key: "cataractMigs",
  },
  {
    key: "cataract_injectables",
    name: "Injectables",
    option_key: "cataractInjectables",
  },
  {
    key: "cataract_steroid",
    name: "Steroid",
    option_key: "cataractSteroid",
  },
  {
    key: "cataract_anaesthetic",
    name: "Anaesthetic",
    option_key: "cataractAnaesthetic",
  },
  {
    key: "cataract_operation",
    name: "Operation",
    option_key: "cataractOperation",
  },
  {
    key: "cataract_dry_eye",
    name: "Dry Eye",
    option_key: "cataractDryEye",
  },
  {
    key: "cataract_post_op",
    name: "Post Op",
    option_key: "cataractPostOp",
  },
];

// button options
export interface IGlButtonOption {
  title: string; // text
  key: string; // uib-btn-radio
  secondary_key?: string; // can put order etc...
  test_id: string; // data-testid
  default?: boolean;
  disabled?: boolean;
}

export const GNET_BUTTON_DICT: {
  [key: string]: IGlButtonOption[];
} = {
  default: [
    {
      title: "Left",
      key: "left",
      test_id: "left",
    },
    {
      title: "Both",
      key: "both",
      test_id: "both",
    },
    {
      title: "Right",
      key: "right",
      test_id: "right",
    },
  ],
  procedures: [
    {
      title: "Right",
      key: "right",
      test_id: "right",
    },
    {
      title: "Right/Left",
      secondary_key: "both",
      key: "right_left",
      test_id: "right_left",
    },
    {
      title: "Left/Right",
      secondary_key: "both",
      key: "left_right",
      test_id: "left_right",
    },
    {
      title: "Left",
      key: "left",
      test_id: "left",
    },
  ],
};

export const GLAUCOMA_FORM_KEYS: ProcedureFormOption[] = [
  {
    key: "glaucoma_risk",
    name: "Glaucoma Risk",
    option_key: "glaucomaRisk",
  },
  {
    key: "target_iop",
    name: "Target IOP",
  },
  {
    key: "diagnosis_progression",
    name: "Progression",
    option_key: "progressionTypes",
  },
];

export class Appendix {
  static injectionName = "appendix";
  // Returns sorted or unsorted terms from the dictionary for a particular field
  get(key: string, dontSort: boolean = false) {
    if (dontSort) {
      return GNET_OPTIONS_DICTIONARY[key];
    } else {
      // Alphabetize the results before returning
      return sortBy(GNET_OPTIONS_DICTIONARY[key], "name");
    }
  }

  // Provide the dictionary you want to get the default for
  getDefaultKey(field: string) {
    switch (field) {
      case "repeat":
        return "five_years";
      case "repeat.amount":
        return 5;
      // case "va_method":
      //   return "unaided";
      default:
        return this.getDefaultFromDictionary(field);
    }
  }

  // @deprecated
  getDrops() {
    return GNET_DROPS;
  }

  // @deprecated
  getTreatmentsPrescriptionDetails() {
    return GNET_TREATMENTS_PRESCRIPTION_DETAIL;
  }

  // errror called here on prescribe
  getTreatmentPrescriptionText(drop: GlDrop) {
    const prescptionText =
      drop.name && GNET_TREATMENTS_PRESCRIPTION_DETAIL[drop.name];
    if (drop.name === "Other" && drop?.name_other) {
      //  const match = Object.entries(GNET_TREATMENTS_PRESCRIPTION_DETAIL).find(([key, value]) => key.toLowerCase() === drop.name_other.toLowerCase())
      const matchingKey = Object.keys(GNET_TREATMENTS_PRESCRIPTION_DETAIL).find(
        (key) => key.toLowerCase() === drop?.name_other.toLowerCase()
      );
      return GNET_TREATMENTS_PRESCRIPTION_DETAIL[matchingKey];
    }
    return prescptionText || drop.name_other || drop.name;
  }

  // @deprecated
  getTreatmentPrescriptionDetail(name: string) {
    return GNET_TREATMENTS_PRESCRIPTION_DETAIL[name];
  }

  getDefaultFromDictionary(field: string) {
    const appendix = GNET_OPTIONS_DICTIONARY[field] || [];
    // Check all the fields and return the one set to default
    return appendix.find((a) => a.default === true);
  }

  getWhere(field: string, key: string, value: any) {
    const appendix = GNET_OPTIONS_DICTIONARY[field];
    // Check all the fields and return the one with the
    return appendix.find((option) => option[key] === value) || null;
  }

  getWhereKey(field: string, key: string) {
    const appendix = GNET_OPTIONS_DICTIONARY[field];
    return appendix.find((option) => option.key === key) ?? null;
  }

  /// OTHER DICTIONARY
  getExtra(field: string) {
    return GNET_OBSERVATIONS_AUTOFILL_MAPPING[field];
  }

  getExtraWhere(field: string, key: string, value: any) {
    const appendix = GNET_OBSERVATIONS_AUTOFILL_MAPPING[field];
    // Check all the fields and return the one with the
    return appendix?.find((option) => option[key] === value) ?? null;
  }

  /* FETCH EXTEA OPTIONS WHERE */
  // ? do we move all of these to autofill option?
  // for observations and IGlOption
  getObservationExtraWhere(field: string, key: string) {
    const appendix = GNET_OBSERVATIONS_AUTOFILL_MAPPING[field];
    if (some([field, key, appendix], (k) => isNil(k)) || !isString(key)) {
      return null;
    }

    return (
      appendix?.find((option) =>
        [
          option?.key?.toLowerCase(),
          option?.autofill_key_alternative?.toLowerCase(),
        ].includes(key?.toLowerCase())
      ) ?? null
    );
  }

  // fetch by key
  getDiagnosisExtraWhere(key: string) {
    return GNET_DIAG_AUTOFILL_MAPPING[key];
  }

  getExternalProcedureWhere(key: string) {
    return GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING[key];
  }

  // ? move to autofill helper or retain here
  //  checks if the option is an autofill by fetching
  // a mapping based on a key
  isOptionAutofill(field: string, key: string) {
    switch (field) {
      // external procedures are different
      case EXTERNAL_PROCEDURES_KEY:
        return this.getExternalProcedureWhere(key);
      case DIAGNOSIS_ARRAY_KEY:
        return this.getDiagnosisExtraWhere(key);
      // else generally its a IGlOptionExtra
      default:
        return this.getObservationExtraWhere(field, key);
    }
  }

  // by default will always be true
  isOptionMultiSelect(field: string, key: string) {
    const option: IGlOptionExtra = this.getObservationExtraWhere(field, key);
    if (!isNil(option)) {
      return !option.disableMultiSelect;
    }
    return true;
  }

  // OPTIONS
  getOptions(field: string) {
    return GNET_NUMERIC_OPTIONS[field];
  }

  getPrescribableDrugTypes() {
    return GL_PRESCRIPTION_DRUG_TYPES;
  }

  getDrugTypeKeywords() {
    return GL_DRUG_TYPE_KEYWORDS;
  }

  getDrugsPerPrescriptionChunk() {
    return GL_DRUGS_PER_PRESCRIPTION_CHUNK;
  }

  getPbsDrugTypeFromProgramCode(programCode: PbsProgramCode) {
    return PBS_DRUG_TYPE_TO_PROGRAM_CODE_MAP[programCode] ?? [programCode];
  }

  getTreatmentStatusClasses() {
    return TREATMENT_STATUS_CLASS_MAP;
  }

  getNumericRangesByKey(key: string) {
    return GNET_NUMERIC_OPTION_RANGES[key] ?? [];
  }

  // lens edge case
  getLensAutofillDictKeys() {
    return ["lens", "IOLType"];
  }

  getUserTypeFromWorkflowState(recordWorkflowState: string) {
    const dashboardStateKeys: string[] = Object.keys(DASHBOARD_STATES);

    // flatmap types
    const allTypes = flatMap(dashboardStateKeys, (k) => {
      return DASHBOARD_STATES[k]?.types ?? [];
    });

    // find the relevant selected workflow state to match provider type
    const foundType = allTypes.find(
      (o) => o.displayText === recordWorkflowState
    );

    // return it else its just undefiend
    return DASHBOARD_STATE_TO_USER_TYPE?.[foundType?.name];
  }

  shouldDisplayOption(option: IGlOption) {
    // as long as it has a name
    // and key isnt part of the hidden ones
    return option?.name && !["not-examined", "none"].includes(option?.key);
  }

  getOptionsByKeys(optionKey: string, selectedOptions: IGlOption[]) {
    const appendixOptions: IGlOption[] = this.get(optionKey) ?? [];
    return appendixOptions.filter(
      (o) => !isNil(selectedOptions.find((so) => so.key === o.key))
    );
  }

  // mapping
  getCataractFormKeys() {
    return CATARACT_FORM_KEYS;
  }

  getCataractFormKeyByKey(key: string) {
    return CATARACT_FORM_KEYS.find((o) => o?.key === key);
  }

  getGlaucomaFormKeyByKey(key: string) {
    return GLAUCOMA_FORM_KEYS.find((o) => o?.key === key);
  }

  // CATARACTS FORM
  // given a list of options, filter them out
  filterCataractOptionsByExisting(field_options: {
    [key: string]: IGlOption[];
  }) {
    for (const key of Object.keys(field_options)) {
      field_options[key] = this.filterCataractOptionsByOptionList(
        key,
        field_options[key]
      );
    }
    return field_options;
  }

  // same as above but for a single list of options
  filterCataractOptionsByOptionList(key: string, options: IGlOption[]) {
    // select based off what exists in current dict
    const _formKey: ProcedureFormOption = this.getCataractFormKeyByKey(key);
    // fitler by which options are currently exisitng
    options = filter(
      options,
      (v) =>
        // must not be empty
        !isNil(this.get(_formKey?.option_key).find((o) => o?.key === v?.key))
    );

    // is there a default option?
    // const hasDefaultOption = options[key].find((o) => o.default);
    // these options might be missing
    // we have to inject them in
    const injectableOptions: IGlOption[] = [
      {
        name: "Other",
        key: "other",
        showOther: true,
      },
      // {
      //   name: "None",
      //   key: "none",
      //   showOther: true,
      //   default: isNil(hasDefaultOption),
      // },
    ];
    for (const option of injectableOptions) {
      const hasOption = options.find((o) => o.key === option.key);
      if (!hasOption) {
        options.push(option);
      }
    }

    return options;
  }

  // custom buttons
  getButtonOptionsByKey(key: string) {
    return GNET_BUTTON_DICT[key] ?? [];
  }

  // EXTERNAL PROCEDURES
  getDefaultExternalProcedureOptions() {
    return GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING;
  }

  getDefaultExternalProcedure(name: string) {
    // otherwise get default
    return GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING[name?.toLowerCase()];
  }
}
