import {
  cloneDeep,
  defaults,
  defaultsDeep,
  get,
  isNil,
  isString,
} from "lodash";
import { IGlSideOrder } from "models/gl-side-order.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { parseServerDate } from "../../../../../lib/parse_server_date";
import { IDayProcedureDetail } from "../../../../../models/gl-in-house-procedure";
import { IGlSide } from "../../../../../models/gl-side.model";
import { PatientProcedureInHouse } from "../../../../../models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { Appendix, IGlButtonOption } from "../../../services/appendix";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { GlFormController } from "../../gl-form-controller";
import "./day-procedures.scss";
export class DayProceduresController
  extends GlFormController
  implements angular.IComponentController, angular.IOnChanges
{
  record: PatientRecord;
  procedures: PatientProcedureInHouse[];
  proceduresToDisplay: PatientProcedureInHouse[];
  nameOptions = this.appendix.get("dayProcedures");
  showEditForm = false;
  editModel: PatientProcedureInHouse;
  saveInProgress = false;
  deleteInProgress = false;
  historicalEditableOnly = false;
  openDatePicker = false;

  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  // if this is toggled all non-hisotrical procedures can be edited
  // this can only be toggled only if
  // historicalEditableOnly = true
  // then all edits will be recorded in the action log
  //
  // if historicalEditableOnly is false, then this is ignored
  retroactiveEditingEnabled: boolean = false;

  // proxy to allow handling of left_right and right_left
  editModelEyeSide: IGlSide | IGlSideOrder;

  constructor(
    public appendix: Appendix,
    private $window: angular.IWindowService,
    private PatientProcedureService: PatientProcedureService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if ((changes.procedures || changes.record) && this.procedures) {
      // if we pass in a specific record, only show procedures that were created in this record, otherwise show all
      this.proceduresToDisplay = this.record
        ? this.procedures.filter(
            (p) => p.data.completed_in_record_id === this.record?.id
          )
        : this.procedures;
    }
  }

  // EDITABLE
  canEditProcedure(procedure: PatientProcedureInHouse) {
    const historicalEditable: boolean =
      (this.historicalEditableOnly && procedure.status === "historical") ||
      !this.historicalEditableOnly;

    const retroactiveEditable: boolean =
      this.canEditRetroactively() && procedure.status !== "historical";

    return this.isEditMode() && (historicalEditable || retroactiveEditable);
  }

  // RETROACTIVE EDITING RELATED
  canEditRetroactively() {
    // only if we are in historical editable mode
    // and is toggled
    return this.historicalEditableOnly && this.retroactiveEditingEnabled;
  }

  procedureIsRetroactivelyEditable(procedure: PatientProcedureInHouse) {
    return (
      this.canEditRetroactively() &&
      procedure?.status !== "historical" &&
      !isNil(procedure.id)
    );
  }

  // history procedures have different editing rules to them
  //
  // but we must also differentiate if the procedure being edited
  // is being edited retroactively
  // this returns false for anything that isnt a historical procedure
  isHistoricalEditable() {
    return (
      this.historicalEditableOnly &&
      (isNil(this.editModel?.id) || this.editModel.status === "historical")
    );
  }

  toggleRetroactiveEditing() {
    this.retroactiveEditingEnabled = !this.retroactiveEditingEnabled;
  }

  edit(procedure: PatientProcedureInHouse) {
    this.editModel = cloneDeep(procedure);
    defaultsDeep(this.editModel, {
      data: {
        left: {
          routine: true,
        },
        right: {
          routine: true,
        },
      },
    });
    if (this.historicalEditableOnly) {
      this.editModel.procedure_date = this.parseDateString(
        this.editModel.procedure_date
      );
    }

    // LEGACY ON INIT
    // all legacy data will be converted to right_left order since thats the default
    if (
      this.editModel.data.eye === "both" &&
      isNil(this.editModel?.data?.order)
    ) {
      defaults(this.editModel.data, { order: "right_left" });
    }

    // update eye side based on order or eye
    this.editModelEyeSide =
      this?.editModel?.data?.order ?? this.editModel?.data?.eye;

    this.showEditForm = true;
  }

  add() {
    const newProcedure: PatientProcedureInHouse = {
      type: "inHouse",
      data: {
        eye: "both" as IGlSide,
        left: {
          routine: true,
        },
        right: {
          routine: true,
        },
        completed_in_record_id: this.record?.id,
        nameAppendix: null,
      },
      procedure_date: new Date(),
      record_id: null,
      status: null,
      created_at: null,
      updated_at: null,
    };
    this.editModel = newProcedure;
    this.showEditForm = true;
  }

  hasSide(procedure: PatientProcedureInHouse, side: IGlSide) {
    if (!procedure?.data) {
      return false;
    }
    return ["both", "left_right", "right_left", side].includes(
      procedure.data.eye
    );
  }

  isLaser(procedure: PatientProcedureInHouse) {
    const data = procedure.data;
    return data?.nameAppendix && data.nameAppendix.type === "Laser";
  }

  getDescriptionPlaceholder(procedure: PatientProcedureInHouse) {
    return this.isLaser(procedure) ? "Settings" : "Description";
  }

  eyeDidChange(option: IGlButtonOption) {
    // quick check on eye side to handle order edge case
    this.eyeOrderOnSelect(option);

    const eyeDefaults = {
      routine: true,
    };
    const isLeft = this.isLeft(this.editModel);
    const isRight = this.isRight(this.editModel);
    if (isLeft) {
      defaultsDeep(this.editModel.data, { left: eyeDefaults });
    }
    if (isRight) {
      defaultsDeep(this.editModel.data, { right: eyeDefaults });
    }
    if (!isLeft) {
      delete this.editModel.data.left;
    }
    if (!isRight) {
      delete this.editModel.data.right;
    }
  }

  eyeOrderOnSelect(option: IGlButtonOption) {
    // if left/right or right/left
    if (option?.secondary_key === "both") {
      // set eye and option appropriately
      this.editModel.data.eye = "both";
      this.editModel.data.order = option?.key as IGlSideOrder;
    } else {
      // otherwise set as usual
      this.editModel.data.eye = option?.key as IGlSide;
      delete this.editModel.data.order;
    }
  }

  isLeft(procedure: PatientProcedureInHouse) {
    return this.hasSide(procedure, "left");
  }

  isRight(procedure: PatientProcedureInHouse) {
    return this.hasSide(procedure, "right");
  }

  getProcedureSummary(procedure: PatientProcedureInHouse, side: IGlSide) {
    const data = procedure.data;
    const eyeDetails = side === "left" ? data.left : data.right;
    const nameStr = get(data, "nameAppendix.name");
    const otherStr = get(data, "name_other");
    const complication = !eyeDetails
      ? ""
      : eyeDetails.routine
      ? "Routine"
      : "Complicated";
    return (
      complication
        ? `${complication} ${otherStr ? otherStr : nameStr}`
        : `${otherStr || nameStr}`
    ).trim();
  }

  getProcedureEyeOrder(procedure: PatientProcedureInHouse) {
    // order of procedure if it counts
    switch (procedure?.data?.order) {
      case "left_right":
        return "(L -> R)";
      case "right_left":
        return "(R -> L)";
      default:
        return "";
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getProcedureDate(procedure: PatientProcedureInHouse, side: IGlSide) {
    return procedure.procedure_date;
  }

  getProcedureBackground(procedure: PatientProcedureInHouse, side: IGlSide) {
    const completionDate = procedure.procedure_date;
    const details: IDayProcedureDetail = procedure.data[side];
    if (procedure.status === "historical") {
      return "bg-secondary";
    } else if (!completionDate) {
      return "bg-warning";
    } else if (completionDate && (!details || (details && details.routine))) {
      // if complete  & routine then green
      return "alert-success";
    } else if (completionDate && "routine" in details && !details.routine) {
      // if complete & complicated then red
      return "alert-danger";
    }
  }

  openDatepicker() {
    this.openDatePicker = true;
  }

  saveClicked() {
    this.saveInProgress = true;

    // if we can edit non historical procedures
    // and it is an existing procedure
    // that isnt a historical one
    // we should log the changes as it is a retroactive change
    const shouldLogChanges: boolean = this.procedureIsRetroactivelyEditable(
      this.editModel
    );

    // try and find the existing procedure by id before it is updated
    // this will be ignored if it is a new procedure
    const existingProcedure: PatientProcedureInHouse =
      (this.editModel?.id
        ? this.procedures?.find((p) => p?.id === this.editModel?.id)
        : null) ?? null;

    if (this.historicalEditableOnly && isNil(this.editModel.id)) {
      this.editModel = { ...this.editModel, status: "historical" };
    }

    /*  
      NOTE: for retroactive editing
      record id is irrelevant since the only data that will
      be changed is just the information linked to the procedure entry

      so record_id will remain constant regardless of state
      if defined, since it already filters based on the provided record id
      chance of failure will be low
    */
    const savePromise = this.editModel.id
      ? this.PatientProcedureService.updateInHouseProcedure({
          recordId: this.record?.id,
          procedure: this.editModel,
          oldProcedure: existingProcedure,
          logChanges: shouldLogChanges,
        })
      : this.PatientProcedureService.createInHouseProcedure(
          this.record?.id,
          this.editModel
        );
    savePromise
      .then(() => this.cancelClicked())
      .finally(() => (this.saveInProgress = false));
  }

  cancelClicked() {
    this.editModel = undefined;
    this.showEditForm = false;
  }

  delete() {
    const shouldDelete = this.$window.confirm(
      "Are you sure you want to remove this procedure?"
    );
    if (shouldDelete) {
      // should we log the delete as well?
      const shouldLogChanges = this.procedureIsRetroactivelyEditable(
        this.editModel
      );

      this.deleteInProgress = true;
      /*  
        NOTE: for retroactive deleting
        record id is irrelevant since the only data that will
        be changed is just the information linked to the procedure entry

        so record_id will remain constant regardless of state
        if defined, since it already filters based on the provided record id
        chance of failure will be low
      */
      this.PatientProcedureService.deleteInHouseProcedure(
        this.record?.id,
        this.editModel,
        shouldLogChanges
      )
        .then(() => this.cancelClicked())
        .finally(() => (this.deleteInProgress = false));
    }
  }

  private parseDateString(dateStr: string | Date) {
    // need to convert the procedure_date to a date object
    // older procedures have SQL date string formats
    if (isString(dateStr) && dateStr.includes("Z")) {
      // ISO8601 String
      return new Date(dateStr);
    } else if (isString(dateStr)) {
      return parseServerDate(dateStr).toDate();
    } else {
      return dateStr;
    }
  }
}

export class DayProceduresComponent implements angular.IComponentOptions {
  static selector = "dayProcedures";
  static template = require("./day-procedures.html");
  static controller = DayProceduresController;
  static bindings = {
    record: "<",
    mode: "@?",
    isEditable: "<?",
    procedures: "<",
    historicalEditableOnly: "<?",
  };
}
