import { GlToastrCategory } from "../toastr-appendix";

export const GENERIC_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    please_try_again: "An error has occured, please try again",
    refresh_try_again: "Please refresh the page and try again",
  },
  info: {},
};
