import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  IOnChangesObject,
  IOnInit,
  IPromise,
} from "angular";
import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { QzPrinterService } from "app/core/services/qz-printer/qz-printer";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { isEmpty, isFunction, isNil, omit } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import {
  PatientProcedureDrug,
  PatientProcedureDrugSelectionWrapper,
} from "models/patient-procedure";
import { PatientRecord } from "models/patient-record.model";
import {
  GlPrescriptionDrugDataSearchDecorator,
  UserFavouriteDrugGroup,
} from "models/user-favourite-drugs";
import { GlStaff, Patient } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";

class UserFavouriteDrugsGroupController
  extends GlFormController
  implements IComponentController, IOnChanges, IOnInit
{
  mode: IGlFormMode = "create";
  record: PatientRecord;
  patient: Patient;
  prescriber: GlStaff;

  // master toggle is on the parent
  favouriteDrugs: PatientProcedureDrugSelectionWrapper[] | [];
  favouriteDrugsFiltered: PatientProcedureDrug[] | [] = [];

  // group could be undefined
  globalGroupId: number = this.UserFavouriteDrugsService.getGlobalGroupId();
  favouriteGroup: UserFavouriteDrugGroup | null;
  favouriteDrugGroups: UserFavouriteDrugGroup[];

  // drug to edit
  drugToEdit: PatientProcedureDrug | object = undefined;
  favouriteDrugGroupToEdit: UserFavouriteDrugGroup | object = undefined;

  errorMessages = this.ToastrAppendix.getAllToastrMessages();

  // favourite group
  saveFavouriteGroupInProgress: boolean = false;
  deleteFavouriteGroupInProgress: boolean = false;

  // progress updaters
  createDrugInProgress: boolean = false;
  saveDrugInProgress: boolean = false;
  updateDrugInProgress: boolean = false;
  deleteDrugInProgress: boolean = false;
  searchOrEditDrugInProgress: boolean = false;

  useRecordIdOnPrescribe: boolean = false;

  // toggle selection drugs
  isFavouriteSelected: (arg: { drug: PatientProcedureDrug }) => void;
  toggleSelection: (arg: { drug: PatientProcedureDrug }) => void;
  // toggleEditFavouriteGroup: (arg: { group: UserFavouriteDrugGroup }) => void;

  onDrugsUpdate: (arg: { patientId: number }) => void;
  onPrescriptionsUpdate: (arg: { patientId: number }) => void;
  onFavouritesUpdate: (arg: { userId: number }) => void;

  constructor(
    private $window: angular.IWindowService,
    private toastr: angular.toastr.IToastrService,
    private PatientProcedureService: PatientProcedureService,
    private ToastrAppendix: ToastrAppendix,
    private UserFavouriteDrugsService: UserFavouriteDrugsService,
    private PrescriptionsService: PrescriptionsService,
    public QzPrinterService: QzPrinterService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    if (this.isCreateMode()) {
      this.favouriteDrugGroupToEdit = {};
    }
  }

  $onChanges(changes: IOnChangesObject): void {
    // if a favourite group is specified, filter view for drugs that are
    // a part of that group
    if (
      (changes?.favouriteGroup && this?.favouriteGroup) ||
      (changes?.favouriteDrugs && this?.favouriteDrugs)
    ) {
      this.favouriteDrugsFiltered = this.getFavouriteDrugsInGroup();
    }
  }

  getFavouriteDrugs() {
    // if a group is defined, get by the group
    return this.favouriteDrugsFiltered;
  }

  getFavouriteDrugsInGroup() {
    // no favourite group specified, return all
    if (isNil(this?.favouriteGroup)) {
      return this?.favouriteDrugs ?? [];
    }

    return this.favouriteGroup?.drugs ?? [];
  }

  // favourite drugs update callback
  handleFavouritesUpdate() {
    if (isFunction(this.onFavouritesUpdate)) {
      this.onFavouritesUpdate({ userId: this.prescriber.id });
    }
  }

  // prescription related
  handlePrescriptionsUpdate() {
    if (isFunction(this.onPrescriptionsUpdate)) {
      this.onPrescriptionsUpdate({ patientId: this.patient.id });
    }
  }

  // UPDATES
  handleDrugsUpdate() {
    if (isFunction(this.onDrugsUpdate)) {
      this.onDrugsUpdate({ patientId: this.patient.id });
    }
  }

  handleToggleEdit(index: number) {
    const selectedDrug: PatientProcedureDrug =
      this.favouriteDrugsFiltered[
        this.favouriteDrugsFiltered.length - 1 - index
      ];
    if (selectedDrug) {
      this.drugToEdit = selectedDrug;
    }
  }

  handleCheckIfFavouriteSelected(drug: PatientProcedureDrug) {
    if (isFunction(this.isFavouriteSelected)) {
      this.isFavouriteSelected({ drug });
    }
  }

  // EDITING DRUG
  // search
  addDrugFromSearch(drugData: GlPrescriptionDrugDataSearchDecorator) {
    // if an existing favourite template selected, reference it
    let foundFavourite: PatientProcedureDrug;

    // check if selected template references a drug id by chance
    if (!isNil(drugData?.favourite_drug_id)) {
      // find the drug amongst all the favourites including globals
      foundFavourite =
        this.UserFavouriteDrugsService.getFavouriteDrugsWithGlobals().find(
          (d) => d.id === drugData?.favourite_drug_id
        );
    }

    // if we find a match then reference that favourite template instead
    if (foundFavourite) {
      this.drugToEdit = foundFavourite;
    } else {
      // otherwise just use the regular template
      // set to edit
      this.drugToEdit = {
        ...this.drugToEdit,
        data: omit(drugData, ["queries"]),
      };
    }

    this.toastr.success(`Editing ${drugData.brand_name} below...`);
  }

  addDrug() {
    this.drugToEdit = {};
    this.toggleCreateDrug();
  }

  editDrug(index: number) {
    this.drugToEdit = this.favouriteDrugsFiltered[index];
  }

  toggleCreateDrug() {
    this.searchOrEditDrugInProgress = true;
  }

  toggleCancelDrug() {
    this.searchOrEditDrugInProgress = false;
  }

  cancelDrug() {
    this.drugToEdit = undefined; // reset
    this.toggleCancelDrug();
  }

  // FAVOURITE GROUPS
  toggleCreateFavouriteGroup() {
    this.favouriteDrugGroupToEdit = {};
  }

  toggleCancelFavouriteGroup() {
    this.favouriteDrugGroupToEdit = this.isCreateMode() ? {} : undefined;
  }

  toggleEditFavouriteGroup() {
    const group: UserFavouriteDrugGroup = this.favouriteGroup;

    if (!group || isEmpty(this.favouriteDrugGroups)) {
      return;
    }

    const foundGroup: UserFavouriteDrugGroup = this.favouriteDrugGroups.find(
      (g) => g.id === group.id
    );
    if (foundGroup) {
      this.favouriteDrugGroupToEdit = foundGroup;
    }
  }

  // check if group can be edited
  favouriteGroupEditable(): boolean {
    // if theres no groupings should be allowed
    return this.UserFavouriteDrugsService.canEditFavouriteGroup(
      this?.favouriteGroup,
      this?.prescriber
    );
  }
  // handle create and save
  saveFavouriteGroup(group: UserFavouriteDrugGroup) {
    if (isNil(group)) {
      return this.toastr.error(
        this.errorMessages.favourite_group.error.general.no_group_specified
      );
    }

    if (!this.prescriber.id) {
      return this.toastr.error(
        this.errorMessages.generic.error.refresh_try_again
      );
    }

    if (!this.UserFavouriteDrugsService.checkIfGroupNameUnique(group.name)) {
      return this.toastr.error(
        this.errorMessages.favourite_group.error.create.group_name_exists
      );
    }

    // sanity check on name
    this.saveFavouriteGroupInProgress = true;

    const savePromise: IPromise<UserFavouriteDrugGroup> = isNil(group?.id)
      ? this.UserFavouriteDrugsService.createUserFavouriteDrugGroup(
          this.prescriber.id,
          group.name
        )
      : this.UserFavouriteDrugsService.updateUserFavouriteDrugGroup(
          this.prescriber.id,
          group.name,
          group.id
        );

    savePromise
      .then((newGroup) => {
        this.toastr.success(
          `Successfully ${group.id ? "saved" : "created"} new favourite group ${
            newGroup.name
          }`
        );
        this.toggleCancelFavouriteGroup();
      })
      .catch((error) => {
        const message: string =
          error?.data?.message ??
          this.errorMessages.generic.error.please_try_again;
        this.toastr.error(message);
      })
      .finally(() => {
        this.saveFavouriteGroupInProgress = false;
        this.handleFavouritesUpdate();
      });
  }

  deleteFavouriteGroup(group: UserFavouriteDrugGroup) {
    // cehck if empty
    if (!isNil(group?.drugs) && !isEmpty(group?.drugs)) {
      return this.toastr.error(
        this.errorMessages.favourite_group.error.delete.group_not_empty
      );
    }
    // otherwise continue with prompt
    const shouldDeleteGroup: boolean = this.$window.confirm(
      `Are you sure you want to delete ${group.name}?`
    );

    if (shouldDeleteGroup) {
      this.deleteFavouriteGroupInProgress = true;

      this.UserFavouriteDrugsService.deleteUserFavouriteDrugGroup(
        this.prescriber.id,
        group.id
      )
        .then(() => {
          this.toastr.success(
            `Successfully deleted favourite group ${group.name}`
          );
          this.toggleCancelFavouriteGroup();
        })
        .finally(() => {
          this.deleteFavouriteGroupInProgress = true;
          this.handleFavouritesUpdate();
        });
    }
  }

  // FAVOURITE DRUGS
  // even if referencing by group
  // since its a many to many we update the regular drug only
  saveDrugFavourite(drug: PatientProcedureDrug) {
    if (isNil(this.prescriber)) {
      return this.toastr.error(
        this.errorMessages.generic.error.refresh_try_again
      );
    }

    this.saveDrugInProgress = true;
    const savePromise: IPromise<PatientProcedureDrug> =
      this._createFavouriteDrugSavePromise(drug);

    savePromise
      .then(() => {
        this.toastr.success(
          `Successfully ${
            drug?.id ? "updated" : "created"
          } user favourite drug ${drug.data.brand_name}!`
        );
        this.drugToEdit = undefined;
      })
      .catch(() => {
        this.toastr.error(
          `Error ${drug?.id ? "saving" : "creating"} ${
            drug.data.brand_name
          } user favourite drug, please try agian.`
        );
      })
      .finally(() => {
        this.saveDrugInProgress = false;
        this.handleFavouritesUpdate();
      });
  }

  deleteDrugFavourite(drug: PatientProcedureDrug) {
    const shouldDeleteDrug: boolean = this.$window.confirm(
      `Are you sure you want to delete ${drug.data.brand_name} from your favourites?`
    );

    if (shouldDeleteDrug) {
      this.deleteDrugInProgress = true;

      this.UserFavouriteDrugsService.deleteUserFavouriteDrug(
        this.prescriber.id,
        drug.id
      )
        .then(() => {
          this.drugToEdit = undefined;
          this.toastr.success(
            `Successfully deleted ${drug.data.brand_name} from favourites!`
          );
          // this.deleteDrugFromSelection(drug);
        })
        .catch(() => {
          this.toastr.error(this.errorMessages.drugs.error.delete);
        })
        .finally(() => {
          this.deleteDrugInProgress = false;
          this.handleFavouritesUpdate();
        });
    }
  }

  // REGULAR DRUG CREATE
  // kind of like a quick add from a template
  createDrugRegular(drug: PatientProcedureDrug) {
    this.createDrugInProgress = true;
    // over here ensure we are using patientid
    drug.user_id = this.patient.id;
    // its a regular drug so if needed can be added back as a favourite
    drug.data.favourite = false;
    const createPromise: IPromise<PatientProcedureDrug> =
      this.PatientProcedureService.createDrug(
        this.useRecordIdOnPrescribe && this?.record.id
          ? this?.record?.id
          : null,
        drug
      );

    createPromise
      .then((createdDrug) => {
        this.toastr.success(
          `Successfully created ${createdDrug.data.brand_name}!`
        );
        this.drugToEdit = undefined;
      })
      .catch(() => {
        this.toastr.error(this.errorMessages.drugs.error.create);
      })
      .finally(() => {
        this.createDrugInProgress = false;
        this.handleDrugsUpdate();
      });
  }

  private _createFavouriteDrugSavePromise(
    drug: PatientProcedureDrug
  ): IPromise<PatientProcedureDrug> {
    // edge case for converting regular drugs/drops into favourites
    // we dont use id here as regular drugs have ids and
    // categories are assigned if created as a favourite
    const isExistingFavourite: boolean =
      drug.category === "favourite" && !isNil(drug.id);

    return isExistingFavourite
      ? this.UserFavouriteDrugsService.updateUserFavouriteDrug(
          this.prescriber.id,
          drug,
          {
            drug_id: drug.data?.id,
            favourite_group_ids: drug.favourite_groups ?? [],
          }
        )
      : this.UserFavouriteDrugsService.createUserFavouriteDrug(
          this.prescriber.id,
          drug,
          {
            drug_id: drug.data?.id,
            favourite_group_ids: drug.favourite_groups ?? [],
          }
        );
  }
}

export class UserFavouriteDrugsGroup implements IComponentOptions {
  static selector = "userFavouriteDrugsGroup";
  static template = require("./user-favourite-drugs-group.html");
  static controller = UserFavouriteDrugsGroupController;
  static bindings = {
    mode: "@",
    isEditable: "<",
    record: "<?",
    patient: "<",
    prescriber: "<",
    favouriteDrugs: "<",
    favouriteGroup: "<",
    favouriteDrugGroups: "<",
    useRecordIdOnPrescribe: "<?",
    isFavouriteSelected: "&",
    onDrugsUpdate: "&",
    onPrescriptionsUpdate: "&",
    onFavouritesUpdate: "&",
  };
}
