import { filter, get, isEmpty, isEqual, isNil, set } from "lodash";
import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { GlExternalDataMinimal } from "models/gl-external-procedure";
import { IGlSide } from "models/gl-side.model";
import { PatientProcedureExternal } from "models/patient-procedure";
import { GlDiagnosis, PatientRecordData } from "models/patient-record.model";
import { EXTERNAL_PROCEDURES_KEY } from "../../../../lib/key-appendix";
import { AutofillHelperService } from "../autofill-helper/autofill-helper.service";
import { GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING } from "../autofill-helper/autofill.mapping";
import { IGlAutofill } from "../autofill/autofill.service";
import { ChangesService } from "../changes/changes.service";
import { PatientProcedureService } from "../patient-procedure.service";
import moment = require("moment");
import { ToastrAppendix } from "../toastr-appendix/toastr-appendix";

export class AutofillExternalProcedureService implements IGlAutofill {
  static injectionName: string = "AutofillExternalProcedureService";

  // * FOR AUTOFILL
  // we keep it here rather than patient procedure services as
  // this is only a temporary state thing
  // and creation is only called if autofill is confirmed
  private temporaryExternalProcedures: PatientProcedureExternal[] = [];

  private autofillMessages = this.ToastrAppendix.getAutofillMessages();

  //
  constructor(
    private AutofillHelperService: AutofillHelperService,
    private PatientProcedureService: PatientProcedureService,
    private ChangesService: ChangesService,
    private toastr: angular.toastr.IToastrService,
    private ToastrAppendix: ToastrAppendix
  ) {
    "ngInject";
  }

  $onDestroy() {
    // reset everything
    this.resetTemporaryProcedures();
  }

  getCreatedExternalProcedures() {
    return this.PatientProcedureService.getExternalProcedures();
  }

  getExternalProcedureByKey(key: string): GlExternalDataMinimal {
    return GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING[key];
  }

  /* HANDLERS FOR TEMP PROCEDURES */
  getTemporaryExternalProcedures() {
    return this.temporaryExternalProcedures;
  }

  addTemporaryExternalProcedure(procedure: PatientProcedureExternal) {
    if (procedure?.is_temp) {
      this.temporaryExternalProcedures.push(procedure);
    }
  }

  // should always be called on any main page reset
  resetTemporaryProcedures() {
    this.temporaryExternalProcedures = [];
  }

  /* CRUD */
  // create tempoary procedure given information
  createTemporaryExternalProcedure({
    externalProcedureData,
    side,
    patientId,
    recordId,
  }: {
    externalProcedureData: GlExternalDataMinimal;
    side: IGlSide;
    patientId: number;
    recordId: number;
  }) {
    // if no procedure found dont bother
    if (isNil(externalProcedureData)) {
      return;
    }

    const currTime = moment().toISOString();
    const tempProcedure: PatientProcedureExternal = {
      record_id: recordId,
      user_id: patientId,
      type: "external",
      data: {
        created_in_record_id: recordId,
        eye: side,
        ...externalProcedureData,
      },
      procedure_date: null,
      status: null,
      created_at: currTime,
      updated_at: null,
      // most imporatnt is temp data
      is_temp: true,
    };

    // setup data as well
    switch (side) {
      case "both":
        tempProcedure.data.left = { complete: false };
        tempProcedure.data.right = { complete: false };
        // default order for both is R/L
        tempProcedure.data.order = "right_left";
        break;
      case "left":
        tempProcedure.data.left = { complete: false };
        break;
      case "right":
        tempProcedure.data.right = { complete: false };
        break;
      default:
        break;
    }

    return tempProcedure;
  }

  /* MISC HELPERS */
  // a procedure is identified by its name, recordId and patientId
  getTemporaryProcedureIndex(procedure: PatientProcedureExternal) {
    // assumed its always a 1-1
    return this.temporaryExternalProcedures.findIndex(
      (p) =>
        p.data?.nameAppendix?.name === procedure?.data?.nameAppendix?.name &&
        p?.record_id === procedure?.record_id
    );
  }

  getTemporaryProcedure(procedure: PatientProcedureExternal) {
    // same as above but returns the whole thing
    this.getTemporaryProcedureWithData(
      procedure?.data?.nameAppendix?.name,
      procedure?.record_id
    );
  }

  getTemporaryProcedureWithData(name: string, recordId: number) {
    return this.temporaryExternalProcedures.find(
      (p) => p?.data?.nameAppendix?.name === name && p?.record_id === recordId
    );
  }

  getTemporaryProcedureIndexWithData(name: string, recordId: number) {
    return this.temporaryExternalProcedures.findIndex(
      (p) => p?.data?.nameAppendix?.name === name && p?.record_id === recordId
    );
  }

  removeTemporaryProcedure(procedure: PatientProcedureExternal) {
    const index: number = this.getTemporaryProcedureIndex(procedure);
    if (index !== -1) {
      this.temporaryExternalProcedures.splice(index, 1);
    }
  }

  removeTemporaryProcedureByIndex(index: number) {
    if (index >= 0) {
      this.temporaryExternalProcedures.splice(index, 1);
    }
  }

  /* CHECKS WITH THE MAIN EXTERNAL PROCEDURES */
  // check if a procedure exists with relation to logic
  // based on a given record id
  hasExistingCreatedProcedure(procedure: PatientProcedureExternal, recordId: number): boolean {
    // check if it exists
    return this.hasExistingCreatedProcedureWithData(
      procedure?.data?.nameAppendix?.name,
      recordId
    );
  }

  // checks for any reference to it, 
  hasExistingCreatedProcedureWithData(name: string, recordId: number): boolean {
    // get external procedures that usually appear in edit mode
    // based on the provided record id
    const externalProcedures: PatientProcedureExternal[] =
      filter(
        this.getCreatedExternalProcedures() ?? [],
        (p) => this.PatientProcedureService.showProcedureInEditMode(p, recordId)
      );

    // find one that matches
    const foundExistingProcedure: PatientProcedureExternal =
      externalProcedures?.find(
        (p) => p?.data?.nameAppendix?.name === name
      );

    // check if it exists
    return !!foundExistingProcedure;
  }

  // generalise the values where appropriate
  // else pass the change data as it is
  createExternalProcedureFromTemporary({
    externalProcedureData,
    recordId,
  }: {
    // PatientProcedureExternal is when confirmed from the target
    // (i.e. change was made)
    // GlExternalDataMinimal is when its confirmed from the source
    externalProcedureData: PatientProcedureExternal | GlExternalDataMinimal;
    recordId: number;
  }) {
    // get minimal version of the data
    const minimalProcedureData: GlExternalDataMinimal =
      this.convertExternalProcedureToMinimal(externalProcedureData);

    // get data of existing temp procedure to alter
    // with reference to the type of external procedure
    const existingTempProcedure: PatientProcedureExternal =
      this.getTemporaryProcedureWithData(
        this.getExternalProcedureNameFromTargetValue(minimalProcedureData),
        recordId
      );

    // check if an existing procedure similar to that exists
    const existingTempProcedureIndex: number =
      this.temporaryExternalProcedures.findIndex((p) =>
        isEqual(p, existingTempProcedure)
      );

    // if doesnt exist dont action
    if (isNil(existingTempProcedure)) {
      return;
    }

    // declare procedure to use
    const procedureToUse: PatientProcedureExternal = { ...existingTempProcedure };
    // if the external procedure passed does have a .data 
    // i.e. is an external procedure passed whole, 
    // use the data for that instead
    if (!isNil(get(externalProcedureData, 'data'))) {
      // use that instead
      procedureToUse.data = (externalProcedureData as PatientProcedureExternal).data;
    }

    // pass that info autofill
    this.PatientProcedureService.createExternalProcedure(
      recordId,
      procedureToUse
    )
      .then(() => {
        // remove temporary procedure by index
        this.removeTemporaryProcedureByIndex(existingTempProcedureIndex);
        this.toastr.success("Successfully created external procedure");
      })
      .catch((err) => {
        console.error("create external procedure from temp error:", err);
        this.toastr.error("Error creating external procedure from autofill");
      });

    // then remove by index
  }

  confirmAllTemporaryProcedures() {
    const temporaryProcedures: PatientProcedureExternal[] =
      this.temporaryExternalProcedures ?? [];

    if (isEmpty(temporaryProcedures)) {
      return;
    }

    // otherwise create a promise map
    Promise.all(
      temporaryProcedures.map((p) =>
        this.PatientProcedureService.createExternalProcedure(p.record_id, p)
      )
    )
      .then(() => {
        this.toastr.success("Successfully confirmed all temporary procedures!");
        this.resetTemporaryProcedures();
      })
      .catch((err) => {
        console.error("Temp procedure create all error", err);
      });
  }

  /* 
     PRIMARY AUTOFILL RELATED FUNCTIONS GO HERE 
   */
  autofillAsTarget(change: IGlChangesRecordData) {
    const { targetValue, side, id, extraData } = change;
    const { patientId = undefined, recordId = undefined } = extraData ?? {};

    // get procedure
    const autofillProcedure: PatientProcedureExternal =
      this.createTemporaryExternalProcedure({
        externalProcedureData: targetValue as GlExternalDataMinimal,
        side,
        patientId,
        recordId,
      });
    autofillProcedure.autofill_id = id;

    // no procedure, return
    if (!autofillProcedure) {
      return this.toastr.error(this.autofillMessages.error.autofill.generic);
    }

    // fill up with temporary dummy data
    switch (side) {
      case "both":
        autofillProcedure.data.left = { complete: false };
        autofillProcedure.data.right = { complete: false };
        autofillProcedure.data.order = "right_left";
        break;
      case "left":
        autofillProcedure.data.left = { complete: false };
        break;
      case "right":
        autofillProcedure.data.right = { complete: false };
        break;
      default:
        break;
    }

    // there is no need to retain the previous state
    // for this until set

    // then set to temporary array in the service
    this.addTemporaryExternalProcedure(autofillProcedure);

    this.toastr.info(this.autofillMessages.info.autofill.external_procedures);
  }

  // this updates an existing procedure depending on
  // what the current one is
  // we do not update the ID for this as
  // there is a different way to check for autofill for bilateral sides
  updateAsTarget(change: IGlChangesRecordData) {
    const { targetValue, side: changeSide, extraData } = change;
    const { recordId } = extraData ?? {};

    // get data of existing temp procedure to alter
    const existingTempProcedure: PatientProcedureExternal =
      this.getTemporaryProcedureWithData(
        this.getExternalProcedureNameFromTargetValue(targetValue),
        recordId
      );

    // get index of that found existing procedure
    const existingTempProcedureIndex: number =
      this.getTemporaryProcedureIndexWithData(
        this.getExternalProcedureNameFromTargetValue(targetValue),
        recordId
      );

    // if doesnt exist dont action
    if (isNil(existingTempProcedure)) {
      return;
    }

    // otherwise depending on side of existing one and current one action accordingly
    const existingSide: IGlSide = existingTempProcedure.data.eye;
    // * if L/R or R/L merge into both
    if (existingSide === "left" && changeSide === "right") {
      // add right data
      set(existingTempProcedure, "data.right", { complete: false });

      // then set to both
      existingTempProcedure.data.eye = "both";
      existingTempProcedure.data.order = "right_left";
    } else if (existingSide === "right" && changeSide === "left") {
      set(existingTempProcedure, "data.left", { complete: false });

      // then set to both
      existingTempProcedure.data.eye = "both";
      existingTempProcedure.data.order = "right_left";
    } else if (existingSide === "both" && changeSide !== "both") {
      // otherwise if the existing side is bilateral
      // and change calls for one to be removed

      // remove order
      delete existingTempProcedure.data.order;
      if (changeSide === "left") {
        // remove left side
        delete existingTempProcedure.data.left;
        // remove order
        delete existingTempProcedure.data.order;
        // replace eye side
        existingTempProcedure.data.eye = "right";
      } else if (changeSide === "right") {
        // remove left side
        delete existingTempProcedure.data.right;
        // remove order
        delete existingTempProcedure.data.order;
        // replace eye side
        existingTempProcedure.data.eye = "left";
      }
    }

    if (existingTempProcedureIndex !== -1) {
      // update
      this.temporaryExternalProcedures.splice(
        existingTempProcedureIndex,
        1,
        existingTempProcedure
      );
    }
  }

  // clear
  // an undo here affects the whole procedure
  clear(change: IGlChangesRecordData) {
    // get temp external procedure
    const { targetValue, extraData } = change;
    // we must have the record id included or it will fail
    const { recordId } = extraData ?? {};

    // get data of existing temp procedure to alter
    const existingTempProcedure: PatientProcedureExternal =
      this.getTemporaryProcedureWithData(
        this.getExternalProcedureNameFromTargetValue(targetValue),
        recordId
      );

    // there is a chance this could be null
    const existingSide: IGlSide = existingTempProcedure?.data?.eye;

    // * CASE 1: if both side then just update to either L or R if not called from external procedures
    if (existingSide === "both") {
      // update to just the remianing side
      this.updateAsTarget(change);
    } else {
      // * CASE 2: single autofill only on L or R should remove all
      // only if a procedure exists as a default
      this.removeTemporaryProcedure(existingTempProcedure);
      // alert user on remove
      this.toastr.success(this.autofillMessages.success.undo);
    }
  }

  // helpers
  getExternalProcedureNameFromTargetValue(targetValue: any) {
    return (
      targetValue?.data?.nameAppendix?.name ?? targetValue?.nameAppendix?.name
    );
  }

  convertExternalProcedureToMinimal(procedure: any): GlExternalDataMinimal {
    if (procedure?.data) {
      return {
        nameAppendix: procedure?.data?.nameAppendix,
        level2Appendix: procedure?.data?.nameAppendix,
      };
    }

    if (procedure?.nameAppendix && procedure?.level2Appendix) {
      return procedure;
    }

    return;
  }

  emitUndoAutofillEvent({
    key = EXTERNAL_PROCEDURES_KEY,
    value,
    side,
    recordData,
    extraData,
  }: {
    key?: string;
    value: GlDiagnosis;
    side: IGlSide;
    recordData: PatientRecordData;
    extraData: any;
  }) {
    const path: string = this.AutofillHelperService.getAutofillPath(key);
    const undoChange: IGlChangesRecordDataOrigin = {
      currentValue: value,
      side,
      originKey: key,
      originPath: path,
      sourceKey: key,
      sourcePath: path,
      type: "autofill_undo",
      id: value?.id,
      origin: "both",
      recordData,
      extraData,
    };
    this.ChangesService.publish(undoChange);
  }
}
