import { GlDiagnosis } from "models/patient-record.model";
import { IGlOption, IGlOptionExtra } from "../appendix";
import {
  DIAGNOSIS_ARRAY_KEY,
  EXTERNAL_PROCEDURES_KEY,
  MAC_OCT_V2_KEY,
  MACULAR_V2_KEY,
  LENS_STATUS_KEY,
  LENS_OBSERVATIONS_KEY,
} from "../../../../lib/key-appendix";
import { GlExternalDataMinimal } from "models/gl-external-procedure";

/**
 * All autofills are declared here in this truth table
 *
 * the values are then linked by a key mapping depending on what type
 *
 * e.g. Cataract -> diagnosis Cataract or external procedure cataract
 * can be nested
 *
 * in this case, all references will generally be from
 * record.data as the root source of truth
 *
 * unless specified e.g. with Cataracts with a different root source
 *
 * this should not act as a TARGET -> SOURCE MAPPING
 *
 */
export const GNET_SOURCE_TO_TARGET_AUTOFILL_KEY_MAPPING: {
  [key: string]: string[];
} = {
  oct_mac_v2: [DIAGNOSIS_ARRAY_KEY],

  macular_v2: [DIAGNOSIS_ARRAY_KEY],

  diagnosis_array: [EXTERNAL_PROCEDURES_KEY],
  "lens.status": [DIAGNOSIS_ARRAY_KEY],
  "lens.observations": [DIAGNOSIS_ARRAY_KEY],
};

// same as above but opposite
export const GNET_AUTOFILL_TARGET_TO_SOURCE_AUTOFILL_KEY_MAPPING: {
  [key: string]: string[];
} = {
  diagnosis_array: [
    MAC_OCT_V2_KEY,
    MACULAR_V2_KEY,
    LENS_STATUS_KEY,
    LENS_OBSERVATIONS_KEY,
  ],
  external_procedure: [DIAGNOSIS_ARRAY_KEY],
};

// these keys could have a bilateral autofill
// meaning one value that can be counted as "both"
export const GNET_AUTOFILL_BILATERAL_TYPE_AUTOFILL: string[] = [
  EXTERNAL_PROCEDURES_KEY,
];

// mapping of segments to keys
// e.g. posterior has etc...
// this can be used to do macro actions like confirm for all in one segment
export const GNET_SEGMENT_TO_KEY_MAPPING: {
  [key: string]: string[];
} = {
  segment_posterior: [
    MAC_OCT_V2_KEY,
    MACULAR_V2_KEY,
    LENS_STATUS_KEY,
    LENS_OBSERVATIONS_KEY,
  ],
  diagnosis_array: [DIAGNOSIS_ARRAY_KEY],
  external_procedures: [EXTERNAL_PROCEDURES_KEY],
};

// FOR OPTION AND
export const GNET_OBSERVATIONS_AUTOFILL_MAPPING: {
  [key: string]: IGlOptionExtra[];
} = {
  lensPhakic: [{ name: "Cataract", key: "cataract" }],
  get lens() {
    return this.lensPhakic;
  },
  get "lens.status"() {
    return this.lensPhakic;
  },
  mac_oct: [
    {
      name: "ERM",
      key: "ERM",
      displayText: "Epiretinal membrane",
      showOther: true,

      autofill_key_alternative: "erm",
    },
    { name: "WNL", key: "WNL", disableMultiSelect: true },
  ],
  // mac_oct
  get mac_oct_v2() {
    return this.mac_oct;
  },
  get oct_mac() {
    return this.mac_oct;
  },
  get oct_mac_v2() {
    return this.mac_oct;
  },
  macular: [
    {
      name: "AMD",
      key: "AMD",
      showOther: true,

      autofill_key_alternative: "macular_degeneration",
    },
    {
      name: "Vein Occlusion",
      key: "veinOcclusion",
      showOther: true,

      autofill_key_alternative: "vein_occlusion",
    },
    {
      name: "ERM",
      key: "ERM",
      displayText: "Epiretinal membrane",
      showOther: true,

      autofill_key_alternative: "erm",
    },
    { name: "WNL", key: "WNL", disableMultiSelect: true },
  ],
  get macular_v2() {
    return this.macular;
  },
  IOLType: [
    {
      name: "PCO",
      key: "PCO",
      requireQuantifier: true,

      autofill_key_alternative: "posterior_capsular_opacification",
    },
  ],
  get "lens.observations"() {
    return this.IOLType;
  },
};

export const GNET_DIAG_AUTOFILL_MAPPING: {
  [key: string]: GlDiagnosis;
} = {
  ERM: {
    level1: {
      name: "Retina",
      key: "retina",
      diagnosis: "Retina",
    },
    level2: {
      name: "ERM",
      key: "erm",
      diagnosis: "Epiretinal Membrane",
    },
  },
  get erm() {
    return this.ERM;
  },
  cataract: {
    level1: {
      name: "Cataract",
      key: "cataract",
      diagnosis: "Cataract",
    },
  },
  get cataractSurgery() {
    return this.cataract;
  },
  PCO: {
    level1: {
      name: "Posterior Capsular Opacification",
      key: "posterior_capsular_opacification",
      diagnosis: "Posterior Capsular Opacification",
    },
  },
  get pco() {
    return this.PCO;
  },
  get posterior_capsular_opacification() {
    return this.PCO;
  },
  get posteriorcapsularopacification() {
    return this.PCO;
  },
  get posteriorCapsularOpacification() {
    return this.PCO;
  },
  AMD: {
    level1: {
      name: "Retina",
      key: "retina",
      diagnosis: "Retina",
    },
    level2: {
      name: "Macular Degeneration",
      key: "macular_degeneration",
    },
  },
  get amd() {
    return this.AMD;
  },
  get macular_degeneration() {
    return this.AMD;
  },
  get macularDegeneration() {
    return this.AMD;
  },
  veinOcclusion: {
    level1: {
      name: "Retina",
      key: "retina",
      diagnosis: "Retina",
    },
    level2: {
      name: "Vein Occlusion",
      key: "vein_occlusion",
    },
  },
  get vein_occlusion() {
    return this.veinOcclusion;
  },
  get "Vein Occlusion"() {
    return this.veinOcclusion;
  },
};

export const GNET_OBS_AUTOFILL_MAPPING: {
  [key: string]: IGlOption;
} = {
  ERM: {
    name: "ERM",
    key: "ERM",
    displayText: "Epiretinal membrane",
    showOther: true,
  },
  get erm() {
    return this.ERM;
  },
  cataract: { name: "Cataract", key: "cataract" },
  PCO: {
    name: "PCO",
    key: "PCO",
    requireQuantifier: true,
  },
  get pco() {
    return this.PCO;
  },
  get posterior_capsular_opacification() {
    return this.PCO;
  },
  AMD: {
    name: "AMD",
    key: "AMD",
    showOther: true,
  },
  get amd() {
    return this.AMD;
  },
  get macular_degeneration() {
    return this.AMD;
  },
  veinOcclusion: {
    name: "Vein Occlusion",
    key: "veinOcclusion",
    showOther: true,
  },
  get vein_occlusion() {
    return this.veinOcclusion;
  },
  get "Vein Occlusion"() {
    return this.veinOcclusion;
  },
};

// default external procedure options depending on name of procedure
export const GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING: {
  [key: string]: GlExternalDataMinimal;
} = {
  cataract: {
    nameAppendix: {
      name: "Cataract",
      key: "cataractSurgery",
    },
    level2Appendix: {
      name: "Normal",
      key: "normal",
      default: true,
      displayText: "Cataract + IOL",
    },
  },
  get Cataract() {
    return this.cataract;
  },
  get cataractSurgery() {
    return this.cataract;
  },
};
