import { IOnChangesObject, IPromise } from "angular";
import { VisitDropHelperService } from "app/core/services/VisitDropHelper/VisitDropHelper.service";
import { GlModelService } from "app/core/services/gl-model.service";
import { filter, isEmpty, isNil, sortBy } from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlPatientRecordWorkflowState,
  GlVisitDrop,
  GlVisitDropDilation,
  IGlVisitDropDilationState,
  PatientRecord,
  PatientRecordData,
} from "models/patient-record.model";
import { GlFormController } from "../../gl-form-controller";

import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { User } from "models/user.model";
import { DASHBOARD_STATES } from "../../../../../app/core/services/appendix";
import "./post-dilation-iop.scss";

export class PostDilationIopController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  dashboardStates = DASHBOARD_STATES;

  // whether user can sign or not (.e. if viewing stricting cant)
  canSign: boolean = false;

  saveAndProgressInProgress: boolean = false;
  signInProgress: boolean = false;
  reopenInProgress: boolean = false;

  toastrMessages = this.ToastrAppendix.getAllToastrMessages();

  record: PatientRecord;
  recordData: PatientRecordData;
  recordHistory: PatientRecordData;
  dilationRecord: PatientRecord;

  user: User;

  selectedState: string;

  visitDropsController: angular.IFormController;

  // from parent
  patientInfoForm: angular.IFormController;

  constructor(
    private $state: angular.ui.IStateService,
    private toastr: angular.toastr.IToastrService,
    private PatientRecordService: PatientRecordService,
    private VisitDropHelperService: VisitDropHelperService,
    private GlModelService: GlModelService,
    private ToastrAppendix: ToastrAppendix
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    if (this.record && changes?.record) {
      this.recordData = this.record.data;
      this.recordHistory = this.GlModelService.consolidatedRecordHistory;
    }
  }

  // has active dilation timer? just show all honestly me thinks
  getActiveDilationDrops() {
    const visitDrops: GlVisitDrop[] = this.recordData?.visit_drops ?? [];
    const sorted: GlVisitDrop[] = filter(
      sortBy(visitDrops, [
        (o) => {
          return Date.parse(o?.dilation?.end_time);
        },
      ]),
      (d) => !isNil(d.dilation)
    );
    return sorted;
  }

  allDilationDropTimersFinished() {
    const dilationDrops: GlVisitDrop[] = this.getActiveDilationDrops();

    // otherwise check if all complete or finished
    // will fail if none because what can you even sign?
    return this.VisitDropHelperService.allDilationTimersFinished(dilationDrops);
  }

  allDilationDropsValueFilled() {
    const dilationDrops: GlVisitDrop[] = this.getActiveDilationDrops();
    // all of the drops must have a value in the iop_after left or right field
    return this.VisitDropHelperService.allDilationDropsValueFilled(
      dilationDrops
    );
  }

  canSignDilationRecordings() {
    return (
      this.allDilationDropTimersFinished() && this.allDilationDropsValueFilled()
    );
  }

  // MARK AS COMPLETE?
  markDilationAsComplete(dilation: GlVisitDropDilation) {
    dilation.state = "COMPLETE";
  }

  // this is a way to trigger form dirty
  setVisitDropDilationState(
    dilation: GlVisitDropDilation,
    state: IGlVisitDropDilationState
  ) {
    dilation.state = state;
    // we trigger setting dirty to allow saving here
    // will be pristine afterwards anyways
    this.visitDropsController.$setDirty();
  }

  // STATES
  dilationTimerOngoing(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerOngoing(dilation);
  }

  dilationTimerAlmostFinished(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerAlmostFinished(dilation);
  }

  dilationTimerFinished(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerFinished(dilation);
  }

  // is dilation timer done?
  dilationTimerComplete(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerComplete(dilation);
  }

  // do a switch statement
  getDilationTimerStatus(
    dilation: GlVisitDropDilation
  ): IGlVisitDropDilationState {
    return this.VisitDropHelperService.getDilationTimerStatus(dilation);
  }

  // TIME
  getDilationTimeRemaining(dilation: GlVisitDropDilation): moment.Duration {
    return this.VisitDropHelperService.getDilationTimeRemaining(dilation);
  }

  getDilationTimeRemainingAsText(dilation: GlVisitDropDilation): string {
    const timeDiff: moment.Duration = this.getDilationTimeRemaining(dilation);
    if (!timeDiff) {
      return "";
    }
    return `${timeDiff.hours()} hr ${timeDiff.minutes()} mins`;
  }

  // get difference between historical and current
  getIOPDifference(drop: GlVisitDrop, side: IGlSideBilateral) {
    const postDilationIOP: number = drop?.dilation?.iop_after?.[side] ?? 0;
    const todayIOP: number = this.recordData?.iop?.[side] ?? 0;

    return postDilationIOP - todayIOP;
  }

  // class difference
  // its cheaper to do multiplication comparison than division
  postDilationIOPCritical(drop: GlVisitDrop, side: IGlSideBilateral) {
    const postDilationIOP: number = drop?.dilation?.iop_after?.[side] ?? 0;
    const todayIOP: number = this.recordData?.iop?.[side] ?? 0;

    // if post dilation is higher by 50% its alert
    return postDilationIOP >= todayIOP * 1.5 ? "critical" : "";
  }

  // show extra iop options
  shouldShowIOPOptions(dilation: GlVisitDropDilation) {
    // show if either finished or completed
    const OPTIONS: IGlVisitDropDilationState[] = ["FINISHED", "COMPLETE"];
    return (
      OPTIONS.includes(this.getDilationTimerStatus(dilation)) ||
      // or if almost finished
      this.dilationTimerAlmostFinished(dilation)
    );
  }

  // get
  getDropTooltip(drop: GlVisitDrop) {
    return this.VisitDropHelperService.getDropTooltipText(drop);
  }

  // DILATION RECORD RELATED STUFF
  // stop event propagation
  handleEvent(event: Event) {
    event.stopPropagation();
  }

  // essentially is the same as an id so should be fine
  hasLinkedDilationRecord() {
    return !isNil(this.dilationRecord);
  }

  dilationRecordIsSigned() {
    if (!this.dilationRecord) {
      return false;
    }
    return this.dilationRecord.data_status === "SIGNED";
  }

  getOrCreateLinkedDilationRecord(): IPromise<PatientRecord> {
    // if not existing, create one and return that promise chain
    if (!this.hasLinkedDilationRecord()) {
      return this.createLinkedDilationRecord();
    } else {
      // otherwise resolve a promise for the current one
      return Promise.resolve(this.dilationRecord);
    }
  }

  // returns a promise to chain
  createLinkedDilationRecord() {
    return this.PatientRecordService.createLinkedRecord(
      this.record,
      "dilation_review"
    );
  }

  // handle sign process for dilation record
  handleSignDilationRecord() {
    // step 1: get record, if not exists create
    this.signInProgress = true;
    this.getOrCreateLinkedDilationRecord()
      .then((dilationRecord: PatientRecord) => {
        // set dilation record information
        // we set here so that in case it has
        // already been created
        // we can just reference the existing one
        this.dilationRecord = dilationRecord;

        // create if empty
        if (
          isNil(this.dilationRecord.data) ||
          isEmpty(this.dilationRecord.data)
        ) {
          this.dilationRecord.data = {};
        }
        // update with the latest data from drops
        this.dilationRecord.data.visit_drops = this.recordData.visit_drops;
        // step 2: sign
        // set complete before signing
        // makes life easier workflow wise as well
        return this.PatientRecordService.sign({
          record: {
            ...dilationRecord,
            status: "COMPLETE",
          },
        });
      })
      .then((dilationRecord) => {
        // step 3: cleanup
        // set dilation record information
        this.dilationRecord = dilationRecord;

        // pristine
        if (this.visitDropsController) {
          this.visitDropsController.$setPristine();
        }

        // do for patient info on the outisde ONLY if parent record
        // is signed
        if (this.record.data_status === "SIGNED") {
          this.setPatientInfoAsPristine();
        }

        this.toastr.success("Signed dilation readings!");
      })
      .catch((err) => {
        console.error("err", err);
        this.toastr.error(
          this.toastrMessages.records.error.dilation_record.sign.error
        );
      })
      .finally(() => {
        this.signInProgress = false;
      });
  }

  // reopen record
  // copied from record.ts
  // will also set to in progress on backend
  reopenDilationRecord() {
    // must exist first
    if (!this.hasLinkedDilationRecord()) {
      return this.toastr.error(
        this.toastrMessages.records.error.dilation_record.re_open
          .create_before_sign
      );
    }

    // then continue
    this.reopenInProgress = true;
    this.PatientRecordService.reopen(this.dilationRecord)
      .then((dilationRecord) => {
        this.toastr.success("Successfully reopened dilation record!");
        this.dilationRecord = dilationRecord;

        // pristine
        if (this.visitDropsController) {
          this.visitDropsController.$setPristine();
        }
      })
      .finally(() => {
        this.reopenInProgress = false;
      });
  }

  // save and progress clicked
  saveAndProgressClicked(nextWorkflowState: GlPatientRecordWorkflowState) {
    this.record.workflow_state = nextWorkflowState;
    this.saveAndProgressInProgress = true;

    // update with the latest data from drops
    if (isNil(this.dilationRecord.data) || isEmpty(this.dilationRecord.data)) {
      this.dilationRecord.data = {};
    }
    this.dilationRecord.data.visit_drops = this.recordData.visit_drops;

    // then save dilation record first
    this.PatientRecordService.updateAndSetPractitioner(
      this.record.dilation_review,
      this.user
    )
      .then(() => {
        // then save original record
        return this.PatientRecordService.updateAndSetPractitioner(
          this.record,
          this.user
        );
      })
      .then(() => {
        // pristine
        if (this.visitDropsController) {
          this.visitDropsController.$setPristine();
        }

        // do for patient info on the outisde ONLY if parent record
        // is signed
        if (this.record.data_status === "SIGNED") {
          this.setPatientInfoAsPristine();
        }

        // finally go to dashboard
        this.$state.go("main.dashboard");
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(this.toastrMessages.drops.error.save.error_try_again);
      })
      .finally(() => {
        this.saveAndProgressInProgress = false;
      });
  }

  // copied from button-record-progress
  getCurrentState() {
    // workflow state is dependent on the parent record
    const workflowState: GlPatientRecordWorkflowState =
      this.record.workflow_state;
    const re = /\((.*)\)/i;
    const wfs = workflowState?.match(re)[1];
    switch (wfs) {
      case "INJ": {
        this.selectedState = "Injection";
        break;
      }
      case "R": {
        this.selectedState = "Retina";
        break;
      }
      case "G": {
        this.selectedState = "Glaucoma";
        break;
      }
      case "C": {
        this.selectedState = "Cataract";
        break;
      }
      case "O":
      default: {
        this.selectedState = "Other";
        break;
      }
    }
  }

  // set current controller as pristine
  setCurrentControllerAsPristine() {
    if (this.visitDropsController) {
      this.visitDropsController.$setPristine();
    }
  }

  // set parent form to pristine
  setPatientInfoAsPristine() {
    if (this.patientInfoForm) {
      this.patientInfoForm.$setPristine();
    }
  }
}

export class PostDilationIopComponent implements angular.IComponentOptions {
  static selector = "postDilationIop";
  static template = require("./post-dilation-iop.html");
  static controller = PostDilationIopController;
  static bindings = {
    mode: "@?",
    user: "<",
    isEditable: "<?",
    canSign: "<?",
    record: "<",
    // this is important as its an extension of the record
    // but we dont want to alter the original record either
    dilationRecord: "=",
    patientInfoForm: "<?",
  };
}
