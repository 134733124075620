import { DIAGNOSIS_ARRAY_KEY } from "../../../../lib/key-appendix";
import { IGlOption } from "../appendix";

// the option will always check by
export const GNET_PREFILL_CHECK_OPTIONS: { [key: string]: IGlOption[] } = {
  lensPhakic: [{ name: "Cataract", key: "cataract" }],
  get lens_status() {
    return this.lensPhakic;
  },
  get "lens.status"() {
    return this.lensPhakic;
  },
};

// targets for prefills
export const GNET_PREFILL_TARGETS: {
  [key: string]: string[];
} = {
  lensPhakic: [DIAGNOSIS_ARRAY_KEY],
  get lens_status() {
    return this.lensPhakic;
  },
  get "lens.status"() {
    return this.lensPhakic;
  },
};
