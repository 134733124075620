import { cloneDeep, get, isArray, isEmpty, isNil, isObject, set } from "lodash";
import { DIAGNOSIS_ARRAY_KEY } from "../../../../lib/key-appendix";
import { AutofillHelperService } from "../autofill-helper/autofill-helper.service";

import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { IGlSide } from "models/gl-side.model";
import {
  GlDiagnosis,
  GlLensObservation,
  PatientRecordData,
} from "models/patient-record.model";
import { AutofillDiagnosisService } from "../autofill-diagnosis/autofill-diagnosis.service";
import { IGlAutofill } from "../autofill/autofill.service";
import { ChangesService } from "../changes/changes.service";
import { PatientRecordHelperService } from "../patient-record-helper/patient-record-helper.service";

export class AutofillPosteriorLensService implements IGlAutofill {
  static injectionName: string = "AutofillPosteriorLensService";

  constructor(
    private PatientRecordHelperService: PatientRecordHelperService,
    private AutofillHelperService: AutofillHelperService,
    private AutofillDiagnosisService: AutofillDiagnosisService,
    private ChangesService: ChangesService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  // * AUTOFILL
  // lens
  autofillStatus(change: IGlChangesRecordData) {
    const {
      sourceKey,
      sourcePath,
      targetKey,
      targetPath,
      targetValue,
      currentValue,
      previousValue,
      side,
      id,
    } = change;

    // examples in this case
    // generate reference, instead of parent key leave it as absolute path
    // this always refers to target no matter what

    const internalLensSourceReferenceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        side,
        id,
      });

    // then handle depending on others
    let internalTargetReferenceKey: string;

    // target key switch
    switch (targetKey) {
      case DIAGNOSIS_ARRAY_KEY:
        // we have to check if an exisitng diagnosis exsits before continuing
        if (this.AutofillDiagnosisService.hasExistingDiagnosis(change)) {
          return this.toastr.error(
            "Diagnosis already exists, will not autofill"
          );
        }

        // MOVE EXISTING DIAGNOSIS CHECK HERE
        // THEN DOUBLE CONFIRM
        internalTargetReferenceKey =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: targetPath,
            fieldKey: targetKey,
            valueKey: this.AutofillHelperService.getOptionKey(
              targetKey,
              targetValue
            ),
            side,
            id,
          });

        // setup bidirectional link
        this.AutofillHelperService.setAutofillLinkSourceTargetBidirectional(
          internalLensSourceReferenceKey,
          internalTargetReferenceKey
        );

        // autofill
        this.AutofillDiagnosisService.autofillAsTarget({
          ...change,
          internalSourceKey: internalLensSourceReferenceKey,
          internalTargetKey: internalTargetReferenceKey,
        });

        // update with old info to pull back for both
        this.PatientRecordHelperService.updateChangesStack(
          internalLensSourceReferenceKey,
          previousValue
        );

        this.PatientRecordHelperService.updateChangesStack(
          internalTargetReferenceKey,
          null
        );

        break;
      default:
        // nothing happened, dont continue
        return;
    }
  }

  autofillObservation(change: IGlChangesRecordData) {
    const {
      sourceKey,
      sourcePath,
      targetKey,
      targetPath,
      targetValue,
      currentValue,
      previousValue,
      side,
      id: currentTimestamp,
    } = change;

    // examples in this case
    // generate reference, instead of parent key leave it as absolute path
    // this always refers to target no matter what

    const internalSourceReferenceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        side,
        id: currentTimestamp,
      });

    // then handle depending on others
    let internalTargetReferenceKey: string;
    // * the difference with lens observation is that
    // * alongside observation, we need to also store the status of
    // * the previous value as there are some stati that
    // * dont have any observations

    switch (targetKey) {
      case DIAGNOSIS_ARRAY_KEY:
        // we have to check if an exisitng diagnosis exsits before continuing
        if (this.AutofillDiagnosisService.hasExistingDiagnosis(change)) {
          return this.toastr.error(
            "Diagnosis already exists, will not autofill"
          );
        }

        // MOVE EXISTING DIAGNOSIS CHECK HERE
        // THEN DOUBLE CONFIRM
        internalTargetReferenceKey =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: targetPath,
            fieldKey: targetKey,
            valueKey: this.AutofillHelperService.getOptionKey(
              targetKey,
              targetValue
            ),
            // this.DiagnosisService.convertDiagnosisOptionToKey(targetValue),
            side,
            id: currentTimestamp,
          });

        // setup bidirectional link
        this.AutofillHelperService.setAutofillLinkSourceTargetBidirectional(
          internalSourceReferenceKey,
          internalTargetReferenceKey
        );

        // autofill
        this.AutofillDiagnosisService.autofillAsTarget({
          ...change,
          internalSourceKey: internalSourceReferenceKey,
          internalTargetKey: internalTargetReferenceKey,
        });

        // update with old info to pull back for both
        this.PatientRecordHelperService.updateChangesStack(
          internalSourceReferenceKey,
          previousValue
        );
        this.PatientRecordHelperService.updateChangesStack(
          internalTargetReferenceKey,
          null
        );

        break;
      default:
        break;
    }

    return;
  }

  // prefill is different as in it only creates the autofill link based on the idea that there
  // is an existing target value of the same key
  // prefill has no old data to refer to so it will just be a revert
  prefillLensStatus(change: IGlChangesRecordData) {
    const {
      sourceKey,
      sourcePath,
      targetKey,
      targetPath,
      targetValue,
      currentValue,
      side,
      id,
    } = change;

    const internalLensSourceReferenceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        side,
        id,
      });

    // then handle depending on others
    let internalTargetReferenceKey: string;

    // target key switch
    switch (targetKey) {
      case DIAGNOSIS_ARRAY_KEY:
        // we have to check if an exisitng diagnosis exsits before continuing
        // no diagnosis === no autofill
        if (!this.AutofillDiagnosisService.hasExistingDiagnosis(change)) {
          return;
        }

        // generate target key
        internalTargetReferenceKey =
          this.AutofillHelperService.generateAutofillReferenceKey({
            path: targetPath,
            fieldKey: targetKey,
            valueKey: this.AutofillHelperService.getOptionKey(
              targetKey,
              targetValue
            ),
            side,
            id,
          });

        // setup bidirectional link
        this.AutofillHelperService.setAutofillLinkSourceTargetBidirectional(
          internalLensSourceReferenceKey,
          internalTargetReferenceKey
        );

        // autofill
        this.AutofillDiagnosisService.prefillAsTarget({
          ...change,
          internalSourceKey: internalLensSourceReferenceKey,
          internalTargetKey: internalTargetReferenceKey,
        });

        break;
      default:
        // nothing happened, dont continue
        return;
    }
  }

  // * REVERT
  revertStatus(change: IGlChangesRecordDataOrigin) {
    // get all values
    const { sourcePath, sourceKey, side, currentValue, id, recordData } =
      change;

    // get path to value we need to replace
    const pathToValue: string = `${
      !isEmpty(sourcePath) ? `${sourcePath}.` : ""
    }${sourceKey}.${side}`;

    // get internal source key
    const internalSourceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        side: side,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        id,
      });

    // get previous value
    const previousSourceValue: any =
      this.PatientRecordHelperService.getValueFromChangesStack(
        internalSourceKey
      ) ?? null;

    // just set
    set(recordData, pathToValue, cloneDeep(previousSourceValue));

    // this.emitUndoAutofillEvent({
    //   value: currentValue,
    //   key: sourceKey,
    //   side,
    //   recordData,
    //   extraData,
    // });
  }

  revertObservation(change: IGlChangesRecordDataOrigin) {
    // for lens it will be similar to select multiple
    // get all values
    const {
      sourcePath,
      sourceKey,
      side,
      currentValue,
      id,
      recordData,
      index,
      // extraData,
    } = change;

    // get path to value we need to replace
    const pathToValue: string = `${
      !isEmpty(sourcePath) ? `${sourcePath}.` : ""
    }${sourceKey}.${side}`;

    // * its either use provided index or fallback on finding it
    let altObsArray: any = get(recordData, pathToValue) ?? [];
    // convert in the odd chance its an object
    altObsArray =
      isObject(altObsArray) && !isArray(altObsArray)
        ? [altObsArray]
        : altObsArray;

    // then get index
    const valueIndex: number =
      index ??
      altObsArray?.findIndex((o) => {
        return (
          this.AutofillHelperService.getOptionKey(sourceKey, currentValue) ===
          this.AutofillHelperService.getOptionKey(sourceKey, o?.type)
        );
      });

    // get internal source key
    const internalSourceKey: string =
      this.AutofillHelperService.generateAutofillReferenceKey({
        path: sourcePath,
        fieldKey: sourceKey,
        side: side,
        valueKey: this.AutofillHelperService.getOptionKey(
          sourceKey,
          currentValue
        ),
        id,
      });

    // get previous value
    const previousSourceValue: any =
      this.PatientRecordHelperService.getValueFromChangesStack(
        internalSourceKey
      ) ?? null;

    if (valueIndex >= 0) {
      // * for observations this needs to be { type: } for some reason
      const valueToSet: GlLensObservation = !isNil(previousSourceValue)
        ? { type: previousSourceValue }
        : null;
      set(recordData, `${pathToValue}.${valueIndex}`, cloneDeep(valueToSet));
    }

    // this.emitUndoAutofillEvent({
    //   value: currentValue,
    //   key: sourceKey,
    //   side,
    //   recordData,
    //   extraData,
    // });
  }

  emitUndoAutofillEvent({
    key,
    value,
    side,
    recordData,
    extraData,
  }: {
    key?: string;
    value: GlDiagnosis;
    side: IGlSide;
    recordData: PatientRecordData;
    extraData: any;
  }) {
    // bilateral select multiple requires a key as there can be multiple differne types
    if (!key) {
      return;
    }
    const path: string = this.AutofillHelperService.getAutofillPath(key);
    // undo event
    const undoChange: IGlChangesRecordDataOrigin = {
      currentValue: value,
      side,
      originKey: key,
      originPath: path,
      sourceKey: key,
      sourcePath: path,
      type: "autofill_undo",
      id: value?.id,
      origin: "both",
      recordData,
      extraData,
    };
    this.ChangesService.publish(undoChange);
  }
}
