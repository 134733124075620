import { has, get } from "lodash";
import { PatientRecord } from "models/patient-record.model";

class RecordButtonController
  implements angular.IController, angular.IOnChanges {
  record: PatientRecord;
  selected: boolean;
  dateFormat: string;
  onClick: any;
  class = "";
  tooltip = "";
  dateFilter = this.$filter("date");
  ngDisabled: boolean = false;
  isOptomReview: boolean = false;

  constructor(
    private $sce: angular.ISCEService,
    private $filter: angular.IFilterService
  ) {
    "ngInject";
  }

  $onChanges(changesObj: angular.IOnChangesObject) {
    if (has(changesObj, "record.currentValue")) {
      this.updateToolTipForRecord();
    }
  }

  updateToolTipForRecord() {
    const createdBy = get(
      this.record,
      "data_signed_by.name",
      get(this.record, "data_updated_by.name")
    );
    const date = this.dateFilter(get(this.record, "updated_at"));
    const recordType = this.getRecordType();
    const type = get(
      this.record,
      "data.meta.current_state.type.title",
      recordType
    );
    this.tooltip = this.$sce.trustAsHtml([date, createdBy, type].join(" | "));
  }

  getRecordTypeClass() {
    const recordType = this.getRecordType();
    if (recordType === "Ophthal Record") {
      return "btn-ophthal-review";
    } else if (recordType === "Optom Record") {
      return "btn-optom-review";
    } else if (recordType === "History Record") {
      return "btn-record-history";
    } else {
      return "btn-tech-review";
    }
  }

  private getRecordType() {
    if (this.record.type === "patient_record") {
      if (this.record.virtual_review) {
        return "Optom Record";
      } else {
        return "Ophthal Record";
      }
    } else if (this.record.type === "history") {
      return "History Record";
    } else if (this.record.type === "tech_record") {
      return "Tech Record";
    }
  }

  private getButtonStyleClass() {
    return this.selected ? "btn-solid" : "btn-inverse";
  }
}
export class RecordButton implements angular.IComponentOptions {
  static selector = "buttonRecord";
  static bindings = {
    record: "<",
    selected: "<",
    dateFormat: "@",
    onClick: "&",
    class: "@",
    ngDisabled: "<?",
  };
  static controller = RecordButtonController;
  static template = `
    <button class="btn btn-patient-record-item {{ $ctrl.getRecordTypeClass() }} {{ $ctrl.class }} {{ $ctrl.getButtonStyleClass() }}"
      ng-click="$ctrl.onClick({record: $ctrl.record})"
      uib-tooltip-html="$ctrl.tooltip"
      ng-disabled="$ctrl.ngDisabled">
      {{($ctrl.record.appointment_date || $ctrl.record.data_signed_at || $ctrl.record.updated_at) | date:$ctrl.dateFormat||'d/M'}}
    </button>
  `;
}
