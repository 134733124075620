import { GlToastrCategory } from "../toastr-appendix";

export const DROPS_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    edit: {
      unsign_before_changing:
        "Please un-sign the Post Dilation IOP readings before making any changes",
    },
  },
  info: {},
};
