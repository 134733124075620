import { isEmpty, isNil, some } from "lodash";
import { IGlSide } from "models/gl-side.model";
import { GlBilateral } from "models/patient-record.model";
import { Appendix, IGlOption } from "../appendix";
import moment = require("moment");

export interface IGlDiagnosisOptionStackDecorator extends IGlOption {
  // for a diagnosis its an observation
  // for an observation its a diagnosis
  // allows to link the both without any coupling since all references are internal
  // and related to autofill
  section?: string;
  option_key?: string;
  side: IGlSide;
}

export interface IValueAutofillKeyParams {
  section?: string;
  parent_key?: string;
  option_key: string;
  side?: IGlSide;
  timestamp_key?: string | number; // to help identify on the observation side for accurate autofill handling
}

export interface IValueAutofillTimestampKeyParams {
  timestampArray: GlBilateral<number[]>;
  index: number;
  side: IGlSide;
}

/**
 * @deprecated remove in favour of id autofill
 * this uses an old timestamp system and is deprecated in favour
 * of the new directive autofill changes sytem
 */
// helps to track the autofill status of variables and set them if needed
export class ValueAutofillService {
  static injectionName = "ValueAutofillService";
  // observation key <==> diagnosis key
  private autofillKeyStatusStack: Map<string, string> = new Map<
    string,
    string
  >();

  constructor(private appendix: Appendix) {
    "ngInject";
  }

  /* AUTOFILL STACK */
  // GET
  getAutofillStatusStack() {
    return this.autofillKeyStatusStack;
  }

  getAutofillValue(key: string) {
    return this.autofillKeyStatusStack.get(key) ?? null;
  }

  getAutofillValueByKeyAndSide(params: IValueAutofillKeyParams) {
    const _key: string = this.generateAutofillReferenceKey(params);
    return this.getAutofillValue(_key);
  }

  // HAS
  hasAutofill(key: string) {
    return this.autofillKeyStatusStack.has(key) ?? false;
  }

  // we go by substring, just need a similar match
  hasSimilarAutofillKey(key: string) {
    const keys: string[] = Array.from(this.autofillKeyStatusStack.keys());
    return (keys ?? []).find((k) =>
      k.toLowerCase().includes(key.toLowerCase())
    );
  }

  // SET
  setAutofillValue(key: string, status: string) {
    this.autofillKeyStatusStack.set(key, status);
  }

  setAutofillValueByKeyAndSide(params: IValueAutofillKeyParams, value: string) {
    const _key: string = this.generateAutofillReferenceKey(params);
    this.setAutofillValue(_key, value);
  }

  // both ways technically
  setAutofillValueBidirectionalKeyAndSide(key: string, value: string) {
    this.setAutofillValue(key, value);
    this.setAutofillValue(value, key);
  }

  // DELETE
  removeAutofillValue(key: string) {
    this.autofillKeyStatusStack.delete(key);
  }

  removeAutofillValueByKeyAndSide(params: IValueAutofillKeyParams) {
    const _key: string = this.generateAutofillReferenceKey(params);
    this.removeAutofillValue(_key);
  }

  // both ways technically
  removeAutofillValueForBoth(key: string, value: string) {
    this.removeAutofillValue(key);
    this.removeAutofillValue(value);
  }

  removeAutofillValueForBothByKey(key: string) {
    const otherKey: string = this.getAutofillValue(key);
    this.removeAutofillValue(key);
    this.removeAutofillValue(otherKey);
  }

  resetAutofillValue() {
    this.autofillKeyStatusStack = new Map<string, string>();
  }

  // KEY RELATED
  generateAutofillReferenceKey(params: IValueAutofillKeyParams) {
    return Object.values(params)
      .filter((p) => !isNil(p))
      .join(".");
  }

  /* 
    SHARED TIMESTAMP FUNCTIONS TO ENSURE CONSISTENCY
    timestamps are treated as a seaprate way of assocaiting specific 
    autofill indexes without mutating the data

    all instances of timestamp ararys are to be declared within their
    parent observation selector and are left/right

    these methods help standardise the logic and prevent convolution

    TO DO: possibly convert this into a directive or add on to GlFormControlelr
  */
  // used as a specific reference for elements on observation side for autofill
  // returns timestamp used
  // generic
  createKeyTimestamp() {
    return moment().unix();
  }

  setKeyTimestampByIndex({
    timestampArray,
    index,
    side,
  }: IValueAutofillTimestampKeyParams) {
    // create new one at timestamp
    return this.setKeyTimestampAtIndex({
      timestampArray,
      index,
      side,
      timestamp: this.createKeyTimestamp(),
    });
  }

  setKeyTimestampAtIndex({
    timestampArray,
    index,
    side,
    timestamp,
  }: IValueAutofillTimestampKeyParams & { timestamp: number }) {
    // set on array
    if (isEmpty(timestampArray?.[side])) {
      timestampArray[side] = [];
    }
    timestampArray[side][index] = timestamp;

    return timestamp;
  }

  // efetch timstamp by reference
  getKeyTimestampFromIndex({
    timestampArray,
    index,
    side,
  }: IValueAutofillTimestampKeyParams) {
    // find element
    return timestampArray?.[side]?.[index] ?? null;
  }

  addToKeyTimestampSide({
    timestampArray,
    side,
    index,
  }: IValueAutofillTimestampKeyParams) {
    timestampArray[side].splice(index + 1, 0, null);
  }

  removeKeyTimestampByIndex({
    timestampArray,
    index,
    side,
  }: IValueAutofillTimestampKeyParams) {
    timestampArray[side].splice(index, 1);
  }

  clearKeyTimestampByIndex({
    timestampArray,
    index,
    side,
  }: IValueAutofillTimestampKeyParams) {
    if (timestampArray?.[side]?.[index]) {
      timestampArray[side][index] = null;
    }
  }

  // MISC
  // surpresses autofill warnings if outside of any of the main pages
  shouldShowAutofillWarnings() {
    // check for
    // record -> patient/{}/record/
    // history record -> patient/{}/history/
    // virtual review -> patient/{}/virtual-review/
    const HREF_REGEX_PATTERNS: RegExp[] = [
      /patient\/\d+\/record\//,
      /patient\/\d+\/history\//,
      /patient\/\d+\/virtual-review\//,
    ];
    // should map
    const matchMapping: boolean[] = HREF_REGEX_PATTERNS.map((regex) =>
      regex.test(window.location.href)
    );
    // if any of them match show
    return some(matchMapping, true);
  }
}
