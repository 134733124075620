import { upperFirst } from "lodash";
import { AUTOFILL_TOASTR_MESSAGES } from "./lib/autofill";
import { DIAGNOSIS_TOASTR_MESSAGES } from "./lib/diagnosis";
import { DROPS_TOASTR_MESSAGES } from "./lib/drops";
import { DRUG_TOASTR_MESSAGES } from "./lib/drugs";
import { FAVOURITE_GROUP_TOASTR_MESSAGES } from "./lib/favourite-group";
import { GENERIC_TOASTR_MESSAGES } from "./lib/generic";
import { OBSERVATIONS_TOASTR_MESSAGES } from "./lib/observations";
import { PRESCRIPTION_TOASTR_MESSAGES } from "./lib/prescription";
import { RECORDS_TOASTR_MESSAGES } from "./lib/records";

// error message
export interface GlErrorMessage {
  key: string;
  type: string;
  message: string;
  array_points?: (string | number)[];
}

// generic interface
export interface GlToastrCategory {
  success: { [key: string]: any };
  error: { [key: string]: any };
  info: { [key: string]: any };
}

const ALL_TOASTR_MESSAGES: { [key: string]: GlToastrCategory } = {
  autofill: AUTOFILL_TOASTR_MESSAGES,
  drugs: DRUG_TOASTR_MESSAGES,
  drops: DROPS_TOASTR_MESSAGES,
  diagnosis: DIAGNOSIS_TOASTR_MESSAGES,
  favourite_group: FAVOURITE_GROUP_TOASTR_MESSAGES,
  generic: GENERIC_TOASTR_MESSAGES,
  observations: OBSERVATIONS_TOASTR_MESSAGES,
  prescription: PRESCRIPTION_TOASTR_MESSAGES,
  records: RECORDS_TOASTR_MESSAGES,
};

export class ToastrAppendix {
  static injectionName = "ToastrAppendix";

  /* SUB GROUPS */
  getAutofillMessages(): GlToastrCategory {
    // we do a spread to amke it much easier to understand
    // as doing a parent -> error -> autofill -> sub message
    // is very redundant
    return AUTOFILL_TOASTR_MESSAGES;
  }

  getDrugsMessages(): GlToastrCategory {
    return DRUG_TOASTR_MESSAGES;
  }

  getDropsMessages(): GlToastrCategory {
    return DROPS_TOASTR_MESSAGES;
  }

  getDiagnosisMessages(): GlToastrCategory {
    return DIAGNOSIS_TOASTR_MESSAGES;
  }

  getFavouriteGroupMessages(): GlToastrCategory {
    return FAVOURITE_GROUP_TOASTR_MESSAGES;
  }

  getGenericMessages(): GlToastrCategory {
    return GENERIC_TOASTR_MESSAGES;
  }

  getObservationsMessages(): GlToastrCategory {
    return OBSERVATIONS_TOASTR_MESSAGES;
  }

  getPrescriptionMessages(): GlToastrCategory {
    return PRESCRIPTION_TOASTR_MESSAGES;
  }

  getRecordsMessages(): GlToastrCategory {
    return RECORDS_TOASTR_MESSAGES;
  }

  // compiled version of all the messages
  getAllToastrMessages(): { [key: string]: GlToastrCategory } {
    return ALL_TOASTR_MESSAGES;
  }

  // some message templates might have a custom name field
  // which you can insert something in to make
  // the message more descriptive
  insertNameForMessage(name: string, template: string, fallback: string) {
    return name ? template.replace("<name>", upperFirst(name)) : fallback;
  }
}
