import { IGlFormMode } from "models/gl-form-mode";
import { PatientRecord } from "models/patient-record.model";
import { GlFormController } from "../../gl-form-controller";
import { User } from "@sentry/browser";
import { Patient } from "models/user.model";
import { LoggingService } from "app/core/services/logging/logging.service";
import { PatientProcedureExternal } from "models/patient-procedure";

export class AutofillTesterController
  extends GlFormController
  implements angular.IController
{
  mode: IGlFormMode = "edit";
  isEditable: boolean = true;
  record: PatientRecord;

  externalProcedures: PatientProcedureExternal[];

  user: User;
  patient: Patient;

  constructor(private LoggingService: LoggingService) {
    "ngInject";
    super();
  }

  getRecordChangesLog() {
    return this.LoggingService.getRecordChangesLog();
  }
}

export class AutofillTester implements angular.IComponentOptions {
  static selector = "autofillTester";
  static controller = AutofillTesterController;
  static template = require("./autofill-tester.html");
  static bindings = {
    record: "<",
    mode: "@?",
    isEditable: "<?",
    user: "<",
    patient: "<",
    externalProcedures: "<",
  };
}
