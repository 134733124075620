import { isFunction } from "angular";
import { VisitDropHelperService } from "app/core/services/VisitDropHelper/VisitDropHelper.service";
import { ClinicService } from "app/core/services/clinic.service";
import { filter, get, isNil } from "lodash";
import {
  GlPatientRecordWorkflowState,
  GlVisitDrop,
  PatientConsentFormRecord,
  PatientRecord,
} from "models/patient-record.model";
import { GlClinicActiveRecord } from "../../../../../models/clinic-active-records.model";
import { User } from "../../../../../models/user.model";
import { DASHBOARD_STATES } from "../../../../core/services/appendix";
import { PatientRecordService } from "../../../../core/services/patient-record/patient-record.service";
import "./dashboard-patient-list.scss";
import moment = require("moment");

class DashboardPatientListController
  implements angular.IController, angular.IOnInit {
  records: GlClinicActiveRecord[];
  user: User;
  dashboardStates = DASHBOARD_STATES;
  deleteRecord?: ({ record }: { record: PatientRecord; }) => void;
  updateWorkflow?: (args: {
    state: string;
    activeRecord: GlClinicActiveRecord;
  }) => void;
  updateRecords: () => void;
  appointmentNotes?: string;
  stateDecided = false;
  providerSelected = false;
  clinicProviders: User[];
  selectedProvider: number;
  accordionStates: any;
  selectedState: any;

  constructor(
    private $state: angular.ui.IStateService,
    private $window: angular.IWindowService,
    private ClinicService: ClinicService,
    private toastr: angular.toastr.IToastrService,
    public PatientRecordService: PatientRecordService,
    public VisitDropHelperService: VisitDropHelperService
  ) {
    "ngInject";
  }

  $onInit() {
    return this.ClinicService.getProvidersForClinic(this.user.clinic).then(
      (providers) => {
        this.clinicProviders = providers;
      }
    );
  }

  isEmpty(input: any) {
    if (input === null || input === "") {
      return true;
    }
  }

  toLower(s: string) {
    return s?.toLowerCase();
  }

  fetchProviderForSelectedRecord(activeRecord: GlClinicActiveRecord) {
    this.selectedProvider = activeRecord.record.provider_id;
  }

  recordsChanged() {
    if (isFunction(this.updateRecords)) {
      this.updateRecords();
    }
  }

  getWorkflowStateKey(wf: string) {
    if (!wf) {
      return;
    }

    if (wf.startsWith("consent_form")) {
      return "consent-form";
    }

    if (wf.startsWith("virtual review")) {
      return "virtual-review";
    }

    return wf
      .split(" ")
      .join("")
      .toLowerCase()
      .replace("(", "")
      .replace(")", "")
      .slice(0, -1);
  }

  getCurrentState(workflowState: GlPatientRecordWorkflowState) {
    this.stateDecided = false;
    const re = /\((.*)\)/i;
    const wfs = workflowState.match(re)?.[1];
    switch (wfs) {
      case "O": {
        this.selectedState = "Other";
        return "Other";
      }
      case "INJ": {
        this.selectedState = "Injection";
        return "Injection";
      }
      case "R": {
        this.selectedState = "Retina";
        return "Retina";
      }
      case "G": {
        this.selectedState = "Glaucoma";
        return "Glaucoma";
      }
      case "C": {
        this.selectedState = "Cataract";
        return "Cataract";
      }
    }
  }

  shouldShowWorkflowState(ar: GlClinicActiveRecord) {
    return ![
      "virtual review",
      "dilation_review",
      "consent_form_cataract",
    ].includes(ar?.record?.workflow_state);
  }

  changeProvider(record: PatientRecord) {
    return this.PatientRecordService.updateProvider(
      this.selectedProvider,
      record
    ).then(() => {
      this.toastr.success("Updated provider successfully");
      this.updateRecords();
    });
  }

  setState(state: string, activeRecord: GlClinicActiveRecord) {
    this.stateDecided = true;
    this.updateWorkflow({ state, activeRecord });
  }

  lockedByAnotherUser(record: GlClinicActiveRecord, activeRecord: number) {
    return (
      record.lock?.patient_record_id === activeRecord &&
      record.lock?.expires_at > moment().format("YYYY-MM-DD HH:mm:ss")
    );
  }

  // Stop the event from closing the dropdown
  handleEvent(event: Event) {
    event.stopPropagation();
  }

  canDeleteRecord(record: GlClinicActiveRecord) {
    return record.record.data_status !== "SIGNED";
  }

  deletePatientRecord({ record, patient }: GlClinicActiveRecord) {
    const shouldDeleteRecord = this.$window.confirm(
      `Are you sure you want to delete ${patient.data.first_name} ${patient.data.last_name}'s Record?`
    );
    if (shouldDeleteRecord && this.deleteRecord) {
      this.deleteRecord({ record });
    }
  }

  // showRecordDropDownMenu(record: GlClinicActiveRecord) {
  //   return this.canDeleteRecord(record) || this.lockedByAnotherUser(record);
  // }

  getAppointmentType(workflow: string) {
    if (!workflow) {
      return "Other";
    }
    const re = /\((.*)\)/i;
    const allow = !!re[1];
    if (allow) {
      const wfs = workflow?.match(re)?.[1];
      switch (wfs) {
        case "O": {
          return "Other";
        }
        case "INJ": {
          return "Injection";
        }
        case "R": {
          return "Retina";
        }
        case "G": {
          return "Glaucoma";
        }
        case "C": {
          return "Cataract";
        }
        default: {
          return "Other";
        }
      }
    }
  }

  getComplaintFromAppointment(AppointmentDescription: string) {
    return AppointmentDescription?.split(":")[0];
  }

  openPatientRecord(activeRecord: GlClinicActiveRecord) {
    if (activeRecord.record?.type === "virtual_review") {
      this.$state.go("main.virtual-review", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
        "review-record": activeRecord.record.optom_record_id,
      });
    } else if (activeRecord.record?.type === "consent_form_cataract") {
      this.$state.go("main.cataract-consent", {
        patientId: activeRecord.patient.id,
        recordId: (activeRecord.record as PatientConsentFormRecord)
          .parent_record_id,
        "form-record": activeRecord.record.id,
      });
    } else if (activeRecord.record?.type === "tech_record") {
      this.$state.go("main.record-tech", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
      });
    } else if (activeRecord.record?.type === "procedure") {
      this.$state.go("main.injections", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
      });
    } else if (activeRecord.record?.type === "referral") {
      this.$state.go("main.referral", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
      });
    } else if (activeRecord.record?.type === "history") {
      this.$state.go("main.record", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
        type: "history",
      });
    } else {
      this.$state.go("main.record", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
        "review-record": activeRecord.record.optom_record_id,
      });
    }
  }

  // check if patient has a dillation thats done but not marked complete yet
  hasFinishedDilationTimers(activeRecord: GlClinicActiveRecord) {
    // has dillation timer
    const visitDrops: GlVisitDrop[] =
      activeRecord?.record?.data?.visit_drops ?? [];
    // check if patient has a dillation thats done but not marked complete yet
    const dilationTimers = filter(visitDrops, (d: GlVisitDrop) => {
      // is after?
      return this.VisitDropHelperService.dilationTimerFinished(d?.dilation);
    });
    // not complete and time is done
    return dilationTimers?.length > 0;
  }

  // has ongoing dilation timers
  hasOngoingDilationTimers(activeRecord: GlClinicActiveRecord) {
    // has dillation timer
    const visitDrops: GlVisitDrop[] =
      activeRecord?.record?.data?.visit_drops ?? [];
    // check if patient has a dillation that is ongoing and nit complete
    const dilationTimers = filter(visitDrops, (d: GlVisitDrop) => {
      // this guard also checks if dilation exists
      // because dilation timer will be null if not instantiated
      return this.VisitDropHelperService.dilationTimerOngoing(d?.dilation);
    });
    // not complete and time is done
    return dilationTimers?.length > 0;
  }

  soonestOngoingDropAlmostFinished(activeRecord: GlClinicActiveRecord) {
    const soonestDilationDrop: GlVisitDrop =
      this.VisitDropHelperService.getSoonestOngoingDilationDrop(
        activeRecord?.record?.data?.visit_drops
      );
    return this.VisitDropHelperService.dilationTimerAlmostFinished(
      soonestDilationDrop?.dilation
    );
  }

  // returns time for latest timer
  getDilationTimeRemainingForSoonest(activeRecord: GlClinicActiveRecord) {
    const soonestDilationDrop: GlVisitDrop =
      this.VisitDropHelperService.getSoonestOngoingDilationDrop(
        activeRecord?.record?.data?.visit_drops
      );
    // return for soonest drop
    if (!isNil(soonestDilationDrop?.dilation)) {
      return this.VisitDropHelperService.getDilationTimeRemainingAsText(
        soonestDilationDrop.dilation
      );
    }
    return null;
  }

  // DILATION RECORD
  hasSignedDilationRecord(activeRecord: GlClinicActiveRecord) {
    return activeRecord?.record?.dilation_review?.data_status === "SIGNED";
  }

  // can expand on it
  isConsentForm(activeRecord: GlClinicActiveRecord) {
    return activeRecord.record.type.startsWith("consent_form_");
  }

  // CONSENT FORMS
  isCataractConsentForm(activeRecord: GlClinicActiveRecord) {
    return activeRecord.record.type === "consent_form_cataract";
  }

  // patient and surgeon signature
  isConsentFormSigned(activeRecord: GlClinicActiveRecord) {
    return (
      ["consent_form_cataract"].includes(activeRecord.record.type) &&
      activeRecord.record.data_status === "SIGNED" &&
      activeRecord.record.data.signature_data.patient.status === "SIGNED"
    );
  }

  // * VIRTUAL REVIEW RELATED
  isDisagreedVR(activeRecord: GlClinicActiveRecord) {
    return (
      get(activeRecord, "record.type") === "virtual_review" &&
      get(activeRecord, "record.data.accept_review") === "disagree"
    );
  }

  isNextPatient(activeRecord: GlClinicActiveRecord) {
    return (
      activeRecord?.lock?.user?.id === this?.user?.id &&
      activeRecord?.record?.id === activeRecord?.lock?.patient_record_id
    );
  }
}

export class DashboardPatientListComponent
  implements angular.IComponentOptions {
  static selector = "dashboardPatientList";
  static template = require("./dashboard-patient-list.html");
  static controller = DashboardPatientListController;
  static bindings = {
    records: "<",
    user: "<",
    deleteRecord: "&?",
    updateRecords: "&?",
    updateWorkflow: "&?",
  };
}
