import { IGlSideBilateral } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";
import angular = require("angular");
import { ChangesService } from "app/core/services/changes/changes.service";
import { Observable, Subscription } from "rxjs";
import { IGlFormMode } from "models/gl-form-mode";
import { GlFormController } from "../../../../app/core/components/gl-form-controller";
import {
  IGlChangesRecordData,
  IGlChangesRecordDataOrigin,
} from "models/changes.model";
import { GNET_KEY_TO_PATH_MAPPING } from "../../../../app/core/services/autofill-helper/autofill-helper.service";
import { isEmpty, set } from "lodash";

export interface GlObserver {
  next: (...arg: any[]) => any;
  error: (...arg: any[]) => any;
  complete: (...arg: any[]) => any;
}

/**
 * this acts as a "on change do something directive"
 * that publishes events to the ChangesService observable
 *
 * the autofill service is subscribed to this as an example
 * but can be expended to watch for more things if needed
 */
export class GlChangesController
  extends GlFormController
  implements angular.IOnInit, angular.IOnDestroy
{
  path: string;
  key: string;
  altKey: string;
  side: IGlSideBilateral;
  recordData: PatientRecordData;

  mode: IGlFormMode;

  ngModelCtrl: angular.INgModelController;

  // optional stuff
  patientId: number;
  recordId: number;

  // most common is bilateral
  index?: number;

  // observers
  autofillSubscriber: Observable<any>;
  changesSubscription: Subscription;

  // a fallback if the change id on the value isnt found
  optionId: string;

  // enable changes, default will always be true
  changesEnabled: boolean = true;

  constructor(
    private $element: JQLite,
    private ChangesService: ChangesService
  ) {
    "ngInject";
    super();
  }

  $onInit() {
    // always activates on change
    this.$element.on("change", (e: any) => {
      this.handleOnChange(e);
    });
  }

  $onDestroy() {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }
  }

  handleOnChange(e: any) {
    // * IF NOT EDIT MODE IGNORE OR ENABLED
    if (this.mode !== "edit" || !this.changesEnabled) {
      return;
    }

    // * otherwise continue
    /* 
      viewValue here always gives us the previous value
      whilst the event target gives us the current value

      this happens before the lifecycle digest as changes must be reflected
      first
    */
    // there might be a case where we have to convert it from
    // a string to the corresponding object
    const currentValue: any = e?.target?.value;
    const previousValue: any = this.ngModelCtrl?.$viewValue;

    const sourcePath: string = this.getAutofillPath(this.getKey());
    const sourceKey: string = this.getKey();

    // fetch from attribute set option id
    const id: string =
      e.target.attributes["option-id"]?.value ??
      currentValue?.id ??
      this.optionId;

    /*
        values passed down that determines if we need to put an undo event before that
      */
    // passed donw values
    const changeObj: IGlChangesRecordData | IGlChangesRecordDataOrigin = {
      currentValue,
      previousValue,
      side: this.side,
      sourceKey,
      sourcePath,
      recordData: this.recordData,
      type: "manual",
      id,
      index: this.index,

      originKey: sourceKey,
      originPath: sourcePath,
      extraData: {
        recordId: this.recordId,
        patientId: this.patientId,
      },
    };

    // any extra data we can include?
    const extraData = this.getExtraData();
    if (!isEmpty(extraData)) {
      changeObj.extraData = extraData;
    }

    // handle changes on edit mode only
    // either do custom function or default
    this.ChangesService.publish(changeObj);
  }

  getKey() {
    if (this.altKey) {
      return this.altKey;
    }
    return this.key;
  }

  // given a key, get the absolute path
  getAutofillPath(field: string) {
    // default would be "" referencing data
    return GNET_KEY_TO_PATH_MAPPING?.[field] ?? "";
  }

  // assemble extra data when needed
  getExtraData() {
    const extraData = {};

    if (this.patientId) {
      set(extraData, "patientId", this.patientId);
    }

    if (this.recordId) {
      set(extraData, "recordId", this.recordId);
    }

    // return object if extra data is found otherwise ignore
    if (!isEmpty(extraData)) {
      return extraData;
    }

    return;
  }
}

export class GlChanges implements angular.IDirective<angular.IScope> {
  static selector = "glChanges";
  controller = GlChangesController;

  require = {
    ngModelCtrl: "?^ngModel",
  };

  restrict?: string = "A";

  // we need to pass get these from the elements
  // TO DO: continue with passing this down and using it as a reference
  // for gl-autofill
  bindToController = {
    path: "@path",
    key: "@key",
    altKey: "@?altKey", // in case there could be conflict due to old gl-model behaviour

    side: "@?side", // optional for unilateral ones

    mode: "@mode",
    index: "<?index", // optional

    // autofill related
    recordData: "<?recordData",
    type: "@?type",

    // source or target?
    isSource: "<?isSource",
    isTarget: "<?isTarget",

    alternativeValue: "<?alternativeValue",

    optionId: "@?optionId",

    patientId: "<?patientId",
    recordId: "<?recordId",

    changesEnabled: "<?changesEnabled",
  };

  constructor() {
    "ngInject";
  }

  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new GlChanges();
    };
    return directive;
  }
}
