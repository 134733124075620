import { GlToastrCategory } from "../toastr-appendix";

export const FAVOURITE_GROUP_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    general: {
      no_group_specified: "Group must be specified when creating or updating",
    },
    create: {
      group_name_exists:
        "Group name already exists, please use a different name",
      name_length: "Group name must be between 2 and 100 characters",
    },
    delete: {
      group_not_empty: "Please ensure favourite group is empty before deleting",
      global_group_primary_delete:
        "Main global favourite group cannot be deleted",
    },
  },
  info: {},
};
