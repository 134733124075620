import { TransitionService } from "@uirouter/angularjs";
import { AuthService } from "app/core/services/auth.service";

export class ReLoginModalController implements angular.IController, angular.IOnInit {

  private email: string;
  private password: string;

  private redirectUrl: string;

  private loginInProgress: boolean = false;

  private attemptedOnce: boolean = false;

  resolve: { email?: string; redirectUrl?: string; };

  close: (args?: {
    $value: { success?: boolean, redirectUrl?: string; };
  }) => void;

  constructor(
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService,
    private $state: angular.ui.IStateService,
    private $transitions: TransitionService
  ) {
    "ngInject";
  }

  $onInit(): void {
    this.email = this.resolve?.email;
    this.redirectUrl = this.resolve?.redirectUrl;

    this.$transitions.onExit({}, () => {
      // if still open and we are exiting, set it to false and close 
      if (this.AuthService.getReloginModalState()) {
        this.AuthService.setReloginModalState(false);
        this.close();
      }
    });
  }

  login() {
    this.loginInProgress = true;
    this.AuthService.login(this.email, this.password)
      .then(() => {
        // if successful, relogin and close the page
        this.close({ $value: { success: true, redirectUrl: this.redirectUrl } });
      })
      .catch((err) => {
        const message = err.data?.message ? err.data.message : err.message;
        this.toastr.error(message, "Login Failed");

        // set flag if failed
        if (!this.attemptedOnce) {
          this.attemptedOnce = true;
        }

      })
      .finally(() => {
        this.loginInProgress = false;
      });
  }

  // if cancelled, ignore and redirect
  goToLoginPage() {
    this.close();
    this.$state.go('login');
  }
}

export class ReLoginModal implements angular.IComponentOptions {
  static selector: string = "reloginModal";
  static controller = ReLoginModalController;
  static template = require('./relogin-modal.html');
  static bindings = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
}