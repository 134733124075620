/**
 * Import the Component styles
 */

import { ToastrAppendix } from "app/core/services/toastr-appendix/toastr-appendix";
import { PageTitleService } from "app/core/services/page-title.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { cloneDeep, isNil } from "lodash";
import { AccessLock } from "models/access-lock.model";
import {
  PatientProcedure,
  PatientProcedureDrug,
} from "models/patient-procedure";
import { GlPrescription } from "models/prescription.model";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { IGlFormMode } from "../../../models/gl-form-mode";
import { GlThread } from "../../../models/messages.model";
import { PatientDocument } from "../../../models/patient-document.model";
import {
  GlPatientRecordWorkflowState,
  PatientRecord,
} from "../../../models/patient-record.model";
import { GlUserTypeString, User } from "../../../models/user.model";
import {
  getClinicalLateralities,
  getManagementLateralities,
} from "../../core/helpers/laterality-helper";
import { getActiveReferral } from "../../core/pipes/active-referral.pipe";
import { AccessLockService } from "../../core/services/access-lock.service";
import { AuthService } from "../../core/services/auth.service";
import {
  DocumentsService,
  GL_DOCUMENT_A_SCAN,
  GL_DOCUMENT_FIELD_LEFT,
  GL_DOCUMENT_FIELD_RIGHT,
  IRecordDocuments,
} from "../../core/services/documents-service/documents.service";
import { GlModelService } from "../../core/services/gl-model.service";
import { LetterService } from "../../core/services/letter.service";
import { PatientClinicService } from "../../core/services/patient-clinic.service";
import { PatientDocumentService } from "../../core/services/patient-document.service";
import { PatientProcedureService } from "../../core/services/patient-procedure.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import { ProviderService } from "../../core/services/provider.service";
import {
  SegmentHelperService,
  Segments,
} from "../../core/services/segment-helper.service";
import { ThreadFacadeService } from "../../core/services/thread-facade.service";
import "./record-tech.scss";
import { Subscription } from "models/subscription.model";
import { SubscriptionService } from "app/core/services/subscription.service";
export interface IGlCardState {
  collapsed: boolean;
  isEditable: boolean;
  mode: IGlFormMode;
  leftEnabled: boolean;
  rightEnabled: boolean;
}

const DEFAULT_GL_CARD_STATE: IGlCardState = {
  collapsed: false,
  isEditable: false,
  mode: "display",
  leftEnabled: true,
  rightEnabled: true,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function glCardStateFactory(state: Partial<IGlCardState>) {
  return { ...DEFAULT_GL_CARD_STATE, ...state };
}

type GlCardStates = { [key in Segments]: IGlCardState };

const DEFAULT_SEGMENT_STATES: GlCardStates = {
  notes: { ...DEFAULT_GL_CARD_STATE },
  info: { ...DEFAULT_GL_CARD_STATE },
  providers: { ...DEFAULT_GL_CARD_STATE },
  medicalHistory: { ...DEFAULT_GL_CARD_STATE },
  whatToDo: { ...DEFAULT_GL_CARD_STATE },
  vaccineStatus: { ...DEFAULT_GL_CARD_STATE },
  patientUploads: { ...DEFAULT_GL_CARD_STATE },
  lensStatus: { ...DEFAULT_GL_CARD_STATE },
  clinicalData: { ...DEFAULT_GL_CARD_STATE },
  documents: { ...DEFAULT_GL_CARD_STATE },
  dryEye: { ...DEFAULT_GL_CARD_STATE },
  anterior: { ...DEFAULT_GL_CARD_STATE },
  gonio: { ...DEFAULT_GL_CARD_STATE },
  general: { ...DEFAULT_GL_CARD_STATE },
  posterior: { ...DEFAULT_GL_CARD_STATE },
  field: { ...DEFAULT_GL_CARD_STATE },
  letters: { ...DEFAULT_GL_CARD_STATE },
  drops: { ...DEFAULT_GL_CARD_STATE },
  externalProcedures: { ...DEFAULT_GL_CARD_STATE },
  inHouseProcedures: { ...DEFAULT_GL_CARD_STATE },
  management: { ...DEFAULT_GL_CARD_STATE },
  todaysDrops: { ...DEFAULT_GL_CARD_STATE },
  dayProcedures: { ...DEFAULT_GL_CARD_STATE },
  drugs: { ...DEFAULT_GL_CARD_STATE },
  prescriptions: { ...DEFAULT_GL_CARD_STATE },
  postDilationIop: { ...DEFAULT_GL_CARD_STATE },
};

const SEGMENTS_FOR_EDITING_USERS: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  clinicalData: { ...DEFAULT_GL_CARD_STATE, isEditable: true, mode: "edit" },
  documents: { ...DEFAULT_GL_CARD_STATE, isEditable: true, mode: "edit" },
  management: { ...DEFAULT_GL_CARD_STATE, isEditable: true, mode: "edit" },
  info: { ...DEFAULT_GL_CARD_STATE, collapsed: false },
  medicalHistory: { ...DEFAULT_GL_CARD_STATE, collapsed: false },
};

class RecordTechPageController
  implements angular.IController, angular.IOnInit, angular.IOnDestroy {
  threads: GlThread[];
  user: User;
  patientId: number = this.$stateParams.patientId;
  recordId: number = this.$stateParams.recordId;
  virtualReviewRecordId: number = this.$stateParams["review-record"];
  goToMessages: boolean = this.$stateParams.goToMessages;
  patient: IPatientResource;
  document: angular.resource.IResource<PatientDocument>;
  records: PatientRecord[];
  rightEnabled = true;
  leftEnabled = true;
  record: PatientRecord;
  accessLockForRecord: AccessLock;
  recordDocuments: IRecordDocuments;
  segmentStates: Partial<GlCardStates> = {};
  debug: boolean = this.$stateParams.debug;
  autoSaveIntervalPromise: angular.IPromise<void>;
  saveInProgress = false;
  saveAndProgressInProgress = false;
  lockInProgress = false;
  reopenInProgress = false;
  disableAccessLockInProgress = false;
  patientInfo: angular.IFormController;
  patientDocuments: PatientDocument[];
  docIdLeftField = GL_DOCUMENT_FIELD_LEFT;
  docIdRightField = GL_DOCUMENT_FIELD_RIGHT;
  docIdAscan = GL_DOCUMENT_A_SCAN;
  activeReferral: { url: string; expiryStatus: string; klass: string; };

  favouriteDrugs: PatientProcedureDrug[] | [];
  favouriteDrugGroups: UserFavouriteDrugGroup[] | [];

  // prescriptions and drugs
  prescriptions: GlPrescription[];
  // issue with causing infinite re-renders so this is a workaround
  prescriptionsFiltered: GlPrescription[];
  drugs: PatientProcedureDrug[];
  drugsFiltered: PatientProcedureDrug[];

  recordMessages = this.ToastrAppendix.getRecordsMessages();

  subscriptionDetails: Subscription;

  constructor(
    private $anchorScroll: angular.IAnchorScrollService,
    private $location: angular.ILocationService,
    private $q: angular.IQService,
    private $state: angular.ui.IStateService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private AccessLockService: AccessLockService,
    private AUTO_SAVE_INTERVAL: number,
    private DocumentsService: DocumentsService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    private SegmentHelperService: SegmentHelperService,
    private toastr: angular.toastr.IToastrService,
    public $stateParams: angular.ui.IStateParamsService,
    public AuthService: AuthService,
    public PatientClinicService: PatientClinicService,
    public PatientDocumentService: PatientDocumentService,
    public PatientProcedureService: PatientProcedureService,
    public PatientRecordService: PatientRecordService,
    public PrescriptionsService: PrescriptionsService,
    public UserFavouriteDrugsService: UserFavouriteDrugsService,
    public PatientService: PatientService,
    public ProviderService: ProviderService,
    public ThreadFacadeService: ThreadFacadeService,
    private PageTitleService: PageTitleService,
    public ToastrAppendix: ToastrAppendix,
    private SubscriptionService: SubscriptionService
  ) {
    "ngInject";
  }

  $onInit() {
    const patient = this.updatePatientDetails();
    patient.$promise.then((patient) =>
      this.PageTitleService.set(
        `${patient.data.first_name} ${patient.data.last_name} - Edit - GlaucoNet`
      )
    );

    // subscription
    const patientSubscriptionPromise = this.updatePatientSubscriptionDetails(this.patientId);

    const messagePromise = this.ThreadFacadeService.init(
      this.recordId,
      0,
      0,
      1
    );

    const getRecordPromise = this.PatientRecordService.getRecordV2ForEditing(
      this.patientId,
      this.recordId
    );
    const getRecordHistoryPromise =
      this.PatientRecordService.getRecordHistoryForUser(this.patientId);
    const accessLockPromise = this.AccessLockService.initPatientLock({
      recordId: this.recordId,
      onBeforeUnload: this.onBeforeUnload.bind(this),
    }).catch(() => undefined);
    const patientProceduresPromise =
      this.PatientProcedureService.getAllForPatient({
        patientId: this.patientId,
      });
    const patientClinicsPromise = this.PatientClinicService.initForPatient({
      patientId: this.patientId,
    });
    const lettersPromise = this.LetterService.init(
      this.patientId,
      this.recordId
    );
    const patientDocPromise = this.updateDocuments();

    // prescriptions
    const patientPrescriptionsPromise = this.updatePatientPrescriptions(
      this.patientId
    );

    const patientDrugsPromise = this.updatePatientDrugs(this.patientId);

    const userFavouriteDrugsPromise = this.updatePrescriberFavouriteDrugs(
      this.user?.id
    );
    const userFavouriteDrugGroupsPromise =
      this.updatePrescriberFavouriteDrugGroups(this.user?.id);

    this.$q
      .all([
        getRecordPromise,
        getRecordHistoryPromise,
        accessLockPromise,
        patientDocPromise,
        patientProceduresPromise,
        patientClinicsPromise,
        lettersPromise,
        messagePromise,
        patientPrescriptionsPromise,
        patientDrugsPromise,
        userFavouriteDrugsPromise,
        userFavouriteDrugGroupsPromise,
        patientSubscriptionPromise
      ])
      .then(([record, recordHistory, accessLock]) => {
        this.record = record;
        this.setManagementLateralities(recordHistory, record);
        this.privateSetClinicalLateralities(recordHistory, record);
        this.accessLockForRecord = accessLock;
        this.recordDocuments = this.DocumentsService.getNamedDocuments(
          this.record.documents
        );
        this.setInitialCardStates(record);
        const recordHistoryExcludingCurrentRecord = recordHistory.filter(
          (r) => r.id !== this.recordId
        );
        this.records = recordHistoryExcludingCurrentRecord;
        this.GlModelService.setRecordHistory(
          recordHistoryExcludingCurrentRecord
        );
        this.activeReferral = getActiveReferral(
          this.records,
          this.patientDocuments
        );

        // setup autosave time
        this.setAutoSaveTimeout();
        return this.setFormPristine();
      })
      .catch((error) => {
        console.error("Record Page - Error loading details", error);
        this.toastr.error("Unable to load patient details");
        this.$state.go("main.dashboard");
      })
      .finally(() => {
        //
        if (this.goToMessages) {
          // Scroll to messages
          this.$location.hash("messages");
          this.$anchorScroll();
        }
      });
  }

  updatePatientDetails() {
    const p = this.PatientService.get(this.patientId);
    p.$promise.then(() => {
      this.patient = p;
    });
    return p;
  }

  getCurrentReferrer() {
    return this.GlModelService.getFromRecordOrHistory(
      "providers.referrer",
      this.record.data,
      this.getConsolidatedRecord()
    );
  }

  $onDestroy() {
    // clear any saved patient procedures on destroy
    this.PatientProcedureService.reset();
    this.cancelAutoSaveTimeout();
  }

  uiCanExit() {
    if (
      this.patientInfo.$dirty &&
      !this.$window.confirm(
        "There are unsaved changes, are you sure you want to exit without saving?"
      )
    ) {
      return false;
    }
    // this callback can be used to release any record locks
    return this.AccessLockService.clearPatientLock();
  }

  updateDocuments() {
    return this.PatientDocumentService.getAllForPatient(this.patientId).then(
      (documents) => {
        this.patientDocuments = documents.filter(
          (d) => d.patient_record_id == null && d.data.field === "generic"
        );
        this.activeReferral = getActiveReferral(
          this.records,
          this.patientDocuments
        );
      }
    );
  }

  setInitialCardStates(record: PatientRecord) {
    // for each segment work out what the current state should be
    const usersWHoCanEdit: GlUserTypeString[] = [
      "technician",
      "ophthalmologist",
      "optometrist",
    ];
    const segmentDefaultsForEditingUser = usersWHoCanEdit.includes(
      this.user.type.name
    )
      ? SEGMENTS_FOR_EDITING_USERS
      : {};
    this.segmentStates = Object.keys(DEFAULT_SEGMENT_STATES).reduce(
      (segmentState, segment) => {
        // check if this record has data for the given segment
        const recordHasDataForSegment =
          this.SegmentHelperService.recordHasSegment(record.data, segment);

        // the segment state is a combination of
        // the default segment state
        // the segmentDefaults for the current user type
        // and if there is any data in the record for the current segment
        segmentState[segment] = {
          ...DEFAULT_SEGMENT_STATES[segment],
          ...segmentDefaultsForEditingUser[segment],
        };
        if (this.canEditRecord()) {
          segmentState[segment] = {
            ...segmentState[segment],
            isEditable: recordHasDataForSegment,
          };
        } else {
          segmentState[segment] = {
            ...segmentState[segment],
            isEditable: false,
            mode: "display",
          };
        }

        return segmentState;
      },
      {}
    );
  }

  setAllCardsToDisplayMode() {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      const segment = this.segmentStates[segmentName];
      segment.mode = "display";
    });
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  userHasAccessLockForRecord() {
    return !!this.accessLockForRecord;
  }

  canEditRecord() {
    return !this.recordIsSigned() && this.userHasAccessLockForRecord();
  }

  recordIsComplete() {
    if (!this.record) {
      return true;
    }
    return this.record.status === "COMPLETE";
  }

  getCollapsed(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.collapsed;
  }
  toggleCollapsed(segment: Segments) {
    this.segmentStates[segment].collapsed =
      !this.segmentStates[segment].collapsed;
  }

  getIsEditable(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return !this.fromSameClinic() ? "false" : state.isEditable;
  }

  setIsEditable(segment: Segments, isEditable: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.isEditable = isEditable;
      if (state.isEditable) {
        // when setting isEditable to true, default to edit mode
        this.setDisplayMode(segment, "edit");
        state.collapsed = false;
      } else {
        this.setDisplayMode(segment, "display");
      }
    }
  }

  fromSameClinic() {
    return !!(
      this.record &&
      this.user &&
      this.record.clinic.id === this.user.clinic.id
    );
  }

  getDisplayMode(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return !this.fromSameClinic() || this.recordIsSigned()
      ? "display"
      : state.mode;
  }

  setDisplayMode(segment: Segments, displayMode: IGlFormMode) {
    const state = this.segmentStates[segment];
    if (state) {
      state.mode = displayMode;
      state.collapsed = false;
      // set all the other modes to display
      if (displayMode === "edit") {
        this.defaultOtherSegmentsToEdit(segment);
      }
    }
  }

  getLeftEnabled(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.leftEnabled;
  }

  setLeftEnabled(segment: Segments, enabled: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.leftEnabled = enabled;
    }
  }

  getRightEnabled(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.rightEnabled;
  }

  setRightEnabled(segment: Segments, enabled: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.rightEnabled = enabled;
    }
  }

  autoSave() {
    // authenticated
    const canContinue: boolean = this.AuthService.autoProcessCanContinue();
    // only auto save if the form has changes
    // and the session is authenticated to avoid issues
    // with spamming 401's to api
    if (this.patientInfo.$dirty && !this.saveInProgress && canContinue) {
      this.save();
    }
  }

  save() {
    /**
     * ! SAVING ISSUE [HIGH PRIORITY]
     * 
     * i suspect the following:
     * 
     * a transient bug occurs where the user saves/ an autosave is performed
     * then the page is redirected
     * the reference is cleaned out by gl-model or cleanDeep, that means an empty
     * reference is saved
     * 
     * we could solve this by creating a clone of the record to use 
     * 
     * OR
     * 
     * there might be a case where if two saves are occuring at
     * the same time, there could be a secario where
     * it silently errors out and does not retain the data
     * 
     * OR 
     * 
     * it could be a problem with cleanDeep where it unintentionally
     * cleans all the data because its empty?
    */

    // create a record reference of the current state you want saved
    // as early as possible
    const recordClone: PatientRecord = cloneDeep(this.record);

    if (recordClone.id) {
      this.saveInProgress = true;
      // resetting autosave timeout before it saves
      // minimising conflict between autosave <-> save and 
      // having issues where data is overwritten (i.e. lost)
      this.setAutoSaveTimeout();

      this.PatientRecordService.updateAndSetPractitioner(recordClone, this.user)
        .then(() => {
          // only pristine if all were successful
          this.toastr.success("Successfully saved record!");
          this.patientInfo.$setPristine();
        })
        .catch(() => {
          this.toastr.error(this.recordMessages.error.save.error_try_again);
        })
        .finally(() => {
          this.saveInProgress = false;
          this.setAutoSaveTimeout();
        });
    }
  }

  signRecord(skipOphthalReview?: boolean) {
    this.lockInProgress = true;
    this.PatientRecordService.sign({
      record: this.record,
      skipOphthalReview,
    })
      .then((record) => {
        this.toastr.success("Successfully signed record!");
        // if an Optometrist, close the record as well
        if (this.user.type.name === "optometrist") {
          return this.completeRecord();
        }
        this.record = record;
        this.patientInfo.$setPristine();
        this.setAllCardsToDisplayMode();
      })
      .catch(() => {
        this.toastr.error(this.recordMessages.error.sign.error_try_again);
      })
      .finally(() => {
        this.lockInProgress = false;
      });
  }

  reopenRecord() {
    this.reopenInProgress = true;
    this.PatientRecordService.reopen(this.record)
      .then((record) => {
        this.record = record;
        this.setInitialCardStates(this.record);
        this.toastr.success("Successfully re-opened record!");
      })
      .catch(() => {
        this.toastr.error(this.recordMessages.error.re_open.error_try_again);
      })
      .finally(() => (this.reopenInProgress = false));
  }

  print() {
    this.PatientRecordService.openPrintRecordWindow({
      patientId: this.patientId,
      recordId: this.recordId,
      printOnLoad: true
    });
  }

  saveAndProgressClicked(nextWorkflowState?: GlPatientRecordWorkflowState) {
    this.record.workflow_state = nextWorkflowState;
    this.saveAndProgressInProgress = true;
    return this.PatientRecordService.updateAndSetPractitioner(
      this.record,
      this.user
    )
      .then(() => this.setFormPristine())
      .then(() => {
        // the record is save, so redirect back to the dashboard
        this.toastr.success(
          "Successfully saved record, re-directing to dashboard..."
        );
        return this.$state.go("main.dashboard");
      })
      .catch((error) => {
        console.error("Error in saveAndProgressClicked saving record", error);
        this.toastr.error(this.recordMessages.error.save.error_try_again);
      })
      .finally(() => {
        this.saveAndProgressInProgress = false;
      });
  }

  completeRecord() {
    this.record.status = "COMPLETE";
    return this.saveAndProgressClicked();
  }

  getConsolidatedRecord() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  shouldShowAdminSummary() {
    return this.user.type.name === "administrator";
  }

  overrideAccessLock() {
    this.disableAccessLockInProgress = true;
    this.AccessLockService.disablePageLock({
      recordId: this.recordId,
      force: true,
    })
      .then(() => {
        this.toastr.success("Successfully overriden access lock!");
        return this.$state.reload();
      })
      .catch(() => {
        this.toastr.error(
          this.recordMessages.error.access_lock.override.error_try_again
        );
      })
      .finally(() => (this.disableAccessLockInProgress = false));
  }

  // update prescriptions
  updatePatientPrescriptions(patientId: number) {
    if (patientId !== undefined) {
      return this.PrescriptionsService.fetchPrescriptions(patientId).then(
        (p) => {
          this.prescriptions = p ?? [];
          this.prescriptionsFiltered = this.prescriptions.filter(
            (p: GlPrescription) => p.record_id === this.recordId
          );
          return p ?? [];
        }
      );
    }
  }

  // update for the drugs
  // currently its filtered but in future there might be a way to clean this
  updatePatientDrugs(patientId: number) {
    if (patientId !== undefined) {
      return this.PatientProcedureService.getAllForPatient({
        patientId: patientId,
      }).then((procedures: PatientProcedure[]) => {
        // we just call since after promise its updated
        this.drugs = procedures?.filter(
          (p) => p.type === "drugs"
        ) as PatientProcedureDrug[];

        this.drugsFiltered = this.drugs.filter(
          (d: PatientProcedureDrug) => d.record_id === this?.recordId
        );
      });
    }
  }

  // FAVOURITE DRUGS
  updatePrescriberFavouriteDrugs(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugs(
        userId
      ).then((favourites: PatientProcedureDrug[]) => {
        this.favouriteDrugs = favourites;
        return favourites;
      });
    }
  }

  updatePrescriberFavouriteDrugGroups(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugGroups(
        userId
      ).then((groups: UserFavouriteDrugGroup[]) => {
        this.favouriteDrugGroups = groups;
        return groups;
      });
    }
  }

  updatePrescriberFavouritesCombinedHandler(userId: number) {
    this.updatePrescriberFavouriteDrugs(userId);
    this.updatePrescriberFavouriteDrugGroups(userId);
  }

  updatePatientSubscriptionDetails(patient_id: number) {
    this.SubscriptionService.get({ patient_id })
      .then((subscription) => {
        this.subscriptionDetails = { ...subscription };
      });
  }

  private setAutoSaveTimeout() {
    this.cancelAutoSaveTimeout();
    if (this.AUTO_SAVE_INTERVAL > 0) {
      this.autoSaveIntervalPromise = this.$timeout(this.AUTO_SAVE_INTERVAL);
      this.autoSaveIntervalPromise
        .then(() => this.autoSave())
        .catch(() => {
          // If this timeout is cancelled, it throws an error. Catch all errors
          // to silence this error
        });
    }
  }

  private cancelAutoSaveTimeout() {
    if (this.autoSaveIntervalPromise) {
      this.$timeout.cancel(this.autoSaveIntervalPromise);
      this.autoSaveIntervalPromise = null;
    }
  }

  private onBeforeUnload() {
    if (this.patientInfo.$dirty) {
      return "Are you sure you want to close this window and discard any changes?";
    }
  }

  // This sets the form to pristine after a short delay to allow gl-model to set
  // any defaults for fields
  private setFormPristine() {
    return this.$timeout(250).then(() => this.patientInfo.$setPristine());
  }

  private defaultOtherSegmentsToEdit(selectedSegment: string) {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      if (
        this.segmentStates[segmentName].mode === "edit" &&
        ![selectedSegment, "management"].includes(segmentName)
      ) {
        this.segmentStates[segmentName].mode = "display";
      }
    });
  }

  private privateSetClinicalLateralities(
    records: PatientRecord[],
    currentRecord: PatientRecord
  ) {
    if (records.length === 1 && records[0]?.data === null) {
      //if first record for patient then leave defaults.
      return;
    }
    const { enableLeft, enableRight } = getClinicalLateralities(
      currentRecord,
      records
    );
    this.setLeftEnabled("clinicalData", enableLeft);
    this.setRightEnabled("clinicalData", enableRight);
  }

  private setManagementLateralities(
    records: PatientRecord[],
    currentRecord: PatientRecord
  ) {
    if (records.length === 1 && records[0]?.data === null) {
      //if first record for patient then leave defaults.
      return;
    }
    const { enableLeft, enableRight } = getManagementLateralities(
      currentRecord,
      records
    );
    this.setLeftEnabled("management", enableLeft);
    this.setRightEnabled("management", enableRight);
  }
}

export class RecordTechPage implements angular.IComponentOptions {
  static selector = "recordTechPage";
  static template = require("./record-tech.html");
  static controller = RecordTechPageController;
  static bindings = {
    user: "<",
  };
}
