import { IGlOption } from "app/core/services/appendix";
import { get, isEmpty, isNil } from "lodash";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  GlDiagnosis,
  PatientRecord,
} from "../../../../../models/patient-record.model";
import { SegmentHelperService } from "../../../../core/services/segment-helper.service";
import { VisualAcuityService } from "../../../../core/services/va.service";
import { DiagnosisService } from "../../../services/diagnosis.service";

export class GlLetterObservationsTableController
  implements angular.IController
{
  static $inject = [
    DiagnosisService.injectionName,
    SegmentHelperService.injectionName,
    VisualAcuityService.injectionName,
  ];
  record: PatientRecord;
  allowedFields: string[];

  constructor(
    private DiagnosisService: DiagnosisService,
    private segmentHelper: SegmentHelperService,
    private vaSvc: VisualAcuityService
  ) {
    // not empty
  }

  // get field
  getLargestFieldSide(fieldName: string) {
    const field = get(this.record.data, `${fieldName}`) ?? [];
    if (!isEmpty(field)) {
      // if left larger than right return else right, same
      // length doesnt matter
      return field?.left?.length > field?.right?.length
        ? field.left
        : field.right;
    }
    return [];
  }

  hasVa() {
    return this.vaSvc.hasVa(this.record.data);
  }

  hasFields(fields: string[]) {
    return this.segmentHelper.hasFields(this.record.data, fields);
  }

  showFields(field: string) {
    if (!this.allowedFields?.includes("*")) {
      return this.allowedFields?.includes(field);
    } else if (this.allowedFields?.includes("*") || !this.allowedFields) {
      return true;
    }
  }

  getFieldToDisplay(
    fieldName: string,
    side: IGlSide,
    otherFieldName: string = `${fieldName}_other`
  ) {
    const val = get(this.record.data, `${fieldName}.${side}`);
    const otherValue =
      get(this.record.data, `${otherFieldName}.${side}`) ?? null;

    // private func
    return this._prettifyFieldToDisplay(val, otherValue, fieldName, side);
  }

  // used for multiple observation values when doing a ng-repeat
  getFieldToDisplayByObservationIndex(
    fieldName: string,
    side: IGlSide,
    index: number
  ) {
    const val = get(this.record.data, `${fieldName}.${side}.${index}`);
    const otherValue = val?.other ?? null;
    if (isNil(val)) {
      return "";
    }

    return this._prettifyFieldToDisplay(val, otherValue, fieldName, side);
  }

  getDiagnosis(side: IGlSide) {
    const diagnosisArray: GlDiagnosis[] =
      this.record?.data?.management?.diagnosis_array?.[side];
    if (diagnosisArray) {
      return diagnosisArray
        .map((d) => this.DiagnosisService.getDiagnosis(d))
        .join(", ");
    }
  }

  private _prettifyFieldToDisplay(
    val: any,
    otherValue: any,
    fieldName: string,
    side: IGlSide
  ) {
    if (fieldName === "lens.status" && val?.key !== "cataract") {
      /**
       * For Lens Status, we format the first observation to add to the letter.
       * We do this for everything EXCEPT for lens.status === 'cataract'
       */
      const lensObservations: IGlOption[] = get(
        this.record.data,
        `lens.observations.${side}`
      );

      if (lensObservations?.length > 0) {
        /**
         * LensObservations aren't typed correctly at the moment. They are typed like the following
         * {
         *  type:
         *    {
         *      name: 'PCO',
         *      key: 'PCO',
         *      requireQuantifier: true,
         *      quantifier: {
         *        name: '2+',
         *        key: '2+'
         *      }
         *   }
         * }
         */
        const [{ type }] = lensObservations as any;
        if (type) {
          const { name, quantifier, quantifierOther } = type;
          otherValue = `${name} ${quantifierOther || quantifier?.name || ""}`;
        } else {
          otherValue = "";
        }
      }
    }

    /**
     * Val can be an Object or a primitive (ie: number) - in this case, just
     * return val if it exists
     */
    if (val?.key !== "other" && !otherValue) {
      return val?.name || val;
    } else if (val?.key !== "other" && otherValue) {
      return `${val?.name || val}${
        // there is a weird edge vase where otherValue can return
        // undefined as a string
        !isNil(otherValue) && otherValue?.trim() !== "undefined"
          ? ` : ${otherValue}`
          : ""
      }`;
    } else {
      // same instance can happen here
      return otherValue?.trim() !== "undefined" ? otherValue : val?.name ?? val;
    }
  }
}

export class GlLetterObservationsTableComponent
  implements angular.IComponentOptions
{
  static selector = "glLetterObservationsTable";
  static template = require("./gl-letter-observations-table.html");
  static controller = GlLetterObservationsTableController;
  static bindings = {
    record: "<",
    allowedFields: "<",
  };
}
