import { IPromise } from "angular";
import { AutofillExternalProcedureService } from "app/core/services/autofill-external-procedure.service/autofill-external-procedure.service";
import {
  AutofillService,
  IAutofillIconParams,
} from "app/core/services/autofill/autofill.service";
import { ChangesService } from "app/core/services/changes/changes.service";
import { get, isEmpty, isFunction, isNil } from "lodash";
import { IGlChangesRecordDataOrigin } from "models/changes.model";
import { EXTERNAL_PROCEDURES_KEY } from "../../../../../lib/key-appendix";
import { ProcedureDetails } from "../../../../../models/gl-external-procedure";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  PatientProcedureExternal,
  PatientProcedureType,
} from "../../../../../models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { GlFormController } from "../../gl-form-controller";
import "./patient-procedures.scss";

// but keep the save logic
export class PatientProceduresController
  extends GlFormController
  implements angular.IComponentController, angular.IOnChanges {
  record: PatientRecord;
  procedureToEdit: PatientProcedureExternal | object;
  procedures: PatientProcedureExternal[];
  saveProcedureInProgress = false;
  deleteProcedureInProgress = false;
  type: PatientProcedureType = "surgical";

  // only allow editing historical records
  historicalEditableOnly = false;

  // if this is toggled all non-hisotrical procedures can be edited
  // this can only be toggled only if
  // historicalEditableOnly = true
  // then all edits will be recorded in the action log
  //
  // if historicalEditableOnly is false, then this is ignored
  retroactiveEditingEnabled: boolean = false;

  patientId: string = this.$stateParams.patientId;

  // filter relevant procedures only
  filteredProceduresToDisplay: PatientProcedureExternal[] = [];

  // autofill icon refs
  autofillIconSourceParams: IAutofillIconParams[];
  autofillIconTargetParams: IAutofillIconParams[];


  selectable: boolean = false;

  onSelect: (arg: { procedure: PatientProcedureExternal; }) => void;

  constructor(
    private toastr: angular.toastr.IToastrService,
    private $stateParams: angular.ui.IStateParamsService,
    private PatientProcedureService: PatientProcedureService,
    private AutofillExternalProcedureService: AutofillExternalProcedureService,
    private ChangesService: ChangesService,
    private AutofillService: AutofillService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // for autofill icon
    this.autofillIconTargetParams =
      this.AutofillService.getAutofillIconParamsForTargetKey(
        EXTERNAL_PROCEDURES_KEY
      );
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.procedures || changes.record) {
      // work out which procedures to display
      this.filterProceduresToDisplay();
    }
  }

  // fetches temporary procedures
  getTemporaryExternalProcedures() {
    return this.AutofillExternalProcedureService.getTemporaryExternalProcedures();
  }

  // PROCEDURE DISPLAY RELATED
  filterProceduresToDisplay() {
    if (!this.procedures) {
      return;
    }
    // temporary ones are technically newest
    this.filteredProceduresToDisplay = this.procedures;
    if (this.record) {
      // only display procedures associated with this record
      // it belongs to this record if
      this.filteredProceduresToDisplay = this.procedures.filter((p) =>
        this.showProcedureInEditMode(p)
      );
    }
  }

  showProcedureInEditMode(procedure: PatientProcedureExternal) {
    return this.PatientProcedureService.showProcedureInEditMode(
      procedure,
      this.record.id
    );
  }

  // is procedure editable?
  canEditProcedure(procedure: PatientProcedureExternal) {
    const historicalEditable: boolean =
      (this.historicalEditableOnly && procedure.status === "historical") ||
      !this.historicalEditableOnly;

    const nonHistoricalEditable: boolean =
      this.canEditRetroactively() && procedure.status !== "historical";

    // must be edit mode and one of the following matches
    return this.isEditMode() && (historicalEditable || nonHistoricalEditable);
  }

  // HISTORICAL EDITABLE RELATED
  canEditRetroactively() {
    // only if we are in historical editable mode
    // and is toggled
    return this.historicalEditableOnly && this.retroactiveEditingEnabled;
  }

  procedureIsRetroactivelyEditable(procedure: PatientProcedureExternal) {
    return (
      this.canEditRetroactively() &&
      procedure?.status !== "historical" &&
      !isNil(procedure.id)
    );
  }

  toggleRetroactiveEditing() {
    this.retroactiveEditingEnabled = !this.retroactiveEditingEnabled;
  }

  // EDITING
  addProcedure() {
    this.procedureToEdit = {};
  }

  edit(procedure: PatientProcedureExternal) {
    this.procedureToEdit = procedure;
  }

  cancel() {
    this.procedureToEdit = undefined;
  }

  // PROCEDURE CRUD
  save(procedure: PatientProcedureExternal) {
    this.saveProcedureInProgress = true;
    let savePromise: IPromise<PatientProcedureExternal>;

    // if we can edit non historical procedures
    // and it is an existing procedure
    // that isnt a historical one
    // we should log the changes as it is a retroactive change
    const shouldLogChanges: boolean =
      this.procedureIsRetroactivelyEditable(procedure);

    // try and find the existing procedure by id before it is updated
    // this will be ignored if it is a new procedure
    const existingProcedure: PatientProcedureExternal =
      (procedure?.id
        ? this.procedures?.find((p) => p?.id === procedure?.id)
        : null) ?? null;

    // if only historical records can be edited and there is no ID associated with it
    // (i.e. a fresh record )
    if (this.historicalEditableOnly && isNil(procedure.id)) {
      // then it will always be a historical procedure
      procedure = { ...procedure, status: "historical" };
    }

    // TEMP PROCEDURE
    // if temp procedure, have to go to a differnet flow to save
    if (procedure?.is_temp) {
      // create a confirm autofill event and save
      const changeObj: IGlChangesRecordDataOrigin = {
        currentValue: procedure,
        side: procedure.data.eye,
        sourceKey: EXTERNAL_PROCEDURES_KEY,
        sourcePath: this.AutofillService.getAutofillPath(
          EXTERNAL_PROCEDURES_KEY
        ),
        originKey: EXTERNAL_PROCEDURES_KEY,
        originPath: this.AutofillService.getAutofillPath(
          EXTERNAL_PROCEDURES_KEY
        ),
        type: "autofill_confirm",
        origin: "both",
        recordData: this.record.data,
        id: procedure?.autofill_id,
        extraData: {
          recordId: this.record.id,
          patientId: this.patientId,
        },
      };

      // this will call the confirm workflow
      // and will ideally remove the temp procedure and
      // create a new one in its place
      this.ChangesService.publish(changeObj);
      this.procedureToEdit = undefined;
      this.saveProcedureInProgress = false;
      return;
    }

    if (procedure.id) {
      savePromise = this.PatientProcedureService.updateExternalProcedure({
        recordId: this.record?.id,
        procedure,
        oldProcedure: existingProcedure,
        logChanges: shouldLogChanges,
      });
    } else {
      savePromise = this.PatientProcedureService.createExternalProcedure(
        this.record?.id,
        procedure
      );
    }
    return savePromise
      .then((createdProcedure: PatientProcedureExternal) => {
        this.procedureToEdit = undefined;
        this.toastr.success(
          `Successfully ${procedure.id ? "updated" : "created"} procedure!`
        );
        return createdProcedure;
      })
      .finally(() => (this.saveProcedureInProgress = false));
  }

  // breaks it into either delete from temporary procedure or actual procedure
  handleDelete(procedure: PatientProcedureExternal) {
    // if temp delete by executing an undo
    if (procedure.is_temp) {
      this.deleteProcedureInProgress = true;
      this.undoAutofillProcedure(procedure);
      this.cancel();
      this.deleteProcedureInProgress = false;
    } else {
      // otherwise delete as normal
      return this.deleteProcedure(procedure);
    }
  }

  // deletes a regular procedure
  deleteProcedure(procedure: PatientProcedureExternal) {
    // should we log the delete as well?
    const shouldLogChanges: boolean =
      this.procedureIsRetroactivelyEditable(procedure);

    this.deleteProcedureInProgress = true;

    /*  
      NOTE: for retroactive deleting
      record id is irrelevant since the only data that will
      be changed is just the information linked to the procedure entry

      so record_id will remain constant regardless of state
      if defined, since it already filters based on the provided record id
      chance of failure will be low
    */
    this.PatientProcedureService.deleteExternalProcedure(
      this.record?.id,
      procedure,
      shouldLogChanges
    )
      .then(() => {
        this.cancel();
        this.toastr.success("Successfully deleted procedure!");
      })
      .finally(() => (this.deleteProcedureInProgress = false));
  }

  // PROCEDURE DISPLAY RELATED
  // procedure has side?
  hasSide(procedure: PatientProcedureExternal, side: IGlSide) {
    const eyeSide = get(procedure, "data.eye");
    return ["both", side].includes(eyeSide);
  }

  getProcedureDate(procedure: PatientProcedureExternal, side: IGlSide) {
    if (procedure.data?.[side]) {
      return procedure.data[side].date;
    } else {
      return procedure.procedure_date;
    }
  }

  // generates a summary line of the procedure
  getProcedureSummary(procedure: PatientProcedureExternal, side: IGlSide) {
    const { data } = procedure;

    /*
      if another name exists and the key is actually other
        use that
        otherwise check level 2 appendix
          use display text or the name
          otherwise general name
    */
    const name =
      data.name_other && data?.level2Appendix.key === "other"
        ? data.name_other
        : data.level2Appendix
          ? data.level2Appendix.displayText || data.level2Appendix.name
          : data.name;

    // some cases we use name_other as a description key
    const descOther: string =
      !isEmpty(data?.name_other) && data?.level2Appendix.key !== "other"
        ? ` (${data.name_other})`
        : "";

    // if no details exist go simple
    const details: ProcedureDetails = data[side];
    if (!details) {
      // this is an older record type
      return `${name}${descOther}`.trim();
    }

    // routine if needed?
    const routineStr = !details.complete
      ? ""
      : details.routine
        ? "Routine"
        : "Complicated";

    /*
        if incomplete
          nothing
          if routine
            show routine
            otherwise complicated
      */
    return `${routineStr} ${name}${descOther}`.trim();
  }

  getProcedureEyeOrder(procedure: PatientProcedureExternal) {
    // order of procedure if it counts
    switch (procedure?.data?.order) {
      case "left_right":
        return "(L -> R)";
      case "right_left":
        return "(R -> L)";
      default:
        return "";
    }
  }

  // procedure background cosmetic
  getProcedureBackground(procedure: PatientProcedureExternal, side: IGlSide) {
    const completionDate = this.getProcedureDate(procedure, side);
    const details: ProcedureDetails = procedure.data[side];

    if (procedure.is_temp) {
      return "bg-info"; // autofill is temporary
    } else if (procedure.status === "historical") {
      return "bg-secondary";
    } else if (!completionDate) {
      return "bg-warning";
    } else if (completionDate && (!details || (details && details.routine))) {
      // if complete  & routine then green
      return "alert-success";
    } else if (completionDate && "routine" in details && !details.routine) {
      // if complete & complicated then red
      return "alert-danger";
    }
  }

  removeTemporaryProcedure(procedure: PatientProcedureExternal) {
    this.AutofillExternalProcedureService.removeTemporaryProcedure(procedure);
  }

  // regular undo
  undoAutofillProcedure(procedure: PatientProcedureExternal) {
    // dispatch undo event
    const changeObj: IGlChangesRecordDataOrigin = {
      currentValue: procedure,
      side: procedure.data.eye,
      sourceKey: EXTERNAL_PROCEDURES_KEY,
      sourcePath: this.AutofillService.getAutofillPath(EXTERNAL_PROCEDURES_KEY),
      originKey: EXTERNAL_PROCEDURES_KEY,
      originPath: this.AutofillService.getAutofillPath(EXTERNAL_PROCEDURES_KEY),
      type: "autofill_undo",
      origin: "both",
      recordData: this.record.data,
      id: procedure?.autofill_id,
      extraData: {
        recordId: this.record.id,
        patientId: this.patientId,
      },
    };
    // dispatch it
    this.ChangesService.publish(changeObj);

    // remove procedure
    this.removeTemporaryProcedure(procedure);
  }



  getEditableRowClass() {
    if (!this.isEditMode()) {
      return 'col-xs-12';
    } else if (this.isEditMode() && this.isSelectable()) {
      return 'col-xs-10';
    } else {
      return 'col-xs-11';
    }
  }

  isSelectable() {
    return this.selectable;
  }

  // pass onto handler
  onProcedureSelect(procedure: PatientProcedureExternal) {
    if (isFunction(this.onSelect)) {
      this.onSelect({ procedure });
    }
  }
}

export class PatientProcedures implements angular.IComponentOptions {
  static selector = "patientProcedures";
  static template = require("./patient-procedures.html");
  static controller = PatientProceduresController;
  static bindings = {
    mode: "@",
    record: "<",
    procedures: "<",
    type: "@",
    historicalEditableOnly: "<?",
    selectable: "<?",
    onSelect: "&?",
  };
}
