import { copy } from "angular";
import { IGlOption } from "app/core/services/appendix";
import { AuthService } from "app/core/services/auth.service";
import { AutofillService } from "app/core/services/autofill/autofill.service";
import { isEmpty, set } from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";
import { Patient } from "models/user.model";
import { GlModelService } from "../../../services/gl-model.service";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import { GNET_SEGMENT_TO_KEY_MAPPING } from "../../../../../app/core/services/autofill-helper/autofill.mapping";

export class SegmentPosteriorController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // @Input()
  record: PatientRecordData;
  patient: Patient;
  enableLeft: boolean;
  enableRight: boolean;
  // Controller properties
  showOctMac = true;
  showOctGcc = true;
  showOctRnfl = true;

  // defaultOption: IGlOption = { name: "Not Examined", key: "not-examined" };
  defaultOption: IGlOption = null;

  posteriorFieldKeys: string[] = [];

  constructor(
    private GlModelService: GlModelService,
    private AutofillService: AutofillService,
    private AuthService: AuthService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    this.posteriorFieldKeys = GNET_SEGMENT_TO_KEY_MAPPING.segment_posterior;
  }

  $onChanges(changes: angular.IOnChangesObject) {
    //
    if (changes.mode && this.isEditMode()) {
      // bring forward previous oct readings into the RNFL field if there has
      // been no previous RNFL observation.
      this.migrateLegacyOctFieldToRnflOct();
      this.updateOctFieldsToDisplay();
    }

    // if one side is toggled from enable to disable do cleanup and
    // clear all autofill status on that side whilst preserving data (i.e. just a confirm)
    if (
      changes?.enableLeft?.previousValue &&
      !changes?.enableLeft?.currentValue
    ) {
      this.clearAutofillOnSideDisable("left");
    }
    if (
      changes?.enableRight?.previousValue &&
      !changes?.enableRight?.currentValue
    ) {
      this.clearAutofillOnSideDisable("right");
    }
  }

  // we mock clearing all diagnosis by "confirming"
  // which also kind of clears the data whilst preserving it
  clearAutofillOnSideDisable(side: IGlSideBilateral) {
    for (const key of this.posteriorFieldKeys) {
      this.AutofillService.confirmAutofillsForSide(key, side);
    }
  }

  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  // handle clear VIT and Disc rows only
  handleClearVitAndDisc() {
    // VIT
    set(this.record, "vit", {
      left: undefined,
      right: undefined,
    });

    // DISC
    set(this.record, "disc", {
      left: undefined,
      right: undefined,
    });
  }

  private migrateLegacyOctFieldToRnflOct() {
    const previousOctDescription = this.GlModelService.get("oct");
    const previousOctOtherDescription = this.GlModelService.get("oct_other");
    const previousRnflOctDescription = this.GlModelService.get("oct_rnfl");
    if (!previousRnflOctDescription && previousOctDescription) {
      this.record.oct_rnfl = copy(previousOctDescription);
      if (previousOctOtherDescription) {
        this.record.oct_rnfl_other = copy(previousOctOtherDescription);
      }
    }
  }

  private updateOctFieldsToDisplay() {
    const { oct_mac, oct_mac_v2, oct_gcc, oct_rnfl } = this.record || {};
    // if there is any data in the current record, then set the toggle defaults
    // so that data is shown. Otherwise default it to all shown;
    this.showOctGcc = !(isEmpty(oct_gcc?.left) && isEmpty(oct_gcc?.right));
    this.showOctMac =
      !(isEmpty(oct_mac?.left) && isEmpty(oct_mac?.right)) ||
      !(isEmpty(oct_mac_v2?.left) && isEmpty(oct_mac_v2?.right));
    this.showOctRnfl = !(isEmpty(oct_rnfl?.left) && isEmpty(oct_rnfl?.right));

    if (!this.showOctGcc && !this.showOctMac && !this.showOctRnfl) {
      this.showOctGcc = this.showOctMac = this.showOctRnfl = true;
    }
  }
}

export class SegmentPosterior implements angular.IComponentOptions {
  static selector = "segmentPosterior";
  static template = require("./segment-posterior.html");
  static controller = SegmentPosteriorController;
  static bindings = {
    enableLeft: "<",
    enableRight: "<",
    record: "<",
    patient: "<",
    ...GlFormControllerBindings,
  };
}
