import { isNil } from "lodash";
import { Subscription } from "models/subscription.model";
import { Patient, SubscriptionState } from "models/user.model";

import './gl-subscription.scss';
import moment = require("moment");
import { IOnChangesObject } from "angular";

export class GlSubscriptionController implements angular.IController, angular.IOnChanges {
  patient: Patient;

  // default not subscribed
  subscriptionDetails: Subscription;
  subscriptionState: SubscriptionState = "inactive";
  subscriptionTooltip: string = "";

  constructor(
    private $scope: angular.IScope
  ) {
    "ngInject";
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes?.subscriptionDetails && this?.subscriptionDetails) {
      this.updateSubscribedState();
    }
  }

  updateSubscribedState(): SubscriptionState {
    // default inactive
    let _subscriptionState: SubscriptionState = "inactive";

    // determine state
    // nothing? inactive
    if (isNil(this.subscriptionDetails)) {
      _subscriptionState = "inactive";
    } else {
      // theres soemthing
      // subscribed state
      const subscribed: boolean = this.subscriptionDetails?.subscribed ?? false;
      // ends at
      const currDate: moment.Moment = moment();
      const endsAt: string = this.subscriptionDetails?.subscription?.ends_at;

      // active with no end date means subscription found 
      if (subscribed && isNil(endsAt)) {
        _subscriptionState = "active";
      } else if (subscribed && currDate.isSameOrAfter(moment(endsAt))) {
        // expired - shouldnt even reach here
        // as api wont return expired subscriptions - but just in case...
        _subscriptionState = "expired";
      } else if (subscribed && endsAt) {
        // active_cancelled means cancelled but subscription will continue running
        // state is subscribed - but ends at X date
        _subscriptionState = "active_cancelled";
      }
      // inactive is the same as expired
      // api will not fetch if so
    }

    // set and return
    this.subscriptionState = _subscriptionState;
    return _subscriptionState;
  }

  getTooltip() {
    let tooltip: string = "";
    // expiration date if applciable
    const expiryDate: string = this?.subscriptionDetails?.subscription?.ends_at
      ? moment(this?.subscriptionDetails?.subscription?.ends_at).format("DD/MM/yyyy")
      : undefined;

    const startDate: string = this?.subscriptionDetails?.subscription?.created_at
      ? moment(this?.subscriptionDetails?.subscription?.created_at).format("DD/MM/yyyy")
      : undefined;

    switch (this.subscriptionState) {
      case "active":
        tooltip = `Subscription active ${startDate ? `since ${startDate}` : ''}`;
        break;
      case "active_cancelled":
        tooltip = `Subscription cancelled - will continue until ${expiryDate ?? 'expiration date'}`;
        break;
      case "expired":
        tooltip = `Subscription expired`;
        break;
      default:
        // inactive and default are the same
        tooltip = "Not subscribed or subscription inactive";
        break;
    }
    this.subscriptionTooltip = tooltip;
    return tooltip;
  }
}

export class GlSubscription implements angular.IComponentOptions {
  static selector: string = 'glSubscription';
  static controller = GlSubscriptionController;
  static template = require('./gl-subscription.html');
  static bindings = {
    patient: "<",
    subscriptionDetails: "<"
  };
}