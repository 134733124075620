import { LoggingService } from "app/core/services/logging/logging.service";
import { cloneDeep, each, get, isFunction, set } from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";

class CopyButtonController implements angular.IController, angular.IOnInit {
  leftSymbol: string;
  rightSymbol: string;
  fields: any;
  record: PatientRecordData;
  clearTooltip?: string = "Clear fields";
  // copy everything over or just by reference?
  mode: "all" | "index" = "all";

  onClear: () => void;

  onCopy: (arg: {
    side: string;
    previousRecordData: PatientRecordData;
  }) => void;

  copyFieldsFromCustom: (
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    arg: { fromSide: IGlSideBilateral; toSide: IGlSideBilateral } | any
  ) => void;

  constructor(private LoggingService: LoggingService) {
    "ngInject";
  }
  $onInit() {
    this.leftSymbol = this.leftSymbol || "fa-arrow-left";
    this.rightSymbol = this.rightSymbol || "fa-arrow-right";
  }

  onClickRight() {
    // fields[0 is always the original]
    const previousRecordData: PatientRecordData = cloneDeep(this.record);
    this.copyFieldsFrom("right", "left");

    // any extra functions
    this.handleOnCopy("left", previousRecordData);
  }

  onClickLeft() {
    // fields[0 is always the original]
    const previousRecordData: PatientRecordData = cloneDeep(this.record);
    this.copyFieldsFrom("left", "right");

    // any extra functions
    this.handleOnCopy("right", previousRecordData);
  }

  handleOnCopy(side: string, previousRecordData: PatientRecordData) {
    if (isFunction(this.onCopy)) {
      this.onCopy({ side, previousRecordData });
    }
  }

  copyFieldsFrom(fromSide: string, toSide: string) {
    // if custom function defined used that first
    if (isFunction(this.copyFieldsFromCustom)) {
      this.copyFieldsFromCustom({ fromSide, toSide });
    } else {
      // otherwise default
      each(this.fields, (field) => {
        const fromValue = get(this.record, `${field}.${fromSide}`);
        const oldValue = cloneDeep(get(this.record, `${field}.${toSide}`));
        set(this.record, `${field}.${toSide}`, cloneDeep(fromValue));

        // for each set, you must update it
        this.LoggingService.pushToRecordChangesLog({
          sourcePath: `${field}.${toSide}`,
          sourceKey: field,
          previousValue: oldValue,
          currentValue: fromValue,
          type: "copy",
          originKey: field,
          originPath: `${field}.${toSide}`,
        });
      });
    }
  }

  handleOnClear() {
    if (isFunction(this.onClear)) {
      this.onClear();
    }
  }
}
export class CopyButton implements angular.IComponentOptions {
  static selector = "buttonCopy";
  static transclude = true;
  static bindings = {
    mode: "@",
    fields: "<",
    record: "<",
    leftSymbol: "<",
    rightSymbol: "<",
    onCopy: "&",
    onClear: "&",
    clearTooltip: "@?",
    copyFieldsFromCustom: "&?",
  };
  static controller = CopyButtonController;
  static template = require("./button-copy.html");
}
