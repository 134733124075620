import {
  camelCase,
  every,
  isEmpty,
  isNil,
  isString,
  some,
  values,
} from "lodash";
import {
  DIAGNOSIS_ARRAY_KEY,
  EXTERNAL_PROCEDURES_KEY,
  LENS_OBSERVATIONS_KEY,
  LENS_STATUS_KEY,
  MACULAR_LEGACY_KEY,
  MACULAR_V2_KEY,
  MAC_OCT_V2_KEY,
} from "../../../../lib/key-appendix";
import { v4 as uuidv4 } from "uuid";
import {
  GNET_AUTOFILL_BILATERAL_TYPE_AUTOFILL,
  GNET_DIAG_AUTOFILL_MAPPING,
  GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING,
  GNET_OBSERVATIONS_AUTOFILL_MAPPING,
  GNET_OBS_AUTOFILL_MAPPING,
  GNET_SOURCE_TO_TARGET_AUTOFILL_KEY_MAPPING,
} from "./autofill.mapping";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GNET_PREFILL_CHECK_OPTIONS,
  GNET_PREFILL_TARGETS,
} from "./prefill.mapping";

/*
  there will be two systems
  one for bindings two sections together
  then the other for referencing them 


  they do not interact with each other but are modular in the sense 
  that we can update them whenever 
*/
export interface IValueAutofillKeyParams {
  path: string; // absolute reference
  fieldKey: string;
  side?: string;

  valueKey: string; // what we are autofilling

  id?: string; // anyhting that can be used as a unique identifier,
}

/**
 * This defines a list of paths that will be the
 * prefix to the fieldKey
 *
 * Assumes we will traverse from .data onwards
 * as most of them will receive record.data
 * passed down
 */
export const GNET_KEY_TO_PATH_MAPPING: {
  [key: string]: string;
} = {
  // base
  data: "",

  // diagnosis section
  diagnosis_array: "management",

  // posterior lens
  lens_status: "",
  lens_observations: "",
  get "lens.status"() {
    return this.lens_status;
  },
  get "lens.observations"() {
    return this.lens_observations;
  },

  // cataracts will be different
  external_procedures: "",
};

export const AUTOFILL_PAIRINGS_THAT_DONT_USE_SIDE: {
  sourceKey: string;
  targetKey: string;
}[] = [
  {
    sourceKey: DIAGNOSIS_ARRAY_KEY,
    targetKey: EXTERNAL_PROCEDURES_KEY,
  },
];

export class AutofillHelperService {
  static injectionName: string = "AutofillHelperService";

  // source key <==> value keys
  // vice versa will also be applied so we
  // need a check on each value in the map as well
  autofillSourceToTargetMapping: Map<string, string[]> = new Map<
    string,
    string[]
  >();

  // * this is an internal helper store target references
  // * not meant for public use
  private autofillTargetToSourceMapping: Map<string, string[]> = new Map<
    string,
    string[]
  >();

  constructor() {
    "ngInject";
  }

  $onDestroy() {
    this.resetAllAutofillMappings();
  }

  resetSourceToTargetAutofillMapping() {
    this.autofillSourceToTargetMapping = new Map<string, string[]>();
  }

  resetTargetToSourceAutofillMapping() {
    this.autofillTargetToSourceMapping = new Map<string, string[]>();
  }

  resetAllAutofillMappings() {
    this.resetSourceToTargetAutofillMapping();
    this.resetTargetToSourceAutofillMapping();
  }

  // given a key, get the absolute path
  getAutofillPath(field: string) {
    // default would be "" referencing data
    return GNET_KEY_TO_PATH_MAPPING?.[field] ?? "";
  }

  // translates a field key into the name used to represent it
  getFieldKeyName(fieldKey: string) {
    switch (fieldKey) {
      // lens is the same thing
      case LENS_STATUS_KEY:
      case LENS_OBSERVATIONS_KEY:
        return "Lens";

      case MACULAR_V2_KEY:
        return "Macular";
      case MAC_OCT_V2_KEY:
        return "MAC OCT";

      case EXTERNAL_PROCEDURES_KEY:
        return "Out of House Procedures";

      case DIAGNOSIS_ARRAY_KEY:
        return "Diagnosis Array";

      default:
        return "";
    }
  }

  /**
   * there are two maps for
   * source <-> target and
   * target <-> source
   *
   * we separate them to help identify what needs to be
   * autofilled and how it should be undone
   *
   * it does add complexity but its necessary to avoid creating
   * multiple other helper functions to determine behaviour
   */

  getSourceToTargetAutofillMapping() {
    return this.autofillSourceToTargetMapping;
  }

  getTargetToSourceAutofillMapping() {
    return this.autofillTargetToSourceMapping;
  }

  // * GET
  // returns a list of keys
  getSourceTargetAutofillValues(sourceKey: string): string[] {
    return this.autofillSourceToTargetMapping.get(sourceKey) ?? [];
  }

  getTargetSourceAutofillValues(targetKey: string): string[] {
    return this.autofillTargetToSourceMapping.get(targetKey) ?? [];
  }

  getSourceTargetAutofillKeys(): string[] {
    return Array.from(this.autofillSourceToTargetMapping.keys()) ?? [];
  }

  getTargetSourceAutofillKeys(): string[] {
    return Array.from(this.autofillTargetToSourceMapping.keys()) ?? [];
  }

  // * CHECKERS
  hasActiveSourceAutofill(sourceKey: string) {
    return this.autofillSourceToTargetMapping.has(sourceKey) ?? false;
  }

  hasActiveTargetAutofill(targetKey: string) {
    return this.autofillTargetToSourceMapping.has(targetKey) ?? false;
  }

  hasActiveAutofillAny(key: string) {
    return (
      this.hasActiveSourceAutofill(key) || this.hasActiveTargetAutofill(key)
    );
  }

  // * DELETE
  // DELETE
  deleteFromSourceAutofillMapping(key: string) {
    this.autofillSourceToTargetMapping.delete(key);
  }

  deleteFromTargetAutofillMapping(key: string) {
    this.autofillTargetToSourceMapping.delete(key);
  }

  // * HELPERS
  // we go by substring, just need a similar match
  getSimilarAutofillSourceKey(key: string) {
    const keys: string[] = Array.from(
      this.autofillSourceToTargetMapping.keys()
    );
    return (keys ?? []).find((k) =>
      k?.toLowerCase()?.includes(key?.toLowerCase())
    );
  }

  getSimilarAutofillSourceKeyByPartial(params: IValueAutofillKeyParams) {
    const { path, fieldKey, side, valueKey, id } = params;

    // imporatnt check: if no value key ignore
    if (some([path, fieldKey, valueKey], (k) => isNil(k))) {
      return;
    }

    // otherwise continue
    // filter out the empty ones
    const filteredParams: string[] = values([
      path,
      fieldKey,
      side,
      valueKey,
      id,
    ]).filter((v) => !isNil(v) && !isEmpty(v));

    // do a search based on it including all the remaining defined key params
    const keys: string[] = Array.from(
      this.autofillSourceToTargetMapping.keys()
    );

    return (keys ?? []).find((k) =>
      every(filteredParams, (p) => k?.toLowerCase()?.includes(p?.toLowerCase()))
    );
  }

  getSimilarAutofillTargetKey(key: string) {
    const keys: string[] = Array.from(
      this.autofillTargetToSourceMapping.keys()
    );

    return (keys ?? []).find((k) =>
      k?.toLowerCase()?.includes(key?.toLowerCase())
    );
  }

  getSimilarAutofillTargetKeyByPartial(params: IValueAutofillKeyParams) {
    const { path, fieldKey, side, valueKey, id } = params;

    // imporatnt check: if no value key ignore
    if (some([path, fieldKey, valueKey], (k) => isNil(k))) {
      return;
    }

    // filter out the empty ones
    const filteredParams: string[] = values([
      path,
      fieldKey,
      side,
      valueKey,
      id,
    ]).filter((v) => !isNil(v) && !isEmpty(v));

    // do a search based on it including all the remaining defined key params
    const keys: string[] = Array.from(
      this.autofillTargetToSourceMapping.keys()
    );

    return (keys ?? []).find((k) =>
      every(filteredParams, (p) => k?.toLowerCase()?.includes(p?.toLowerCase()))
    );
  }

  // * SETTERS
  // set as a whole
  setAutofillSourceValueArray(sourceKey: string, targetKeys: string[]) {
    this.autofillSourceToTargetMapping.set(sourceKey, targetKeys);
  }

  setAutofillTargetValueArray(targetKey: string, sourceKeys: string[]) {
    this.autofillTargetToSourceMapping.set(targetKey, sourceKeys);
  }

  // add to value
  // if new, instantiate else add
  setAutofillSourceValue(sourceKey: string, targetKey: string) {
    if (!this.hasActiveSourceAutofill(sourceKey)) {
      this.autofillSourceToTargetMapping.set(sourceKey, [targetKey]);
    } else {
      const targetKeys: string[] =
        this.getSourceTargetAutofillValues(sourceKey) ?? [];
      targetKeys.push(targetKey);
      this.autofillSourceToTargetMapping.set(sourceKey, targetKeys);
    }
  }

  setAutofillTargetValue(targetKey: string, sourceKey: string) {
    if (!this.hasActiveTargetAutofill(targetKey)) {
      this.autofillTargetToSourceMapping.set(targetKey, [sourceKey]);
    } else {
      const sourceKeys: string[] =
        this.getTargetSourceAutofillValues(targetKey) ?? [];
      sourceKeys.push(sourceKey);
      this.autofillTargetToSourceMapping.set(targetKey, sourceKeys);
    }
  }

  // * HAS AUTOFILL
  // finds an autofill associated key in a haystack
  sourceHasTargetAutofill(sourceKey: string, targetKey: string) {
    const targetKeys: string[] =
      this.getSourceTargetAutofillValues(sourceKey) ?? [];
    return targetKeys.includes(targetKey);
  }

  targetHasSourceAutofll(targetKey: string, sourceKey: string) {
    const sourceKeys: string[] =
      this.getTargetSourceAutofillValues(sourceKey) ?? [];
    return sourceKeys.includes(sourceKey);
  }

  // * MISC
  // both ways technically
  setAutofillLinkSourceTargetBidirectional(source: string, target: string) {
    this.setAutofillSourceValue(source, target);
    this.setAutofillTargetValue(target, source);
  }

  getOptionKey(key: string, option: any) {
    // if its a string just put it in
    // its either a diagnosis

    switch (key) {
      // options
      case "mac_oct":
      case "oct_mac":
      case MAC_OCT_V2_KEY:
      case "mac_oct_v2":
      case MACULAR_LEGACY_KEY:
      case MACULAR_V2_KEY:
        return camelCase(isString(option) ? option : camelCase(option?.key));

      // diagnosis
      case DIAGNOSIS_ARRAY_KEY:
        const diagnosisLevels: string[] = [
          // "level4",
          // "level3",
          // * autofill goes to max lvl 2
          "level2",
          "level1",
        ];

        // check if there is a diagnosis key
        for (const level of diagnosisLevels) {
          if (!isNil(option?.[level]?.key)) {
            return camelCase(option?.[level]?.key);
          }
        }
        /*
            if it was a string just go with it 
            otherwise take either the key of the option
            or just the option itself
          */
        return camelCase(isString(option) ? option : option?.key ?? option);

      // LENS
      // status
      case LENS_STATUS_KEY:
      case "lens_status":
      case "lensPhakic":
        return camelCase(option?.key ?? option);

      // observation
      case LENS_OBSERVATIONS_KEY:
      case "lens_observations":
      case "IOLType":
        return camelCase(option?.type?.key ?? option?.key ?? option);

      // procedure
      case EXTERNAL_PROCEDURES_KEY:
        return camelCase(
          option?.data?.nameAppendix?.key ?? option?.nameAppendix?.key ?? option
        );
      default:
        return camelCase(option?.key ?? option);
    }
  }

  // * GENERATE
  generateAutofillReferenceKey(params: IValueAutofillKeyParams) {
    /*
      NOTE: we dont use index here as we just check for 
      A SINGLE REFERENCE amongst all of them only
    */
    const {
      path, // absolute reference
      fieldKey,
      side, // for unilateral cases this is ignored and filtered out

      // value key follows after
      valueKey, // what we are autofilling
      id, // unique reference
    } = params;

    // * EDGE CASE: always ignore if no field key or value key
    if (some([fieldKey, valueKey], (p) => isNil(p) || isEmpty(p))) {
      return;
    }

    // main array to generate key string from
    const keys: string[] = [path, fieldKey, side, valueKey, id];

    // else continue and generate
    // re-oder it so that it always appears in this order
    return keys
      .filter((p) => !isNil(p) && !isEmpty(String(p)))
      .map((p) => String(p))
      .join(".");
  }

  // autofill id should be consisntent to avoid issues
  generateAutofillId() {
    return uuidv4();
  }

  // should use side
  autofillPairingUsesSide(sourceKey: string, targetKey: string) {
    // if not found, it does require side in its key
    return isNil(
      AUTOFILL_PAIRINGS_THAT_DONT_USE_SIDE.find(
        (p) =>
          (p.sourceKey === sourceKey && p.targetKey === targetKey) ||
          // there could be a chance that its all inverted
          (p.sourceKey === targetKey && p.targetKey === sourceKey)
      )
    );
  }

  /* MAPPING RELATED STUFF */

  // given a key, check where else we need to autofill
  getAutofillTargets(sourceKey: string) {
    return GNET_SOURCE_TO_TARGET_AUTOFILL_KEY_MAPPING?.[sourceKey] ?? [];
  }

  // given a target key, find out which source key exists
  getAutofillSources(targetKey: string) {
    const keys: string[] = Object.keys(
      GNET_SOURCE_TO_TARGET_AUTOFILL_KEY_MAPPING
    );
    return (
      keys.filter((k) =>
        (GNET_SOURCE_TO_TARGET_AUTOFILL_KEY_MAPPING[k] ?? []).includes(
          targetKey
        )
      ) ?? []
    );
  }

  // autofill keys should be based off appendix.ts style
  /// OTHER DICTIONARY
  getAutofillOption(field: string) {
    return GNET_OBSERVATIONS_AUTOFILL_MAPPING[field];
  }

  getAutofillOptionWhere(field: string, key: string, value: any) {
    const appendix = GNET_OBSERVATIONS_AUTOFILL_MAPPING[field];
    // Check all the fields and return the one with the
    return appendix?.find((option) => option[key] === value) ?? null;
  }

  getAutofillOptionWhereKey(field: string, key: string) {
    const appendix = GNET_OBSERVATIONS_AUTOFILL_MAPPING[field];
    return (
      appendix?.find((option) =>
        [
          option?.key.toLowerCase(),
          option?.autofill_key_alternative?.toLowerCase(),
        ].includes(key?.toLowerCase())
      ) ?? null
    );
  }

  // FETCHERS FOR FINDING AUTOFILL VALUES
  // get diagnosis atuofill
  getDiagnosisAutofillByKey(key: string) {
    return GNET_DIAG_AUTOFILL_MAPPING?.[key];
  }

  getObservationAutofillByKey(key: string) {
    return GNET_OBS_AUTOFILL_MAPPING?.[key];
  }

  getExternalProcedureAutofillByKey(key: string) {
    return GNET_EXTERNAL_PROCEDURES_AUTOFILL_MAPPING?.[key];
  }

  getSides(): IGlSideBilateral[] {
    return ["right", "left"];
  }

  // find out if the key has to be handled specially
  isBilateralAutofillType(key: string) {
    return GNET_AUTOFILL_BILATERAL_TYPE_AUTOFILL.includes(key);
  }

  /* PREFILL RELATED */
  // autofill prefil check
  getAutofillPrefillDict() {
    return GNET_PREFILL_CHECK_OPTIONS;
  }

  getPrefillTargetsDict() {
    return GNET_PREFILL_TARGETS;
  }

  getPrefillTargetKeys(fieldName: string) {
    return GNET_PREFILL_TARGETS[fieldName];
  }

  isOptionPrefill(fieldName: string, key: string) {
    return !isNil(
      (GNET_PREFILL_CHECK_OPTIONS[fieldName] ?? []).find((o) => o.key === key)
    );
  }
}
