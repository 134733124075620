import { cloneDeep, forEach, isNil } from "lodash";
import { PatientProcedure, PatientProcedureSelectable, PatientProcedureType } from "models/patient-procedure";
import { PatientRecordData } from "models/patient-record.model";

export class ConsentFormLinkedProcedureController implements
  angular.IController {


  // are we creating a consent form or updating it?
  create: boolean = false;

  // detials
  procedureType: PatientProcedureType;
  record: PatientRecordData;

  // could be for any procedure
  procedures: PatientProcedureSelectable[];

  // currently selected procedure
  selectedProcedure: PatientProcedure;

  // modal stuff
  resolve: {
    create?: boolean;
    linkedProcedureId?: number;
    procedureType: PatientProcedureType;
    record: PatientRecordData;
    procedures?: PatientProcedure[],
  };

  close: (args?: {
    $value?: {
      procedure: PatientProcedure;
    };
  }) => void;
  onSelect: (args: { procedure: PatientProcedure; }) => void;

  constructor(
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onInit(): void {
    this.procedureType = this.resolve.procedureType;
    this.record = this.resolve.record;
    this.create = this.resolve?.create ?? false;

    // load procedures
    this.procedures = cloneDeep(this.resolve?.procedures ?? []);

    this.initWithLinkedProcedure();
  }

  // have an exisitng linked procedure selected on init
  initWithLinkedProcedure() {
    const linkedProcedureId: number = this.resolve?.linkedProcedureId;
    // if there is a linked procedure
    if (!isNil(linkedProcedureId)) {
      // find it with reference and select it
      const foundProcedure: PatientProcedureSelectable =
        (this.procedures ?? []).find((p) => p?.id === linkedProcedureId);

      if (foundProcedure) {
        this.onSelectProcedure(foundProcedure);
      }
    }
  }

  // on select - ensure only one can be selected
  onSelectProcedure(procedure: PatientProcedureSelectable) {
    forEach(this.procedures, (p) => {
      if (p.id !== procedure.id) {
        p.selected = false;
      } else {
        p.selected = true;
        this.selectedProcedure = p;
      }
    });
  }

  hasSelectedProcedure() {
    return !isNil(this.selectedProcedure);
  }

  onCancel() {
    this.close();
  }

  onConfirm() {
    if (!this.selectedProcedure) {
      this.toastr.error('Please select a procedure to link the consent form to and try again.');
    }
    this.close({ $value: { procedure: this.selectedProcedure } });
  }
}

export class ConsentFormLinkedProcedure implements
  angular.IComponentOptions {

  static controller = ConsentFormLinkedProcedureController;
  static template = require('./consent-form-linked-procedure.html');
  static selector = "consentFormLinkedProcedure";
  static bindings = {
    dismiss: "&",
    close: "&",
    resolve: "<",
    onSelect: "&"
  };

}