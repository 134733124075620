import { GlToastrCategory } from "../toastr-appendix";

export const DRUG_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    generic: {
      root_required: "Route must be specified",
      quantity_required: "Quantity must be specified",
      dose_required: "Dose must be specified",
      frequency_required: "Frequency must be specified",
      repeats_required: "Repeats must be specified",
      is_empty: "Please select a drug",

      // generic
      create: "An error has occured whilst creating the drug, please try again",
      save: "An error has occured whilst saving the drug, please try again",
      delete: "An error has occured whilst deleting the drug, please try again",
    },
    favourite: {
      drug_exists:
        "Drug already exists in one of the favourites groups or user defaults, please refer to existing template. User will still be able to 'Create Drug'.",
      drug_in_global_group:
        "Drug is a part of a global group.\nOnly authorised users or creator will be able to edit and save the template, but regular users will be able to add them to their own favourite groups.",
      group_selection_empty:
        "Favourite drugs must be added into at least one favourite group to be saved.",
    },
  },
  info: {},
};
