import { GlToastrCategory } from "../toastr-appendix";

export const AUTOFILL_TOASTR_MESSAGES: GlToastrCategory = {
  success: {
    confirm: "Sucessfully confirmed autofill!",
    undo: "Successfully undone autofill!",
  },
  error: {
    autofill: {
      exists: "Existing autofill already exists, will not autofill",
      duplicate: "Duplicate autofill value selected, will not autofill",
      generic: "Error creating autofill, please try again.",
    },
  },
  info: {
    autofill: {
      diagnosis: "An autofill was created in the Diagnosis section!",
      diagnosis_name: "<name> was autofilled in the Diagnosis section!",

      external_procedures:
        "An autofill was created in the Out of House Procedures section!",
      external_procedures_name:
        "<name> was autofilled in the Out of House Procedures section!",
    },
    prefill: {
      diagnosis: "A prefill was created in the Diagnosis section!",
      diagnosis_name: "<name> was prefilled in the Diagnosis section!",
    },
  },
};
