import { IComponentOptions, IController } from "angular";
import { IConsolidatedInjection } from "app/core/services/injection-helper/injection-helper.service";

export class InjectionCreateWarningController
  implements IController {
  // @Input()

  // @Output()
  dismiss: (args?: { $value: any; }) => void;
  close: (args?: {
    $value: { create?: boolean; };
  }) => void;
  resolve: { injectionsAtCycleMax?: IConsolidatedInjection[], injectionsAtCycleOverflow?: IConsolidatedInjection[], noInjections?: boolean; };

  // also display to the user which injections have that issue
  injectionsAtCycleMax: IConsolidatedInjection[] = [];
  injectionsAtCycleOverflow: IConsolidatedInjection[] = [];
  noInjections: boolean = false;

  // warnings acknowledged
  warningsAcknowledged: boolean = false;

  constructor() {
    "ngInject";
  }

  $onInit() {
    this.injectionsAtCycleMax = this.resolve?.injectionsAtCycleMax ?? [];
    this.injectionsAtCycleOverflow = this.resolve?.injectionsAtCycleOverflow ?? [];
    this.noInjections = this.resolve.noInjections;
  }

  handleOnCancel() {
    return this.close({ $value: { create: false } });
  }


  handleCreateInjection() {
    // pass down args on sign confirm
    return this.close({ $value: { create: true } });
  }

}

export class InjectionCreateWarning implements IComponentOptions {
  static selector = "injectionCreateWarning";
  static template = require("./injection-create-warning.html");
  static controller = InjectionCreateWarningController;
  static bindings = {
    dismiss: "&", // automatically injected by angular.ui.bootstrap modal
    close: "&", // automatically injected by angular.ui.bootstrap modal
    resolve: "<"
  };
}
