import "./autofill-icon-popover.scss";
import { isEmpty, isFunction, isNil, isObject, set } from "lodash";
import { AutofillService } from "app/core/services/autofill/autofill.service";
import { IGlSide } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";
import { ChangesService } from "app/core/services/changes/changes.service";
import { AutofillHelperService } from "app/core/services/autofill-helper/autofill-helper.service";
import { IGlAutofillOrigin } from "models/changes.model";
import { EXTERNAL_PROCEDURES_KEY } from "../../../../../lib/key-appendix";

export type DiagnosisAutofillSource = "observation" | "diagnosis";
export type DiagnosisAutofillTarget = "diagnosis" | "procedure";

export class AutofillIconPopoverController
  implements angular.IComponentController, angular.IOnInit
{
  title: string;
  tooltip: string = "Click to show autofill options";

  recordData: PatientRecordData;

  autofillName: string;
  popoverPlacement: string = "top";

  // * autofill related
  side: IGlSide;
  sourceKey: string;
  targetKey: string;

  // * reference from .data onwards, i.e. .data === ""
  sourcePath: string = "";
  targetPath: string = "";

  // * get the most relevant key that is applicable
  autofillValue: any;

  // * this can help with referencing when undo'ing for array source/targets
  index: number;

  // * the parent of the component it exists in and not relative to source or target
  origin: IGlAutofillOrigin;
  originKey: string;
  originPath: string;

  // * extra info
  // optional stuff
  patientId: number;
  recordId: number;

  // custom functions
  isAutofillActiveCustom: () => boolean;

  undoAutofill: () => void;
  acceptAutofill: () => void;

  constructor(
    private AutofillService: AutofillService,
    private AutofillHelperService: AutofillHelperService,
    private ChangesService: ChangesService
  ) {
    "ngInject";
  }

  $onInit(): void {
    // handle popover placement for style reasons else default
    if (!isNil(this.side)) {
      switch (this.side) {
        case "left":
          this.popoverPlacement = "top-right";
          break;
        case "right":
          this.popoverPlacement = "top-left";
          break;
        default:
          this.popoverPlacement = "top";
          break;
      }
    }
  }

  /*
    we could separarte into autofill active for source and target
    but in the end since we just check if there is a link
    between the source and target key
  */
  isAutofillActive() {
    // a custom handler can be assigned if needed to
    if (isFunction(this.isAutofillActiveCustom)) {
      return this.isAutofillActiveCustom();
    }

    // else go back to default behaviour
    // if no source value ignore
    if (isNil(this.autofillValue) || isEmpty(this.autofillValue)) {
      return false;
    } else if (
      // if an object and all values are empty return
      isObject(this.autofillValue) &&
      Object.values(this.autofillValue).every((v) => isNil(v))
    ) {
      return false;
    }

    // source and target option keys
    const sourceOptionKey: string = this.getOptionKey(
      this.sourceKey,
      this.autofillValue
    );
    const targetOptionKey: string = this.getOptionKey(
      this.targetKey,
      this.autofillValue
    );

    // setup so that we can allow various ways to check for autofill
    switch (this.originKey) {
      // external procedures has a differnet way of checking
      case EXTERNAL_PROCEDURES_KEY:
        return this.externalProcedureAutofillActive(
          sourceOptionKey,
          targetOptionKey
        );
      default:
        // else do default
        return this.defaultAutofillActive(sourceOptionKey, targetOptionKey);
    }
  }

  // the main is autofill active check
  defaultAutofillActive(sourceOptionKey: string, targetOptionKey: string) {
    // * autofill should be either R/L, include edge cases if required

    // try and get a match for the source key
    const similarSourceKey: string =
      this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
        path: this.AutofillService.getAutofillPath(this.sourceKey),
        fieldKey: this.sourceKey,
        valueKey: isEmpty(sourceOptionKey) ? undefined : sourceOptionKey,
        id: this.getAutofillId(),
        side: this.side,
      });
    const sourceKeysTargets: string[] =
      this.AutofillHelperService.getSourceTargetAutofillValues(
        similarSourceKey
      );

    // try and get a match for the target key
    const similarTargetKey: string =
      this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
        path: this.AutofillService.getAutofillPath(this.targetKey),
        fieldKey: this.targetKey,
        valueKey: isEmpty(targetOptionKey) ? undefined : targetOptionKey,
        id: this.getAutofillId(),
        side: this.side,
      });

    const targetKeySources: string[] =
      this.AutofillHelperService.getTargetSourceAutofillValues(
        similarTargetKey
      );

    // there needs to be a link between both keys
    const hasSourceTargetLink: boolean =
      !!sourceKeysTargets.find((k) => k?.includes(this.targetKey)) ||
      !!targetKeySources?.find((k) => k?.includes(this.sourceKey));

    // check if its either in source or target and there is an actual link between
    const hasAutofill: boolean =
      (this.AutofillHelperService.hasActiveSourceAutofill(similarSourceKey) ||
        this.AutofillHelperService.hasActiveTargetAutofill(similarTargetKey)) &&
      hasSourceTargetLink;

    return hasAutofill;
  }

  externalProcedureAutofillActive(
    sourceOptionKey: string,
    targetOptionKey: string
  ) {
    // * external procedures should not use id or side to check and do a similar match only
    // we can do this because for each temporary procedure type there can ONLY
    // be one of each
    // if it is a bilateral external procedure, do a loose check for EITHER L/R
    // by the stem

    // or we just implicity check for one side (i.e. right since both exists)
    const includeSide: boolean = this.side !== "both";

    // try and get a match for the source key (side or no side depending)
    // we dont use ID here
    const similarSourceKey: string =
      this.AutofillHelperService.getSimilarAutofillSourceKeyByPartial({
        path: this.AutofillService.getAutofillPath(this.sourceKey),
        fieldKey: this.sourceKey,
        valueKey: isEmpty(sourceOptionKey) ? undefined : sourceOptionKey,
        side: includeSide ? this.side : "right",
      });
    const sourceKeysTargets: string[] =
      this.AutofillHelperService.getSourceTargetAutofillValues(
        similarSourceKey
      );

    // try and get a match for the target key (side or no side depending)
    const similarTargetKey: string =
      this.AutofillHelperService.getSimilarAutofillTargetKeyByPartial({
        path: this.AutofillService.getAutofillPath(this.targetKey),
        fieldKey: this.targetKey,
        valueKey: isEmpty(targetOptionKey) ? undefined : targetOptionKey,
        side: includeSide ? this.side : "right",
      });
    const targetKeySources: string[] =
      this.AutofillHelperService.getTargetSourceAutofillValues(
        similarTargetKey
      );

    // there needs to be a link between both keys
    const hasSourceTargetLink: boolean =
      !!sourceKeysTargets.find((k) => k?.includes(this.targetKey)) ||
      !!targetKeySources?.find((k) => k?.includes(this.sourceKey));

    // check if its either in source or target and there is an actual link between
    return (
      (this.AutofillHelperService.hasActiveSourceAutofill(similarSourceKey) ||
        this.AutofillHelperService.hasActiveTargetAutofill(similarTargetKey)) &&
      hasSourceTargetLink
    );
  }

  // generate a target key
  generateTargetValue() {
    // handle edge case by path
    return this.AutofillService.convertToAutofillOutput({
      targetKey: this.targetKey,
      valueKey: this.getOptionKey(this.sourceKey, this.autofillValue),
    });
  }

  getFieldNameFromKey(key: string) {
    return this.AutofillHelperService.getFieldKeyName(key);
  }

  // there are some fields that dont track using side, only id
  shouldUseSide() {
    return this.AutofillHelperService.autofillPairingUsesSide(
      this.sourceKey,
      this.targetKey
    );
  }

  /* HANDLERS */
  handleUndoAutofill() {
    const sourcePath: string = this.getAutofillPath(this.sourceKey);
    const targetPath: string = this.getAutofillPath(this.targetKey);
    const originPath: string = this.getAutofillPath(this.originKey);

    // add any extra data
    const extraData = this.getExtraData();

    // dispatch
    this.ChangesService.publish({
      // we call this as a generic source/origin and undo from a
      // soruce and target perspective

      // include source
      sourceKey: this.sourceKey,
      sourcePath,

      // target
      targetKey: this.targetKey,
      targetPath,

      // and also where it was called from
      originKey: this.originKey,
      originPath,

      type: "autofill_undo",
      // origin: this.getOrigin(),
      origin: "both",

      // rest is standard
      currentValue: this.autofillValue,
      side: this.side,
      recordData: this.recordData,
      index: this.index,
      id: this.getAutofillId(),
      // if not empty itll just be a new json added to it, else we spread an
      // empty json
      ...(!isEmpty(extraData) ? { extraData } : extraData),
    });
  }

  // * publish to autofill subject to handle
  handleConfirmAutofill() {
    const sourcePath: string = this.getAutofillPath(this.sourceKey);
    const targetPath: string = this.getAutofillPath(this.targetKey);
    const originPath: string = this.getAutofillPath(this.originKey);

    const origin: IGlAutofillOrigin = this.getOrigin();

    const extraData = this.getExtraData();

    if (!origin) {
      return;
    }

    this.ChangesService.publish({
      currentValue: this.autofillValue,
      side: this.side,
      sourceKey: this.sourceKey,
      sourcePath,
      targetKey: this.targetKey,
      targetPath,
      originKey: this.originKey,
      originPath,
      type: "autofill_confirm",
      origin,
      recordData: this.recordData,
      id: this.getAutofillId(),
      extraData,
    });
  }

  // assemble extra data when needed
  getExtraData() {
    const extraData = {};
    if (this.patientId) {
      set(extraData, "patientId", this.patientId);
    }

    if (this.recordId) {
      set(extraData, "recordId", this.recordId);
    }

    return extraData;
  }

  getAutofillPath(key: string) {
    return this.AutofillHelperService.getAutofillPath(key);
  }

  getOptionKey(key: string, value: any) {
    return this.AutofillHelperService.getOptionKey(key, value);
  }

  getAutofillId() {
    return this?.autofillValue?.id ?? this?.autofillValue?.autofill_id;
  }

  getOrigin() {
    return this?.origin ?? "both";
  }

  isSource() {
    return this?.origin === "source";
  }

  isTarget() {
    return this?.origin === "target";
  }
}

export class AutofillIconPopover implements angular.IComponentOptions {
  static selector = "autofillIconPopover";
  static template = require("./autofill-icon-popover.html");
  static controller = AutofillIconPopoverController;
  static bindings = {
    title: "@",
    tooltip: "<",
    side: "@",
    autofillName: "@?",

    recordData: "<",

    isTarget: "<?",
    isSource: "<?",
    popoverPlacement: "@?",
    undoAutofill: "&",
    acceptAutofill: "&",

    isAutofillActiveCustom: "&?",

    sourceKey: "@",
    targetKey: "@",
    sourcePath: "@",
    targetPath: "@",
    autofillKey: "@",

    autofillValue: "<",

    // not all of them have indexes
    index: "<?",

    // origin key and path
    origin: "@?",
    originKey: "@",
    originPath: "@?",

    // extra data
    patientId: "<?",
    recordId: "<?",
  };
}
