// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { ViewManagerComponent } from "./components/view-manager/view-manager.component";
import { VirtualReviewConfirmationComponent } from "./components/virtual-review-confirmation/virtual-review-confirmation.component";
import { RecordPage } from "./record";
import { InjectionCreateWarning } from "./components/injection-create-warning/injection-create-warning";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.record", {
    url:
      "/patient/{patientId:int}/{type:record|history}/{recordId:int}?{debug:bool}&{review-record:int}&{goToMessages:bool}",
    component: RecordPage.selector,
    data: {
      requiresLogin: true,
    },
    params: {
      // This makes the recordId optional
      recordId: {
        squash: true,
        value: null,
      },
      type: {
        value: "record",
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.record", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(RecordPage.selector, RecordPage)
  .component(ViewManagerComponent.selector, ViewManagerComponent)
  .component(
    VirtualReviewConfirmationComponent.selector,
    VirtualReviewConfirmationComponent
  )
  .component(
    InjectionCreateWarning.selector,
    InjectionCreateWarning
  )
  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
