import { GlToastrCategory } from "../toastr-appendix";

export const OBSERVATIONS_TOASTR_MESSAGES: GlToastrCategory = {
  success: {},
  error: {
    selection: {
      multi_select_not_allowed: {
        left: `Unable to copy value, please select a different value on the left observations first.`,
        right: `Unable to copy value, please select a different value on the right observations first.`,
      },
    },
  },
  info: {},
};
